import React, { Component } from "react";
import { API } from "../../../../API";
import Loading from "../../../../components/Loading";
import AutoComplete from "../../../../components/AutoComplete";
import { ToastContainer, toast } from "react-toastify";
import Products from "../../../../components/Products";
export class Product extends Component {
	constructor(props) {
		super(props);
		this.addList = this.addList.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.removeList = this.removeList.bind(this);
		this.save = this.save.bind(this);
		this.change = this.change.bind(this);
	}
	state = {
		loading: false,
		list: [],
		lote: false,
		productsLote: null,
	};
	async getProducts(e) {
		await this.setState({ list: e });
	}

	addList(e) {
		this.setState({
			list: this.state.list.concat(e),
		});
	}
	async change(e) {
		await this.setState({ productsLote: e.target.value });
	}
	removeList(index) {
		this.state.list.splice(index, 1);
		this.forceUpdate();
	}
	async saveLote() {
		var data_array = this.state.productsLote
			.replace(/(?:\r\n|\r|\n)/g, ",")
			.split(",");
		for (let i = 0; i < data_array.length; i++) {
			API.get("api/autocomplete/products/" + data_array[i])
				.then(async (response) => {
					this.addList(response.data);
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
		await this.setState({ lote: false });
	}
	save(i) {
		if (this.state.list.length > 1) {
			var text = " Produtos";
		} else {
			var text = " Produto";
		}
		var item = {
			products: this.state.list,
			value: this.state.list,
		};
		var itemView = {
			name: "Produtos",
			value: this.state.list.length + text,
			itemId: "products",
			modal: "#conditionModalProducts",
		};
		this.props.newCondition(item, itemView);
	}
	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalProducts"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalProducts"
				aria-hidden="true"
			>
				<div className="modal-dialog  modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Produtos</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body p-3">
							<Products getProducts={this.getProducts} externalProducts={[]} />
						</div>
						<div className="modal-footer product-modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success"
								onClick={() => this.save()}
								data-dismiss={this.state.lote ? "" : "modal"}
							>
								Adicionar {this.state.list.length ? this.state.list.length : 0}{" "}
								produtos
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Product;
