import React from "react";
import Button from "./Button";

export default function Empty(props) {
	return (
		<div className="empty">
			{props.orders ? (
				<div className="col-12 text-center">
					<img src={props.img} width="20%" />
					<h3>
						<b>Não há pedidos para os filtros inseridos</b>
					</h3>
				</div>
			) : (
				<div className="col-12 text-center">
					<img src={props.img} width="20%" />
					<h3>
						<b>Ops! Não tem nada por aqui...</b>
					</h3>
					<p>
						Você pode criar {props.genre} {props.name} clicando no botão abaixo
					</p>
					{props.tutorial ? (
						<Button
							type="button"
							className="btn btn-primary"
							onClick={() => props.onClick()}
						>
							Criar {props.name}
						</Button>
					) : (
						<>
							<a className="btn btn-primary" href={props.link}>
								Criar {props.name}
							</a>
							&nbsp;&nbsp;
						</>
					)}

					{props.isDefault ? (
						<Button
							type="button"
							className="btn btn-info light btn-icon"
							onClick={() => (window.location.href = "new-default")}
						>
							{props.isDefaultName}
						</Button>
					) : null}
					{props.import ? (
						<Button
							type="button"
							className="btn btn-info light btn-icon"
							onClick={() => (window.location.href = "/import")}
						>
							<span className="material-icons" style={{ fontSize: "22px" }}>
								arrow_upward
							</span>
						</Button>
					) : null}
					{props.importIn ? (
						<Button type="button" className="btn btn-info light btn-icon">
							<input
								type="file"
								onChange={props.onChange}
								style={{ opacity: 0, position: "absolute", zIndex: 1 }}
							/>
							<span className="material-icons" style={{ fontSize: "22px" }}>
								arrow_upward
							</span>
						</Button>
					) : null}
				</div>
			)}
		</div>
	);
}
