import React, { useEffect } from "react";
import DealersContext from "./providers/DealersContext";
import DealersList from "./DealersList";

export default function Dealers(props) {
	useEffect(() => {
		localStorage.removeItem("PARAMS");
		if (!props.match.params.page) {
			window.location.href = `/salespeople/1`;
		}
	}, [props.match.params]);

	return (
		<DealersContext>
			<DealersList page={parseInt(props.match.params.page)} />
		</DealersContext>
	);
}
