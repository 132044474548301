import React, { Component } from 'react';

class PreviewAppCarouselBanner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="app-list-banners ">
                <ul>
                    {this.props.banners.map((item, i) =>
                        <li className="active carousel-item" key={i}>
                            <div className="banner-body " style={{backgroundImage: "url(" + item.imageUrl + ")"}}></div>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
};

export default PreviewAppCarouselBanner;
