import React, { useState } from "react";
import "react-widgets/dist/css/react-widgets.css";

import FormDealers from "./components/FormDealers";
import DealersContext from "./providers/DealersContext";

export default function NewDealers() {
	return (
		<DealersContext>
			<div className="container dash-content">
				<FormDealers />
			</div>
		</DealersContext>
	);
}
