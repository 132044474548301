import React, { useEffect, useState, useCallback } from "react";
import { useVouchers } from "../providers/VouchersContext";
import Button from "../../../../../components/Button";
import Conditions from "./../Conditions";
import Tags from "./../Tags";
import Modality from "./../Modality";
import State from "./../State";
import Stores from "./../Stores";
import Payments from "./../Payments.jsx";
import FirstBuy from "./../FirstBuy";
import SingleUse from "./../SingleUse";
import MinimumValue from "./../MinimumValue";
import Cpf from "./../Cpf";
import Product from "./../Product";
import Cities from "./../Cities.jsx";
import CategoriesListVouchers from "../components/CategoriesListVouchers";
import MinimumQuantity from "./../MinimumQuantity";
import Modal from "../../../../../components/Modal";
import RepricedProducts from "../components/RepricedProducts";
import Loading from "../../../../../components/Loading";

export default function VouchersConditions({ editing, id }) {
	const { loading, coupon, setCoupon, voucher, setVoucher, getNewVouchers } =
		useVouchers();
	const [selectConditionModal, setSelectConditionModal] = useState("condition");
	const [conditionItem, setConditionItem] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [tags, setTags] = useState([]);
	const [sellers, setSellers] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [oneByUser, setOneByUser] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [minValue, setMinValue] = useState([]);
	const [minQuantity, setMinQuantity] = useState([]);
	const [firstUse, setFirstUse] = useState([]);
	const [shippingType, setShippingType] = useState([]);
	const [isNotApplyProductReprice, setIsNotApplyProductReprice] = useState([]);
	const [changeCities, setChangeCities] = useState("");
	const [conditionsList, setConditionsList] = useState([
		{ label: "Valor mínimo do carrinho", modal: "#conditionModalMinValue" }, //1
		{ label: "Categorias", modal: "#conditionModalCategories" }, //2
		{ label: "Produtos", modal: "#conditionModalProducts" }, //3
		{
			label: "Quantidade mínima de itens",
			modal: "#conditionModalMinQuantity",
		}, //4
		{ label: "Tags", modal: "#conditionModalTags" }, //5
		{ label: "Consumidores por CPF", modal: "#conditionModalDocuments" }, //6
		{ label: "Modalidade de compra", modal: "#conditionModalShippingType" }, //7
		{ label: "Utilização única", modal: "#conditionModalOneByUser" }, //8
		{ label: "Primeira compra", modal: "#conditionModalFirstUse" }, //9
		{ label: "Cidade", modal: "#conditionModalCities" }, //10
		{ label: "Estado", modal: "#conditionModalStates" }, //11
		{ label: "Loja", modal: "#conditionModalSellers" }, //12
		{ label: "Meio de pagamento", modal: "#conditionModalPaymentMethods" }, //13
		{
			label: "Não aplica desconto em produto remarcado?",
			modal: "#conditionModalIsNotApplyProductReprice",
		}, //14
	]);

	useEffect(() => {
		id && showCupom();
	}, [voucher]);

	const newCondition = (item, itemView) => {
		setConditionsList(
			conditionsList.filter((condition, i) => {
				return condition.modal !== itemView.modal;
			})
		);
		let name = itemView.itemId;
		setVoucher({ ...voucher, [name]: item.value });
		const condition = conditionItem.some(
			(items) => items.itemId === itemView.itemId
		);
		if (condition) {
			conditionItem.forEach((item, i) => {
				//Verificar se existe essa condições, e atualizar
				if (conditionItem[i].itemId === itemView.itemId) {
					conditionItem[i] = {
						name: itemView.name,
						value: itemView.value,
						itemId: itemView.itemId,
						modal: itemView.modal,
					};
					setConditionItem([...conditionItem]);
				}
			});
		} else {
			setConditionItem([...conditionItem, itemView]);
		}
	};

	const deleteCondition = (item, index) => {
		let name = item.itemId;
		setVoucher({ ...voucher, [name]: "" });
		conditionItem.splice(index, 1);
		setConditionsList([
			...conditionsList,
			{ label: item.name, modal: item.modal },
		]);
	};

	const showCupom = () => {
		const cupons = [
			{
				label: "Cpfs",
				value: voucher.documents.length,
				item: voucher.documents.length === 1 ? " Cpf" : " Cpfs",
				modal: "#documents",
			},
			{
				label: "Categorias",
				value: voucher.categories.length,
				item: voucher.categories.length === 1 ? " Categoria" : " Categorias",
				modal: "#categories",
			},
			{
				label: "Produtos",
				value: voucher.products.length,
				item: voucher.products.length === 1 ? " Produto" : " Produtos",
				modal: "#products",
			},
			{
				label: "Loja",
				value: voucher?.sellerId?.length,
				item: voucher?.sellerId?.length === 1 ? " Loja" : " Lojas",
				modal: "#sellers",
			},
			{
				label: "Coleção de produtos",
				value: voucher.tags.length,
				item: voucher.tags.length === 1 ? " Coleção" : " Coleções",
				modal: "#tags",
			},
			{
				label: "Estado",
				value: voucher.states.length,
				item: voucher.states.length === 1 ? " Estado" : " Estados",
				modal: "#states",
			},
			{
				label: "Cidades",
				value: voucher.cities.length,
				item: voucher.cities.length === 1 ? " Cidade" : " Cidades",
				modal: "#cities",
			},
			{
				label: "Meio de pagamento",
				value: voucher.paymentMethods.length,
				item:
					voucher.paymentMethods.length === 1
						? " Selecionado"
						: " Selecionados",
				modal: "#paymentMethods",
			},
			{
				label: "Utilização única",
				value: voucher.oneByUser ? "Sim" : "Não",
				item: "",
				modal: "#oneByUser",
			},
			{
				label: "Primeira compra",
				value: voucher.firstUse ? "Sim" : "Não",
				item: "",
				modal: "#firstUse",
			},
			{
				label: "Não aplica desconto em produto remarcado",
				value: voucher.isNotApplyProductReprice ? "Sim" : "Não",
				item: "",
				modal: "#isNotApplyProductReprice",
			},
			{
				label: "Modalidade de compra",
				value: voucher.shippingType === undefined ? "" : voucher.shippingType,
				item: voucher.shippingType === 1 ? " Modalidade" : " Modalidades",
				modal: "#shippingType",
			},
			{
				label: "Valor mínimo",
				value: voucher.minValue === undefined ? "" : voucher.minValue,
				item: ",00",
				modal: "#minValue",
			},
			{
				label: "Quantidade minima de itens",
				value: voucher.minQuantity,
				item: "",
				modal: "#minQuantity",
			},
		];
		setCoupon(cupons);
		setDocuments(voucher.documents);
		setCategories(voucher.categories);
		setProducts(voucher.products);
		setTags(voucher.tags);
		setSellers(!voucher.sellers ? [] : voucher.sellers);
		setStates(voucher.states);
		setCities(voucher.cities);
		setPaymentMethods(!voucher.paymentMethods ? [] : voucher.paymentMethods);
		setOneByUser(voucher.oneByUser);
		setFirstUse(voucher.firstUse);
		setIsNotApplyProductReprice(voucher.isNotApplyProductReprice);
		setShippingType(voucher.shippingType);
		setMinValue(voucher.minValue);
		setMinQuantity(voucher.minQuantity);
	};

	return (
		<>
			{editing ? (
				<>
					<label className="col-12 row pb-3" htmlFor="condicoes">
						<strong>Condições de uso</strong>
					</label>
					{coupon.map((cupom, i) => (
						<ul key={i} className="navbar-nav">
							{!cupom.value ? null : (
								<li className="nav-item pb-3">
									<div className="input-group col-5">
										<div className="input-group-prepend">
											<div className="input-group-text">{cupom.label}</div>
										</div>
										<input
											type="text"
											className="form-control"
											value={
												cupom.label === "Valor mínimo"
													? cupom.value.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
													  })
													: cupom.value + cupom.item
											}
										/>
										<button
											type="button"
											className="btn btn-white btn-sm btn-icon btn-action"
											style={{ marginLeft: "-45px", zIndex: "1" }}
											onClick={() => {}}
											data-toggle="modal"
											data-target={cupom.modal}
										>
											<i className="material-icons">remove_red_eye</i>
										</button>
									</div>
								</li>
							)}
						</ul>
					))}
				</>
			) : (
				<div className="form-group ">
					<label className="col-12 row" htmlFor="limiteUso">
						<strong>Condições de uso</strong>
					</label>
					{conditionItem.map((item, i) => (
						<div key={i} className="row mt-3">
							<div className="input-group col-5">
								<div className="input-group-prepend">
									<div className="input-group-text">{item.name}</div>
								</div>
								<input
									type="text"
									className="form-control"
									value={
										item.itemId === "minValue"
											? item.value.toLocaleString("pt-br", {
													style: "currency",
													currency: "BRL",
											  })
											: item.value
									}
								/>
							</div>
							<div className="col mt-2">
								<a
									href="#"
									className="text-dark"
									data-toggle="modal"
									data-target={item.modal}
								>
									{/* {item.modal} */}
									editar
								</a>
								<a
									href="#"
									className="ml-4 text-danger"
									onClick={() => deleteCondition(item, i)}
								>
									remover
								</a>
							</div>
						</div>
					))}
					<br />
					<div className="input-group col row">
						<Button
							className={
								conditionItem.length >= 14
									? `btn btn-light btn-icon-c-l`
									: `btn btn-primary btn-icon-c-l`
							}
							type="button"
							dataToggle="modal"
							dataTarget={conditionItem.length >= 14 ? "" : "#voucherModal"}
							dataDismiss="modal"
						>
							<i className="material-icons">add</i>
							Adicionar nova condição
						</Button>
					</div>
				</div>
			)}
			{/* Modal Conditions */}
			<div
				className="modal"
				id="voucherModal"
				tabindex="-1"
				role="dialog"
				aria-labelledby="voucherModal"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="voucherModal">
								Condições de Uso
							</h5>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body mt-3">
							<div className="modal-body conditions">
								<ul>
									{conditionsList.map((condition, i) => (
										<li
											key={i}
											data-toggle="modal"
											data-target={condition.modal}
											data-dismiss="modal"
										>
											{condition.label}
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Tags newCondition={newCondition} />
			<CategoriesListVouchers newCondition={newCondition} />
			<MinimumQuantity newCondition={newCondition} />
			<Product newCondition={newCondition} />
			<MinimumValue newCondition={newCondition} />
			<State newCondition={newCondition} />
			<Cities newCondition={newCondition} />
			<Stores newCondition={newCondition} />
			<Cpf newCondition={newCondition} />
			<Payments newCondition={newCondition} />
			<SingleUse newCondition={newCondition} />
			<RepricedProducts newCondition={newCondition} />
			<Modality newCondition={newCondition} />
			<FirstBuy newCondition={newCondition} />

			<Modal
				id={"documents"}
				title={"Cpfs"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{documents.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom.document}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"categories"}
				title={"Categoria"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{categories.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom.category.code}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"products"}
				title={"Produtos"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{products.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom.product.code}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"tags"}
				title={"Coleções de Produtos"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{tags.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom.tag.name}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"sellers"}
				title={"Lojas"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{sellers.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom?.sellers}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"states"}
				title={"Estados"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{states.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom.name}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"cities"}
				title={"Cidades"}
				content={
					<div className="modal-body tags checkbox-list">
						<ul className="navbar-nav">
							{cities.map((cupom, i) => (
								<li key={i} className="nav-item">
									<strong>{cupom.name}</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
			<Modal
				id={"paymentMethods"}
				title={"Meios de pagamentos"}
				content={
					<div className="modal-body tags checkbox-list mt-3">
						<ul className="navbar-nav">
							{paymentMethods.map((cupom, i) => (
								<li key={i} className="nav-item p-0 m-0">
									<strong>
										{cupom.type === 1
											? "Cartão de crédito (via APP)"
											: cupom.type === 2
											? "Na retirada"
											: cupom.type === 3
											? "Boleto (via APP)"
											: cupom.type === 6
											? "Pix"
											: ""}
									</strong>
									<hr />
								</li>
							))}
						</ul>
					</div>
				}
			/>
		</>
	);
}
