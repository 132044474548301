import React, { useState, useEffect } from "react";
import List from "../../../components/List/List";

export default function FreightList(props) {
	const columsList = [
		{
			name: "Modalidade",
			selector: "name",
			sortable: true,
			width: "10%",
			height: "80px",
			style: {
				overflow: "visible",
				color: "white",
			},
			cell: (row) => (
				<div className="col">
					<div className="d-flex justify-content-center bg-success">
						<button
							type="button"
							className="btn btn-light btn-sm btn-icon bg-white"
						>
							<i
								className="material-icons"
								style={{ fontSize: "20px", fontWeight: "bold", padding: "0" }}
							>
								{row.icon}
							</i>
						</button>
					</div>
				</div>
			),
		},
		{
			name: "",
			selector: "name",
			sortable: false,
			width: "65%",
			style: {
				overflow: "hidden",
				wordBreak: "break-word",
			},
			cell: (row) => (
				<div className="col">
					<div>
						<h6
							className="font-weight-bold"
							style={{ wordBreak: "break-word" }}
						>
							{row.name}
						</h6>
					</div>
				</div>
			),
		},
		{
			name: "Status",
			sortable: true,
			center: true,
			selector: "isActive",
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-primary stadium w-100">ATIVO</span>
				) : (
					<span className="badge badge-danger stadium w-100">INATIVO</span>
				),
		},
		{
			name: "Ações",
			sortable: true,
			center: true,
			width: "150px",
			cell: (row) => (
				<div className="actions d-flex align-items-center justify-content-center">
					<div className="btn-group" role="group" aria-label="Basic example">
						<button
							type="button"
							className="btn btn-light btn-sm btn-icon bg-white"
							onClick={() => (window.location.href = "freight/edit/" + row.id)}
						>
							<i
								className="material-icons text-muted"
								style={{ fontSize: "20px", padding: "0" }}
							>
								create
							</i>
						</button>
						<button
							type="button"
							className="btn btn-light btn-sm btn-icon bg-white"
							onClick={() =>
								(window.location.href = "new-carousel/edit/" + row.id)
							}
						>
							<i
								className="material-icons text-danger"
								style={{ fontSize: "20px", padding: "0" }}
							>
								delete_outline
							</i>
						</button>
					</div>
				</div>
			),
		},
	];
	const itemsList = [
		{
			imageUrl: "local_shipping",
			name: "PAC Correios",
			isActive: true,
			icon: "local_shipping",
		},
		{
			imageUrl: "",
			name: "SEDEX Correios",
			isActive: false,
			icon: "drive_eta",
		},
		{
			imageUrl: "",
			name: "SEDEX 10 Correios",
			isActive: true,
			icon: "local_airport",
		},
		{
			imageUrl: "",
			name: "Transportadora",
			isActive: false,
			icon: "moped",
		},
		{
			imageUrl: "",
			name: "Internacional",
			isActive: true,
			icon: "directions_walk",
		},
		{
			imageUrl: "",
			name: "Motoboy",
			isActive: true,
			icon: "directions_bike",
		},
		{
			imageUrl: "",
			name: "Loggi",
			isActive: true,
			icon: "local_shipping",
		},
	];

	useEffect(() => {
		localStorage.removeItem("PARAMS");
	}, []);

	const handleNewFreight = () => {
		console.log("Novo Frete");
	};

	return (
		<div className="container">
			<div className="card mb-3">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">{` Fretes Disponíveis (${itemsList.length}) `}</h4>
					<div className="card-actions text-right">
						<button type="button" className="btn btn-primary">
							Adicionar Novo
						</button>
					</div>
				</div>
				<div className="card-body">
					{!itemsList ? (
						<div className="">
							<p className="text-freight">
								Você ainda não possui modalidades de frete cadastradas!
							</p>
							<p className="text-freight mb-3">
								Clique em aqui para buscar as melhores opções de frete para a
								sua empresa!
							</p>
						</div>
					) : (
						<div className="box-table">
							<table className="table history-import">
								<tbody>
									<tr>
										<th className="text-start">MODALIDADE</th>
										<th className="text-center"></th>
										<th className="text-center">STATUS</th>
										<th className="text-center">AÇÕES</th>
									</tr>
									{itemsList.map((item, i) => (
										<tr key={i}>
											<td
												className="text-center"
												style={{ width: "10%", height: "60px" }}
											>
												<button
													type="button"
													className="btn btn-light btn-sm btn-icon bg-white"
												>
													<i
														className="material-icons"
														style={{
															fontSize: "20px",
															fontWeight: "bold",
															padding: "0",
														}}
													>
														{item.icon}
													</i>
												</button>
											</td>
											<td className="text-start" style={{ width: "70%" }}>
												<h6
													className="font-weight-bold"
													style={{ wordBreak: "break-word" }}
												>
													{item.name}
												</h6>
											</td>
											<td className="text-end" style={{ width: "10%" }}>
												{item.isActive === true ? (
													<span className="badge badge-primary stadium w-100">
														ATIVO
													</span>
												) : (
													<span className="badge badge-danger stadium w-100">
														INATIVO
													</span>
												)}
											</td>
											<td className="text-center" style={{ width: "10%" }}>
												<div className="actions d-flex align-items-center justify-content-center">
													<div
														className="btn-group"
														role="group"
														aria-label="Basic example"
													>
														<button
															type="button"
															className="btn btn-light btn-sm btn-icon bg-white"
															onClick={() =>
																(window.location.href =
																	"new-carousel/edit/" + item.id)
															}
														>
															<i
																className="material-icons text-muted"
																style={{ fontSize: "20px", padding: "0" }}
															>
																create
															</i>
														</button>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>

			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">{` Formas de Envios Personalizadas (${itemsList.length}) `}</h4>
					<div className="card-actions text-right">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => (window.location.href = "/freight/new")}
						>
							Adicionar Novo
						</button>
					</div>
				</div>
				<div className="card-body">
					{!itemsList ? (
						<p className="text-freight mb-3">
							Você ainda não possui Modalidades de Envio Personalizadas
							cadastradas
						</p>
					) : (
						<List data={itemsList} colums={columsList} />
					)}
				</div>
			</div>
		</div>
	);
}
