/* eslint-disable react/no-direct-mutation-state */
import React, { useEffect, useState } from 'react';
import ContentLoader from "react-content-loader"
import { APIV2 } from '../../../API';
import Modal from '../../../components/Modal';
import SelectStores from '../../../components/SelectStores';


export default function SearchList(props) {
	const [sellers, setSellers] = useState([])
	const [list, setList] = useState([])
	
	const [displayedContacts, setDisplayedContacts] = useState(null)
	const [deleteId, setDeleteId] = useState(null)
	const [editStock, setEditStock] = useState(null)
	
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(true)
	const [skuId, setSkuId] = useState(null)

	const [sellerId, setSellerId] = useState(null)
	const [storeCnpj, setStoreCnpj] = useState(null)
	const [quantity, setQuantity] = useState(null)
	const [stock, setStock] = useState([])


	useEffect(() => {
		setSkuId(props.skuId)
		APIV2.get('management/catalog/sellers')
			.then(async response => {
				setSellers(response.data)
				setLoading(false)
			})
			.catch((error) => {
				console.log(error)
				setLoading(false)
			})
	}, [])

	const searchHandler = (event) => {
		let searcjQery = event.target.value.toLowerCase(),
			displayedContacts = list.filter((el) => {
				let searchValue = el.seller.fullName.toLowerCase();
				return searchValue.indexOf(searcjQery) !== -1;
			})
		setDisplayedContacts(displayedContacts)
	}

	const selectItem = (id) => {
		for (var i in list) {
			if (list[i].id == id) {
				list[i].select = !list[i].select;

				if (props.change != undefined && props.change != null && list[i].select == true) {
					props.change(list[i])
				}
			}
		}
	}

	const getIdDelete = (e) => {
		setDeleteId(e)
	}

	const deleteItem = () => {
		var id = deleteId
		props.delete(id)
	}

	const getEdit = (id, stock) => {
		setEdit(true)
		setEditStock(id)
		setQuantity(stock.quantity)
		setSellerId(stock.sellerId)
	}

	const editItem = () => {
		var body = {
			quantity: parseInt(quantity),
			skuId: skuId,
			sellerId: sellerId,
			id: editStock
		}
		setEdit(false)
		
		//console.log(JSON.stringify(body))
		props.edit(body)
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		}, 3000);
	}

	const createStock = () => {
		
		const body = { 
			sellerId: sellerId,
			quantity: quantity
		}

		setStock([ ...stock, body ])


	}

	const skeleton = () => {
		const skele = [0, 1, 2,]
		return (
			skele.map(() =>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: '100%' }}

				>
					<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
					<rect x="500" y="26" rx="3" ry="3" width="52" height="10" />
					<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
				</ContentLoader>
			)

		)
	}

	return (
        <div className="container dash-content mt-0">
            <div className="card">
                <div className="card-content">
                    <div className="card-header">
                        <h4 className="card-title">Estoques de 1234566789</h4>
                    </div>
                    <div className="">
                        <div className='row px-4'>
                            <div className="form-group autocomplete icon-right col-10">
                                <input type="text" className="search form-control" name='pesquisa' onChange={searchHandler} placeholder={props.placeholder} />
                                <i className="material-icons" style={{ right: '25px' }}>search</i>
                            </div>
                            
                            <button className='btn btn-primary d-flex justify-content-center align-items-center mr-2' data-toggle="modal" data-target="#newSku" style={{ padding: '5px 30px', height:'40px'}}>
                                <i className="material-icons">add</i>Novo Estoque
                            </button>
                        </div>
                    </div>
				
                    {/* <div className='col-12 text-center mt-5 mb-5'>
                        <div className='mt-1 '>
                            <h3>Nenhum estoque</h3>
                        </div>
                        <div className='mt-2'>
                            <button className='btn btn-primary' onClick={() => props.change()}>Adicionar novo</button>
                        </div>
                    </div> */}

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Ponto de venda</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        {stock.map((stock, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        {editStock == stock.id && edit == true 
											? <>
												<td>
													<SelectStores storeCnpj={storeCnpj} setStoreCnpj={e => setStoreCnpj(e)}/>
												</td>
												<td>
													<input type="number" className="form-control" id="quantity" name="quantity" defaultValue={stock.quantity} onChange={(e) => setQuantity(e.target.value)} />
												</td> 
											</>
											: <>
												<td><SelectStores storeCnpj={storeCnpj} setStoreCnpj={e => setStoreCnpj(e)}/></td>
												<td> {stock.quantity}</td>
											</>
                                        }
                                        
										<td className='text-right'>
                                            <div className="btn-group" role="group">
                                                {editStock == stock.id && edit == true ?
                                                    <button type="button" className="btn btn-light btn-icon outline btn-sm" onClick={() => editItem()}>
                                                        <i className="material-icons">save</i>
                                                    </button> : null
                                                }
                                                <button type="button" className="btn btn-light btn-icon outline btn-sm" onClick={() => getEdit(stock.id, stock)}>
                                                    <i className="material-icons">create</i>
                                                </button>
                                                <button type="button" className="btn btn-light outline btn-icon btn-sm text-danger" data-toggle="modal" data-target="#deleteSku" onClick={() => getIdDelete(stock.id)}><i className="material-icons">
                                                    delete_outline</i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                </div>
			</div>
            <Modal
				id='newSku'
				title={'Novo Estoque'}
                large={true}
				create={true}
				//edit={deleteItem}
				content={
                    <div className="row">
						<div className="form-group col-8">
                            <label htmlFor="oldPrice"><strong>Ponto de Venda</strong></label>
                            <SelectStores storeCnpj={storeCnpj} setStoreCnpj={e => setStoreCnpj(e)}/>
						</div>
                        <div className="form-group col-4">
                            <label htmlFor="oldPrice"><strong>Quantidade</strong></label>
							<input type="number" min="0" className="form-control" id="minStock" placeholder="0" name='minStock' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                        </div>
				    </div>
                }
				//buttonId={createStock}
				actionCreate={createStock}
				//actionEdit={deleteItem}
			/>
			<Modal
				id={'deleteSku'}
				title={'Certeza que deseja excluir este item?'}
				delete={true}
				actionDelete={deleteItem}
			/>
		</div>
	)
};
