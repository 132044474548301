import React, { useState } from 'react';
import Home from './components/Home/Home';

// import { Container } from './styles';

function AppEditor() {

  const [tab, setTab] = useState('home')

  const activeTab = (type) => {
    switch (type) {
      case 'home':
        setTab('home')
        break;
      case 'options':

        setTab('options')
        break;
      case 'tutorials':
        setTab('tutorials')

        break;
      case 'terms':
        setTab('terms')

        break;
      case 'generalConfigs':
        setTab('generalConfigs')

        break;
      default:
        setTab('home')
        break;
    }
  }


  const renderComponent = () => {
    switch (tab) {
      case 'home':
        return <Home/>
      case 'options':

        // setTab('options')
        break;
      case 'tutorials':
        // setTab('tutorials')

        break;
      case 'terms':
        // setTab('terms')

        break;
      case 'generalConfigs':
        // setTab('generalConfigs')

        break;
      default:
        setTab('home')
        break;
    }
  }

  return (
    <>
      <div className="container">
        <div className="detail">
          <div className="detail-tabs">
            <button onClick={() => activeTab('home')} className={tab === 'home' && "actived"}>Home/Início</button>
            <button onClick={() => activeTab('options')} className={tab === 'options' && "actived"}>Opções</button>
            <button onClick={() => activeTab('tutorials')} className={tab === 'tutorials' && "actived"}>Tutorial</button>
            <button onClick={() => activeTab('terms')} className={tab === 'terms' && "actived"}>Termo de uso</button>
            <button onClick={() => activeTab('generalConfigs')} className={tab === 'generalConfigs' && "actived"}>Configurações Gerais</button>
          </div>
        </div>
      </div>
      <div className="container my-3">
        {renderComponent()}
      </div>
    </>
  );
}

export default AppEditor;