import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { APIV2 } from '../../../API';
import { toast } from 'react-toastify';
import axios from 'axios'

export default function Filter(props) {
   const [customStatus, setCustomStatus] = useState('')
   const [runtimeStatus, setRuntimeStatus] = useState('')
   const [output, setOutput] = useState('')
   const [load, setLoad] = useState(false)
   const [isActive, setIsActive] = useState(props.isActive == null ? "" : props.isActive)
   const [isAvailable, setIsAvailable] = useState(props.isAvailable == null ? "" : props.isAvailable )
   const [hasStock, setHasStock] = useState(props.hasStock == null ? "" : props.hasStock)
   const [hasPrice, setHasPrice] = useState(props.hasPrice == null ? "" : props.hasPrice)
   const [hasImage, setHasImage] = useState(props.hasImage == null ? "" : props.hasImage)
   const [query, setQuery] = useState(props.query == null ? "" : props.query)
   const history = useHistory();
   const [labelEmpty, setLabelEmpty] = useState(null)
   const [labelFilter, setLabelFilter] = useState(false)
   const [labelClear, setLabelClear] = useState(false)
   const [reload, setReload] = useState(false)
   const [count, setCount] = useState(1);
   const [color, setColor] = useState(false)
   const [filter, setFilter] = useState({
      isActive: isActive,
      isAvailable: isAvailable,
      hasStock: hasStock,
      hasPrice: hasPrice,
      hasImage: hasImage
   })
   const [clearfilter, setClearFilter] = useState({
      isActive: '',
      isAvailable: '',
      hasStock: '',
      hasPrice: '',
      hasImage: '',
   })

   useEffect(() => {
      //getExportFilterProducts()

      if(isActive !== "" || isAvailable !== "" || hasStock !== "" || hasPrice !== "" || hasImage!== "") {
         setLabelFilter(true)
         setColor(true)
         setLabelClear(false)
         setOutput("")
         setRuntimeStatus("")
      }else {
         setLabelFilter(true)
         setLabelClear(false)
      }

      const a = isActive !== '' ? 1 : 0
      const b = isAvailable !== '' ? 1 : 0
      const c = hasStock !== '' ? 1 : 0
      const d = hasPrice !== '' ? 1 : 0
      const e = hasImage !== '' ? 1 : 0
      setCount(a + b + c + d + e)

      setFilter({...filter,
         isActive: isActive,
         isAvailable: isAvailable,
         hasStock: hasStock,
         hasPrice: hasPrice,
         hasImage: hasImage
      })

   }, [isActive, isAvailable, hasStock, hasPrice, hasImage])

   const handleClearFilter = () => {
      if(count === 0) {
         setLabelClear(false)
         setColor(false)
         props.searchFilter(filter)   
      }else {
         props.searchFilter(filter)
         setLabelFilter(false)
         setLabelClear(true)
      }
   }

   const clearSearchFilter = (e) => {
      setColor(false)
      setCount(0)
      setFilter({
         isActive: "",
         isAvailable: "",
         hasStock: "",
         hasPrice: "",
         hasImage: "",
      })
      setIsActive("")
      setIsAvailable("")
      setHasStock("")
      setHasPrice("")
      setHasImage("")
      setLabelEmpty(null)
      setLabelFilter(false)
      setLabelClear(false)
      setReload(true)
      props.searchFilter(clearfilter)
   }

   const getExportFilterProducts = async () => {
      await APIV2.get(`insights/export/products?clientId=${localStorage.getItem('clientId')}&hasPrice=${hasPrice}&hasStock=${hasStock}&isActive=${isActive}&isAvailable=${isAvailable}&hasImage=${hasImage}&query=${query}`)
         .then(response => {
            setLoad(true)
            var exportFilter = setInterval(async () => {
               const resp = await axios.get(response.data.statusQueryGetUri)
               setCustomStatus(resp.data.customStatus)
               console.log(resp.data.customStatus)
               setRuntimeStatus(resp.data.runtimeStatus)
               console.log(resp.data.runtimeStatus)
               setOutput(resp.data.output)
               console.log(resp.data.output)
               
               if(resp.data.output !== null ) {
                  clearInterval(exportFilter);
                  document.getElementById("exportList").click()
                  setLoad(false)
               }
               if(resp.data.runtimeStatus === "Completed"){
                  toast.success('Download realizado com sucesso!😃');
               }
            }, 2000)

            
         })
         .catch((error) => {
            console.log(error)
            toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
         })
   }

   return (
      <div className="d-flex mt-4">
         <div className="container m-0 px-1">
            <div className="d-flex flex-wrap justify-content-between mx-3">
               <div className="d-flex flex-column">
                  <label className="" htmlFor="isActive"><strong>Produtos</strong></label>
                  <select className="custom-select" id="isActive" name='isActive' value={isActive} onChange={e => {setIsActive(e.target.value)}} style={{ width: '200px' }}>
                        <option value="">MOSTRANDO TODOS</option>
                        <option value="true">SOMENTE ATIVOS</option>
                        <option value="false">SOMENTE INATIVOS</option>
                  </select>
               </div>

               <div className="d-flex flex-column">
                  <label className="" htmlFor="hasStock"><strong>Disponibilidade em estoque</strong></label>
                  <select className="custom-select" id="hasStock" name='hasStock' value={hasStock} onChange={e => {setHasStock(e.target.value)}} style={{ width: '200px' }}>
                        <option value="">MOSTRANDO TODOS</option>
                        <option value="true">SOMENTE EM ESTOQUE</option>
                        <option value="false">SOMENTE SEM ESTOQUE</option>
                  </select>
               </div>

               <div className="d-flex flex-column">
                  <label className="" htmlFor="hasPrice"><strong>Preço em produtos</strong></label>
                  <select className="custom-select" id="hasPrice" name='hasPrice' value={hasPrice} onChange={e => {setHasPrice(e.target.value)}} style={{ width: '200px' }}>
                        <option value="">MOSTRANDO TODOS</option>
                        <option value="true">COM PREÇO CADASTRADO</option>
                        <option value="false">SEM PREÇO CADASTRADOS</option>
                  </select>
               </div>

               <div className="d-flex flex-column">
                  <label className="" htmlFor="isAvailable"><strong>Disponibilidade para venda</strong></label>
                  <select className="custom-select" id="isAvailable" name='isAvailable' value={isAvailable} onChange={e => {setIsAvailable(e.target.value)}} style={{ width: '200px' }}>
                        <option value="">MOSTRANDO TODOS</option>
                        <option value="true">DISPONÍVEL PARA VENDAS</option>
                        <option value="false">INDISPONÍVEL PARA VENDAS</option>
                  </select>
               </div>
               
               <div className="d-flex flex-column">
                  <label className="" htmlFor="hasImage"><strong>Produtos sem foto</strong></label>
                  <select className="custom-select" id="hasImage" name='hasImage' value={hasImage} onChange={e => {setHasImage(e.target.value)}} style={{ width: '200px' }}>
                        <option value="">MOSTRANDO TODOS</option>
                        <option value="true">PRODUTOS COM IMAGENS</option>
                        <option value="false">PRODUTOS SEM IMAGENS</option>
                  </select>
               </div>

            </div>
            <div className="d-flex p-3">
               <div className="d-flex align-items-center" style={{ width: '100%' }}><strong>MOSTRANDO { props.totalProducts } RESULTADOS</strong></div>
               <div style={{ width: '100%'}}>
                  <div className="row m-0 d-flex justify-content-end">
                     {load === true
                           ?  <> 
                              <a href={ output } id="exportList" className="d-none" style={{ padding: '10px 20px'}}></a>
                              <button disabled className="btn btn-outline btn-icon mr-2 d-flex justify-content-center" style={{ padding: '10px', border: '1px solid #E9ECEC', background: 'transparent', color: '#747E8B', width: '200px', cursor: 'not-allowed'}} >
                                 <><i className="fas fa-spinner fa-spin"></i><span className="p-0 m-0" style={{ fontWeight: '500' }}>&nbsp;&nbsp;Processando...</span></>
                              </button>
                              </>
                           :  <>
                              <a href={ output } id="exportList" className="d-none" style={{ padding: '10px 20px'}}></a>
                              <button className="btn btn-outline btn-icon mr-2 d-flex justify-content-center" style={{ padding: '10px', border: '1px solid #E9ECEC', background: 'transparent', color: '#747E8B', width: '200px'}} onClick={() => getExportFilterProducts()} >
                                 <><i className="material-icons">get_app</i><span className="p-0 m-0" style={{ fontWeight: '400' }}>&nbsp;&nbsp;Exportar Produtos</span></> 
                              </button>
                              </> 
                     }
                     
                     {labelFilter === true 
                        ? <button className={color === true ? "btn btn-primary btn-icon d-flex justify-content-center" : "btn btn-outline btn-icon d-flex justify-content-center"} onClick={() => handleClearFilter() } style={{ padding: '10px 20px', width: '200px'}}>
                           Aplicar filtros ({count}) 
                        </button>
                        : null
                     }

                     {labelClear === true 
                        ? <button className="btn btn-outline btn-icon d-flex justify-content-center" onClick={() => clearSearchFilter() } style={{ padding: '10px 20px', border: '1px solid #E9ECEC', background: '#E9ECEC',width: '200px', color: '#747E8B'}}>
                           <i className="material-icons">delete_sweep</i>&nbsp;&nbsp;Limpar filtros ({count})
                        </button>
                        : null
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
