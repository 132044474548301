import React, { Component } from "react";
export class Modality extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		id: "",
		loading: false,
		shippingType: "",
		modality: "",
		delivery: false,
		inStore: false,
	};

	onChange(modality) {
		if (modality === "0") {
			this.setState({
				delivery: false,
				inStore: true,
			});
		} else {
			this.setState({
				delivery: true,
				inStore: false,
			});
		}
		this.setState({ modality: modality });
		this.forceUpdate();
	}

	save(i) {
		var item = {
			value: this.state.modality,
		};

		var text = " Modalidade";

		var itemView = {
			name: "Modalidade de compra",
			value: this.state.modality.length + text,
			itemId: "shippingType",
			modal: "#conditionModalShippingType",
		};
		this.props.newCondition(item, itemView);
	}

	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalShippingType"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalShippingType"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Modalidade de compra</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="custom-control custom-checkbox my-1 mr-sm-2">
								<input
									type="checkbox"
									checked={this.state.inStore}
									className="custom-control-input"
									id="customControlInline10"
									value={this.id}
									onChange={() => this.onChange("0")}
								/>
								<label
									className="custom-control-label"
									for="customControlInline10"
								>
									Retira na loja
								</label>
							</div>

							<div className="custom-control custom-checkbox my-1 mr-sm-2">
								<input
									type="checkbox"
									checked={this.state.delivery}
									className="custom-control-input"
									id="customControlInline11"
									defaultValue={this.id}
									onChange={() => this.onChange("1")}
								/>
								<label
									className="custom-control-label"
									for="customControlInline11"
								>
									Recebe em casa
								</label>
							</div>
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col-5"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col-5"
								onClick={() => this.save()}
								data-dismiss="modal"
							>
								Pronto
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Modality;
