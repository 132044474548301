import React, { Component } from 'react';
import ButtonPrimary from '../../assets/ButtonPrimary';
import {API} from '../../API';
import Loading from '../../components/Loading'
require('dotenv/config');

export class Welcome extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nome: '',
      loading:true,
    }
  }


  componentWillMount() {


    //Consulta materiais disponíveis a partir do token do usuário.
    API.get('api/autosetup/retailers/')
      .then(response => {
        // If request is good...
    
        this.setState({
          nome: response.data.fullName,
          loading:false
        })

        localStorage.setItem('retailerId', response.data.id)
      
        this.forceUpdate();
      })
      .catch((error) => {
        console.log(error)
      })
  }
  renderScreen() {
    return (
      <div className="welcome d-flex align-items-center justify-content-center">
        <div className="col-xl-2 col-lg-4 col-md-6 align-self-center justify-content-end lori">
          <img src="/images/lori@2x.png" alt="Lori" />
        </div>
        <div className="col-4 text-align-center">
          <h1>Bem-vinda {this.state.nome} a plataforma<br />
            <b>LORI</b> da <img src="/images/logo.png" /></h1>
          <p>Estamos muito felizes de ter vocês com a gente, aqui vamos te guiar por alguns passos
            para iniciar sua jornada omnichannel.
          </p>
          <br />
          {/* Botão primario presente em assets */}
          <ButtonPrimary title='Começar' icon='arrow_forward' link={'/setup/onboarding/materials'} />
        </div>
      </div>
    )
  }
  render() {
    if(this.state.loading){
      return (
        <Loading/>
      )
    }
    else{
      return (
      this.renderScreen()
      )
    }
   
  }
};


export default Welcome;