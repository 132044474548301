import React, { Component } from 'react';
import {API} from '../../../API'
import Modal from '../../../components/Modal'
export class Delete extends Component {
  constructor(props) {
    super(props);
  }

  delete() {
 
    API.delete('api/autosetup/sellers/' + this.props.id)
      .then(async response => {

        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
      })
  }
  render() {
    return (

      <Modal
        id={'modalExemplo'}
        title={'Você está excluindo o item permanentemente, tem certeza disso?'}
        delete={true}
        actionDelete={this.delete}
      />
    )
  }
};

export default Delete;
