import React, { useCallback, useEffect, useState } from "react";
import { useOrders } from "../providers/OrdersContext";

function FilterOrdersModal({ filterSelect }) {
	const { updateFilterData, reloadFilter } = useOrders();
	const [count, setCount] = useState(0);
	const [status, setStatus] = useState({ name: "", value: "" });
	const [isDelivery, setIsDelivery] = useState({ name: "", value: "" });
	//const [payment, setPayment] = useState({ name: "", value: "" });
	const [channel, setChannel] = useState({ name: "", value: "" });

	const [statusOrders, setStatusOrders] = useState([
		{ checked: true, status: "", name: "Todos" },
		{ checked: false, status: "InProcessing", name: "Pedido em Andamento" },
		{ checked: false, status: "Finalized", name: "Pedido Finalizado" },
		{ checked: false, status: "Canceled", name: "Pedido Cancelado" },
		{ checked: false, status: "Error", name: "Pedido com Erro" },
	]);

	const [modality, setModality] = useState([
		{ checked: true, modality: "", name: "Todos" },
		{ checked: false, modality: "true", name: "Receba em Casa" },
		{ checked: false, modality: "false", name: "Retire na Loja" },
		/* { checked: false, modality: "2", name: "Drive Thru" }, */
	]);

	// const [paymentStatus, setPaymentStatus] = useState([
	//   { checked: false, paymentStatus: "", name: "Todos" },
	//   { checked: false, paymentStatus: "Aprove", name: "Aprovado" },
	//   { checked: false, paymentStatus: "Pending", name: "Pendente" },
	//   { checked: false, paymentStatus: "canceled", name: "Cancelado" },
	//   {
	//     checked: false,
	//     paymentStatus: "error",
	//     name: "Carência para Cancelamento",
	//   },
	// ]);

	useEffect(() => {
		const a = status.value !== "" ? 1 : 0;
		const b = isDelivery.value !== "" ? 1 : 0;
		const c = channel.value !== "" ? 1 : 0;
		//const d = payment.value !== "" ? 1 : 0;
		setCount(a + b + c);
	}, [status, isDelivery, channel, updateFilterData, reloadFilter]);

	useEffect(() => {
		getStatusOrders();
		getModalities();
		getChannel();
	}, [updateFilterData]);

	const getStatusOrders = () => {
		if (updateFilterData.status) {
			setStatusOrders([
				{ checked: true, status: "", name: "Todos" },
				{ checked: false, status: "InProcessing", name: "Pedido em Andamento" },
				{ checked: false, status: "Finalized", name: "Pedido Finalizado" },
				{ checked: false, status: "Canceled", name: "Pedido Cancelado" },
				{ checked: false, status: "Error", name: "Pedido com Erro" },
			]);
			setStatus({ name: status.name, value: "" });
		} else {
			setStatusOrders([
				{
					checked: reloadFilter.status === "" ? true : false,
					status: "",
					name: "Todos",
				},
				{
					checked: reloadFilter.status === "InProcessing" ? true : false,
					status: "InProcessing",
					name: "Pedido em Andamento",
				},
				{
					checked: reloadFilter.status === "Finalized" ? true : false,
					status: "Finalized",
					name: "Pedido Finalizado",
				},
				{
					checked: reloadFilter.status === "Canceled" ? true : false,
					status: "Canceled",
					name: "Pedido Cancelado",
				},
				{
					checked: reloadFilter.status === "Error" ? true : false,
					status: "Error",
					name: "Pedido com Erro",
				},
			]);
			setStatus({ name: status.name, value: reloadFilter.status });
		}
	};

	const getModalities = () => {
		if (updateFilterData.isDelivery) {
			setModality([
				{ checked: true, modality: "", name: "Todos" },
				{ checked: false, modality: "true", name: "Receba em Casa" },
				{ checked: false, modality: "false", name: "Retire na Loja" },
			]);
			setIsDelivery({ name: isDelivery.name, value: "" });
		} else {
			setModality([
				{
					checked: reloadFilter.isDelivery === "" ? true : false,
					modality: "",
					name: "Todos",
				},
				{
					checked: reloadFilter.isDelivery === "true" ? true : false,
					modality: "true",
					name: "Receba em Casa",
				},
				{
					checked: reloadFilter.isDelivery === "false" ? true : false,
					modality: "false",
					name: "Retire na Loja",
				},
			]);
			setIsDelivery({ name: isDelivery.name, value: reloadFilter.isDelivery });
		}
	};

	const getChannel = () => {
		if (updateFilterData.channel) {
			setChannel({ value: "" });
		} else {
			setChannel({ value: reloadFilter.channel });
		}
	};

	const changeStatus = useCallback((e) => {
		const arrData = [];
		statusOrders.forEach((data) => {
			if (e.target.value === data.status) {
				const body = {
					checked: e.target.checked,
					status: data.status,
					name: data.name,
				};
				setStatus({ name: data.name, value: data.status });
				arrData.push(body);
			} else {
				const body = {
					checked: false,
					status: data.status,
					name: data.name,
				};
				arrData.push(body);
			}
		});
		setStatusOrders(arrData);
	}, []);

	const changeModality = useCallback((e) => {
		const arrData = [];
		modality.forEach((data) => {
			if (e.target.value === data.modality) {
				const body = {
					checked: e.target.checked,
					modality: data.modality,
					name: data.name,
				};
				setIsDelivery({ name: data.name, value: data.modality });
				arrData.push(body);
			} else {
				const body = {
					checked: false,
					modality: data.modality,
					name: data.name,
				};
				arrData.push(body);
			}
		});
		setModality(arrData);
	}, []);

	// const changePaymentStatus = useCallback((e) => {
	//   const arrData = [];
	//   paymentStatus.forEach((data) => {
	//     if (e.target.value === data.paymentStatus) {
	//       const body = {
	//         checked: e.target.checked,
	//         paymentStatus: data.paymentStatus,
	//         name: data.name,
	//       };
	//       setPayment({ name: data.name, value: data.paymentStatus });
	//       arrData.push(body);
	//     } else {
	//       const body = {
	//         checked: false,
	//         paymentStatus: data.paymentStatus,
	//         name: data.name,
	//       };
	//       arrData.push(body);
	//     }
	//   });
	//   setPaymentStatus(arrData);
	// }, []);

	const getFilterSelect = () => {
		const body = [
			{ name: status.name, value: status.value },
			{ name: isDelivery.name, value: isDelivery.value },
			{
				name:
					channel.value === "android"
						? "Android"
						: channel.value === "ios"
						? "IOS"
						: "Marketplace",
				value: channel.value,
			},
			//{ name: payment.name, value: payment.value },
		];

		const filter = [];
		body.forEach((data) => {
			if (data.value !== "") {
				filter.push({ name: data.name, value: data.value });
			}
		});

		const filterApi = {
			status: status.value,
			isDelivery: isDelivery.value,
			channel: channel.value,
		};

		filterSelect(filter, filterApi);
	};

	return (
		<div
			className="modal fade"
			id="filterOrders"
			tabindex="-1"
			aria-labelledby="filterOrdersLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "500px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "500px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="modal-title mb-2">Selecionar e aplicar filtros</h5>
						<p style={{ color: "#747E8B", fontSize: "14px" }}>
							Escolha os filtros nas opções abaixo para refinar seus resultados.
						</p>
					</div>
					<div className="modal-body">
						<div className="d-flex flex-row justify-content-start">
							<div className="">
								<label className="card-title" style={{ fontSize: "13px" }}>
									Status do Pedido
								</label>
								{statusOrders.map((data, i) => (
									<div
										key={i}
										className="custom-control custom-checkbox my-1 mr-sm-2"
									>
										<input
											type="checkbox"
											checked={data.checked}
											className="custom-control-input"
											id={`s${data.status}`}
											defaultValue={data.status}
											onChange={changeStatus}
										/>
										<label
											style={{ cursor: "pointer" }}
											className="custom-control-label"
											for={`s${data.status}`}
										>
											{data.name}
										</label>
									</div>
								))}
							</div>

							{localStorage.getItem("role") !== "8" && (
								<>
									<div className="mx-3">
										<label className="card-title" style={{ fontSize: "13px" }}>
											Modalidade
										</label>
										{modality.map((data, i) => (
											<div
												key={i}
												className="custom-control custom-checkbox my-1 mr-sm-2"
											>
												<input
													type="checkbox"
													checked={data.checked}
													className="custom-control-input"
													id={`m${data.modality}`}
													value={data.modality}
													onChange={changeModality}
												/>

												<label
													style={{ cursor: "pointer" }}
													className="custom-control-label"
													for={`m${data.modality}`}
												>
													{data.name}
												</label>
											</div>
										))}
									</div>
									{/* <div className="">
                  <label className="card-title" style={{ fontSize: "13px" }}>
                    Status de Pagamento
                  </label>
                  {paymentStatus.map((data, i) => (
                    <div
                      key={i}
                      className="custom-control custom-checkbox my-1 mr-sm-2"
                    >
                      <input
                        type="checkbox"
                        checked={data.checked}
                        className="custom-control-input"
                        id={`p${data.paymentStatus}`}
                        defaultValue={data.paymentStatus}
                        onChange={changePaymentStatus}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        className="custom-control-label"
                        for={`p${data.paymentStatus}`}
                      >
                        {data.name}
                      </label>
                    </div>
                  ))}
                </div> */}
								</>
							)}
						</div>
						{/* {localStorage.getItem("role") !== "8" && (
							<div className="d-flex flex-column my-4">
								<label className="card-title" style={{ fontSize: "13px" }}>
									Canal de Origem (loja)
								</label>
								<select
									className="form-control"
									value={channel.value}
									onChange={(e) => setChannel({ value: e.target.value })}
								>
									<option value="">Mostrando todas as lojas</option>
									<option value="marketplace">Marketplace</option>
									<option value="android">Android</option>
									<option value="ios">Ios</option>
								</select>
							</div>
						)} */}
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-link"
							data-dismiss="modal"
							style={{ width: "150px" }}
						>
							Cancelar
						</button>

						{count !== 0 ? (
							<button
								className="btn btn-primary btn-icon d-flex justify-content-center"
								data-dismiss="modal"
								onClick={getFilterSelect}
								style={{ padding: "10px 20px", width: "200px" }}
							>
								Aplicar filtros ({count})
							</button>
						) : (
							<button
								className="btn btn-outline btn-icon d-flex justify-content-center"
								/* onClick={() => clearSearchFilter() } */ style={{
									padding: "10px 20px",
									border: "1px solid #ECECEC",
									background: "#ECECEC",
									color: "#A1A3A5",
									width: "200px",
								}}
							>
								{/* <i className="material-icons">delete_sweep</i>&nbsp;&nbsp; */}
								Aplicar filtros ({count})
							</button>
						)}

						{/*  <button type="button" className="btn btn-titles" data-dismiss="modal" style={{ width: '150px' }} onClick={() => deleteCategory(selectedCategory)}>Aplicar Filtros ({`${1}`})</button> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default FilterOrdersModal;
