import React, { useState, useEffect } from 'react';
import { API } from '../../../API';
import Loading from '../../../components/Loading';
import Empty from '../../../components/Empty';
import CollectionsList from './CollectionsList';
import { toast } from 'react-toastify';

export default function Index(props) {
    const [loading, setLoading] = useState(true)
    const [collections, setCollections] = useState([])
    const [totalCollections, setTotalCollections] = useState([])
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (!props.match.params.page) {
            window.location.href = "/collections/1";
         }
    }, [])

    const deleteColection = (id) => {
        API.delete('api/tags?id=' + id)
            .then(async response => {
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
                toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
            })
    }
    return (
        <>
            <CollectionsList loading={loading} collections={collections} deleteColection={deleteColection} numberPages={parseInt(totalCollections / 10)} /* changePage={changePage} */ totalItems={totalCollections} page={page} />
        </>
    )
}