import React, { useState, useEffect } from 'react';
import DatePickerNeo from '../../components/DatePickerNeo';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import { API, APIV2 } from '../../API';
import { useOrders } from './providers/OrdersContext';

export default function OrderInvoice(props) {
    const [invoiceId, setInvoiceId] = useState('')
    const [number, setNumber] = useState(null)
    const [serieNumber, setSerieNumber] = useState(null)
    const [key, setKey] = useState(null)
    const [startIn, setStartIn] = useState("")
    const [xmlUrl, setXmlUrl] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
    const [fileType, setFileType] = useState('')
    const [type, setType] = useState(false)
    const [isOpen, setIsOpen] = useState(true)
    const [load, setLoad] = useState(false)
    const [nameStatus, setNameStatus ] = useState("")
    const [blockStatus, setBlockStatus ] = useState(false)
    const handleChangeStartIn = async date => {
        setStartIn(date)    
    }

    const { loading, invoice, status, setLoading, setReload } = useOrders()

    useEffect(() => {
        setLoad(true)
        if(invoice === "" || invoice == null ) {
            setStartIn("")
            setLoad(false)
        }else {
            setInvoiceId(invoice.id)
            if(invoice.key === "" || invoice.key == null) {
                setIsOpen(true)
                setStartIn("")
                setFileUrl(invoice.fileUrl)
                setLoad(false)
            }else {
                setIsOpen(false)
                setStartIn(new Date(invoice.issuanceDate))
                setNumber(invoice.number)
                setSerieNumber(invoice.serieNumber)
                setKey(invoice.key)
                setXmlUrl(invoice.xmlUrl)
                setFileUrl(invoice.fileUrl)
                setLoad(false)
            }
        }
        
        if(status === 9) {
            setNameStatus("finalizado")
            setBlockStatus(true)
        }else if(status === 10 || status === 11 || status === 12 || status === 21){
            setNameStatus("cancelado")
            setBlockStatus(true)
        } 
        
    }, [])

    const onFileDrop =  async (files) => {
        setLoading(true)
        if(files === "") {
            setType('')
            setLoading(false)
        }else {
            const xml = files[0].type.indexOf('xml')
            const formData = new FormData();
            formData.append('file', files[0])
            
            if(xml === -1) {
                setFileType('PDF')
                API.post('api/images', formData, { headers: { ContentType: 'multipart/form-data' } })
                .then(async response => {
                    setFileUrl(response.data.absoluteUrl)
                    setLoading(false)
                    setType(files[0].type)
                    APIV2.put(`management/commerce/orders/${props.ordersId}/invoiceFile?invoiceUrl=${response.data.absoluteUrl}`)
                        .then(response => {
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
            }else {
                setFileType('XML')
                API.post('api/images', formData, { headers: { ContentType: 'multipart/form-data' } })
                .then(async response => {
                    setXmlUrl(response.data.absoluteUrl)
                    APIV2.put(`management/commerce/orders/${props.ordersId}/invoiceXml?invoiceUrl=${response.data.absoluteUrl}`)
                        .then(response => {
                            APIV2.get(`management/commerce/orders/${props.ordersId}`)
                                .then(response => {
                                    const invoice = new Array(response.data.invoice)
                                    setType(files[0].type)
                                    setInvoiceId(invoice[0].id)
                                    setStartIn(new Date(invoice[0].issuanceDate))
                                    setNumber(invoice[0].number)
                                    setSerieNumber(invoice[0].serieNumber)
                                    setKey(invoice[0].key)
                                    setLoading(false)
                                    setIsOpen(false)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
            }     
        }
    }

    const handleSubmit = () => {
        setLoad(true)
        setIsOpen(false)
        var body = {
            id: props.ordersId,
            invoice: {
                key: key,
                number: number,
                serieNumber: serieNumber,
                xmlUrl: xmlUrl,
                fileUrl: fileUrl,
                issuanceDate: startIn,
                id: invoiceId,
              }
        }
        APIV2.put(`management/commerce/orders/${props.ordersId}/invoicedetails`, body)
            .then(response => {
                toast.success('Nota Fiscal enviada com successo 😃');
                setLoad(false)
                setReload(true)
            })
            .catch((error) => {
                console.log(error)
                setLoad(false)
                setXmlUrl(null)
                setNumber('')
                setSerieNumber('')
                setKey('')
                setStartIn("")
                setIsOpen(true)
                toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
            })
    }

    const chooseFile = (file) => {
        setFileType(file)
    }

    const deleteInvoiceUrl = () => {
        setLoading(true)
        setLoad(true)
        APIV2.delete(`management/commerce/orders/${props.ordersId}/invoiceUrl?typeUrl=${fileType}`)
            .then(response => {
                if(fileType === 'xml') {
                    setXmlUrl(null)
                    setNumber('')
                    setSerieNumber('')
                    setKey('')
                    setStartIn("")
                    setIsOpen(true)
                }else {
                    setFileUrl(null)
                    if(xmlUrl == null || xmlUrl === undefined) {
                        setIsOpen(false)
                    }else {
                        setIsOpen(false)
                    }
                }
                setType('')
                setLoad(false)
                setReload(true)
                setTimeout(() => {
                    toast.error('Nota Fiscal deletada com successo 😃');
                },2000)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    
    return (
        <div className="card mb-3">
            {load === true 
                ? <div style={{ height: '400px' }}>
                    <Loading />
                </div> 
                : <>
                <div className="card-header">
                    <h4 className="card-title">Informações de Nota Fiscal</h4>
                    { blockStatus === true &&
                        <div className={nameStatus === "finalizado" ? "alert alert-success mt-2" : "alert alert-danger mt-2"} role="alert">
                            {`Seu pedido está ${nameStatus}!`}
                        </div>
                    }
                </div>
                <div className="">
                    <div className="d-flex flex-column-reverse flex-lg-row"> {/* flex-column-reverse */}
                        <div className="px-4 w-100">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="form-group w-75 pr-2">
                                    
                                <label className="" htmlFor="status"><strong>Número da Nota</strong></label>
                                    <div>
                                        {loading === true || blockStatus === true
                                            ? <input disabled type="text" className="form-control" id="numberCode" name='numberCode' value={number} onChange={(e) => setNumber(e.target.value)}/>
                                            : isOpen === true
                                                ? <input type="text" className="form-control" id="numberCode" name='numberCode' value={number} onChange={(e) => setNumber(e.target.value)}/>
                                                : <input disabled type="text" className="form-control" id="numberCode" name='numberCode' value={number} onChange={(e) => setNumber(e.target.value)}/>
                                        }
                                    </div>
                                </div>
                                <div className="form-group w-25">
                                    <label htmlFor="numberSerie"><strong>Série: </strong></label>
                                    <div >
                                        {loading === true || blockStatus === true
                                            ? <input disabled type="text" className="form-control" id="numberSerie" name='numberSerie' value={serieNumber} onChange={(e) => setSerieNumber(e.target.value)} />
                                            : isOpen === true 
                                                ? <input type="text" className="form-control" id="numberSerie" name='numberSerie' value={serieNumber} onChange={(e) => setSerieNumber(e.target.value)} />
                                                : <input disabled type="text" className="form-control" id="numberSerie" name='numberSerie' value={serieNumber} onChange={(e) => setSerieNumber(e.target.value)} />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="form-group">
                                    <label htmlFor="numberKey"><strong>Chave: </strong></label>
                                    <div>
                                        {loading === true || blockStatus === true
                                            ? <input disabled type="text" className="form-control" id="numberKey" name='numberKey' value={key} onChange={(e) => setKey(e.target.value)}/>
                                            : isOpen === true
                                                ? <input type="text" className="form-control" id="numberKey" name='numberKey' value={key} onChange={(e) => setKey(e.target.value)}/>
                                                : <input disabled type="text" className="form-control" id="numberKey" name='numberKey' value={key} onChange={(e) => setKey(e.target.value)}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="m-0 p-0">
                                <label className="mb-2" htmlFor="numberKey"><strong>Data da Emissão: </strong></label>
                                {loading === true || blockStatus === true
                                    ? <div className="date-orders">
                                        <i className="material-icons">date_range</i>
                                        <input className="form-control" disabled type="text" id="star" name='startIn' value={startIn === "" ? `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString('pt-BR')}` : `${new Date(startIn).toLocaleDateString()} - ${new Date(startIn).toLocaleTimeString('pt-BR')}`} />
                                    </div>
                                    : isOpen === true 
                                    ? <div className="form-group" style={{ marginTop: '-20px' }}>
                                        <DatePickerNeo
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            time={true}
                                            defaultValue={startIn}
                                            selected={startIn}
                                            onChange={handleChangeStartIn}
                                        />
                                    </div>
                                    : <div className="date-orders">
                                        <i className="material-icons">date_range</i>
                                        <input className="form-control" disabled type="text" id="star" name='startIn' value={`${new Date(startIn).toLocaleDateString()} - ${new Date(startIn).toLocaleTimeString('pt-BR')}`} />
                                    </div> 
                                }
                            </div>
                        </div>

                        <div className="px-4 w-100">
                            <div className="form-group">
                                {blockStatus === false &&
                                    <> 
                                    <label><strong>Arquivo da Nota: </strong></label><br />
                                    <Dropzone
                                        onDrop={onFileDrop}
                                        accept=""
                                        multiple={true}>
                                            {({ getRootProps, getInputProps }) => {
                                                return (
                                                    <div className="download-pdf-xml" style={{ width: '100%' }} {...getRootProps()}>
                                                        <input {...getInputProps()}  disabled />
                                                        {
                                                            <div className="success" style={{ width: '100%' }}>
                                                                {type == ""
                                                                    ? <p className="m-1" style={{ width: '100%', textAlign: 'center' }}>
                                                                        {loading === true 
                                                                            ? <i className="fas fa-spinner fa-spin"></i> 
                                                                            :<> 
                                                                                <i className="material-icons"> note_add </i>&nbsp;&nbsp;Arraste ATÉ AQUI ou CLIQUE PARA ESCOLHER um arquivo
                                                                            </>
                                                                        } 
                                                                    </p>
                                                                    : <i className="material-icons success-icon"> task_alt </i>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }}
                                    </Dropzone>
                                    <label style={{ color: "#A1A3A5", fontWeight: "500", fontSize: "12px" }}><em>São suportados arquivos no formato XML e PDF</em></label>
                                    </>
                                }
                                
                                <div className="d-flex justify-content-center flex-column m-0 p-0">
                                    {xmlUrl == null ? null : 
                                        <div className="download-group m-0 p-0 mb-2">
                                            <div className="download-group-label">
                                                <i className="material-icons">insert_drive_file</i>
                                                <span>&nbsp;Arquivo XML da Nota Fiscal</span>
                                            </div>
                                            <div className="download-group-icons">
                                                <button onClick={() => chooseFile('xml')} data-toggle="modal" data-target="#deleteNF"><i className="material-icons" style={{color: '#FF5B5C', paddingRight:'10px'}}>delete_outline</i></button>
                                                <a href={xmlUrl} target="_blank"><i className="material-icons">get_app</i></a> 
                                            </div>      
                                        </div>
                                    }
                                    
                                    {fileUrl == null ? null : 
                                        <div className="download-group m-0 p-0">
                                            <div className="download-group-label">
                                                <i className="material-icons">insert_drive_file</i>
                                                <span>&nbsp;Arquivo PDF da Nota Fiscal</span>
                                            </div>
                                            <div className="download-group-icons">
                                                <button onClick={() => chooseFile('pdf')} data-toggle="modal" data-target="#deleteNF"><i className="material-icons" style={{color: '#FF5B5C', paddingRight:'10px'}}>delete_outline</i></button>
                                                <a href={fileUrl} target="_blank"><i className="material-icons">get_app</i></a>
                                            </div>      
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 my-4 mx-3">
                        {blockStatus === true
                            ? <button className="btn btn-light">{/* {type == '' ? 'Faturar pedido' : 'Enviar'} */}Faturar pedido</button>
                            : <button onClick={handleSubmit} className="btn btn-success">{/* {type == '' ? 'Faturar pedido' : 'Enviar'} */}Faturar pedido</button>
                        }
                    </div>
                </div>
                </>
            }
            <Modal
                id={'deleteNF'}
                title={'Certeza que deseja excluir a Nota Fiscal?'}
                delete={true}
                actionDelete={deleteInvoiceUrl}
            />
        </div>
    )
}