import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { API, APIV2 } from '../../../API';
import Loading from '../../../components/Loading';
import { toast } from 'react-toastify';
import NewCategory from './components/NewCategory';
import CategoriesList from "./components/CategoriesList";
import CategoriesContext from "./providers/CategoriesContext";
import DeleteCategory from "./components/DeleteCategory";
import { useCategory } from "./providers/CategoriesContext";
import Toast from "../../../components/toast/Toast";

export default function Categories(props) {

  return (<>
    <CategoriesContext>
      <div className="container mb-3">
        <div className="row">
          <div className="col-12" style={{ paddingRight: '35px' }}>
            <Messages />
          </div>
          <div className="col-5 mb-2" style={{ maxWidth: '427px' }}>
            <CategoriesList />
          </div>
          <div className="col-7" style={{ minWidth: '693px' }}>
            <NewCategory></NewCategory>
          </div>
        </div>
      </div>

    </CategoriesContext>
  </>
  )
}

const Messages = () => {
  const { success, error, setError, setSuccess } = useCategory()

  const [err, setErr] = useState('')
  const [succs, setSuccs] = useState('')

  useEffect(() => {
    console.log(error)
    setErr(error)
    setSuccs(success)
  }, [error, success])


  const closeToast = () => {
    console.log('teste')
    setError('')
    setSuccess('')
  }


  return (
    <Toast message={success} success={succs} error={err} closeToast={closeToast} />
  )
}
