import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { APIV2 } from '../../../API';
import TimeKeeper from 'react-timekeeper';
import moment, { months } from 'moment'
import Button from '../../../components/Button';

// import { Container } from './styles';

export default function WorkHour(props) {
  const [disable, setDisable] = useState(false)
  const [selectedHour, setSelectedHour] = useState({})
  const [workHours, setWorkHour] = useState([
    {
      id: "",
      sellerId: props.id,
      dayWeek: 0,
      openHour: "",
      closeHour: ""
    },
    {
      id: "",
      sellerId: props.id,
      dayWeek: 1,
      openHour: "",
      closeHour: ""
    },
    {
      id: "",
      sellerId: props.id,
      dayWeek: 2,
      openHour: "",
      closeHour: ""
    },
    {
      id: "",
      sellerId: props.id,
      dayWeek: 3,
      openHour: "",
      closeHour: ""
    }
    ,
    {
      id: "",
      sellerId: props.id,
      dayWeek: 4,
      openHour: "",
      closeHour: ""
    }
    ,
    {
      id: "",
      sellerId: props.id,
      dayWeek: 5,
      openHour: "",
      closeHour: ""
    },
    {
      id: "",
      sellerId: props.id,
      dayWeek: 6,
      openHour: "",
      closeHour: ""
    }
  ])

  useEffect(() => {
    APIV2.get(`management/retailer/sellers/${props.id}/workinghours`)
      .then(async response => {

        let newArray = [...workHours]
        response.data.map(data => {
          newArray[workHours.map(dayWork => dayWork.dayWeek).indexOf(data.dayWeek)] = data
        })
        let order = newArray.sort((a, b) => {
          if (a.dayWeek > b.dayWeek) {
            return 1;
          }
          if (a.dayWeek < b.dayWeek) {
            return -1;
          }
          return 0;
        })

        setWorkHour(order)
      })
      .catch((error) => {
        console.log(error);
        toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
      })
  }, [])

  const getDayWeek = (dayWeek) => {
    switch (dayWeek) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Segunda-Feira';
      case 2:
        return 'Terça-Feira';
      case 3:
        return 'Quarta-Feira';
      case 4:
        return 'Quinta-Feira';
      case 5:
        return 'Sexta-Feira';
      case 6:
        return 'Sábado';
      default:
        break;
    }
  }

  const onChangeHour = (name, hour, day) => {
    // let value = e.value
    // let name = e.name
    let newArray = [...workHours]
    name === 'openHour' ? newArray[workHours.map(dayWork => dayWork.dayWeek).indexOf(day)].openHour = hour.length === 5 ? hour : `0${hour}` :
      newArray[workHours.map(dayWork => dayWork.dayWeek).indexOf(day)].closeHour = hour.length === 5 ? hour : `0${hour}`
    setWorkHour(newArray)
  }

  const saveHour = async () => {
    setDisable(true)
    let arr = workHours.map(hour => {
      return { dayWeek: hour.dayWeek, openHour: hour.openHour, closeHour: hour.closeHour }
    })
    try {
      await APIV2.put(`management/retailer/sellers/${props.id}/workinghours`, arr)
      toast.success('Horário criado com sucesso');
      setDisable(false)
    } catch (e) {
      console.log(e)
      toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
      setDisable(false)
    }
  }

  const closeHour = (element) => {
    const el = document.querySelector(`#${element}`)
    el.classList.remove('show')
  }

  return (
    <>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h4 className="card-title">Horário de Funcionamento</h4>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Dia da semana</th>
            <th scope="col">Abertura</th>
            <th scope="col">Fechamento</th>
          </tr>
        </thead>
        <tbody>
          {workHours.map((dayWeek, index) =>
            <tr key={index}>
              <th scope="row">{getDayWeek(dayWeek.dayWeek)}</th>
              <td>
                {/* <div> */}
                {/* <input className="form-control" pattern="[0-9]{2}:[0-9]{2}" name="openHour" value={dayWeek.openHour} onClick={() => setSelectedHour({ dayWeek: dayWeek.dayWeek, type: "openHour" })} />
                  {selectedHour.type === "openHour" && dayWeek.dayWeek === selectedHour.dayWeek && */}
                <div class="dropdown">
                  <button class="btn dropdown-toggle calendar-toggle" style={{ backgroundColor: '#fff', border: '2px solid #dee2e6', width: '150px', padding: '5px 10px', textAlign: 'left' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {dayWeek.openHour ? dayWeek.openHour : '00:00'}
                  </button>
                  <div class="dropdown-menu" id={"openHour" + index} style={{ padding: '0px', overflow: 'hidden' }}>
                    <form>
                      <TimeKeeper hour24Mode
                        time={dayWeek.openHour ? dayWeek.openHour : null}
                        name="openHour"
                        onChange={(newTime) => onChangeHour("openHour", newTime.formatted24, dayWeek.dayWeek)}
                        doneButton={(newTime) => (
                          <button
                            className="btn btn-block"
                            type="button"
                            onClick={() => closeHour(`openHour${index}`)}
                          >
                            Fechar
                          </button>
                        )}
                      />
                    </form>
                  </div>
                  <div />
                  {/* } */}
                </div>
              </td>
              <td>
                {/* <div>
                  <input type="time" className="form-control" pattern="[0-9]{2}:[0-9]{2}" name="closeHour" value={dayWeek.closeHour} onClick={() => console.log(dayWeek.dayWeek, "openHour")} />
                  <TimeKeeper hour24Mode time={dayWeek.closeHour ? dayWeek.closeHour : null} name="closeHour" onChange={(newTime) => onChangeHour("closeHour", newTime.formatted24, dayWeek.dayWeek)} />;
                </div> */}
                <div class="dropdown">
                  <button class="btn dropdown-toggle calendar-toggle" style={{ backgroundColor: '#fff', border: '2px solid #dee2e6', width: '150px', padding: '5px 10px', textAlign: 'left' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {dayWeek.closeHour ? dayWeek.closeHour : '00:00'}
                  </button>
                  <div class="dropdown-menu" id={"closeHour" + index} style={{ padding: '0px', overflow: 'hidden' }}>
                    <form>
                      <TimeKeeper hour24Mode
                        time={dayWeek.closeHour ? dayWeek.closeHour : null}
                        name="closeHour"
                        onChange={(newTime) => onChangeHour("closeHour", newTime.formatted24, dayWeek.dayWeek)}
                        doneButton={(newTime) => (
                          <button
                            className="btn btn-block"
                            type="button"
                            onClick={() => closeHour(`closeHour${index}`)}
                          >
                            Fechar
                          </button>
                        )} />
                    </form>
                  </div>
                  <div />
                  {/* } */}
                </div>
              </td>
            </tr>
          )
          }

        </tbody>
      </table>

      <div className="form-group row mt-20 ml-2">
        <div className="col-12 d-flex flex-row-reverse">
          <Button type="button" loading={disable} className="btn btn-primary" onClick={saveHour}>{props.id ? 'Editar loja' : 'Criar loja'}</Button>
        </div>
      </div>
    </>
  );
}

