import React from 'react';



function Sidebar(props) {

	return (
		<div className="card sidebar">
			<div className="card-header d-flex align-items-center">
				<h3 className="title-card">{props.menu.title}</h3>
			</div>
			<ul>
				{props.menu.conteudo.map((item, i) => {
					return (
						<li key={i} className={item.active ? 'active' : null}>
							{item.EnableUserPromotion ?
								<a href={item.link}>
									<i className="material-icons">
										{item.icon}
									</i>
									{item.title}
								</a>
								: null
							}
						</li>
					)
				})}
			</ul>
		</div>
	)
};

export default Sidebar;
