import React, { Component } from 'react';

require('dotenv/config');
const qs = require('querystring')

export class RoleList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            pass: '',
            role: []
        }
      
        this.onChange = this.onChange.bind(this);
        this.selectId = this.selectId.bind(this)
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeDev() {
        this.props.dev();

    }
    changePass() {
        this.props.pass();

    }
    selectId(id) {
        this.props.select(id)
    }


    render() {

        return (
            <div className="initial">
                <h2>Login</h2><br />
                <p style={{ color: '#C4C4C4' }}>Escolha em qual cliente deseja entrar</p>
              
                <div className="roleContainer">
                    {this.props.role.map((item, i) => {
                        return (<li key={i} className="itemRole">
                            <div className="d-flex">
                                <div className="col-2 text-center d-flex align-items-center">
                                {item.retailerIconUrl != '' ? 
                                     <img src={item.retailerIconUrl} className='avatar' /> :  <img src='/images/avatar.jpg' className='avatar' />}
                                </div>
                                <div className="col text-left d-flex align-items-center py-3">
                                    <div>
                                        <span className="name"> {item.retailerName}</span>
                                        <small className="role">{item.roleName}</small>
                                    </div>
                                </div>
                                <div className="col-3 go d-flex align-items-center">
                                    <a href="#" onClick={() => this.selectId(item)}>Entrar</a>
                                </div>

                            </div>

                        </li>
                        )
                    })
                    }

                </div>


                <div className="footer-login">
                    <div className="row">
                        <div className="col ajuda">
                            <a href="https://neomode.zendesk.com/" target="_blank">Ajuda</a>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
};


export default RoleList;