import React, { Component } from "react";
import Loading from "../../../../components/Loading";

export class MinimumQuantity extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		loading: false,
		minQuantity: null,
	};
	save(i) {
		var item = {
			minQuantity: parseFloat(this.state.minQuantity),
			value: parseFloat(this.state.minQuantity),
		};
		var itemView = {
			name: "Quantidade mínima de itens",
			value: this.state.minQuantity,
			itemId: "minQuantity",
			modal: "#conditionModalMinQuantity",
		};
		this.props.newCondition(item, itemView);
	}
	async handleChange(e) {
		await this.setState({ minQuantity: e.target.value });
	}
	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalMinQuantity"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalMinQuantity"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Quantidade mínima de itens</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body py-0">
							<div className="form-group mb-0">
								<input
									type="text"
									className="dinheiro form-control"
									placeholder="10"
									defaultValue={this.state.minQuantity}
									onKeyUp={this.handleChange}
								/>
							</div>
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col-5"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col-5"
								onClick={() => this.save()}
								data-dismiss="modal"
							>
								Pronto
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MinimumQuantity;
