import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIV2 } from "../../../../API";

export const DealersProvider = createContext({});

export default function DealersContext(props) {
	const [dealersList, setDealersList] = useState([]);
	const [roleClient, setRoleClient] = useState("");
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [messageToast, setMessageToast] = useState("");
	const [query, setQuery] = useState("");
	const [loadQuery, setLoadQuery] = useState(false);
	const [dealers, setDealers] = useState({
		id: "",
		clientId: "",
		isActive: false,
		fullName: "",
		role: "",
		salesmanCpf: "",
		storeCnpj: "",
		dealerCode: "",
	});
	const [filterData, setFilterData] = useState({
		cnpj: "",
		role: "",
	});

	useEffect(() => {
		const data = window.location.pathname.substring(1).split("/");
		setLoading(true);
		if (data[3] != null) {
			getDealers(data[3]);
		} else {
			getDealersList(Number(data[1]));
		}
	}, [query, filterData]);

	const getDealersList = (page) => {
		setLoadQuery(true);
		APIV2.get(
			`management/marketing/dealers?page=${page}&perpage=10&Query=${query}&Cnpj=${filterData.cnpj}&Role=${filterData.role}`
		)
			.then(async (response) => {
				setLoading(false);
				setRoleClient(localStorage.getItem("role"));
				if (response.status !== 204) {
					const arrDealers = [];
					const cpfs = response.data.forEach((cpf) => {
						let mask = /(\d{3})(\d{3})(\d{3})(\d{2})/;
						let cpfs = String(cpf.salesmanCpf);
						let body = {
							clientId: cpf.clientId,
							companyName: cpf.companyName,
							dealerCode: cpf.dealerCode,
							fullName: cpf.fullName,
							id: cpf.id,
							isActive: cpf.isActive,
							isDeleted: cpf.isDeleted,
							role: cpf.role,
							salesmanCpf: cpfs.replace(mask, "$1.$2.$3-$4"),
							sellerId: cpf.sellerId,
							storeCnpj: cpf.storeCnpj,
						};
						arrDealers.push(body);
					});
					setDealersList(arrDealers);
					setLoading(false);
					setLoadQuery(false);
				} else {
					console.log(response);
					setLoadQuery(false);
					setDealersList([]);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const getDealers = async (id) => {
		setEdit(true);
		await APIV2.get(`management/marketing/dealers/${id}`)
			.then((response) => {
				setDealers({
					id: response.data.id,
					clientId: response.data.clientId,
					isActive: response.data.isActive,
					fullName: response.data.fullName,
					role: response.data.role,
					salesmanCpf: response.data.salesmanCpf,
					storeCnpj: response.data.storeCnpj,
					dealerCode: response.data.dealerCode,
				});
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
		setLoading(false);
	};

	const createDealer = (body) => {
		APIV2.post("management/marketing/dealers", body)
			.then(async (response) => {
				localStorage.setItem("createDealer", true);
				window.location.href = "/salespeople/1";
				//toast.success('Vendedor(a) criado com sucesso! 😃');
			})
			.catch((error) => {
				if (error.response.data) {
					toast.error(error.response.data.message);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
				console.log(error);
			});
	};

	const updateDealer = async (body) => {
		await APIV2.put(`management/marketing/dealers/${dealers.id}`, body)
			.then(async (response) => {
				localStorage.setItem("updateDealer", true);
				window.location.href = "/salespeople/1";
				//toast.success('Vendedor editado com sucesso! 😃');
			})
			.catch((error) => {
				console.log(error);
				if (error.response.data) {
					toast.error(error.response.data.message);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const getSearch = (query) => {
		console.log(query);
		setQuery(query);
	};

	const getFilter = async (val) => {
		console.log(val);
		setFilterData(val);
	};

	return (
		<DealersProvider.Provider
			value={{
				dealersList,
				dealers,
				loading,
				loadQuery,
				edit,
				messageToast,
				roleClient,
				setMessageToast,
				setDealers,
				setLoading,
				setEdit,
				createDealer,
				updateDealer,
				getSearch,
				getFilter,
			}}
		>
			{props.children}
		</DealersProvider.Provider>
	);
}

export const useDealers = () => useContext(DealersProvider);
