import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../../../API";

export default function Payments({ newCondition }) {
	const [payments, setPayments] = useState([]);
	const [loading, setLoading] = useState(false);

	const onChangePayment = (e) => {
		const payment = { type: e.target.value, name: e.target.name };
		payments <= 0 && setPayments([...payments, payment]);
		let listData = [...payments].findIndex((val) => val.type === payment.type);

		if (listData < 0) {
			setPayments([...payments, payment]);
		} else {
			setPayments((currentPayments) =>
				currentPayments.filter((val, i) => val.type !== payment.type)
			);
		}
	};

	const savePayment = () => {
		const item = {
			paymentMethods: payments,
			value: payments,
		};
		if (payments.length > 1) {
			var text = " Selecionados";
		} else {
			var text = " Selecionado";
		}
		const itemView = {
			name: "Meios de pagamento",
			value: payments.length + text,
			itemId: "paymentMethods",
			modal: "#conditionModalPaymentMethods",
		};
		newCondition(item, itemView);
	};

	return (
		<div
			class="modal fade"
			id="conditionModalPaymentMethods"
			role="dialog"
			aria-labelledby="conditionModalPaymentMethods"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title">Meio de pagamentos</h3>
						<button
							type="button"
							className="close text-danger"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="custom-control custom-checkbox my-1 mr-sm-2">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customControlInline1"
								name="Cartão de crédito (via APP)"
								value={1}
								onChange={onChangePayment}
							/>
							<label
								className="custom-control-label"
								for="customControlInline1"
							>
								Cartão de crédito (via APP)
							</label>
						</div>

						<div className="custom-control custom-checkbox my-1 mr-sm-2">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customControlInline2"
								name="Na retirada"
								value={2}
								onChange={onChangePayment}
							/>
							<label
								className="custom-control-label"
								for="customControlInline2"
							>
								Na retirada
							</label>
						</div>
						<div className="custom-control custom-checkbox my-1 mr-sm-2">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customControlInline3"
								name="Boleto (via APP)"
								value={3}
								onChange={onChangePayment}
							/>
							<label
								className="custom-control-label"
								for="customControlInline3"
							>
								Boleto (via APP)
							</label>
						</div>

						<div className="custom-control custom-checkbox my-1 mr-sm-2">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customControlInline6"
								name="PIX"
								value={6}
								onChange={onChangePayment}
							/>
							<label
								className="custom-control-label"
								for="customControlInline6"
							>
								PIX
							</label>
						</div>
					</div>

					<div
						className="modal-footer text-center"
						style={{ justifyContent: "center" }}
					>
						<button
							type="button"
							className="btn btn-light col-5"
							data-dismiss="modal"
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success col-5"
							onClick={savePayment}
							data-dismiss="modal"
						>
							Pronto
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
