import React, { Component } from 'react';
export class AppPreview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="preview-app">
        <div className="barra" style={{ backgroundColor: this.props.barra }}>
        </div>
        <div className="banner-app">
        </div>
        <div className="rectangle-copy" style={{ color: this.props.primaria }}>
          <div className="row" style={{  paddingTop: '5%' }}>
            <div className="col-3 text-center">
              <i className="material-icons">
                storefront
              </i>
            </div>
            <div className="col-6 text-rect">
              <span className="block-font"> ipsuim lore testeimpsum</span>
            </div>
          </div>
        </div>
        <div className="banner-app-2">
        </div>
        <div className="footer-app">
          <div className="row">
            <div className="col">
              <i className="material-icons" style={{color: this.props.secundaria}}>
                star
              </i>
            </div>
            <div className="col">
              <i className="material-icons">
                star
              </i>
            </div>
            <div className="col">
              <i className="material-icons">
                star
              </i>
            </div>
            <div className="col">
              <i className="material-icons">
                star
              </i>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default AppPreview;
