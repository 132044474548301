import React from "react";

function RepricedProducts(props) {
	const renderScreen = () => {
		const save = (i) => {
			let item = {
				isNotApplyProductReprice: !i,
				value: i,
			};
			let itemView = {
				name: "Não aplica desconto em produto remarcado?",
				value: i == true ? "Sim" : "Não",
				itemId: "isNotApplyProductReprice",
				modal: "#conditionModalIsNotApplyProductReprice",
			};

			props.newCondition(item, itemView);
		};

		return (
			<div
				class="modal fade"
				id="conditionModalIsNotApplyProductReprice"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalIsNotApplyProductReprice"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">
								Não aplica desconto em produto remarcado?
							</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col"
								data-dismiss="modal"
								onClick={() => save(true)}
							>
								Sim
							</button>
							<button
								type="button"
								className="btn btn-danger col"
								data-dismiss="modal"
								onClick={() => save(false)}
							>
								Não
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return <>{renderScreen()}</>;
}

export default RepricedProducts;
