import React, { Component } from 'react';

require('dotenv/config');

export class Initial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            pass: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeDev() {
        this.props.dev();

    }
    changePass() {
        this.props.pass();

    }

    onSubmit(e) {
        e.preventDefault();
        this.props.login(this.state.email, this.state.pass)
    }
    render() {

        return (
            <div className="initial">
                <h2>Login</h2><br />
                <form onSubmit={this.onSubmit}>
                    {this.props.error ? <div className="alert alert-danger erro" role="alert">
                        <i className="material-icons">error_outline</i>  Usuário ou senha incorretos
                    </div> : null}
                    <div className="form-group">
                        <input type="email" className="form-control" placeholder="Seu e-mail" name='email' onChange={this.onChange} autoComplete="email"/>
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Sua senha" name='pass' onChange={this.onChange} autoComplete="password" />
                    </div>
                    <div className="form-group d-flex justify-content-end">
                        <a href="/reset-password" className="text-right reset-password" >esqueci minha senha</a>
                    </div>
                    <div className="">
                        <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                    </div>
                </form>
                <div className="footer-login">
                    <div className="row">
                        <div className="col ajuda">
                            <a href="https://neomode.zendesk.com/" target="_blank">Ajuda</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};


export default Initial;