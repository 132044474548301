import React, { Component } from 'react'
import PreviewAPP from '../../../../components/preview/PreviewApp'

export class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className="card border border-white">
                <div className="card-content">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col">
                                <PreviewAPP />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index
