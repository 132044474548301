import React, { Component } from "react";
import { storiesOf } from "@storybook/react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DataTable, { createTheme } from "react-data-table-component";
import { deepPurple } from "@material-ui/core/colors";
import NewPaginationComponent from "./newPaginationComponent";

// const useStyles = makeStyles(theme => ({
//     root: {
//         width: '100%',
//         '& > * + *': {
//             marginTop: theme.spacing(2),

//         },

//     },
// }));

// const LinearIndeterminate = () => {
//     const classes = useStyles();

//     return (
//         <div className={classes.root}>
//             <LinearProgress />
//         </div>
//     );
// };

class List extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
		// this.getNumberOfPages = this.getNumberOfPages.bind(this);
		this.getPagesArray = this.getPagesArray.bind(this);
		// this.newPaginationComponent = this.newPaginationComponent.bind(this);

		this.state = {
			data: [],
			loading: false,
			totalRows: 0,
			perPage: 0,
			// pageInputValue: this.props.page,
		};
	}
	async componentDidMount() {
		this.setState({ loading: true });
		this.setState({
			loading: false,
			totalRows: this.props.totalRows,
			data: this.props.data,
			colums: this.props.colums,
			perPage: this.props.rowsPerPage,
		});
		this.forceUpdate();
	}

	handlePageChange = async (page) => {
		this.setState({ loading: true });
		await this.props.handlePageChange(page);
		this.setState({ loading: false });
	};

	// Nova função
	handleRowsPerPageChange = async (rows) => {
		this.setState({ loading: true });
		await this.props.handleRowsPerPageChange(rows);
		this.setState({ loading: false });
	};

	// getNumberOfPages(totalRows, rowsPerPage) {
	// 	return Math.ceil(totalRows / rowsPerPage);
	// }

	getPagesArray(pages) {
		const results = [];

		for (let i = 1; i <= pages; i++) {
			results.push(i);
		}

		return results;
	}

	render() {
		const { loading, data, totalRows, perPage } = this.state;
		const customStyles = {
			headRow: {
				style: {
					border: "0",
					borderBottom: "1px solid #ebebeb",
					borderTop: "1px solid #ebebeb",
					marginTop: "0px",
					paddingLeft: "10px",
				},
			},
			headCells: {
				style: {
					color: "#4B5F79",
					fontSize: "14px",
					fontWeight: "bold",
					textTransform: "uppercase",
					paddingTop: "8px", // override the cell padding for head cells
					paddingBottom: "8px",
				},
			},
			cells: {
				style: {
					border: "none",
					color: "#747E8B",
					fontSize: "14px",
				},
			},
			rows: {
				style: {
					border: "0",
					borderBottom: "1px solid #ebebeb",
					padding: "10px",
				},
			},
			pagination: {
				style: {
					border: "none",
				},
			},
		};
		const columns = [
			{
				name: "Title",
				selector: "title",
				sortable: true,
			},
			{
				name: "Director",
				selector: "director",
				sortable: true,
			},
			{
				name: "Year",
				selector: "year",
				sortable: true,
			},
		];
		return (
			<>
				{this.props.paginationServer ? (
					<DataTable
						columns={this.state.colums}
						data={this.state.data}
						progressPending={loading}
						noHeader={true}
						customStyles={customStyles}
						pagination
						paginationServer={this.props.paginationServer}
						sortServer={this.props.sortServer}
						paginationDefaultPage={this.props.page}
						paginationTotalRows={totalRows}
						paginationPerPage={this.props.rowsPerPage}
						paginationComponentOptions={{
							rowsPerPageText: "Itens por página: ",
							rangeSeparatorText: "de",
						}}
						paginationComponent={(e) => (
							<NewPaginationComponent
								{...e}
								changeSelectOptions={this.props.changeSelectOptions}
								getPagesArray={this.getPagesArray}
								pageValue={this.props.page}
							/>
						)}
						// paginationComponent={this.newPaginationComponent}
						onChangeRowsPerPage={this.handleRowsPerPageChange}
						onChangePage={this.handlePageChange}
						onSort={(column, sortDirection, event) =>
							this.props.sortedReturn(column, sortDirection, event)
						}
						defaultSortAsc={this.props.defaultSortAsc}
					/>
				) : (
					<DataTable
						columns={this.state.colums}
						noHeader={true}
						data={this.state.data}
						customStyles={customStyles}
						paginationComponentOptions={{ noRowsPerPage: true }}
						progressPending={loading}
						pagination
					/>
				)}
			</>
		);
	}
}

storiesOf("Pagination", module).add("Server-Side", () => <List />);

export default List;
