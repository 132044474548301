import React, { useEffect, useState } from "react";
import { Transition, animated, config } from "react-spring";

// import { Container } from './styles';

function Toast(props) {
	const [open, setOpen] = useState(false);
	const [isSuccesses, setIsSuccess] = useState(false);
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (props.success) {
			setIsSuccess(true);
			setOpen(true);
			setMessage(props.success);
			if (props.isMatcher && !props.matcherDownloading) {
				setTimeout(() => {
					close();
				}, 1000);
			} else if (!props.isMatcher) {
				setTimeout(() => {
					close();
				}, 10000);
			}
		}
		if (props.error) {
			setIsSuccess(false);
			setOpen(true);
			setMessage(props.error);
			if (props.isMatcher && !props.matcherDownloading) {
				setTimeout(() => {
					close();
				}, 1000);
			} else if (!props.isMatcher) {
				setTimeout(() => {
					close();
				}, 10000);
			}
		}
	}, [props.success, props.error, props.matcherDownloading]);

	const close = () => {
		setOpen(false);
		props.closeToast();
	};

	return (
		<>
			<Transition
				items={open}
				from={{ opacity: 0 }}
				enter={{ opacity: 1 }}
				leave={{ opacity: 0 }}
				// reverse={open}
				delay={1000}
				config={config.molasses}
				// onRest={() => {
				//   setOpen(!open)
				// }}
			>
				{(styles, item) =>
					item && (
						<animated.div style={styles}>
							<div className={isSuccesses ? "toast successes" : "toast error"}>
								<div className="toast-content">
									<span class="material-icons mr-2">
										{props.dealer
											? "person"
											: isSuccesses
											? "check_circle_outline"
											: "error_outline"}
									</span>
									{message}
								</div>
								<button
									className="btn btn-link material-icons toast-close"
									onClick={() => close()}
								>
									close
								</button>
							</div>
						</animated.div>
					)
				}
			</Transition>
		</>
	);
}

export default Toast;
