import ReactDOM from 'react-dom'
import React from 'react'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'

import TagManager from 'react-gtm-module'

import { initializeFirebase } from './push-notification'
import { registerServiceWorker } from "./serviceWorker";
import  ConfigProviders  from './providers/ConfigProvider'


const tagManagerArgs = {
    gtmId: 'GTM-KZHJRQC'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <ConfigProviders>
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProviders>, document.getElementById('root'))


registerServiceWorker()