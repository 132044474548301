import React, { useCallback, useState, Component } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend'
import update from "immutability-helper";
import cuid from "cuid";
import {API} from '../API'
import Dropzone from "react-dropzone";
import ImageList from "./ImageList";
import { isTouchDevice } from "./utils";

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

class NewDropImage extends Component {
    constructor(props) {
        super(props);
        this.onImageDrop = this.onImageDrop.bind(this);
        this.handleImageUploadCarrousel = this.handleImageUploadCarrousel.bind(this);
        this.moveImage = this.moveImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }
    state = {
        carrouselImages: [],
        uploadedFile: ''
    }

    async onImageDrop(files) {
        await this.setState({ uploadedFile: files[0] })
        for (var i = 0; i < files.length; i++) {
            this.handleImageUploadCarrousel(files[i]);
        }


    }

    componentDidMount(){
        if (this.props.type == 'carrousel' && this.props.data != null || this.props.data != undefined) {
            this.setState({ carrouselImages: this.props.data });
            this.forceUpdate();
        } 
    }
    
    componentWillReceiveProps() {

        if (this.props.type == 'carrousel' && this.props.data != null || this.props.data != undefined) {
            this.setState({ carrouselImages: this.props.data });
            this.forceUpdate();
        }
    }
    handleImageUploadCarrousel(files) {
        const formData = new FormData();
        formData.append('file', files)
      
        this.setState({ carrouselImages: this.props.data })
        API.post('api/images', formData, { headers: { ContentType: 'multipart/form-data' } })
            .then(async response => {

                // If request is good...
                var image = {
                    order: this.state.carrouselImages.length + 1,
                    imageUrl: response.data.relativeUrl
                }

                this.state.carrouselImages.push(image)
                // setCarrouselImages(carrouselImages => [...carrouselImages, image]);
                this.props.recoveryStateLogo(this.state.carrouselImages)
            })
            .catch((error) => {
                console.log(error)
            })


    }
    moveImage(dragIndex, hoverIndex) {
        const draggedImage = this.state.carrouselImages[dragIndex];
        this.state.carrouselImages.splice(dragIndex, 1); // removing what you are dragging.
        this.state.carrouselImages.splice(hoverIndex, 0, draggedImage); // inserting it into hoverIndex    
        this.forceUpdate()
        this.props.recoveryStateLogo(this.state.carrouselImages)
    };
    removeImage(index) {
        const temp = [...this.state.carrouselImages];
        temp.splice(index, 1);
        this.state.carrouselImages.splice(index, 1)
        //this.setState({ carrouselImages: temp })
        this.forceUpdate()
        this.props.recoveryStateLogo(this.state.carrouselImages)
        
    };
    render() {
        return (
            <main className="dropzone">
                <DndProvider backend={backendForDND}>
                    <ImageList images={this.props.data} moveImage={this.moveImage} removeImage={this.removeImage} />
                    {this.props.data.length > 0 
                        ? <Dropzone
                            onDrop={this.onImageDrop}
                            accept="image/*"
                            multiple={true}>
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <div className="hover-input" {...getRootProps()} >
                                        <input {...getInputProps()} />
                                        {
                                            <div className="flex-center box-img">
                                                <p style={{ width: '100%', textAlign: 'center' }}>
                                                    <i className="material-icons">
                                                        note_add</i>
                                                    <br /> Arraste ou escolha um arquivo de imagem</p>
                                            </div>
                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                        : this.props.sku == true 
                        
                        
                        ? <Dropzone
                            onDrop={this.onImageDrop}
                            accept="image/*"
                            multiple={true}>
                                {({ getRootProps, getInputProps }) => {
                                    return (
                                        <div className="hover-input" style={{ width: '100%' }} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {
                                                <div className="flex-center d-flex justify-content-center box-img" style={{ width: '100%', height: '50px'}}>
                                                    <p className="d-flex justify-content-center align-items-center mt-3" style={{ color: "#A1A3A5", fontWeight: "500", fontSize: "12px" }}>
                                                        <i className="material-icons"> note_add </i>&nbsp;&nbsp;ARRASTE ATÉ AQUI OU CLIQUE PARA ESCOLHER UM ARQUIVO 
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    )
                                }}
                        </Dropzone>
                        
                        : <Dropzone
                            onDrop={this.onImageDrop}
                            accept="image/*"
                            multiple={true}>
                                {({ getRootProps, getInputProps }) => {
                                    return (
                                        <div className="hover-input" style={{ width: '100%' }} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {
                                                <div className="flex-center box-img" style={{ width: '100%' }}>
                                                    <p style={{ width: '100%', textAlign: 'center' }}>
                                                        <i className="material-icons"> note_add </i>
                                                        <br /> Arraste ou escolha um arquivo de imagem
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    )
                                }}
                        </Dropzone>
                        
                    }
                </DndProvider>


            </main>
        )
    }

}

export default NewDropImage;