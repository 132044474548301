import React, { useState, useEffect } from 'react';
import { getToken, onMessageListener } from '../../push-notification';
import { API, APIV2 } from '../../API'
import MenuBar from './MenuBar/index.js'
import { getNotificationPermission } from '../../push-notification';
import { ToastContainer, toast } from 'react-toastify';
import { useConfig } from '../../providers/ConfigProvider';


// export class Menu extends Component {
//     constructor(props) {
//         super(props);
//         logout = logout.bind(this)
//         state = {
//             userName: 'Administrador',
//             retailerName: localStorage.getItem('retailerName'),
//             iconUrl: localStorage.getItem('retailerIconUrl'),
//         }
//     }
//     componentDidMount() {

//         getNotificationPermission()
//       .then((firebaseToken) => {
//         // eslint-disable-next-line no-console
//         let body = {Token: firebaseToken, Role: localStorage.getItem('role')}
//         APIV2.put('/management/retailer/pushnotificationtoken', body)
//       })
//       .catch((err) => {
//         return err;
//       });
//         //Consulta materiais disponíveis a partir do token do usuário.
//         if (localStorage.getItem('token')) {
//             API.get('api/buyers')
//                 .then(async response => {

//                     // If request is good...
//                     if (response.data.fullName == '' || response.data.fullName == undefined || response.data.fullName == null) {
//                         setState({
//                             userName: response.data.email,
//                             loading: false,
//                         })
//                     } else {
//                         setState({
//                             userName: response.data.fullName,
//                             loading: false,
//                         })
//                     }

//                     forceUpdate();

//                 })
//                 .catch((error) => {
//                     console.log(error)
//                 })
//         }
//     }
//     logout() {
//         localStorage.clear();
//         window.location.href = '/login'
//     }
//     render() {
//         
//     }
// }

export default function Menu(props) {

	const [userName, setUserName] = useState('Administrador');
	const [retailerName, setRetailerName] = useState(localStorage.getItem('retailerName'));
	const [iconUrl, setIconUrl] = useState(localStorage.getItem('retailerIconUrl'));
	const [isTokenFound, setTokenFound] = useState(false);


	useEffect(() => {
		//console.log(isTokenFound)
	}, [isTokenFound,])

	getToken(setTokenFound);
	
	onMessageListener()
		.then(payload => {
			var audio = new Audio("/images/alert-push-01.mp3");
			audio.play();
			toast.success(<button onClick={() => window.location.href = '/orders/1'} className="push-order" >{payload.notification.title}</button>)
		}).catch(err => console.log('failed: ', err));

	useEffect(() => {
		if (navigator.vendor !== 'Apple Computer, Inc.') {
			getNotificationPermission()
				.then((firebaseToken) => {
					let body = { Token: firebaseToken, Role: localStorage.getItem('role') }
					APIV2.put('/management/retailer/pushnotificationtoken', body)
				})
				.catch((err) => {
					return err;
				});
		}
		//Consulta materiais disponíveis a partir do token do usuário.
		// if (localStorage.getItem('token')) {
		// 	API.get('api/buyers')
		// 		.then(async response => {
		// 			if (!response.data.fullName) {
		// 				setUserName(response.data.email)
		// 			} else {
		// 				setUserName(response.data.fullName)
		// 			}

		// 		})
		// 		.catch((error) => {
		// 			console.log(error)
		// 		})
		// }
	}, [])

	const logout = () => {
		localStorage.clear();
		window.location.href = '/login'
	}


	return (
		<>
			<header className="header header-primary">
				<div className="container d-flex align-items-center">
					<button className="navbar-toggler d-block d-lg-none" type="button" data-toggle="collapse" data-target="#conteudoNavbarSuportado" aria-controls="conteudoNavbarSuportado" aria-expanded="true" aria-label="Alterna navegação">
						<span className="material-icons">menu</span>
					</button>
					<span className="badge badge-primary d-none d-lg-block">beta</span>
					<a className="brand" href="/">
						<span className="brand-logo">
							<img src="/images/logo-primary.svg" height="30" />
						</span>
					</a>
					<div className="navbar-wrapper">
						<ul className="nav  float-right d-flex align-items-center">
							{/* <li className="dropdown dropdown-notification nav-item">
							<a className="nav-link nav-link-label" href="#" data-toggle="dropdown">
								<i className="material-icons">notifications_none</i>
								<span className="badge badge-pill badge-danger badge-up">5</span>
							</a>
							<ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
								<li className="dropdown-menu-header">
									<div className="dropdown-header d-flex justify-content-between">
										<span className="notification-title">7 novas notificações</span>
									</div>
								</li>
								<li className="scrollable-container media-list ps">
									<ul className="list-unstyled">
										<li className="media new">
											<div className="media-icon">
												<div className="icon">
													<i className="material-icons">receipt</i>
												</div>
											</div>
											<div className="media-body">
												<h5>Novo pedido nº09384203!</h5>
												<span className="media-date">20 de janeiro de 2020</span>
											</div>
											<div className="media-status">

											</div>
										</li>
										<li className="media">
											<div className="media-icon">
												<div className="icon">
													<i className="material-icons">receipt</i>
												</div>
											</div>
											<div className="media-body">
												<h5>Novo pedido nº09384203!</h5>
												<span className="media-date">20 de janeiro de 2020</span>
											</div>
											<div className="media-status">

											</div>
										</li>
									</ul>
								</li>
								<li className="dropdown-menu-footer">
									<a className="dropdown-item p-50 text-primary justify-content-center" onClick={() => console.log('teste notificaçãoes')}>Ver todas as notificações</a>
								</li>
							</ul>
						</li> */}
							<li className="dropdown dropdown-user nav-item">
								<a className="dropdown-toggle nav-link dropdown-user-link d-flex" href="#" data-toggle="dropdown">
									<div className="user-nav d-none d-lg-block">
										<span className="user-name">{userName}</span>
										<span className="user-role">{retailerName}</span>
									</div>
									<span className="user-avatar d-flex align-items-center">
										<img className="round" src={(iconUrl != null && iconUrl != 'undefined') ? iconUrl : "/images/avatar.jpg"} alt="avatar" height="40" width="40" />
									</span>
								</a>
								<div className="dropdown-menu dropdown-menu-right">
									{/* <a className="dropdown-item" href="#">
									<i className="material-icons">person_outline</i> Meu perfil
								</a>
								<div className="dropdown-divider mb-0"></div> */}
									<button className="dropdown-item" onClick={() => logout()} >
										<i className="material-icons">power_settings_new</i> Sair
								</button>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</header>
			<MenuBar />
			{/*  {
			process.env.REACT_APP_AMBIENTE !== 'Produção' ?
			<Breadcrumb /> : null
		} */}
		</>
	)
}
