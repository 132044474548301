import React, { Component } from "react";
import { closedRoutes, openRoutes } from "./Routes";
import { connect } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Stepper from "./components/Stepper";
import Redirects from "./Redirect";
import Error from "./screens/404/Index";

import Breadcrumbs from "./components/layout/Breadcrumb";

import "antd/dist/antd.css";
import "./App.css";
import "./assets/css/style.scss";
import "react-toastify/dist/ReactToastify.css";

require("moment/locale/pt-br.js");
require("moment-timezone");
require("dotenv/config");

function PrivateRoute({
	layout: Layout,
	component: Component,
	authed,
	...rest
}) {
	return (
		<Route
			{...rest}
			render={(props) => {
				const crumbs = closedRoutes
					.filter(({ path }) => props.match.path.includes(path))
					.map(({ path, ...rest }) => ({
						path: Object.keys(props.match.params).length
							? Object.keys(props.match.params).reduce(
									(path, param) =>
										path.replace(`:${param}`, props.match.params[param]),
									path
							  )
							: path,
						...rest,
					}));

				return authed ? (
					Layout ? (
						<Layout {...props} crumbs={crumbs}>
							<Component {...props} />
						</Layout>
					) : (
						<Component {...props} />
					)
				) : (
					<Redirect
						to={{ pathname: "/login", state: { from: props.location } }}
					/>
				);
			}}
		/>
	);
}

function OpenRoute({ component: Component, authed, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				!authed ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: "/dashboard", state: { from: props.location } }}
					/>
				)
			}
		/>
	);
}

export class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setup: false,
			url: window.location.pathname.split("/")[2],
			setupUrl: window.location.pathname.split("/")[1],
			crumbs: {},
		};
	}

	componentDidMount() {
		if (this.state.url == "setup" || this.state.setupUrl == "setup") {
			this.setState({ setup: true });
			this.forceUpdate();
		}
	}
	componentWillReceiveProps() {
		this.setState({
			url: window.location.pathname.split("/")[2],
			setupUrl: window.location.pathname.split("/")[1],
		});
		this.forceUpdate();
	}

	render() {
		return (
			<div>
				<ToastContainer
					position="bottom-right"
					autoClose={5000}
					hideProgressBar={false}
					closeOnClick={true}
					pauseOnHover={true}
					draggable={true}
					progress={undefined}
					preventDuplicate={true}
				/>
				{process.env.REACT_APP_AMBIENTE != "Produção" ? (
					<div className="alert alert-neomode" role="alert">
						👋 Você está em ambiente de <b>{process.env.REACT_APP_AMBIENTE} </b>
					</div>
				) : null}

				{this.state.setupUrl == "setup" ? (
					this.state.url != "welcome" && this.state.url != "confirm" ? (
						<div></div>
					) : null
				) : null}

				{this.state.setupUrl == "setup" ? (
					this.state.url !== "welcome" && this.state.url !== "confirm" ? (
						<Stepper />
					) : null
				) : null}
				<Router>
					<Switch>
						<Route path="/redirect" component={Redirects} />
						{openRoutes.map(({ path, name, Component }, index) => (
							<OpenRoute
								hey={index}
								authed={localStorage.getItem("token")}
								path={path}
								component={Component}
							/>
						))}

						{closedRoutes.map(({ path, name, Component, Layout }, index) => (
							<PrivateRoute
								exact
								hey={index}
								authed={localStorage.getItem("token")}
								path={path}
								component={Component}
								layout={Layout}
							/>
						))}
						<Route path="*" component={Error} />
					</Switch>
				</Router>
			</div>
		);
	}
}
export default connect((store) => ({
	text: store.text,
	req: store.req,
	state: store.state,
	token: store.token,
	res: store.res,
}))(App);
