import React, { createContext, useContext, useEffect, useState } from "react";
import { API } from "../../../../../API";
import { toast } from "react-toastify";

export const VouchersProvider = createContext({});

export default function VouchersContext({ children }) {
	const [loading, setLoading] = useState(true);
	const [disable, setDisable] = useState(true);
	const [coupon, setCoupon] = useState([]);
	const [voucher, setVoucher] = useState({
		startDate: new Date(),
		endDate: new Date(),
		tipoDesconto: "real",
		code: "",
		minValue: "",
		maxValue: "",
		minQuantity: "",
		freeShipping: false,
		isApplyInTotalCart: false,
		hasNoFeeInstallments: false,
		discountPercentage: "",
		discountValue: "",
		documents: [],
		products: [],
		categories: [],
		categoriesId: [],
		states: [],
		cities: [],
		tags: [],
		sellers: [],
		sellerId: "",
		maxInstallmentsWithoutFee: "",
		oneByUser: "",
		firstUse: "",
		shippingType: "",
		paymentMethods: "",
		amountOrders: "",
		subTotalOrders: "",
		ticketAverage: "",
		discountTotalOrders: "",
		isNotApplyProductReprice: "",
		usageLimit: 0,
		discountApplyType: 3,
	});

	useEffect(() => {
		setDisable(false);
	}, [voucher]);

	const getNewVouchers = async (id) => {
		id &&
			(await API.get(`api/vouchers/info?id=${id}`)
				.then((response) => {
					setVoucher(response.data);
					setLoading(false);
					setDisable(false);
				})
				.catch((error) => {
					setLoading(false);
					setDisable(false);
					console.log(error);
				}));
	};

	const createCupom = (event) => {
		event.preventDefault();
		API.post(`api/vouchers/save`, voucher)
			.then(async (response) => {
				toast.success("Voucher criado com sucesso! 😃");
				window.location.href = "/vouchers/1";
				setDisable(false);
			})
			.catch((error) => {
				setDisable(false);
				console.log(error);
				if (error.response.data) {
					toast.error(error.response.data.Message);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const updateCupom = (event) => {
		event.preventDefault();
		API.post(`api/vouchers/update`, voucher)
			.then(async (response) => {
				toast.success("Voucher editado com sucesso! 😃");
				setDisable(false);
				window.location.href = "/vouchers/1";
			})
			.catch((error) => {
				console.log(error);
				setDisable(false);
				if (error.response.data) {
					toast.error(error.response.data.Message);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	return (
		<VouchersProvider.Provider
			value={{
				loading,
				disable,
				coupon,
				setCoupon,
				voucher,
				setVoucher,
				setLoading,
				getNewVouchers,
				createCupom,
				updateCupom,
			}}
		>
			{children}
		</VouchersProvider.Provider>
	);
}

export const useVouchers = () => useContext(VouchersProvider);
