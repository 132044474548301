import React, { useEffect, useState } from "react";
import { useVouchers } from "../providers/VouchersContext";
import Button from "../../../../../components/Button";
import DatePickerNeo from "../../../../../components/DatePickerNeo";
import MaskPrice from "../../../../../components/MaskPrice";
import CurrencyFormat from "react-currency-format";
import VouchersConditions from "./VouchersConditions";
import ContentLoader from "react-content-loader";

export default function VouchersForm({ id }) {
	const {
		disable,
		loading,
		voucher,
		setVoucher,
		setLoading,
		getNewVouchers,
		createCupom,
		updateCupom,
	} = useVouchers();
	const [editing, setEditing] = useState(false);
	const [discount, setDiscount] = useState("numericalValue");

	useEffect(() => {
		getNewVouchers(id);
		if (id) {
			setEditing(true);
		} else {
			setEditing(false);
			setLoading(false);
		}
	}, []);

	const randomCode = () => {
		let text = "";
		const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		for (let i = 0; i < 6; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		setVoucher({ ...voucher, code: text });
	};

	const handleDiscountValue = (e) => {
		if (e.value) {
			setVoucher({
				...voucher,
				discountValue: "",
				discountPercentage: e.value,
			});
		} else {
			let value = e.currentTarget.value;
			value = value.replace(/\D/g, "");
			value = value.replace(/(\d)(\d{2})$/, "$1.$2");
			e.currentTarget.value = value;
			setVoucher({
				...voucher,
				discountValue: value,
				discountPercentage: "",
			});
		}
	};

	const skeleton = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "10px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="40" height="20" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="40" height="20" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="180" height="40" />
					<rect x="220" y="25" rx="3" ry="3" width="180" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", marginBottom: "35px" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="180" height="40" />
				</ContentLoader>
			</>
		);
	};

	const renderScreen = () => {
		return (
			<div className="card-content">
				<div className="card-body pt-0">
					<div className="card-header ml-0 pl-0">
						<h4 className="card-title">
							{editing ? "Editar cupom de desconto" : "Novo cupom de desconto"}
						</h4>
					</div>
					<form onSubmit={editing ? updateCupom : createCupom}>
						{/* codigo do cupom */}
						<div className="form-group row">
							<label className="col-12" htmlFor="code">
								<strong>Código do cupom</strong>
							</label>
							<div className="col-sm-4">
								<input
									type="text"
									maxLength="10"
									className="form-control"
									disabled={!editing ? false : true}
									id="code"
									placeholder="ABC123456"
									name="code"
									value={voucher.code}
									onChange={(event) =>
										setVoucher({ ...voucher, code: event.target.value })
									}
								/>
							</div>
							{!editing && (
								<div className="col-sm-8">
									<a
										className="btn btn-link px-0"
										href="#"
										onClick={randomCode}
									>
										Gerar código de cupom
									</a>
								</div>
							)}
						</div>
						{/* limite de uso */}
						<div className="form-group row">
							<label className="col-12" htmlFor="usageLimit">
								<strong>Limite de uso</strong>
							</label>
							<div className="col-sm-4">
								<input
									type="number"
									min="0"
									maxLength="6"
									className="form-control"
									disabled={!editing ? false : true}
									id="usageLimit"
									name="usageLimit"
									placeholder="0"
									value={voucher.usageLimit}
									onChange={(event) =>
										setVoucher({ ...voucher, usageLimit: event.target.value })
									}
								/>
							</div>
							<div className="col-sm-8 d-flex align-items-center">
								<small id="emailHelp" className="text-muted">
									0 = ilimitado
								</small>
							</div>
						</div>
						{/* desconto */}
						<div className="form-group row">
							<label className="col-12" htmlFor="desconto">
								<strong>Desconto</strong>
							</label>
							<div className="input-group col-sm-5">
								<div className="input-group-prepend">
									<label htmlFor="inputGroupSelect01">
										{editing ? (
											voucher.discountPercentage ? (
												<p className="custom-select btn-light">Porcentagem</p>
											) : (
												<p className="custom-select btn-light">Valor</p>
											)
										) : (
											<select
												className="custom-select btn-light"
												value={discount}
												onChange={(event) => setDiscount(event.target.value)}
											>
												<option selected value="numericalValue">
													Valor
												</option>
												<option value="percentage">Porcentagem</option>
											</select>
										)}
									</label>
								</div>
								{editing ? (
									voucher.discountValue ? (
										<CurrencyFormat
											value={String(voucher.discountValue)}
											displayType={"input"}
											name="discountValue"
											disabled
											className="form-control disabled"
											decimalSeparator={"."}
											prefix={"R$"}
											onValueChange={(values) => {
												const { formattedValue, value } = values;
												voucher.discountValue = parseFloat(value);
											}}
										/>
									) : (
										<CurrencyFormat
											value={String(voucher.discountPercentage)}
											suffix={"%"}
											format={"##%"}
											disabled
											className="form-control disabled"
											name="discountPercentage"
											thousandSeparator={true}
											onValueChange={(values) => {
												const { formattedValue, value } = values;
												voucher.discountPercentage = parseFloat(value);
											}}
										/>
									)
								) : discount === "numericalValue" ? (
									<div
										className="input-group-prepend d-flex flex-nowrap"
										style={{ height: "38px" }}
									>
										<div
											className="input-group-text"
											style={{ fontSize: "13px", fontWeight: "500" }}
										>
											R$
										</div>
										<MaskPrice
											className="form-control"
											defaultValue={String(
												voucher.discountValue.toLocaleString("pt-br", {
													minimumFractionDigits: 2,
												})
											)}
											name="oldPrice"
											onChange={handleDiscountValue}
										/>
									</div>
								) : (
									<CurrencyFormat
										value={String(voucher.discountPercentage)}
										suffix={"%"}
										format={"##%"}
										className="form-control"
										name="discountPercentage"
										thousandSeparator={true}
										onValueChange={handleDiscountValue}
									/>
								)}
							</div>
						</div>
						{/* frete gratis */}
						<div className="form-group d-flex flex-wrap">
							<label className="switch ">
								<input
									type="checkbox"
									disabled={!editing ? false : true}
									value={voucher.freeShipping}
									checked={voucher.freeShipping}
									onChange={(event) =>
										setVoucher({
											...voucher,
											freeShipping: event.target.checked,
										})
									}
								/>
								<span className="slider round"></span>
							</label>
							<label className="ml-2" htmlFor="status">
								<strong>Frete grátis?</strong>
							</label>
							<br />
						</div>
						{/* Aplicar desconto no valor total da compra? */}
						<div className="form-group d-flex flex-wrap">
							<label className="switch">
								<input
									id="status"
									type="checkbox"
									disabled={!editing ? false : true}
									value={voucher.isApplyInTotalCart}
									checked={voucher.isApplyInTotalCart}
									onChange={(event) =>
										setVoucher({
											...voucher,
											isApplyInTotalCart: event.target.checked,
										})
									}
								/>
								<span className="slider round"></span>
							</label>
							<label className="ml-2" htmlFor="status">
								<strong>Aplicar desconto no valor total da compra?</strong>
								<button
									type="button"
									className="btn btn-secondary btn-link btn-icon"
								>
									<i
										style={{ fontSize: "14px" }}
										className="material-icons ml-1"
										data-toggle="tooltip"
										data-placement="top"
										title="Atenção, o desconto será aplicado tanto no produto quanto no frete !"
									>
										error_outline
									</i>
								</button>
							</label>
							<br />
						</div>
						{/* datas */}
						<div className="form-group d-flex flex-wrap">
							<label className="w-100">
								<strong>Vigência</strong>
							</label>
							<br />
							<div className="mr-5">
								<DatePickerNeo
									label="Data início"
									dateFormat="dd/MM/yyyy, HH:mm"
									showTime={{ user12hours: false }}
									time={true}
									selected={new Date(voucher.startDate)}
									onChange={(date) =>
										setVoucher({
											...voucher,
											startDate: new Date(date),
										})
									}
									minDate={new Date()}
								/>
							</div>
							<div className="" style={{ zIndex: "1000" }}>
								<DatePickerNeo
									label="Data de término"
									dateFormat="dd/MM/yyyy, HH:mm"
									time={true}
									selected={
										voucher.endDate < voucher.startDate
											? new Date(voucher.startDate)
											: new Date(voucher.endDate)
									}
									onChange={(date) =>
										setVoucher({
											...voucher,
											endDate: new Date(date),
										})
									}
									minDate={new Date()}
								/>
							</div>
						</div>
						<VouchersConditions editing={editing} id={id} />
						<div className="form-group mt-5">
							<Button
								className="btn btn-success"
								type="submit"
								loading={disable}
							>
								{editing ? "Editar cupom" : "Criar cupom"}
							</Button>
							<Button
								onClick={() => (window.location.href = " /vouchers/1")}
								className="btn btn-light ml-3"
							>
								Cancelar
							</Button>
						</div>
					</form>
				</div>
			</div>
		);
	};
	return <>{loading ? skeleton() : renderScreen()}</>;
}
