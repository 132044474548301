import React from "react";
import CurrencyFormat from "react-currency-format";
import "react-widgets/dist/css/react-widgets.css";
import { usePromotion } from "../Providers/PromotionContext";

export default function MechanicsPromotions() {
	const { rules, setRules, setLoading } = usePromotion();

	const limit = (val, max) => {
		if (val.length === 1 && val[0] > max[0]) {
			val = +val;
		}
		if (val.length === 1) {
			if (Number(val) === 0) {
				val = "1";
			} else if (val > max) {
				val = max;
			}
		}
		if (val.length === 3) {
			if (Number(val) === 0) {
				val = "1";
			} else if (val > max) {
				val = max;
			}
		}
		return val;
	};

	const percentage = (val) => {
		let percentageMax = limit(val.substring(0, 3), "100");
		return `${percentageMax}%`;
	};

	const chageCartItemQuantity = (event) => {
		const value = event.target.value;
		const id = event.target.name;
		rules.filter((data) => {
			if (data.tempId === id) {
				var newData = { ...data, cartItemQuantity: value };
				setRules((prevState) => [
					...prevState.filter((state) => state.tempId !== id),
					newData,
				]);
			}
		});
	};

	const chageQuantityThatWillReceiveDiscount = (event) => {
		const value = event.target.value;
		const id = event.target.name;
		rules.filter((data) => {
			if (data.tempId === id) {
				var newData = { ...data, quantityThatWillReceiveDiscount: value };
				setRules((prevState) => [
					...prevState.filter((state) => state.tempId !== id),
					newData,
				]);
			}
		});
	};

	const changeApplyFromCartItemsQuantity = (event) => {
		const value = event.target.checked;
		const id = event.target.name;
		rules.filter((data) => {
			if (data.tempId === id) {
				var newData = { ...data, applyFromCartItemsQuantity: value };
				setRules((prevState) => [
					...prevState.filter((state) => state.tempId !== id),
					newData,
				]);
			}
		});
	};

	const onchangeDiscountPercentage = (values, id) => {
		rules.filter((data) => {
			if (data.tempId === id) {
				var newData = {
					...data,
					discountPercentage: values.formattedValue,
				};
				setRules((prevState) => [
					...prevState.filter((state) => state.tempId !== id),
					newData,
				]);
			}
		});
	};

	const onchangeDiscountValue = (values, id) => {
		rules.filter((data) => {
			if (data.tempId === id) {
				var newData = {
					...data,
					discountValue: values.floatValue,
				};
				setRules((prevState) => [
					...prevState.filter((state) => state.tempId !== id),
					newData,
				]);
			}
		});
	};

	const onChangeMaxValueItem = (values, id) => {
		rules.filter((data) => {
			if (data.tempId === id) {
				var newData = {
					...data,
					maxValueItem: values.floatValue,
				};
				setRules((prevState) => [
					...prevState.filter((state) => state.tempId !== id),
					newData,
				]);
			}
		});
	};

	const removeItem = (id) => {
		setLoading(true);
		const removeRules = rules.filter((item) => item.tempId !== id);
		setRules(removeRules);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};

	return (
		<>
			{rules
				.sort((a, b) => a.order - b.order)
				.map((data) => (
					<div className="col-7 ml-3 card-promotions p-3 mt-4">
						<button
							onClick={() => removeItem(data.tempId)}
							type="button"
							className="close-img"
						></button>
						<p>{data.label}</p>
						<div className="form-group d-flex flex-column">
							{data.labelCartItemQuantity === "cartItemQuantity" && (
								<div className="form-group d-flex flex-row">
									<label
										htmlFor="cartItemQuantity"
										className="col-4 col-form-label"
									>
										Quantidade mínima
									</label>
									<div className="col-sm-6">
										<input
											type="number"
											min="1"
											className="form-control"
											placeholder="00"
											id="cartItemQuantity"
											name={data.tempId}
											defaultValue={data.cartItemQuantity}
											onChange={chageCartItemQuantity}
										/>
									</div>
								</div>
							)}

							{data.labelQuantityThatWillReceiveDiscount ===
								"quantityThatWillReceiveDiscount" && (
								<div className="form-group d-flex flex-row">
									<label
										htmlFor="quantityThatWillReceiveDiscount"
										className="col-4 col-form-label"
									>
										Quantidade receberá benefício
									</label>
									<div className="col-sm-6">
										<input
											type="number"
											min="1"
											className="form-control"
											placeholder="00"
											id="quantityThatWillReceiveDiscount"
											name={data.tempId}
											defaultValue={data.quantityThatWillReceiveDiscount}
											onChange={chageQuantityThatWillReceiveDiscount}
										/>
									</div>
								</div>
							)}

							{data.labelApplyFromCartItemsQuantity ===
								"applyFromCartItemsQuantity" && (
								<div className="form-grou d-flex flex-row">
									<label
										htmlFor="applyFromCartItemsQuantity"
										className="col-4 col-form-label"
									>
										Aplicável a partir de uma quantidade
									</label>

									<div className="">
										<label className="switch ml-3">
											<input
												type="checkbox"
												id="applyFromCartItemsQuantity"
												name={data.tempId}
												defaultValue={data.applyFromCartItemsQuantity}
												defaultChecked={data.applyFromCartItemsQuantity}
												onChange={changeApplyFromCartItemsQuantity}
											/>
											<span className="slider round"></span>
										</label>
									</div>
								</div>
							)}

							{data.labelDiscountPercentage === "discountPercentage" && (
								<div className="form-group d-flex flex-row">
									<label className="col-4 col-form-label">Desconto</label>
									<div className="col-sm-6">
										<CurrencyFormat
											suffix={"%"}
											format={percentage}
											className="form-control"
											thousandSeparator={true}
											placeholder="0%"
											name={data.tempId}
											value={data.discountPercentage}
											onValueChange={(values) =>
												onchangeDiscountPercentage(values, data.tempId)
											}
										/>
									</div>
								</div>
							)}

							{data.labelDiscountValue === "discountValue" && (
								<div className="form-group d-flex flex-row">
									<label className="col-4 col-form-label">Desconto</label>
									<div className="col-sm-6">
										<CurrencyFormat
											prefix={"R$"}
											className="form-control"
											decimalSeparator={","}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandSeparator={`.`}
											placeholder="R$ 00,00"
											name={data.tempId}
											value={!data.discountValue ? "" : data.discountValue}
											onValueChange={(values) =>
												onchangeDiscountValue(values, data.tempId)
											}
										/>
									</div>
								</div>
							)}

							{data.labelMaxValueItem === "maxValueItem" && (
								<div className="form-group d-flex flex-row">
									<label className="col-4 col-form-label">
										Valor máximo item
									</label>
									<div className="col-sm-6">
										<CurrencyFormat
											prefix={"R$"}
											className="form-control"
											decimalSeparator={","}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandSeparator={`.`}
											placeholder="R$ 00,00"
											name={data.tempId}
											value={!data.maxValueItem ? "" : data.maxValueItem}
											onValueChange={(values) =>
												onChangeMaxValueItem(values, data.tempId)
											}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				))}
		</>
	);
}
