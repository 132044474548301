import React, { Component } from 'react';
import { API } from '../../../API';
import { connect } from 'react-redux'
import appActions from '../../../appActions';
import EditorHtml from '../../../assets/EditorHtml';
import Loading from '../../../components/Loading';

export class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {
    content: null,
    loading: true,
  }
  componentWillMount() {

    //Consulta materiais disponíveis a partir do token do usuário.
    API.get('api/privacypolicy/')
      .then(async response => {

        // If request is good...
        if (response.data == null) {
          await this.setState({
            noExist: true,
            loading: false,
          })
        } else {
          await this.setState({
            content: response.data.content,
            loading: false,

          })
        }
        this.forceUpdate();
        this.setFirstData();
      })
      .catch((error) => {
        console.log(error)
        this.setState({

          loading: false,

        })
      })
  }
  componentDidMount() {
    this.setNextRoute();
    this.setFirstData();
  }

  setNextRoute() {
    //Passa para o Redux o proximo passo do autosetup
    this.props.dispatch(appActions.setNextRoute('/setup/app-store/', false))
  }
  setFirstData() {
    let data = {
      content: this.state.content,
    }
    //Passa para o Redux os dados para put da API
    //ajustar o TRUe para um state 
    this.props.dispatch(appActions.sendData(data, true, 'api/privacypolicy/', 'content', 'api/autosetup/email/stepsellercomplete'))
  }
  async handleChange(e) {

    await this.setState({ content: e });

    this.forceUpdate();
    let data = {
      content: this.state.content,
      loading: false,

    }
    //Passa para o Redux os dados para put da API
    //ajustar o TRUe para um state 
    this.props.dispatch(appActions.sendData(data, true, 'api/privacypolicy/', 'content'))
  }
  render() {
    if (this.state.loading) {
      return (
        <Loading />
      )
    }
    else {
      return (
        this.renderScreen()
      )
    }
  }
  renderScreen() {
    return (
      <div className="terms-of-use d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col-12">
            <div className="container sm">
              <h1 className="text-center " >Política de Privacidade</h1>
              <div className="mt-4 text-center"  >
                <p>Para garantir a privacidade a segurança dos seus usuários você precisará publicar sua política de privacidade, garantindo através de requisitos legais a proteção de dados coletada no app.</p>
                <div className="alert alert-danger" role="alert">
                  O modelo utilizado de exemplo deverá ser <strong>validado e reformulado</strong> junto ao setor jurídico da sua empresa antes da publicação no app
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="container" style={{ margin: 'auto', width: '90%', marginTop: '20px' }}>
              <EditorHtml data={this.state.content} change={this.handleChange} />
              <br />
              <button className='btn btn-light btn-icon-l outline'
                data-container="body"
                data-toggle="popover"
                title="Não esqueça"
                data-placement="top"
                data-content="Valide o conteúdo previamente junto ao jurídico de sua empresa."
                onClick={() => window.open('https://loriprd.blob.core.windows.net/lori-static/f8b7bfb4-c62a-48d1-ae57-d53d03d3b326.docx')}>
                <i className="material-icons"
                  style={{ fontSize: '24', float: 'left', marginRight: '10px' }}>
                  vertical_align_bottom
                </i>
                Baixe aqui nosso modelo de política de privacidade
                </button>
            </div>
          </div>
        </div>
      </div>

    )
  }
};

export default connect(store => ({ text: store.text, req: store.req }))(PrivacyPolicy);
