import React, { Component, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { API, APIV2 } from "../../API";
import Loading from "../../components/Loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TextInputMask from "react-masked-text";
import SelectInputNeo from "../../components/SelectInputNeo";

export default function BasicInfo(props) {
	const [loading, setLoading] = useState(true);
	const [address, setAddress] = useState({});
	const [onSave, setOnSave] = useState(false);
	const [store, setStore] = useState({
		isEcommerce: false,
		isActive: true,
		pointSaleId: "",
		gatewayId: null,
		credentialsString: "",
		googlePlacesId: null,
		contactPhone: null,
		whatsappPhoneNumber: "",
		pickUpInstructions: null,
		contactEmail: null,
		isSellerOnline: false,
		isShowSellerInMap: false,
		currentCluster: "",
		integrationConfig: {
			orderIntegrationId: "",
			fraudIntegrationId: "",
			paymentIntegrationId: "",
			promotionEngineIntegrationId: null,
			userPromotionIntegrationId: null,
			orderIntegrationCredentials: "",
			fraudIntegrationCredentials: "",
			paymentIntegrationCredentials: "",
			promotionEngineIntegrationCredentials: null,
			userPromotionCredentials: null,
		},
		address: {
			id: "",
			buyerId: null,
			street: "",
			number: "",
			complement: null,
			neighborhood: "",
			cep: "",
			city: "",
			reference: null,
			label: null,
			state: "",
			country: "Brasil",
			isPrimary: false,
			isDeleted: false,
			fullAddress: "",
		},
		addressId: "",
		modalities: [],
		companyName: "",
		cnpj: "",
		fullName: "",
		email: "",
		password: "",
		facebookId: "faceid",
		facebookToken: "facetoken",
		googleId: "googleid",
		googleToken: "googletoken",
		imageUrl: null,
		clientId: localStorage.getItem("client_id"),
		isDeleted: false,
		emailConfirmed: false,
		securityStamp: null,
		phoneNumber: null,
		phoneNumberConfirmed: false,
	});

	const [sellerClusters, setSellerClusters] = useState([]);

	useEffect(() => {
		onChangeSellerClusters();
		if (props.id) {
			APIV2.get("management/retailer/sellers/" + props.id)
				.then(async (response) => {
					setStore(response.data);
					setSellerClusters(response.data.sellerClusters);
					setAddress(response.data.address);
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
					// if (error.response.data) {
					//     toast.error(error.response.data.message)
					// } else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					// }
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		// if (store.isEcommerce) {
		setStore((prevState) => ({
			...prevState,
			address: {
				// id: null,
				buyerId: null,
				street: "",
				number: "",
				complement: null,
				neighborhood: "",
				cep: "",
				city: "",
				reference: null,
				label: null,
				state: "",
				country: "Brasil",
				isPrimary: false,
				isDeleted: false,
				fullAddress: "",
			},
		}));
		// } else {
		//     setStore(prevState => ({ ...prevState, address: address }))
		// }
	}, [store.isEcommerce]);

	const onChange = (e) => {
		let name = e.target.name;
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		setStore((prevState) => ({ ...prevState, [name]: value }));
		props.change(e);
	};

	const onChangeWhatsApp = (e) => {
		if (e.length < 4) {
			setStore((prevState) => ({ ...prevState, whatsappPhoneNumber: "" }));
		} else {
			let value = e;
			setStore((prevState) => ({ ...prevState, whatsappPhoneNumber: value }));
		}
	};

	const onChangePhoneNumber = (e) => {
		let value = e;
		setStore((prevState) => ({ ...prevState, phoneNumber: value }));
	};

	const onChangeSellerClusters = async () => {
		try {
			const response = await APIV2.get("management/retailer/sellerClusters");
			setSellerClusters(response.data.sellerClusters);
		} catch (error) {
			console.log(error);
		}
	};

	const saveInfos = async () => {
		console.log(store);
		if (
			!store.pointSaleId ||
			!store.fullName ||
			(!store.password && !props.id) ||
			!store.email
		) {
			setOnSave(true);
			return;
		}
		let body = {
			email: store.email,
		};
		try {
			if (store.id) {
				if (store.isEcommerce) {
					await APIV2.put(
						`management/retailer/sellers/${store.id}/address`,
						store.address
					);
					setOnSave(false);
				}
				await APIV2.put("management/retailer/sellers/" + store.id, store);
				await props.save();
				setOnSave(false);
				toast.success("Loja editada com sucesso! 😃");
			} else {
				const response = await APIV2.post(
					"management/retailer/sellers/",
					store
				);
				await API.post("api/users/rememberpassword", body);
				await toast.success("Loja criada com sucesso! 😃");
				setOnSave(false);
				await props.save();
				if (window.location.pathname.search("setup") === -1) {
					window.location.href = `/stores/1`;
				} else {
					window.location.href = `/setup/stores/1`;
				}
			}
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const renderForm = () => {
		return (
			<>
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Informações básicas</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<div className="row">
							<div className="form-group col-sm-6">
								<label htmlFor="">
									<strong>Id do ponto de venda *</strong>
								</label>
								<div>
									{/* input clone nessessário para tirar o auto complete */}
									<input
										className="form-control"
										type="text"
										name="pointSaleId"
										style={{ display: "none" }}
									/>
									{/* input clone nessessário para tirar o auto complete */}
									<input
										className={
											onSave && !store.pointSaleId
												? "form-control is-invalid"
												: "form-control"
										}
										name="pointSaleId"
										id="pointSaleId"
										value={store.pointSaleId}
										onChange={onChange}
										autoComplete="off"
									/>
									{onSave && !store.pointSaleId && (
										<small className="text-danger">Campo Obrigatório</small>
									)}
								</div>
							</div>
							<div className="form-group col-sm-5">
								<label htmlFor="nome">
									<strong>Nome completo</strong>
								</label>
								<div>
									<input
										type="text"
										className={
											onSave && !store.fullName
												? "form-control is-invalid"
												: "form-control"
										}
										id="nome"
										name="fullName"
										value={store.fullName}
										onChange={onChange}
									/>
									{onSave && !store.fullName && (
										<small className="text-danger">Campo Obrigatório</small>
									)}
								</div>
							</div>
							<div className="form-group col-sm-1">
								<label htmlFor="productActive">
									<strong>Ativo?</strong>
								</label>
								<br />
								<label className="switch ">
									<input
										id="Status"
										type="checkbox"
										name="isActive"
										value={store.isActive}
										defaultChecked={store.isActive}
										onChange={onChange}
									/>
									<span className="slider round"></span>
								</label>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="email">
									<strong>Email</strong>
								</label>
								<div>
									<input
										className="form-control"
										type="email"
										name="email"
										style={{ display: "none" }}
									/>
									<input
										type="email"
										className={
											onSave && !store.email
												? "form-control is-invalid"
												: "form-control"
										}
										id="email"
										name="email"
										value={store.email != null ? store.email : ""}
										onChange={onChange}
										required
										autoComplete="off"
									/>
									{onSave && !store.email && (
										<small className="text-danger">Campo Obrigatório</small>
									)}
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="senha">
									<strong>Senha</strong>
								</label>
								<div>
									<input
										className="form-control"
										type="password"
										name="password"
										style={{ display: "none" }}
									/>
									<input
										type="password"
										autoComplete="new-password"
										className={
											onSave && !store.password
												? "form-control is-invalid"
												: "form-control"
										}
										id="senha"
										name="password"
										value={store.password != null ? store.password : ""}
										onChange={onChange}
										required
									/>
									{onSave && !store.password && (
										<small className="text-danger">Campo Obrigatório</small>
									)}
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="cnpj">
									<strong>CNPJ</strong>
								</label>
								<div>
									<InputMask
										mask="99.999.999/9999-99"
										className="form-control"
										type="text"
										placeholder="99.999.999/9999-99"
										id="cnpj"
										name="cnpj"
										value={store.cnpj != null ? store.cnpj : ""}
										onChange={onChange}
									/>
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="social">
									<strong>Razão social</strong>
								</label>
								<div>
									<input
										type="text"
										className="form-control"
										id="social"
										name="companyName"
										value={store.companyName != null ? store.companyName : ""}
										onChange={onChange}
										required
									/>
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="phoneNumber">
									<strong>Telefone</strong>
								</label>
								<div>
									<TextInputMask
										className="form-control"
										value={store.phoneNumber != null ? store.phoneNumber : ""}
										kind={"cel-phone"}
										onChangeText={onChangePhoneNumber}
									/>
									{/* <InputMask mask={store.phoneNumber.length > 14 ? '(99) 9999?-9999?' : '(99) 9999-9999'}
                                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                                        className="form-control" type="text" placeholder="(99) 9999-9999" id="phoneNumber" name='phoneNumber' value={store.phoneNumber != null ? store.phoneNumber : ''} onChange={onChange} /> */}
									{/* <input type="text" className="form-control" id="phoneNumber" name='phoneNumber' value={store.phoneNumber != null ? store.phoneNumber : ''} onChange={onChange} required /> */}
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="whatsappPhoneNumber">
									<strong>Número do Whatsapp</strong>
								</label>
								<PhoneInput
									style={{
										width: "20px !important",
										height: "20px !important",
									}}
									countryCodeEditable={false}
									masks={{ br: "(..) . ....-...." }}
									country={"br"}
									value={store.whatsappPhoneNumber}
									onChange={(phone) => onChangeWhatsApp(phone)}
								/>
								{/* <InputMask mask="(99) 99999-9999" className="form-control" type="text" placeholder="(99) 99999-9999" id="whatsappPhoneNumber" name='whatsappPhoneNumber' value={store.whatsappPhoneNumber != null ? store.whatsappPhoneNumber : ''} onChange={onChange} /> */}
								{/* <input type="text" className="form-control" id="whatsappPhoneNumber" name='whatsappPhoneNumber' value={store.whatsappPhoneNumber != null ? store.whatsappPhoneNumber : ''} onChange={onChange} required /> */}
							</div>

							{sellerClusters.length > 0 && (
								<div className="form-group col-sm-6">
									<label htmlFor="whatsappPhoneNumber">
										<strong>Grupo Econômico</strong>
									</label>
									<SelectInputNeo
										inputsArraySelect={sellerClusters}
										inputValue={store.currentCluster}
										inputChange={(e) =>
											setStore((prevState) => ({
												...prevState,
												currentCluster: e,
											}))
										}
									/>
								</div>
							)}

							<div className="list-group-item border-0 col-sm-6">
								<div
									className={`custom-control custom-checkbox ${
										sellerClusters.length > 0 ? "mt-3" : ""
									}`}
								>
									<input
										type="checkbox"
										id="isEcommerce"
										name="isEcommerce"
										className="custom-control-input"
										checked={props.store.isEcommerce}
										onChange={onChange}
									/>
									<label className="custom-control-label" htmlFor="isEcommerce">
										Essa loja é um e-commerce
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-20 ml-2">
					<div className="col-12 d-flex flex-row-reverse">
						<button
							type="button"
							className="btn btn-primary"
							onClick={saveInfos}
						>
							Salvar
						</button>
					</div>
				</div>
			</>
		);
	};

	return <>{loading ? <Loading /> : renderForm()}</>;
}
