import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API, APIV2 } from "../../../API";
import Pagination from "react-js-pagination";
import Modal from "../../../components/Modal";
import List from "../../../components/List/List";
import { toast } from "react-toastify";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import ContentLoader from "react-content-loader";
import Filter from "./Filter";
import { useHistory } from "react-router-dom";
import { useConfig } from "../../../providers/ConfigProvider";
import Button from "../../../components/Button";

export default function ProductList(props) {
	const history = useHistory();
	const { managerConfigs } = useConfig();
	const [load, setLoad] = useState(false);
	const [products, setProducts] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [totalProducts, setTotalProducts] = useState(null);
	const [page, setPage] = useState(props.page);
	const [query, setQuery] = useState(props.query === null ? "" : props.query);
	const [filterBackground, setFilterBackGround] = useState(false);
	const [sortField, setSortField] = useState("name");
	const [sortAscending, setSortAscending] = useState(true);
	// NOVA VARIÁVEL
	const [rowsPerPage, setRowsPerPage] = useState(20);

	const [stock, setStock] = useState("");

	const [isActive, setIsActive] = useState("");

	const [filterData, setFilterData] = useState({
		isActive: props.isActive === null ? "" : props.isActive,
		isAvailable: props.isAvailable === null ? "" : props.isAvailable,
		hasStock: props.hasStock === null ? "" : props.hasStock,
		hasPrice: props.hasPrice === null ? "" : props.hasPrice,
		hasImage: props.hasImage === null ? "" : props.hasImage,
	});
	const loads = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	const columsList = [
		{
			name: "Produto",
			selector: "name",
			sortable: true,
			width: "100px",
			style: {
				overflow: "visible",
				color: "white",
				justifyContent: "center",
			},
			cell: (row) => (
				<div className="product-image">
					<img
						src={!row.imageUrl ? "/images/placeholder.jpg" : row.imageUrl}
						alt="Produto"
						className="shadow-sm"
					/>
				</div>
			),
		},

		{
			name: "",
			selector: "name",
			sortable: false,
			width: "600px",
			style: {
				overflow: "hidden",
				wordBreak: "break-word",
			},
			cell: (row) => (
				<div className="col">
					<div>
						<h6
							className="font-weight-bold"
							style={{ wordBreak: "break-word" }}
						>
							{row.name}
						</h6>
						<small className="text-muted">{row.code}</small>
					</div>
				</div>
			),
		},
		{
			sortable: true,
			name: "Status",
			selector: "isActive",
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-primary stadium">ATIVO</span>
				) : (
					<span className="badge badge-danger stadium">INATIVO</span>
				),
		},
		{
			name: "",
			sortable: true,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<a
						id="btnGroupDrop1"
						href={`/products/${page}/edit/${!row.id ? row.code : row.id}`}
						className="btn btn-info"
					>
						Editar
					</a>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>
					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							href="#"
							data-toggle="modal"
							data-target="#deleteProduct"
							onClick={() => setDeleteId(!row.id ? row.code : row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		//console.log(query)
		if (query) {
			history.push(
				`${props.page}?query=${query === null ? "" : query}&hasStock=${
					filterData.hasStock == null ? "" : filterData.hasStock
				}&hasPrice=${
					filterData.hasPrice == null ? "" : filterData.hasPrice
				}&isAvailable=${
					filterData.isAvailable == null ? "" : filterData.isAvailable
				}&isActive=${
					filterData.isActive == null ? "" : filterData.isActive
				}&hasImage=${filterData.hasImage == null ? "" : filterData.hasImage}`
			);
		}
		// getProducts();
	}, [query]);

	useEffect(() => {
		setPage(props.page);
	}, []);

	// Adicionado novo parametro de verificação
	useEffect(() => {
		getProducts();
	}, [sortAscending, filterData, page, query, rowsPerPage]);

	useEffect(() => {
		const inputSearch = document.querySelector("#search");
		const searchProd = fromEvent(inputSearch, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => setQuery(text));
		return searchProd.unsubscribe;
	}, []);

	const getProducts = async () => {
		setLoad(true);
		await APIV2.get(
			//troquei o "10" em perPage pela variável criada
			`management/catalog/products?query=${query}&page=${page}&perPage=${rowsPerPage}&SortAscending=${sortAscending}&sortField=${sortField}&isActive=${
				props.isActive == null ? filterData.isActive : props.isActive
			}&isAvailable=${
				props.isAvailable == null ? filterData.isAvailable : props.isAvailable
			}&hasStock=${
				props.hasStock == null ? filterData.hasStock : props.hasStock
			}&hasPrice=${
				props.hasPrice == null ? filterData.hasPrice : props.hasPrice
			}&hasImage=${
				props.hasImage == null ? filterData.hasImage : props.hasImage
			}`
		)
			.then((response) => {
				setProducts(response.data.products);
				setTotalProducts(response.data.maxCount);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const filter = async (val) => {
		if (
			val.hasStock == "" &&
			val.hasPrice == "" &&
			val.isAvailable == "" &&
			val.isActive == "" &&
			val.hasImage == ""
		) {
			history.push("/products/1");
		} else {
			history.push(
				`${page}?query=${query}&hasStock=${
					val.hasStock == null ? "" : val.hasStock
				}&hasPrice=${val.hasPrice == null ? "" : val.hasPrice}&isAvailable=${
					val.isAvailable == null ? "" : val.isAvailable
				}&isActive=${val.isActive == null ? "" : val.isActive}&hasImage=${
					val.hasImage == null ? "" : val.hasImage
				}`
			);
		}

		try {
			setFilterData(val);
			setLoad(false);
		} catch (error) {
			console.log(error);
			setLoad(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const search = (query) => {
		setQuery(query);
		setLoad(true);
		/* const stateObj = { page: 1 };
		window.history.pushState(stateObj, '', 1);
		setPage('1')
		if (!query) {
			getProducts()
		} else {
			//Consulta materiais disponíveis a partir do token do usuário.
			APIV2.get(`management/catalog/products?query=${query}&page=1&perPage=10&SortAscending=${sortAscending}&sortField=${sortField}`)
				.then(async response => {
					// If request is good...
					setProducts(response.data.products)
					setTotalProducts(response.data.maxCount)
					setLoad(false)
				})
				.catch((error) => {
					console.log(error)
					setLoad(false)
					toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
				})
		} */
	};

	const changePage = (e) => {
		setLoad(true);
		setPage(e);
		const stateObj = { page: props.page };
		if (
			props.hasStock == null ||
			props.hasPrice == null ||
			props.isAvailable == null ||
			props.isActive == null ||
			props.hasImage == null
		) {
			window.history.pushState(stateObj, "", `${e}`);
		} else {
			window.history.pushState(
				stateObj,
				"",
				`${e}?query=${props.query}&hasStock=${props.hasStock}&hasPrice=${props.hasPrice}&isAvailable=${props.isAvailable}&isActive=${props.isActive}&hasImage=${props.hasImage}`
			);
		}
	};

	// Nova função criada
	const changeRowsPerPage = (e) => {
		setLoad(true);
		setRowsPerPage(e);
	};

	const sortBy = async (column, sortDirection, event) => {
		try {
			setSortAscending(() => !sortAscending);
			await setSortField(() => column.selector);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSwitchFilterButtonStyle = () => {
		if (filterBackground === false) {
			setFilterBackGround(true);
		} else {
			setFilterBackGround(false);
		}
	};

	const deleteProduct = () => {
		APIV2.delete("management/catalog/products/" + deleteId)
			.then(() => {
				getProducts();
			})
			.catch((error) => {
				console.log(error);
				toast.error("Não foi possível excluir o produto");
			});
	};

	const renderLoading = () => {
		return (
			<>
				<div className="container dash-content">
					<div
						className=""
						style={{ overflow: "hidden", paddingBottom: "1em" }}
					>
						{loads.map(() => (
							<ContentLoader
								width={1100}
								height={100}
								backgroundColor="#e6e6e6"
								foregroundColor="#fafafa"
								// viewBox="0 0 100 100"
							>
								<rect x="120" y="40" rx="5" ry="5" width="400" height="10" />
								<rect x="120" y="59" rx="5" ry="5" width="100" height="5" />
								<rect x="690" y="50" rx="5" ry="5" width="55" height="18" />
								<rect x="950" y="45" rx="3" ry="5" width="90" height="40" />
								<rect x="20" y="10" rx="0" ry="0" width="80" height="190" />
							</ContentLoader>
						))}

						{/* <Loading /> */}
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="container dash-content">
				<div className="card">
					<div className="card-header d-flex justify-content-between align-items-center p-3">
						<div className="col-6 p-0 autocomplete icon-right">
							<input
								type="text"
								className="search form-control"
								id="search"
								name="pesquisa"
								placeholder="Pesquise pelo nome do produto ou código"
								defaultValue={query}
							/>
							{load ? (
								<i className="fas fa-spinner fa-spin"></i>
							) : (
								<i className="material-icons">search</i>
							)}
						</div>
						<div className="">
							<Button
								className=" btn-info light btn-icon mr-2"
								onClick={() => (window.location.href = "/import")}
							>
								<i className="material-icons">publish</i>&nbsp;&nbsp;Importar
								Arquivos
							</Button>
							<button
								className="btn btn-primary btn-icon"
								style={{ padding: "8px" }}
								onClick={() =>
									(window.location.href = `/products/${props.page}/new`)
								}
							>
								<i className="material-icons">add</i>
							</button>
						</div>
					</div>
					<Filter
						searchFilter={filter}
						totalProducts={totalProducts}
						isAvailable={props.isAvailable}
						hasStock={props.hasStock}
						hasPrice={props.hasPrice}
						isActive={props.isActive}
						hasImage={props.isImage}
						query={query}
					/>
					<div className="card-content pt-1">
						<div className="card-body no-padding">
							{totalProducts === 0 ? (
								<div className="empty">
									<div className="col-12 text-center">
										<img src={"/images/empty_products.svg"} width="20%" />
										<h3>
											<b>Ops! Não tem nada por aqui...</b>
										</h3>
									</div>
								</div>
							) : load ? (
								renderLoading()
							) : (
								<List
									// changeSelectOptions={[1, 2, 3, 20, 25, 30, 40, 50]}
									data={products}
									page={parseInt(page)}
									colums={columsList}
									totalRows={totalProducts}
									// adicionado
									rowsPerPage={rowsPerPage}
									handlePageChange={changePage}
									//adicionado
									handleRowsPerPageChange={changeRowsPerPage}
									paginationServer={true}
									sortedReturn={sortBy}
									sortServer={true}
									defaultSortAsc={sortAscending}
								/>
							)}
						</div>
					</div>
				</div>

				<Modal
					id={"deleteProduct"}
					title={"Certeza que deseja excluir este item?"}
					delete={true}
					actionDelete={deleteProduct}
				/>
			</div>
		</>
	);
}
