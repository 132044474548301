import React, { useState, useEffect } from "react";
import { APIV2 } from "../../../API";
import Modal from "../../../components/Modal";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import { useOrders } from "../providers/OrdersContext";

export default function ProgressOrders({ historic, id }) {
	const {
		loading,
		order,
		status,
		actionsButtons,
		buttonColors,
		positions,
		descriptions,
		levels,
		orderId,
		setLoading,
		setButtonColors,
		role,
		freight,
		getOrders,
	} = useOrders();
	const [statusOrder, setStatusOrder] = useState({ status: status, color: "" });
	const [statusButton, setStatusButton] = useState("");

	useEffect(() => {
		getStatusOrders();
	}, [status]);

	const getStatusOrders = () => {
		status === 1 || status === "Pending"
			? setStatusOrder({
					status: "Pedido criado na Neomode",
					color: "text-success",
			  })
			: status === 2 || status === "PendingCreate"
			? setStatusOrder({
					status: "Aguardando criação do pedido",
					color: "text-info",
			  })
			: status === 3 || status === "PendingApproval"
			? setStatusOrder({
					status: "Aguardando confirmação do ponto de venda",
					color: "text-info",
			  })
			: status === 4 || status === "ApprovedExternal"
			? setStatusOrder({
					status: "Pedido confirmado no ponto de venda",
					color: "text-success",
			  })
			: status === 5 || status === "Processing"
			? setStatusOrder({ status: "Em faturamento", color: "text-warning" })
			: status === 6 || status === "Separate"
			? setStatusOrder({ status: "Produtos separados", color: "text-info" })
			: status === 7 || status === "InDelivery"
			? setStatusOrder({ status: "Em transporte", color: "text-info" })
			: status === 8 || status === "ReadyForPickup"
			? setStatusOrder({
					status: "Pronto para retirada",
					color: "text-success",
			  })
			: status === 9 || status === "Finalized"
			? setStatusOrder({ status: "Finalizado", color: "text-success" })
			: status === 10 || status === "CanceledBySeller"
			? setStatusOrder({
					status: "Cancelado pelo ponto de venda",
					color: "text-danger",
			  })
			: status === 11 || status === "CanceledByBuyer"
			? setStatusOrder({
					status: "Cancelado pelo consumidor",
					color: "text-danger",
			  })
			: status === 12 || status === "CanceledByAdmin"
			? setStatusOrder({
					status: "Cancelado pelo administrador",
					color: "text-danger",
			  })
			: status === 13 || status === "PendingCancel"
			? setStatusOrder({
					status: "Pendente de cancelamento",
					color: "text-warning",
			  })
			: status === 14 || status === "PendingCreatePreOrder"
			? setStatusOrder({
					status: "Aguardando criação da pré-venda",
					color: "text-info",
			  })
			: status === 15 || status === "PreOrderCreated"
			? setStatusOrder({
					status: "Pré-venda criada no ponto de venda",
					color: "text-success",
			  })
			: status === 16 || status === "ErrorCancel"
			? setStatusOrder({
					status: "Erro ao cancelar pedido",
					color: "text-danger",
			  })
			: status === 17 || status === "PendingUpdate"
			? setStatusOrder({
					status: "Pendente de atualização",
					color: "text-warning",
			  })
			: status === 18 || status === "Updated"
			? setStatusOrder({ status: "Pedido atualizado", color: "text-success" })
			: status === 19 || status === "ErrorUpdate"
			? setStatusOrder({
					status: "Erro ao atualizar pedido",
					color: "text-danger",
			  })
			: status === 20 || status === "InvoiceCreated"
			? setStatusOrder({ status: "Nota fiscal emitida", color: "text-info" })
			: status === 21 || status === "CanceledByProcessor"
			? setStatusOrder({
					status: "Cancelado via processo",
					color: "text-danger",
			  })
			: setStatusOrder({ status: "Pedido Realizado", color: "text-secundary" });
	};

	const getButton = (statusBtn) => {
		setStatusButton(statusBtn);
	};

	const getStatus = async () => {
		setLoading(true);
		await APIV2.put(
			`management/commerce/orders/${orderId}/status/${statusButton}`
		)
			.then((response) => {
				switch (statusButton) {
					case "CanceledByAdmin":
						setButtonColors({
							backgroundColor: "btn btn-danger",
							textColor: "text-danger",
						}); // Pedido Cancelado (Cliente) || Pedido Cancelado (Lojista)
						toast.success("Status cancelado com sucesso! 😃");
						break;
					case "CanceledBySeller":
						setButtonColors({
							backgroundColor: "btn btn-danger",
							textColor: "text-danger",
						}); // Pedido Cancelado (Cliente) || Pedido Cancelado (Lojista)
						toast.success("Status cancelado com sucesso! 😃");
						break;
					case "CanceledByBuyer":
						setButtonColors({
							backgroundColor: "btn btn-danger",
							textColor: "text-danger",
						}); // Pedido Cancelado (Cliente) || Pedido Cancelado (Lojista)
						toast.success("Status cancelado com sucesso! 😃");
						break;
					case "Separate":
						setButtonColors({
							backgroundColor: "btn btn-secondary separete",
							textColor: "btn btn-secondary",
						}); // Separar Pedido || Pedido Separado
						toast.success("Status alterado com sucesso! 😃");
						break;
					case "InDelivery":
						setButtonColors({
							backgroundColor: "btn btn-info",
							textColor: "btn btn-info",
						}); // Enviar Pedido || Pedido Enviado
						toast.success("Status alterado com sucesso! 😃");
						break;
					case "ApprovedExternal":
						setButtonColors({
							backgroundColor: "btn btn-success",
							textColor: "text-warning",
						}); // Faturar Pedido || Pedido Faturado
						toast.success("Status alterado com sucesso! 😃");
						break;
					case "Finalized":
						setButtonColors({
							backgroundColor: "btn btn-success",
							textColor: "btn btn-success",
						}); // Finalizar Pedido || Pedido Finalizado
						toast.success("Status alterado com sucesso! 😃");
						break;
					case "ReadyForPickup":
						setButtonColors({
							backgroundColor: "btn btn-info",
							textColor: "btn btn-info",
						}); // Aprontar para Retirada || Pronto para Retirada
						toast.success("Status alterado com sucesso! 😃");
						break;
					default:
						setButtonColors({
							backgroundColor: "btn btn-titles",
							textColor: "text-titles",
						});
				}
				setStatusButton("");
				setTimeout(() => {
					window.location.reload();
				}, 3000);

				//getOrders(id);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	const handleHistoric = (bool) => {
		historic(bool);
	};

	const skeleton = () => {
		const skele = [0];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={200}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1800 500"
				style={{ width: "100%", height: "100%" }}
			>
				<rect
					x="30"
					y="100"
					rx="10"
					ry="10"
					width="400"
					height="60"
					className="bg-danger"
				/>
				<rect
					x="1240"
					y="100"
					rx="10"
					ry="10"
					width="500"
					height="60"
					className="bg-danger"
				/>
				<circle cx="220" cy="350" r="25" />
				<rect x="220" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="570" cy="350" r="25" />
				<rect x="550" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="900" cy="350" r="25" />
				<rect x="880" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="1250" cy="350" r="25" />
				<rect x="1220" y="345" rx="10" ry="10" width="350" height="10" />
				<circle cx="1565" cy="350" r="25" />
			</ContentLoader>
		));
	};

	return (
		<>
			{loading ? (
				skeleton()
			) : (
				<>
					<div className="status" style={{ height: "60px" }}>
						<div className="order-label d-flex flex-column">
							<h2 className="card-title m-0 p-0">
								Status do pedido:&nbsp;
								<label className={`${statusOrder.color} m-0 p-0`}>
									{statusOrder.status}
								</label>
							</h2>
							{role === 8 ? (
								<span className="card-subtitle mt-1">
									<strong>Tipo de envio: </strong>
									{freight.description}
								</span>
							) : (
								<span className="card-subtitle mt-1">
									<strong>Modalidade: </strong>
									{order.isDelivery === false
										? "Retire na Loja"
										: "Receba em Casa"}
								</span>
							)}
						</div>

						<div class="btn-group dropdown">
							{actionsButtons.length > 1 ? (
								<>
									<button
										type="button"
										className={`${
											actionsButtons.action === "DELETE"
												? "btn btn-danger rounded"
												: buttonColors.backgroundColor
										} d-flex justify-content-start border border-white`}
										data-toggle="modal"
										data-target="#statusChangeModal"
										style={{ width: "220px", height: "40px" }}
										onClick={() => getButton(actionsButtons[0].endpoint)}
									>
										{actionsButtons[0].name}
									</button>

									<button
										type="button"
										className={`${buttonColors.backgroundColor} dropdown-toggle dropdown-toggle-split border border-white`}
										data-toggle={"dropdown"}
										aria-haspopup="true"
										aria-expanded="false"
										style={{ height: "40px" }}
									>
										<span class="sr-only">Toggle Dropdown</span>
									</button>
									<div
										class="dropdown-menu p-0"
										style={{ marginLeft: "-218px", height: "40px" }}
									>
										{actionsButtons.map((button) =>
											actionsButtons[0].name === button.name ? null : (
												<button
													className="dropdown-item text-danger d-flex justify-content-start"
													data-toggle="modal"
													data-target="#statusChangeModal"
													onClick={() => getButton(button.endpoint)}
													style={{
														paddingLeft: "15px",
														width: "262px",
														height: "40px",
														backgroundColor: "#ffffff",
														cursor: "pointer",
													}}
												>
													{button.name}
												</button>
											)
										)}
									</div>
								</>
							) : (
								<>
									{actionsButtons.map((button) => (
										<button
											type="button"
											className={`${
												button.action === "DELETE"
													? "btn btn-danger rounded"
													: buttonColors.backgroundColor
											} d-flex justify-content-start border border-white`}
											data-toggle="modal"
											data-target="#statusChangeModal"
											style={{ width: "220px", height: "40px" }}
											onClick={() => getButton(button.endpoint)}
										>
											{button.name}
										</button>
									))}
								</>
							)}
						</div>
					</div>
					<div className="progress-orders">
						<div className="timeline">
							<ul>
								{/* "start" / "waiting" / "active" / "completed" */}
								<li
									className={
										positions[0] === 1 && levels[0] === 1
											? "started"
											: positions[0] === 1 && levels[0] === 3
											? "validated"
											: ""
									}
								>
									<span>{descriptions[0]}</span>
								</li>
								<li
									className={
										levels[0] === 1 &&
										levels[1] === 1 &&
										levels[2] === 1 &&
										levels[3] === 1 &&
										levels[4] === 1
											? ""
											: positions[1] === 100 && levels[1] === 4
											? "err"
											: positions[1] === 2 && levels[1] === 1
											? "waiting"
											: positions[1] === 2 && levels[1] === 2
											? "warning"
											: positions[1] === 2 && levels[1] === 3
											? "success"
											: positions[1] === 2 && levels[1] === 4
											? "err"
											: ""
									}
								>
									<span>{descriptions[1]}</span>
								</li>
								<li
									className={
										levels[1] === 1 &&
										levels[2] === 1 &&
										levels[3] === 1 &&
										levels[4] === 1
											? ""
											: positions[2] === 100 && levels[1] === 4
											? "err"
											: positions[2] === 3 && levels[2] === 1
											? "waiting"
											: positions[2] === 3 && levels[2] === 2
											? "warning"
											: positions[2] === 3 && levels[2] === 3
											? "success"
											: positions[2] === 3 && levels[2] === 4
											? "err"
											: ""
									}
								>
									<span>{descriptions[2]}</span>
								</li>
								<li
									className={
										levels[2] === 1 && levels[3] === 1 && levels[4] === 1
											? ""
											: positions[3] === 100 && levels[1] === 4
											? "err"
											: positions[3] === 4 && levels[3] === 1
											? "waiting"
											: positions[3] === 4 && levels[3] === 2
											? "warning"
											: positions[3] === 4 && levels[3] === 3
											? "success"
											: positions[3] === 4 && levels[3] === 4
											? "err"
											: ""
									}
								>
									<span>{descriptions[3]}</span>
								</li>
								<li
									className={
										levels[3] === 1 && levels[4] === 1
											? ""
											: positions[4] === 100 && levels[1] === 4
											? "err"
											: positions[4] === 5 && levels[4] === 1
											? "waiting"
											: positions[4] === 5 && levels[4] === 2
											? "warning"
											: positions[4] === 5 && levels[4] === 3
											? "success"
											: positions[4] === 5 && levels[4] === 4
											? "err"
											: ""
									}
								>
									<span>{descriptions[4]}</span>
								</li>
							</ul>
						</div>
						<div className="pt-4 d-flex justify-content-end">
							<button
								className="text-link-timeline pr-4"
								onClick={() => handleHistoric(true)}
							>
								Ver histórico completo
							</button>
						</div>
					</div>
				</>
			)}
			<Modal
				id={"statusChangeModal"}
				title={"Deseja confirmar o status do pedido?"}
				confirm={true}
				content={<div></div>}
				actionConfirm={getStatus}
			/>
		</>
	);
}
