import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { API, APIV2 } from '../../../../API';
import DropOneImage from '../DropOneImage';
import { useCategory } from '../providers/CategoriesContext';

// import { Container } from './styles';

function NewCategoryForm(props) {

  const [category, setCategory] = useState({
    id: null,
    name: '',
    description: '',
    parentId: '',
    iconUrl: '',
    isActive: false,
    order: null,
    clientId: localStorage.getItem("client_id")
  })
  const [previewImage, setPreviewImage] = useState('')
  const [disableButton, setDisableButton] = useState(false)

  const { categoryList, selectedCategory, getCategoryList, cancelCategory, openDelete, isSubCategory, setSuccess, setError } = useCategory()

  useEffect(() => {
    if (selectedCategory.id && !isSubCategory) {
      setCategory(selectedCategory)
    }
    if (selectedCategory.id && isSubCategory) {
      setCategory(prevState => ({
        id: null,
        name: '',
        description: '',
        parentId: selectedCategory.id,
        iconUrl: '',
        iconType: '',
        isActive: false,
        order: null,
        clientId: localStorage.getItem("client_id")
      }))
    }
  }, [selectedCategory])

  useEffect(() => {
    setPreviewImage(category.iconUrl)
  }, [category])

  const newCategory = async () => {
    category.order = categoryList.length + 1
    setDisableButton(true)
    if (!selectedCategory.id) {
      try {
        await APIV2.post(`management/catalog/categories`, category)
        getCategoryList()
        setSuccess('Categoria criada com sucesso')
        setDisableButton(false)
      } catch (error) {
        console.log(error);
        setError('Parece que tivemos um erro... Tente novamente mais tarde.');
        setDisableButton(false)
      }
    } else {
      try {
        await APIV2.put(`/management/catalog/categories/${selectedCategory.id}`, category)
        getCategoryList()
        setSuccess('Categoria editada com sucesso')
        setDisableButton(false)
      } catch (error) {
        console.log(error);
        setError('Parece que tivemos um erro... Tente novamente mais tarde.');
        setDisableButton(false)
      }
    }
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setCategory(prevState => ({ ...prevState, [name]: value }))
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="card-title category-title">{!selectedCategory.id ? 'Nova Categoria' : 'Editar Categoria'}</h4>
        <div className="form-group">
          <label htmlFor="activeCategory"><strong>A categoria está ativa? </strong></label>
          <label className="switch ml-2 ">
            <input id="activeCategory" type="checkbox" value={category.isActive} name='isActive' checked={category.isActive} onChange={e => setCategory(prevState => ({ ...prevState, isActive: !category.isActive }))} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <label htmlFor="productCode"><strong>Título da categoria</strong></label>
          <div >
            <input type="text" className="form-control" id="productCode" placeholder="Clique aqui para preencher" name='name' value={category.name} onChange={e => handleChange(e)} />
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="productCode"><strong>Descrição</strong></label>
          <div >
            <input type="text" className="form-control" id="productCode" placeholder="Clique aqui para preencher" name='description' value={category.description} onChange={e => handleChange(e)} />
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="productCode"><strong>Localização da Categoria</strong></label>
          <p className="aux-text">Ao escolher uma “categoria pai” para sua categoria, significa que a categoria criada está dentro da “categoria pai”. Selecionando a opção “esta categoria é uma categoria principal”, quer dizer que ela se encontra na lista principal do catálogo.</p>
          <div >
            <select className="form-control"
              id="parentsCategory"
              name="parentId"
              value={category.parentId}
              onChange={e => handleChange(e)}>
              {!category.parentId && <option>{props.name}</option>}
              {categoryList.map(category =>
                <option key={category.id} value={category.id}>{category.name}</option>
              )}
            </select>
          </div>
        </div>
        <div className="form-group col-12">
          <label htmlFor="productCode"><strong>Tipo de Imagem da Categoria</strong></label> 
          <p className="aux-text">A imagem de categoria representa para os clientes o tipo de produtos estão contidos em cada categoria. Se desejar alterar o tipo de visualização de imagem, <a className="link">clique aqui (Configurações Gerais)</a></p>
          <div className="d-flex justify-content-between">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="iconType" id="icon" value="icon" checked={category.iconType === 'icon'} onChange={e => handleChange(e)} />
              <label class="form-check-label" for="icon">
                Imagem como ícone pequeno
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="iconType" id="image" value="image" checked={category.iconType === "image"} onChange={e => handleChange(e)} />
              <label class="form-check-label" for="image">
                Imagem grande
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="iconType" id="noImage" value="noImage" checked={category.iconType === "noImage"} onChange={e => handleChange(e)} />
              <label class="form-check-label" for="noImage">
                Sem imagem
              </label>
            </div>
          </div>

        </div>
        <div className="col-8">
          <label htmlFor="productCode"><strong>Imagem da Categoria</strong></label>
          <DropOneImage
            previewImage={category.iconUrl}
            recoveryStateLogo={e => setCategory(prevState => ({ ...prevState, iconUrl: e }))}
            height={'100%'}
            width={'100%'}
            background={'#fff'}
            colorText={'#747E8B'} />
        </div>
        <div className="col-4">
          <label htmlFor="productCode"><strong>Pré-Visualização</strong></label>
          <div className="icon" style={{
            width: category.iconType === 'icon' ? '96px' : 'auto',
            display: (category.iconType === 'noImage' || category.iconType === '') && 'none',
            backgroundImage: `url(${category.iconUrl})`
          }}>
            {
              !category.iconUrl &&
              'Sem imagem adicionada'
            }
          </div>
        </div>
        <div className=" col-12">
          <small style={{fontStyle: 'italic', color: '#A1A3A5', fontSize: '12px'}}>São suportados arquivos no formato JPG e PNG. Para a opção “imagem grande” o tamanho é 000 x 000</small>
        </div>
        {category.iconUrl &&
          <div className=" col-12 mt-3">
            <label htmlFor="productCode"><strong>Arquivo Inserido</strong></label>
            <div className="inserted-image">
              <div className="inserted-image-infos">
                <span class="material-icons mr-2">image</span>
                {/* <p> */}
                <strong>{category.iconUrl.split(/[\/\\\.]/).pop().toUpperCase()}</strong>
                {/* </p> */}
              </div>
              <div className="inserted-image-buttons">
                <button className="btn btn-link btn-outline btn-sm mr-2 mt-1"
                  style={{ padding: '0', textDecoration: 'none', color: '#FF5B5C' }}
                  onClick={() => setCategory(prevState => ({ ...prevState, iconUrl: '' }))}
                >
                  <span class="material-icons">delete_outline</span>
                </button>
                <a href={category.iconUrl} download className="btn btn-link mt-1"
                  style={{ padding: '0', textDecoration: 'none',}}
                >
                  <span class="material-icons">download</span>
                </a>
              </div>
            </div>
          </div>
          }
        <div className="col-12 mt-4 d-flex justify-content-between">
          <div className="mr-2">
            <button className="btn btn-success mr-2 btn-new-width" type='button' disabled={disableButton} onClick={() => newCategory()}>{!selectedCategory.id ? "Criar" : "Salvar"}</button>
            <button className="btn btn-light btn-new-width" type='button' onClick={() => cancelCategory()}>Cancelar</button>
          </div>
          <div className="mr-2">
            {selectedCategory.id &&
              <button className="btn btn-outline-danger btn-new-width"
                type='button'
                onClick={() => openDelete()}
                data-toggle="modal"
                data-target="#deleteCategory">
                Excluir Categoria
                </button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCategoryForm;