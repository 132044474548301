import React, { useState } from "react";
import { APIV2 } from "../../../../../API";
import Button from "../../../../../components/Button";
import { toast } from "react-toastify";
import axios from "axios";

export default function VouchersExport() {
	const [output, setOutput] = useState("");
	const [load, setLoad] = useState(false);

	const getExportFilterStores = async () => {
		setLoad(true);
		await APIV2.get(`/insights/export/vouchers`)
			.then((response) => {
				const exportFilter = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (resp.data.runtimeStatus === "Running") {
					} else {
						switch (resp.data.runtimeStatus) {
							case "Completed":
								//console.log("Entrou no Completed");
								setOutput(resp.data.output);
								clearInterval(exportFilter);
								document.getElementById("exportList").click();
								setLoad(false);
								toast.success("Download realizado com sucesso!😃");
								break;
							case "Failed":
								//console.log("Entrou no Failed");
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
								break;
							default:
								//console.log("Entrou no Default");
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
						}
					}
				}, 4000);
			})
			.catch((error) => {
				console.log(error);
				setLoad(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	return (
		<>
			{load === true ? (
				<>
					<a href={output} id="exportList" className="d-none"></a>
					<Button
						disabled
						className="btn btn-light btn-icon mr-2 d-flex justify-content-center"
					>
						<>
							<i className="fas fa-spinner fa-spin"></i>
							<span>&nbsp;&nbsp;Processando...</span>
						</>
					</Button>
				</>
			) : (
				<>
					<a href={output} id="exportList" className="d-none"></a>
					<Button
						className="btn btn-ligt btn-icon mr-2 d-flex justify-content-center"
						onClick={() => getExportFilterStores()}
					>
						<>
							<i className="material-icons-outlined">get_app</i>
							<span>&nbsp;&nbsp;Exportar Cupons</span>
						</>
					</Button>
				</>
			)}
		</>
	);
}
