import React, { useEffect, useState } from 'react';
import RecommendationCel from './celComponents/RecommendationCel';
import BannerCarrousel from './components/BannerCarrousel';
import BodyBanner from './components/BodyBanner';
import Contact from './components/Contact';
import Content from './components/Content';
import EditPlugin from './components/EditPlugin';
import LastOrder from './components/LastOrder';
import Showcase from './components/Showcase';
import SideBar from './components/SideBar';

// import { Container } from './styles';

function Home() {
  const [plugins, setPlugins] = useState([])
  const [newPluginList, setNewPluginList] = useState([])
  const [pluginEdit, setPluginEdit] = useState({})
  // const [removePlugin, setRemovePlugin] = useState(null)

  useEffect(() => {
    console.log('aaaaa', plugins)
    setPlugins(newPluginList)
  }, [newPluginList])

  const savePluginList = (plugin) => {

    let newPlugin = {
      id: `${plugins.length}`,
      type: plugin.type,
      icon: plugin.icon
    }
    setPlugins(prevState => [...prevState, newPlugin])
  }

  const renderEdit = () => {
    switch (pluginEdit.type) {
      case "carousel":
        return <BannerCarrousel />
      case "body":
        return <BodyBanner />
      case "lastProducts":
        return <LastOrder />
      case "showcase":
        return <Showcase />
      case "contact":
        return <Contact />
      default:
        break;
    }
  }



  const removePlugin = (plugin) => {
    console.log(plugin)
    console.log(plugins)
    let pluginList = plugins;
    // let findPlugin = plugins.indexOf(plugins.find(pl => pl.id === plugin.index))
    // console.log(findPlugin)
    // pluginList.filter( (pl, index) => index !== plugin.index)
    // console.log(pluginList)
    // console.log(pluginList)
    setPlugins(prevState => prevState.filter((pl, index) => index !== plugin.index))
  }



  return (
    <div className="row">
      <div className="col-4"><SideBar plugin={e => savePluginList(e)} pluginList={plugins} /></div>
      <div className="col-8"><Content plugins={plugins} selectedPlugin={plugin => setPluginEdit(plugin)} setNewList={e => setPlugins(e)} /></div>
      <EditPlugin plugin={pluginEdit} index={plugins.indexOf(plugins.find(pl => pl.id === pluginEdit.id))} remove={e => removePlugin(e)} close={() => setPluginEdit({})}>
        {renderEdit()}
      </EditPlugin>
    </div>
  );
}

export default Home;