import React, { Component } from "react";
//import { Multiselect } from 'react-widgets'
import { API, APIV2 } from "../../../../API";
import DropImage from "../../../../components/DropImage";
import DatePickerNeo from "../../../../components/DatePickerNeo";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";
import { Multiselect } from "react-widgets";
//import PreviewAPP from '../Config/Index'
import CategoriesList from "../../../../components/CategoriesList";
import Products from "../../../../components/Products";
import Modal from "../../../../components/Modal";
import PreviewAPP from "../../../../components/preview/PreviewApp";
import {
	detectAnyAdblocker,
	detectOperaAdblocker,
	detectBraveShields,
	detectDomAdblocker,
} from "just-detect-adblock";
import Button from "../../../../components/Button";

export class New extends Component {
	constructor(props) {
		super(props);
		this.createBanner = this.createBanner.bind(this);
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
		this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.recoveryStateImage = this.recoveryStateImage.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.getCategories = this.getCategories.bind(this);
		this.changeAction = this.changeAction.bind(this);
		this.createBannerDefault = this.createBannerDefault.bind(this);
		//this.handleChangeP = this.handleChangeP.bind(this)
		this.handleTags = this.handleTags.bind(this);
		this.handleChangeWebview = this.handleChangeWebview.bind(this);
		this.handleChangeUrl = this.handleChangeUrl.bind(this);
		this.getBanner = this.getBanner.bind(this);
		this.adblockDetect = this.adblockDetect.bind(this);

		this.state = {
			id: null,
			title: null,
			imageUrl: "",
			startIn: new Date(),
			endIn: new Date(),
			startDate: new Date(),
			endDate: new Date(),
			order: null,
			type: null,
			action: null,
			referenceId: null,
			edit: false,
			banners: [],
			categories: null,
			collectionList: [],
			promotions: [],
			tags: [],
			isDefault: null,
			carousel: false,
			selectTag: null,
			open_tag: null,
			open_video: "",
			isAdblock: false,
			closeAlert: false,
		};
	}
	componentDidMount() {
		this.getBanner();
		this.adblockDetect();
	}

	adblockDetect() {
		detectAnyAdblocker().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});

		detectOperaAdblocker().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});

		detectBraveShields().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});

		detectDomAdblocker().then((detected) => {
			if (detected) {
				// an adblocker is detected
				this.setState({ isAdblock: true });
			}
		});
	}

	getBanner() {
		this.setState({ loading: true });
		API.get("api/tags")
			.then(async (response) => {
				this.setState({
					collectionList: response.data.length > 0 ? response.data : [],
					open_tag: response.data.length > 0 ? response.data[0].url : null,
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});

		if (
			this.props.url.match.params.id === null ||
			this.props.url.match.params.id === undefined
		) {
			this.setState({ loading: false });
		}

		var _this = this;
		if (_this.props.url.match.params.id) {
			_this.setState({ edit: true });
			APIV2.get(
				"management/marketing/banners/" + _this.props.url.match.params.id
			)
				.then(async (response) => {
					console.log("banner =>", response.data);
					await _this.setState({
						id: response.data.id,
						order: response.data.order,
						title: response.data.title,
						imageUrl: response.data.imageUrl,
						startIn: new Date(response.data.startIn),
						endIn: new Date(response.data.endIn),
						type: response.data.type,
						action: response.data.action,
						referenceId: response.data.referenceId,
						isDefault: response.data.isDefault,
						open_url: response.data.referenceId,
						open_webview: response.data.referenceId,
						open_product: response.data.referenceId,
						open_category: response.data.referenceId,
						open_tag: response.data.referenceId,
						open_video: response.data.referenceId,
					});
					this.setState({ loading: false });
				})
				.catch((error) => {
					console.log(error.response);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	}

	handleChangeVideo = (e) => {
		var link = e.target.value;
		if (link.match(/youtube/) || link.match(/vimeo/) || link === "") {
			this.setState({ open_video: e.target.value });
		} else {
			this.setState({ open_video: "" });
			this.setState({ closeAlert: true });
			setInterval(() => {
				this.setState({ closeAlert: false });
			}, 5000);
		}
	};

	async changeAction(e) {
		await this.setState({
			action: e.target.value,
		});
		this.forceUpdate();
	}
	async handleChangeUrl(e) {
		await this.setState({
			open_url: e.target.value,
		});
	}
	async handleChangeWebview(e) {
		await this.setState({
			open_webview: e.target.value,
		});
	}
	handleTags(e) {
		this.setState({ open_tag: e.target.value });
	}
	async handleChange(e) {
		await this.setState({
			[e.target.name]: e.target.value,
		});
		this.forceUpdate();
	}
	handleChangeStartDate = async (date) => {
		await this.setState({ startIn: date });
		this.forceUpdate();
	};
	handleChangeEndDate = async (date) => {
		await this.setState({ endIn: date });
		this.forceUpdate();
	};
	async recoveryStateImage(e) {
		this.setState({ imageUrl: e });
		this.forceUpdate();
	}
	async getProducts(e) {
		var body = e;
		var p = body.map((prod) => {
			return prod.id;
		});
		const _this = this;
		if (p.length > 1) {
			toast.error("Apenas um produto pode ser adicionado 😃");
		} else {
			const product = p.toString();
			_this.setState({ open_product: product });
		}
	}
	async getCategories(e) {
		if (e) {
			const categorie = e.toString();
			await this.setState({ open_category: categorie });
			this.forceUpdate();
		} else {
			await this.setState({ open_category: null });
			this.forceUpdate();
		}
	}
	createBannerDefault(e) {
		e.preventDefault();
		if (this.state.edit) {
			var body = {
				id: this.state.id,
				title: this.state.title,
				action: this.state.action,
				referenceId:
					this.state.action === "open_url"
						? this.state.open_url
						: this.state.action === "open_webview"
						? this.state.open_webview
						: this.state.action === "open_product"
						? this.state.open_product
						: this.state.action === "open_category"
						? this.state.open_category
						: this.state.action === "open_tag"
						? this.state.open_tag
						: this.state.action === "open_video"
						? this.state.open_video
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				imageUrl: this.state.imageUrl,
				isDefault: true,
				type: 0,
			};
			APIV2.put("management/marketing/banners/" + this.state.id, body)
				.then(async (response) => {
					toast.success("Banner padrão editado com sucesso! 😃");
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			var body = {
				title: this.state.title,
				action: this.state.action,
				referenceId:
					this.state.action === "open_url"
						? this.state.open_url
						: this.state.action === "open_webview"
						? this.state.open_webview
						: this.state.action === "open_product"
						? this.state.open_product
						: this.state.action === "open_category"
						? this.state.open_category
						: this.state.action === "open_tag"
						? this.state.open_tag
						: this.state.action === "open_video"
						? this.state.open_video
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				imageUrl: this.state.imageUrl,
				isDefault: true,
				type: 0,
			};
			APIV2.post("management/marketing/banners", body)
				.then(async (response) => {
					toast.success("Banner padrão criado com sucesso! 😃");
					window.location.href = `/channel-management/app/${this.props.screen}`;
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	}

	createBanner(e) {
		e.preventDefault();
		this.setState({ loading: true, disable: true });

		const start = this.state.startIn.getTime() - 10800000;
		const startIn = new Date(start);

		const end = this.state.endIn.getTime() - 10800000;
		const endIn = new Date(end);

		if (this.state.edit) {
			var body = {
				id: this.state.id,
				order: this.state.order,
				action: this.state.action,
				title: this.state.title,
				imageUrl: this.state.imageUrl,
				startIn: startIn,
				endIn: endIn,
				referenceId:
					this.state.action === "open_url"
						? this.state.open_url
						: this.state.action === "open_webview"
						? this.state.open_webview
						: this.state.action === "open_product"
						? this.state.open_product
						: this.state.action === "open_category"
						? this.state.open_category
						: this.state.action === "open_tag"
						? this.state.open_tag
						: this.state.action === "open_video"
						? this.state.open_video
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				type: this.props.url.match.params.screen === "new-carousel" ? 0 : 1,
			};
			APIV2.put("management/marketing/banners/" + this.state.id, body)
				.then(async (response) => {
					toast.success("Banner editado com sucesso! 😃");
					this.getBanner();
					this.setState({ disable: false });
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					this.setState({ disable: false });
				});
		} else {
			var body = {
				action: this.state.action,
				title: this.state.title,
				imageUrl: this.state.imageUrl,
				startIn: startIn,
				endIn: endIn,
				referenceId:
					this.state.action === "open_url"
						? this.state.open_url
						: this.state.action === "open_webview"
						? this.state.open_webview
						: this.state.action === "open_product"
						? this.state.open_product
						: this.state.action === "open_category"
						? this.state.open_category
						: this.state.action === "open_tag"
						? this.state.open_tag
						: this.state.action === "open_video"
						? this.state.open_video
						: this.state.action === "zoom"
						? null
						: this.state.action === "none"
						? null
						: null,
				type: this.props.url.match.params.screen === "new-carousel" ? 0 : 1,
			};
			APIV2.post("management/marketing/banners", body)
				.then(async (response) => {
					toast.success("Banner criado com sucesso! 😃");
					this.getBanner();
					this.setState({ disable: false });
					window.location.href = `/channel-management/app/${this.props.screen}`;
					this.forceUpdate();
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					this.setState({ disable: false });
				});
		}
	}

	render() {
		if (this.state.loading) {
			return <Loading />;
		} else {
			return this.renderScreen();
		}
	}
	renderScreen() {
		return (
			<div className="card">
				<div className="card-header" id="headingOne">
					<h5 className="mb-0">
						{this.props.default == true ? (
							<h4 className="card-title">
								{this.state.edit
									? "Editar Banner - Padrão"
									: "Novo Banner - Padrão"}{" "}
							</h4>
						) : this.props.url.match.params.screen === "new-carousel" ? (
							<h4 className="card-title">
								{this.state.edit
									? "Editar Banner - Carrossel"
									: "Novo Banner - Carrossel"}{" "}
							</h4>
						) : (
							<h4 className="card-title">
								{this.state.edit
									? "Editar Banner - Corpo"
									: "Novo Banner - Corpo"}{" "}
							</h4>
						)}
					</h5>
				</div>

				<div id="dadosProdutos" className="#" aria-labelledby="headingOne">
					<div className="card-content">
						<div className="card-body pt-0">
							{/* <AdBlockDetect> */}
							{this.state.isAdblock && (
								<div class="alert alert-danger" role="alert">
									AdBlock foi detectador, favor desativar e recarregue a página
								</div>
							)}
							{/* </AdBlockDetect> */}
							<form
								onSubmit={
									this.props.default == true
										? this.createBannerDefault
										: this.createBanner
								}
							>
								<div className="row">
									<div className="col-6">
										<div className="w-100">
											{/* TITULO */}
											<div className="form-group w-100">
												<label htmlFor="codigoCupom">
													<strong>Título do banner</strong>
												</label>
												<div>
													<input
														type="text"
														className="form-control"
														id="codigoCupom"
														name="title"
														value={this.state.title}
														onChange={this.handleChange}
													/>
												</div>
											</div>

											{this.props.default == true ? null : (
												<div className="form-group w-100 d-flex flex-wrap">
													<label className="w-100">
														<strong>Vigência do banner</strong>
													</label>
													<div className="mr-3">
														<DatePickerNeo
															label="Data de início"
															dateFormat="dd/MM/yyyy HH:mm"
															time={true}
															defaultValue={this.state.startIn}
															selected={this.state.startIn}
															onChange={this.handleChangeStartDate}
															minDate={new Date()}
														/>
													</div>
													<div className="mt-3 w-100">
														<DatePickerNeo
															label="Data de término"
															dateFormat="dd/MM/yyyy HH:mm"
															time={true}
															defaultValue={this.state.endIn}
															selected={
																this.state.endIn < this.state.startIn
																	? this.state.startIn
																	: this.state.endIn
															}
															onChange={this.handleChangeEndDate}
															minDate={new Date()}
														/>
													</div>
												</div>
											)}

											<div className="form-group w-100">
												<div className="input-group w-100 p-0 m-0">
													<div className="d-flex flex-column w-100 mr-2">
														<label className="" htmlFor="codigoCupom">
															<strong>Ação do banner</strong>
														</label>
														<select
															className="custom-select w-100 mb-2"
															id="codigoCupom"
															name="codigoCupom"
															defaultValue={this.state.action}
															value={this.state.action}
															onChange={this.changeAction}
														>
															<option value="none">Não fazer nada</option>
															<option value="open_product">
																Ir para o produto
															</option>
															<option value="open_category">
																Ir para a categoria
															</option>
															<option value="open_url">
																Abrir URL fora do APP
															</option>
															<option value="open_tag">
																Ir para a coleção
															</option>
															<option value="open_webview">
																Abrir URL dentro do APP
															</option>
															<option value="open_video">Abrir um vídeo</option>
															<option value="zoom">Zoom</option>
														</select>
													</div>

													{this.state.action === "open_webview" ? (
														<input
															type="text"
															className="form-control mr-2"
															placeholder="Abrir URL dentro do APP"
															value={this.state.open_webview}
															onChange={this.handleChangeWebview}
														/>
													) : null}
													{this.state.action === "open_url" ? (
														<input
															type="text"
															className="form-control mr-2"
															placeholder="Abrir URL fora do APP"
															value={this.state.open_url}
															onChange={this.handleChangeUrl}
														/>
													) : null}
													{this.state.action === "open_category" ? (
														<input
															type="text"
															className="form-control mr-2"
															placeholder="Categoria"
															defaultValue={
																this.state.open_category == null
																	? null
																	: 1 + " Categoria"
															}
															data-toggle="modal"
															data-target="#selectCategories"
														/>
													) : null}
													{this.state.action === "open_product" ? (
														<input
															type="text"
															className="form-control mr-2"
															placeholder="Produto"
															value={
																this.state.open_product == null
																	? null
																	: 1 + " Produto"
															}
															data-toggle="modal"
															data-target="#selectProduct"
														/>
													) : null}
													{this.state.action === "open_video" ? (
														<input
															type="text"
															className="form-control mr-2"
															placeholder="Abrir um vídeo"
															value={this.state.open_video}
															onChange={this.handleChangeVideo}
														/>
													) : null}

													{this.state.action === "open_tag" ? (
														<select
															className="form-control"
															placeholder="Coleções"
															name="referenceId"
															defaultValue={this.state.open_tag}
															value={this.state.open_tag}
															onChange={this.handleTags}
														>
															{this.state.collectionList.map((tag) => (
																<option key={tag.id} value={tag.url}>
																	{tag.name}
																</option>
															))}
														</select>
													) : null}

													{this.state.action === "zoom" ||
													this.state.action === "none" ||
													this.state.referenceId == null
														? null
														: null}
													{this.state.closeAlert && (
														<div
															className="alert alert-danger w-100 mt-2 p-2 d-flex justify-content-center"
															role="alert"
														>
															<i
																className="material-icons"
																style={{
																	fontSize: "23px",
																	paddingRight: "5px",
																}}
															>
																error_outline
															</i>
															{`Atenção! O endereço de vídeo não é compatível com o aplicativo. Para que esta função carregue corretamente, adicione um endereço de vídeo do Youtube ou Vimeo!`}
														</div>
													)}
												</div>
											</div>
											<div className="form-group">
												<DropImage
													previewImage={this.state.imageUrl}
													recoveryStateLogo={this.recoveryStateImage}
													height={"310px"}
													width={"320px"}
													background={"#F2F4F4"}
												/>
											</div>
										</div>
									</div>
									<div className="col-6 ml-0 pl-0">
										<PreviewAPP
											screenType={this.props.url.match.params}
											recoveryStateLogo={this.state.imageUrl}
										/>
									</div>
								</div>

								<div className="form-group col-12 d-flex justify-content-start mt-3 pl-0">
									{this.props.default == true ? (
										<Button
											className="btn btn-primary"
											loading={this.disable}
											type="submit"
										>
											{this.state.edit ? "Editar banner" : "Salvar banner"}
										</Button>
									) : (
										<Button
											className="btn btn-primary"
											loading={this.disable}
											type="submit"
											onClick={() => {}}
										>
											{this.state.edit ? "Editar banner" : "Salvar banner"}
										</Button>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
				<Modal
					id={"selectProduct"}
					title={"Produtos"}
					banners={true}
					large={true}
					content={<Products getProducts={this.getProducts} banners={true} />}
				/>
				<Modal
					id={"selectCategories"}
					title={"Categorias"}
					banners={true}
					content={
						<CategoriesList
							newCondition={this.getCategories}
							singleChecked={true}
							editValue={[this.state.referenceId]}
						/>
					}
				/>
			</div>
		);
	}
}

export default New;
