import React, { useEffect, useState } from 'react';

function AssistantShipping(props) {
    const [ step, setStep ] = useState('')
    
    useEffect(() => {
        handleChange()
        setStep(props.step)
    }, [props.step])
    
    const handleChange = (e) => {
        props.save(e)
        switch (e) {
            case 1 :
              setStep(1)     
              break;
            case 2 :
                setStep(2)
              break;
            case 3 :
                setStep(3)
              break;
            case 4 :
                setStep(4)
              break;
            default:
          }
    }

    return (
        <div className="container">
            <div className="assistant-shipping">
                    <div className="timeline">
                        <ul>
                            <li className={ step >= 2 && "completed" } >
                                <button className={ step > 0 && "checked" } onClick={() => handleChange(1)}>
                                    { step >= 2 
                                        ? <i className="material-icons" style={{ color: '#fff', marginLeft: '-8px', marginBottom: '2px' }}>done</i>
                                        : <p style={{ marginTop:'10px', fontWeight: '600', color: '#fff'}}>1</p>
                                    }
                                </button>
                                <span>Informações Básicas</span>
                            </li>
                            <li className={ step >= 3 && "completed" }>
                                <button className={ step > 1 && "checked"} onClick={() => handleChange(2)}>
                                    { step >= 3 
                                        ? <i className="material-icons" style={{ color: '#fff', marginLeft: '-8px', marginBottom: '2px' }}>done</i>
                                        : <p style={{ marginTop:'10px', fontWeight: '600', color: '#fff'}}>2</p>
                                    }
                                </button>
                                <span>Envio de Tabela</span>
                            </li>
                            <li className={ step >= 4 && "completed" } >
                                <button className={ step > 2 && "checked" } onClick={() => handleChange(3)}>
                                    { step >= 4
                                        ? <i className="material-icons" style={{ color: '#fff', marginLeft: '-8px', marginBottom: '2px' }}>done</i>
                                        : <p style={{ marginTop:'10px', fontWeight: '600', color: '#fff'}}>3</p>
                                    }
                                </button>
                                <span>Regras para o Frete</span>
                            </li>
                            <li>
                                <button className={ step === 4 && "checked" } onClick={() => handleChange(4)}>
                                    { step === 4 
                                        ? <i className="material-icons" style={{ color: '#fff', marginLeft: '-8px', marginBottom: '2px' }}>done</i>
                                        : <p style={{ marginTop:'10px', fontWeight: '600', color: '#fff'}}>4</p>
                                    }
                                </button>
                                <span style={ step === 4 ? { color: '#6D4AED', fontWeight: 'bold'} : {color: '#BCBEC0'}}>Revisão</span>
                            </li>
                        </ul>
                    </div>
                </div>
        </div>
    );
}

export default AssistantShipping;