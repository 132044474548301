import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt'


registerLocale('pt', pt);


export class DatePickerNeo extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        //console.log(this.props.time)
        const CustomInput = ({ value, onClick, onChange }) => (
            <>
                <label><strong>{this.props.label}</strong></label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className='input-group-text'>
                            <i className="far fa-calendar-alt"></i>
                        </div>
                    </div>
                    {this.props.time == true ?
                        <InputMask mask="99/99/9999 99:99" className="form-control" onChange={onChange} defaultValue={value} onClick={onClick} /> :
                        <InputMask mask="99/99/9999" className="form-control" onChange={onChange} defaultValue={value} onClick={onClick} />
                    }
                </div>
            </>
        );
        return (
            <DatePicker
                dateFormat={this.props.dateFormat}
                selected={this.props.selected}
                onChange={this.props.onChange}
                locale="pt"
                customInput={<CustomInput />}
                minDate = {this.props.minDate}
                /* timeInputLabel={this.props.time ? "Horário:" : ""}
                showTimeInput={this.props.time} */
                timeCaption={this.props.time ? "Horário:" : ""}
                showTimeSelect={this.props.time}
            />
        )
    }
};

export default DatePickerNeo;
