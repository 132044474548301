import React from 'react';

// import { Container } from './styles';

function SideBar(props) {
  const sendPluginType = (plugin) => {
    props.plugin(plugin)
  }
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="mb-6 pb-6"> <strong>Componentes</strong>  </h4>
        <h6 class="card-subtitle mb-2 text-muted mb-6">Para começar, selecione um ou mais componentes na lista abaixo e escolha as características desejadas</h6>
        <div class="list-group ">
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'carousel', icon: 'burst_mode' })} disabled={props.pluginList.find(plugin => plugin.type === 'carousel')}><i class="material-icons">burst_mode</i> Banner de Carrossel</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'body', icon: 'view_day'})}><i class="material-icons">view_day</i> Banner de Corpo</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'showcase', icon: 'view_carousel'})}><i class="material-icons">view_carousel</i> Vitrine de Produtos</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'contact', icon: 'phone'})} disabled={props.pluginList.find(plugin => plugin.type === 'contact')}><i class="material-icons">phone</i> Contato</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'department', icon: 'grid_on'})}><i class="material-icons">grid_on</i> Departamento</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'lastProducts', icon: 'remove_red_eye'})}><i class="material-icons">remove_red_eye</i> Último Produto Visto</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'recommendation', icon: 'local_mall'})}><i class="material-icons">local_mall</i> Recomendações</button>
          <button class="btn btn-icon-c-l list-group-item list-group-item-action app-editor-sidnav-itens" onClick={() => sendPluginType({type:'offer', icon: 'money_off'})}><i class="material-icons">money_off</i> Oferta do dia</button>
        </div>
      </div>
    </div>
  );
}

export default SideBar;