import React, { Component } from 'react';

class PreviewAppBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appBarColor: localStorage.getItem('primaryColor'),
            appLogo: localStorage.getItem('primaryLogo')
        }
    }
    render() {
        return (
            <div className="app-bar" style={{backgroundColor: this.state.appBarColor}}>
                <div className="action-left">
                    <i className="material-icons">{this.props.actionLeft}</i>
                </div>
                <div className="action-center">
                    {this.props.logo ? <div className="page-title"><img src={this.state.appLogo} height="25"/></div> : <div className="page-title">{this.props.title}</div> }
                </div>
                <div className="action-right">
                    <i className="material-icons">{this.props.actionRight}</i>
                </div>
            </div>
        )
    }
};

export default PreviewAppBar;
