import * as axios from "axios";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import List from "../../../components/List/List";

// import { Container } from './styles';

function BlackFridayMonitoring() {
	const [productCount, setProductCount] = useState([]);
	const [list, setNewList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const columsList = [
		{
			name: "Cliente",
			selector: "clientId",
			sortable: false,
			width: "250px",
			center: false,
			style: {
				overflow: "visible",
				color: "white",
			},
			cell: (row) => (
				<div className="col">
					<div>
						<h6
							className="font-weight-bold"
							style={{ wordBreak: "break-word" }}
						>
							{row.name}
						</h6>
						<strong className="text-muted">{row.clientId}</strong>
					</div>
				</div>
			),
		},

		{
			name: "PRODUTOS ATIVOS ONTEM",
			selector: "activeProductsYesterday",
			sortable: false,
			width: "300px",
			center: true,
			style: {
				overflow: "visible",
				wordBreak: "break-word",
			},
			cell: (row) => (
				<div className="col text-center">
					<div>
						<h6
							className="font-weight-bold"
							style={{ wordBreak: "break-word" }}
						>
							{row.name}
						</h6>
						<small className="text-muted">{row.activeProductsYesterday}</small>
					</div>
				</div>
			),
		},
		{
			name: "PRODUTOS ATIVOS HOJE",
			selector: "activeProducts",
			sortable: false,
			width: "350px",
			center: true,
			style: {
				overflow: "visible",
				wordBreak: "break-word",
			},
			cell: (row) => (
				<div className="col text-center">
					<div>
						<h6
							className="font-weight-bold"
							style={{ wordBreak: "break-word" }}
						>
							{row.name}
						</h6>
						<small className="text-muted">{row.activeProducts}</small>
					</div>
				</div>
			),
		},
		{
			name: "%  ",
			selector: "percentage",
			sortable: true,
			width: "150px",
			center: true,
			style: {
				overflow: "visible",
				wordBreak: "break-word",
			},
			cell: (row) => (
				<div
					className="col text-center d-flex align-items-center"
					style={{
						color:
							row.activeProductsYesterday - row.activeProducts < 0
								? "#6CD691"
								: row.activeProductsYesterday - row.activeProducts > 0 &&
								  "#FF5B5C",
					}}
				>
					<i className="material-icons">
						{row.activeProductsYesterday - row.activeProducts < 0 &&
							"arrow_drop_up"}
						{row.activeProductsYesterday - row.activeProducts > 0 &&
							"arrow_drop_down"}
						{row.activeProductsYesterday - row.activeProducts === 0 && "remove"}
					</i>
					<small>{row.percentage}%</small>
				</div>
			),
		},
	];

	useEffect(() => {
		getClients();
		setInterval(async () => {
			getClients();
		}, 60000);
	}, []);

	const getClients = async () => {
		let clientList = [];
		try {
			let response = await axios.get(
				`${process.env.REACT_APP_API_MICROSOFT_HEALTH}api/productcount`
			);
			response.data.forEach((product) => {
				let newClient = {
					clientId: product.clientId,
					activeProductsYesterday: product.activeProductsYesterday,
					activeProducts: product.activeProducts,
					percentage:
						product.activeProductsYesterday === 0 &&
						product.activeProductsYesterday < product.activeProducts
							? product.activeProducts * 100
							: (
									Math.abs(
										(product.activeProductsYesterday - product.activeProducts) *
											100
									) / product.activeProductsYesterday
							  ).toFixed(2),
				};
				clientList.push(newClient);
			});
			setProductCount(clientList);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			setError(true);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.", {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				preventDuplicate: true,
			});
		}
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="40" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="400" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="700" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="26" rx="3" ry="3" width="52" height="10" />
			</ContentLoader>
		));
	};

	const noIntegration = () => {
		return (
			<div className=" align-items-center py-5 empty">
				<div className="text-center">
					{/* <h3 style={{ color: '#4B5F79', fontSize: '24px', fontWeight: 'bold' }}>Ops, não temos nada por aqui ainda!</h3> */}
					<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
						{" "}
						Seus status ainda não estão disponíveis no momento
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className="card">
			<div class="card-header d-flex justify-content-between align-items-center">
				<h4 class="card-title">Volume de produtos</h4>
				<div class="card-actions">
					<button
						className="btn-sm btn-info light d-flex align-items-center"
						onClick={() => getClients()}
					>
						<i className="material-icons">autorenew</i>
					</button>
				</div>
			</div>
			<div className="card-body">
				<div className="row">
					{loading ? (
						skeleton()
					) : error ? (
						noIntegration()
					) : (
						<List
							data={productCount}
							colums={columsList}
							totalRows={productCount.length}
							paginationServer={false}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default BlackFridayMonitoring;
