import React, { Component } from 'react';

// import { Container } from './styles';

export default class ButtonPrimary extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      // Insere o link do botão
      <button className={this.props.icon != null ? "btn btn-primary btn-icon-c-r btn-lg" : "btn-primary"}style={{width: this.props.width}} onClick={() => window.location.href = this.props.link}>
        {/* Insere o titulo do botão */}
        {this.props.title}
        {/* Verifica se existe icone, se houver insere ao lado direito */}
        {this.props.icon != null ? <i className="material-icons ">
          {this.props.icon}
        </i> : ``
        } </button>
    );
  }
}
