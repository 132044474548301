import React, { useEffect, useState } from "react";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import { toast } from "react-toastify";
import { useDealers } from "../providers/DealersContext";
import Select from "react-select";

export default function FilterDealers(props) {
	const { getSearch, loadQuery, getFilter } = useDealers();
	const [runtimeStatus, setRuntimeStatus] = useState("");
	const [output, setOutput] = useState("");
	const [load, setLoad] = useState(false);
	const [role, setRole] = useState("");
	const [cnpj, setCnpj] = useState("");
	const [query, setQuery] = useState("");
	const [labelEmpty, setLabelEmpty] = useState(null);
	const [labelFilter, setLabelFilter] = useState(false);
	const [labelClear, setLabelClear] = useState(false);
	const [reload, setReload] = useState(false);
	const [count, setCount] = useState(1);
	const [color, setColor] = useState(false);
	const [positions, setPositions] = useState([
		{ label: "Nenhum", value: "" },
		{ label: "Vendedor(a)", value: "Salesperson" }, //0
		{ label: "Vendedor(a) Responsável", value: "ResponsibleSeller" }, //1
		{ label: "Supervisor(a)", value: "Supervisor" }, //2
		{ label: "Coordenador(a)", value: "Coordinator" }, //3
		{ label: "Subgerente", value: "AssistantManager" }, //4
		{ label: "Gerente", value: "Manager" }, //5
		{ label: "Sócio Proprietário", value: "Partner" }, //6
	]);
	const [filter, setFilter] = useState({
		role: role,
		cnpj: cnpj,
	});
	const [clearfilter, setClearFilter] = useState({
		role: role,
		cnpj: cnpj,
	});
	//const [stores, setStores] = useState([]);

	useEffect(() => {
		if (role || cnpj) {
			setLabelFilter(true);
			setColor(true);
			setLabelClear(false);
			setOutput("");
			setRuntimeStatus("");
		} else {
			setLabelFilter(true);
			setLabelClear(false);
		}
		const a = role !== "" ? 1 : 0;
		const b = cnpj !== "" ? 1 : 0;
		setCount(a + b);

		setFilter({
			...filter,
			role: role,
			cnpj: cnpj,
		});
	}, [role, cnpj]);

	const handleClearFilter = () => {
		if (count === 0) {
			setLabelClear(false);
			setColor(false);
			getFilter(filter);
		} else {
			getFilter(filter);
			setLabelFilter(false);
			setLabelClear(true);
		}
	};

	useEffect(() => {
		searchInputData();
	}, [query]);

	const searchInputData = () => {
		const inputSearch = document.querySelector("#search");
		const searchProd = fromEvent(inputSearch, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => search(text));
		return searchProd.unsubscribe;
	};

	const search = (query) => {
		getSearch(query);
		//setLoad(true)
	};

	const clearSearchFilter = () => {
		setColor(false);
		setCount(0);
		setFilter({ role: "", cnpj: "" });
		setRole("");
		setCnpj("");
		setLabelEmpty(null);
		setLabelFilter(false);
		setLabelClear(false);
		setReload(true);
		getFilter(clearfilter);
	};

	const customStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 17,
			},
		}),
	};

	const getExportFilterDealers = async () => {
		toast.error("Não foi possível realizar a exportação!");
		// setLoad(true)
		// await APIV2.get(`insights/export/sellers?isActive=${filter.isActive} `)
		//    .then(response => {
		// 	  const exportFilter = setInterval(async () => {
		// 		 const resp = await axios.get(response.data.statusQueryGetUri)
		// 		 if(resp.data.runtimeStatus === "Running") {
		// 			console.log("Análise =>", response.data.statusQueryGetUri)
		// 		 }else {
		// 			switch (resp.data.runtimeStatus) {
		// 			   case "Completed" :
		// 				  console.log("Entrou no Completed")
		// 				  setOutput(resp.data.output)
		// 				  clearInterval(exportFilter);
		// 				  document.getElementById("exportList").click()
		// 				  setLoad(false)
		// 				  toast.success('Download realizado com sucesso!😃');
		// 				  break;
		// 			   case "Failed" :
		// 				  console.log("Entrou no Failed")
		// 				  clearInterval(exportFilter);
		// 				  toast.error('Não foi possível realizar a exportação!');
		// 				  setLoad(false)
		// 				  break;
		// 			   default:
		// 				  console.log("Entrou no Default")
		// 				  clearInterval(exportFilter);
		// 				  toast.error('Não foi possível realizar a exportação!');
		// 				  setLoad(false)
		// 			}
		// 		 }
		// 	  }, 4000)
		//    })
		//    .catch((error) => {
		// 	  console.log(error)
		// 	  toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
		//    })
	};

	return (
		<>
			<div className="mx-4">
				<div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
					<div className="w-100 mr-1">
						<label htmlFor="search">
							<strong>Busca</strong>
						</label>
						<div className="autocomplete icon-right">
							<input
								type="text"
								className="search form-control"
								id="search"
								name="pesquisa"
								placeholder="Procure pelo nome ou CPF"
								defaultValue={query}
								style={{ width: "100%" }}
							/>
							{loadQuery ? (
								<i className="fas fa-spinner fa-spin"></i>
							) : (
								<i className="material-icons">search</i>
							)}
						</div>
					</div>

					<div className="d-flex flex-column flex-lg-row w-50">
						{/* <div className="d-flex flex-column w-100 mr-1">
							<label htmlFor="typeSeller">
								<strong>Lojas</strong>
							</label>
							<div>
								<SelectStores
									storeCnpj={cnpj}
									filter={true}
									setStoreCnpj={(e) => setCnpj(e)}
								/>
							</div>
						</div> */}
						<div className="d-flex flex-column w-100 mr-1">
							<label className="" htmlFor="role">
								<strong>Cargo</strong>
							</label>
							<div>
								<Select
									styles={customStyles}
									className="react-select-container"
									placeholder="Mostrando Todos"
									value={
										!role
											? role
											: positions[
													positions.findIndex(
														(position) => position.value === role
													)
											  ]
									}
									options={positions}
									onChange={(event) => setRole(event.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="d-flex justify-content-between align-items-center">
				<div
					className="d-flex align-items-center mx-4"
					style={{ width: "100%" }}
				>
					<strong>MOSTRANDO TODOS OS VENDEDORES ({props.dealers})</strong>
				</div>
				<div className="d-flex flex-column flex-lg-row justify-content-end mx-4 my-4">
					{/* {load === true ? (
						<>
							<a
								href={output}
								id="exportList"
								className="d-none"
								style={{ padding: "10px 20px" }}
							></a>
							<button
								disabled
								className="btn btn-outline btn-icon mr-2 d-flex justify-content-center"
								style={{
									padding: "10px",
									border: "1px solid #E9ECEC",
									background: "transparent",
									color: "#747E8B",
									width: "200px",
									cursor: "not-allowed",
								}}
							>
								<>
									<i className="fas fa-spinner fa-spin"></i>
									<span className="p-0 m-0" style={{ fontWeight: "500" }}>
										&nbsp;&nbsp;Processando...
									</span>
								</>
							</button>
						</>
					) : (
						<>
							<a
								href={output}
								id="exportList"
								className="d-none"
								style={{ padding: "10px 20px" }}
							></a>
							<button
								className="btn btn-outline btn-icon mr-2 d-flex justify-content-center"
								style={{
									padding: "10px",
									border: "1px solid #E9ECEC",
									background: "transparent",
									color: "#747E8B",
									width: "200px",
								}}
								onClick={() => getExportFilterDealers()}
							>
								<>
									<i className="material-icons">get_app</i>
									<span className="p-0 m-0" style={{ fontWeight: "400" }}>
										&nbsp;&nbsp;Exportar Vendedores
									</span>
								</>
							</button>
						</>
					)} */}

					{labelFilter && (
						<button
							className={
								color === true
									? "btn btn-primary btn-icon d-flex justify-content-center"
									: "btn btn-outline btn-icon d-flex justify-content-center"
							}
							onClick={() => handleClearFilter()}
							style={{ padding: "10px 20px", width: "200px" }}
						>
							Aplicar Filtros ({count})
						</button>
					)}

					{labelClear && (
						<button
							className="btn btn-outline btn-icon d-flex justify-content-center"
							onClick={() => clearSearchFilter()}
							style={{
								padding: "10px 20px",
								border: "1px solid #747E8B",
								background: "#747E8B",
								width: "200px",
								color: "#E9ECEC",
							}}
						>
							<i className="material-icons">delete_outline</i>&nbsp;&nbsp;Limpar
							Filtros ({count})
						</button>
					)}
				</div>
			</div>
		</>
	);
}
