import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import Toast from "../../../../components/toast/Toast";
import { useMatcher } from "../providers/MatcherContext";

function MatcherToast() {
	const { success, error, setError, setSuccess, loadingDownload } =
		useMatcher();

	const [err, setErr] = useState("");
	const [succs, setSuccs] = useState("");
	const [matcherDownloading, setMatcherDownloading] = useState(loadingDownload);

	useEffect(() => {
		setErr(error);
		setSuccs(success);
		setMatcherDownloading(loadingDownload);
	}, [error, success, loadingDownload]);

	const closeToast = () => {
		setError("");
		setSuccess("");
	};

	return (
		<Toast
			success={succs}
			error={err}
			closeToast={closeToast}
			isMatcher={true}
			matcherDownloading={matcherDownloading}
		/>
	);
}

export default MatcherToast;
