import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fromEvent } from 'rxjs'
import { map, debounceTime } from 'rxjs/operators'

export default function Tutorial(props) {
    const [view, setView] = useState(false)
    const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('primaryColor'))
    const [iconName, setIconName] = useState(props.item.iconName)
    const [title, setTitle] = useState(props.item.title)
    const [content, setContent] = useState(props.item.content)
    const [order, setOrder] = useState(props.item.order)
    const [icons, setIcons] = useState(props.icons)
    const [tutorial, setTutorial] = useState({
        iconName: '',
        title: '',
        order: props.item.order,
        content: ''
    })
    const [oldTutorial, setOldTutorial] = useState({
        iconName: '',
        title: '',
        order: props.item.order,
        content: ''
    })
    let timer

    const memo = useMemo(() => {
        setTutorial({
            iconName: props.item.iconName,
            title: props.item.title,
            order: props.item.order,
            content: props.item.content
        })

        setOldTutorial({
            iconName: props.item.iconName,
            title: props.item.title,
            order: props.item.order,
            content: props.item.content 
        })
    }, [])

    useCallback(() => {
        props.changeItem(tutorial)
    }, [tutorial])

    const changeView = () => {
        props.setItemPreview(props.index)
        setView(!view)
    }


    const cancelEdit = () => {
        setTutorial(oldTutorial)
        props.changeItem(oldTutorial)
        changeView();
    }


    const selectView = () => {
        props.setItemPreview(props.index);
    }
    const saveQuestion = () => {
        changeView();
        props.changeItem(tutorial)
    }

    const change = useCallback((e) => {
        const name = e.target.name
        const value = e.target.value
        setTutorial(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ))
        clearTimeout(timer)
        timer = setTimeout(() => {
            if ("activeElement" in document)
                document.activeElement.blur();
        }, 2000);
    }, [])


    return (
        <div className="dragndrop-item" onClick={() => selectView()}>
            <div className={!view ? 'd-flex flex-column preview-collapse' : 'd-none'}>
                <div className="row">
                    <div className="col-1 p-0 ml-2 ">
                        <div className="rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: primaryColor, width: '40px', height: '40px' }}>
                            <i className="material-icons text-white">{props.item.iconName}</i>
                        </div>
                    </div>
                    <div className="content col-6 d-flex align-items-center justify-content-start">
                        <h5 className='title-tutorial'>{props.item.title}</h5>
                    </div>
                    <div className="actions d-flex align-items-center justify-content-end col-4 p-0">
                        <div className="btn-group p-0" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-light btn-sm outline btn-icon" onClick={() => changeView()}><i className="material-icons">edit</i></button>
                            <button type="button" className="btn btn-light btn-sm outline btn-icon" data-toggle="modal" data-target={props.modal} onClick={() => props.setOrder(props.item.order)}><i className="material-icons text-danger">delete_outline</i></button>
                        </div>
                    </div>

                </div>
                <div className="row mt-2">
                    <span className="description-tutorial mb-0 ml-2 p-1">{props.item.content}</span>
                </div>
            </div>

            <div className={view ? '' : 'd-none'}>
                <div>
                    <form>
                        <div className="form-group row">
                            <div className="col-2 p-3">
                                <div className="rounded-circle d-flex align-items-center justify-content-center" style={{ backgroundColor: primaryColor, width: '50px', height: '50px' }}>
                                    <i className="material-icons text-white">{tutorial.iconName}</i>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className='font-weight-bold'>Ícone: </label>

                                <select className="custom-select btn-light" id="icons" name='iconName' value={tutorial.iconName} onChange={change} onBlur={() => props.changeItem(tutorial)}>
                                    {icons.map((icon, i) =>
                                        <option key={icon.id} value={icon.ligature} className="material-icons">{icon.ligature}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="question" className='font-weight-bold'>Título: </label>
                            <input type="text" className="form-control" id="title" name="title" value={tutorial.title} onChange={change} onBlur={() => props.changeItem(tutorial)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="question" className='font-weight-bold'>Conteúdo: </label>
                            <textarea className="form-control" type="number" id="exampleFormControlTextarea1" name="content" rows="5" value={tutorial.content} onChange={change} onBlur={() => props.changeItem(tutorial)}></textarea>
                        </div>
                        <div className="text-right">
                            <button className='btn btn-light' type='button' onClick={() => cancelEdit()}>Cancelar</button>&nbsp;&nbsp;
                            <button className='btn btn-success' type='button' onClick={() => saveQuestion()}>Salvar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}