import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { API, APIV2 } from "../../API";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { ArrowContainer, Popover } from "react-tiny-popover";
import List from "../../components/List/List";
import { Link } from "react-router-dom";

export default function Index(props) {
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");
	const [statusBlobUrl, setStatusBlobUrl] = useState("");
	const [createImport, setCreateImport] = useState("");
	const [lastUpdatedTime, setLastUpdatedTime] = useState("");
	const [input, setInput] = useState("");
	const [runtimeStatus, setRuntimeStatus] = useState("");
	const [blobStatus, setBlobStatus] = useState([]);
	const [isPopoverOpen, setIsPopoverOpen] = useState("");
	const [configurations, setConfigurations] = useState({});
	const [enableWholesalePrice, setEnableWholesalePrice] = useState("");
	const [status, setStatus] = useState([]);

	const clickMeButtonRef = useRef();

	useEffect(() => {
		setStatus(blobStatus);
	}, [blobStatus]);

	useEffect(() => {
		// get item of array localStorage
		const config = JSON.parse(localStorage.getItem("configurations"));
		console.log(config[0].enableWholesalePrice);
		setEnableWholesalePrice(config[0].enableWholesalePrice);
		getStatus();
	}, []);

	const getStatus = async () => {
		let arrayList = [];
		setTimeout(async () => {
			try {
				const response = await APIV2.get(
					"import/importHistoric?page=1&perPage=5"
				);
				arrayList = await Promise.all(
					response.data.historics.map(async (file) => {
						try {
							const resp = await axios.get(file.urlStatus);
							return { data: resp.data, id: file.id, file: file };
						} catch (error) {
							console.log(error);
							return { data: {}, id: file.id, file: file };
						}
					})
				);
				setBlobStatus(arrayList);
				setLoading(false);
				if (
					arrayList.some(
						(item) => item.data.ProgressStatus !== 100 && !item.data.message
					)
				) {
					getStatus();
				} else {
					clearTimeout();
					return;
				}
			} catch (error) {
				console.log(error);
			}
		}, 1000);
	};

	const importDocument = (type) => async (event) => {
		setLoading(true);
		let formData = new FormData();
		formData.append("file", event.target.files[0]);
		try {
			const response = await APIV2.post("import/file/excel/" + type, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			await getStatus();
			// setLoading(false)
			toast.success("Seu arquivo está sendo importado em segundo plano. 😃");
		} catch (error) {
			console.log(error);
			// setLoading(false)
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	/* const changeAttributes = (e) => {
		let formData = new FormData();
		setLoading(true)
		formData.append("file", e.target.files[0]);

		API.post('api/products/skus/attributes/import', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then(async response => {
				setShow(true)
				setTitle('Sucesso!')
				setLoading(false)
				setMessage(response.data)

			})
			.catch((error) => {
				console.log(error);
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
				setShow(true)
				setTitle('Erro!')
				setLoading(false)
				setMessage("Infelizmente tivemos um problema, tente novamente ou contate o suporte.")
			})
	} */

	const renderScreen = () => {
		return (
			<div className="container dash-content ">
				<div className="card  pb-5">
					<div className="card-header">
						<h4 className="card-title mb-3">Importação de arquivo</h4>
						<p className="mb-0">
							Escolha abaixo qual o tipo de importação deseja realizar
						</p>
					</div>
					<div className="card-body pt-0">
						<div className="row">
							<div className="col-12">
								<h5 className="text-bold mb-3">Catálogo</h5>
							</div>
							{localStorage.getItem("role") == "3" ? null : (
								<>
									<div className="col-sm-4">
										<div className="importItem">
											<div className="header">
												<div className="icon">
													<i className="material-icons">ballot</i>
												</div>
												<div>
													<span className="title">
														Importar produtos{" "}
														<a
															className="help"
															target="_blank"
															href="https://neomode.zendesk.com/hc/pt-br/articles/360045637073-Como-importar-produtos-para-Lori"
														>
															<i className="material-icons">help_outline</i>
														</a>
													</span>
													<a
														className="attachment"
														target="_blank"
														href="https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_produtos.xlsx"
													>
														baixe o modelo aqui
													</a>
												</div>
											</div>
											<div className="file-button">
												<label className="btn btn-primary btn-block mb-0">
													<input
														type="file"
														onChange={importDocument("products")}
													/>
													Enviar arquivos
												</label>
											</div>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="importItem">
											<div className="header">
												<div className="icon">
													<i className="material-icons">invert_colors</i>
												</div>
												<div>
													<span className="title">
														Importar Atributos do SKU{" "}
														<a
															className="help"
															target="_blank"
															href="https://neomode.zendesk.com/hc/pt-br/articles/360047673894-Como-importar-atributos-SKUs"
														>
															<i className="material-icons">help_outline</i>
														</a>
													</span>
													<a
														className="attachment"
														target="_blank"
														href="https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_atributos_sku.xlsx"
													>
														baixe o modelo aqui
													</a>
												</div>
											</div>
											<div className="file-button">
												<label className="btn btn-primary btn-block mb-0">
													<input
														type="file"
														onChange={importDocument("attributes")}
													/>
													Enviar arquivos
												</label>
											</div>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="importItem">
											<div className="header">
												<div className="icon">
													<i className="material-icons">attach_money</i>
												</div>
												<div>
													<span className="title">
														Importar Preços{" "}
														<a
															className="help"
															target="_blank"
															href="https://neomode.zendesk.com/hc/pt-br/articles/360053794353-Como-importar-pre%C3%A7o"
														>
															<i className="material-icons">help_outline</i>
														</a>
													</span>
													<a
														className="attachment"
														target="_blank"
														href={
															enableWholesalePrice
																? "https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_preco_atacado.xlsx"
																: "https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_preco.xlsx"
														}
													>
														baixe o modelo aqui
													</a>
												</div>
											</div>
											<div className="file-button">
												<label className="btn btn-primary btn-block mb-0">
													<input
														type="file"
														onChange={importDocument("prices")}
													/>
													Enviar arquivos
												</label>
											</div>
										</div>
									</div>
								</>
							)}
							<div className="col-sm-4 mt-3">
								<div className="importItem">
									<div className="header">
										<div className="icon">
											<i className="material-icons">move_to_inbox</i>
										</div>
										<div>
											<span className="title">
												Importar estoque{" "}
												<a
													className="help"
													target="_blank"
													href="https://neomode.zendesk.com/hc/pt-br/articles/360047506814-Como-importar-estoque"
												>
													<i className="material-icons">help_outline</i>
												</a>
											</span>
											<a
												className="attachment"
												target="_blank"
												href="https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_estoque.xlsx"
											>
												baixe o modelo aqui
											</a>
										</div>
									</div>
									<div className="file-button">
										<label className="btn btn-primary btn-block mb-0">
											<input type="file" onChange={importDocument("stocks")} />
											Enviar arquivos
										</label>
									</div>
								</div>
							</div>
							<div className="col-sm-4 mt-3">
								<div className="importItem">
									<div className="header">
										<div className="icon">
											<i className="material-icons">art_track</i>
										</div>
										<div>
											<span className="title">
												Importar caracteristicas{" "}
												<a
													className="help"
													target="_blank"
													href="https://neomode.zendesk.com/hc/pt-br/articles/1500005081241-Como-importar-caracter%C3%ADsticas"
												>
													<i className="material-icons">help_outline</i>
												</a>
											</span>
											<a
												className="attachment"
												target="_blank"
												href="https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_caracteristicas.xlsx"
											>
												baixe o modelo aqui
											</a>
										</div>
									</div>
									<div className="file-button">
										<label className="btn btn-primary btn-block mb-0">
											<input
												type="file"
												onChange={importDocument("characteristics")}
											/>
											Enviar arquivos
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							{localStorage.getItem("role") == "8" ||
							localStorage.getItem("role") == "3" ? null : (
								<>
									<div className="col-12">
										<h5 className="text-bold mb-3">Lojas</h5>
									</div>
									<div className="col-sm-4">
										<div className="importItem">
											<div className="header">
												<div className="icon">
													<i className="material-icons">ballot</i>
												</div>
												<div>
													<span className="title">
														Importar lojas{" "}
														<a
															className="help"
															target="_blank"
															href="https://neomode.zendesk.com/hc/pt-br/articles/360047674194-Como-importar-lojas-f%C3%ADsicas"
														>
															<i className="material-icons">help_outline</i>
														</a>
													</span>
													<a
														className="attachment"
														target="_blank"
														href="https://loriprd.blob.core.windows.net/lori-static/neomode_importacao_lojas.xlsx"
													>
														baixe o modelo aqui
													</a>
												</div>
											</div>
											<div className="file-button">
												<label className="btn btn-primary btn-block mb-0">
													<input
														type="file"
														onChange={importDocument("sellers")}
													/>
													Enviar arquivos
												</label>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="mt-3">
						<div className="card-header d-flex justify-content-between">
							<h4 className="card-title mb-3">Status das importações</h4>
							<a
								class="btn btn-link text-secondary"
								style={{ textDecorationLine: "underline" }}
								href="import/history/1"
							>
								Ver importações anteriores
							</a>
						</div>
						{loading ? <Loading /> : importList()}
					</div>
				</div>
				<div
					style={show ? { display: "block" } : { display: "none" }}
					className={show ? "modal fade show" : "modal fade"}
					id="exampleModal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => setShow(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body text-center">
								<h3>{title}</h3>
								<p>{message}</p>
							</div>
							<div className="modal-footer text-center">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal"
									onClick={() => setShow(false)}
								>
									Fechar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const importList = () => {
		return (
			<table className="table history-import">
				<tbody>
					<tr>
						<th className="text-center">Tipo da importação</th>
						<th className="text-center">Início</th>
						<th className="text-center">Fim</th>
						<th className="text-center">Status</th>
						<th className="text-center"></th>
						<th className="text-center"></th>
					</tr>
					{status.map((blob, i) => (
						<tr key={i}>
							<td
								className={
									blob.data.HasError && blob.data.ProgressStatus !== 100
										? "text-center  text-danger font-weight-bold"
										: blob.data.HasError
										? "text-center  text-warning font-weight-bold"
										: "text-center"
								}
							>
								{blob.file.type == "products"
									? "Produtos"
									: blob.file.type == "prices"
									? "Preços"
									: blob.file.type == "sellers"
									? "Loja"
									: blob.file.type === "stocks"
									? "Estoques"
									: blob.file.type == "attributes"
									? "Atributo"
									: blob.file.type == "characteristics"
									? "Caracteristicas"
									: blob.file.type == "skus"
									? "SKUs"
									: "Imagem de Produto"}
							</td>
							<td
								className={
									blob.data.HasError && blob.data.ProgressStatus !== 100
										? "text-center  text-danger font-weight-bold"
										: blob.data.HasError
										? "text-center  text-warning font-weight-bold"
										: "text-center"
								}
							>
								{moment(blob.file.startDate).format("DD/MM/YYYY HH:mm")}
							</td>
							<td
								className={
									blob.data.HasError && blob.data.ProgressStatus !== 100
										? "text-center  text-danger font-weight-bold"
										: blob.data.HasError
										? "text-center  text-warning font-weight-bold"
										: "text-center"
								}
							>
								{moment(blob.file.timestamp).format("DD/MM/YYYY HH:mm")}
							</td>
							<td className="text-center" colSpan="2">
								{blob.data.HasError && blob.data.ProgressStatus !== 100 ? (
									<span className="badge badge-danger stadium text-center">
										Não importado
									</span>
								) : blobStatus.hasError ? (
									<span className="badge badge-warning stadium text-center">
										{" "}
										Arquivo com erro
									</span>
								) : (
									<div className="progress ">
										<div
											className={
												!blob.data.CurrentStatus
													? "progress-bar  bg-danger"
													: blob.data.HasError
													? "progress-bar  bg-warning"
													: "progress-bar  bg-primary"
											}
											role="progressbar"
											style={{
												width: !blob.data.CurrentStatus
													? "100%"
													: blob.data.ProgressStatus + "%",
												textAlign: "center",
											}}
											aria-valuenow="25"
											aria-valuemin="0"
											aria-valuemax="100"
										>
											{blob.data.ProgressStatus > 0 ? (
												<>{blob.data.ProgressStatus} %</>
											) : blob.data.CurrentStatus ? (
												<small>executando</small>
											) : (
												<>Erro</>
											)}
										</div>
										{(blob.data.HasError || !blob.data.CurrentStatus) && (
											<Popover
												style={{ zIndex: 10 }}
												isOpen={isPopoverOpen === blob.id}
												position={["top", "left"]} // if you'd like, you can limit the positions
												padding={10} // adjust padding here!
												reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
												onClickOutside={() => setIsPopoverOpen("")} // handle click events outside of the popover/target here!
												content={() => (
													// you can also provide a render function that injects some useful stuff!

													<div
														style={{
															zIndex: 100,
															backgroundColor: "rgba(0,0,0, 0.8)",
															color: "white",
															padding: "1em",
															borderRadius: 5,
															width: 200,
															textAlign: "center",
														}}
													>
														Atenção: houve um problema na importação
													</div>
												)}
											>
												<i
													className="material-icons text-danger"
													onBlur={() => setIsPopoverOpen("")}
													onClick={() => setIsPopoverOpen(blob.id)}
												>
													error_outline
												</i>
											</Popover>
										)}
									</div>
								)}
							</td>
							<td className="text-center">
								<a
									href="#"
									className="btn btn-link text-secondary"
									style={{ textDecorationLine: "underline" }}
									data-toggle="modal"
									data-target={`#details-${blob.id}`}
								>
									ver detalhes
								</a>
								<Modal
									id={`details-${blob.id}`}
									import="true"
									titleComponent={
										<>
											<h3 className="modal-title">
												Detalhes da importação{" "}
												{blob.data.HasError ? (
													<span
														className="text-warning"
														style={{ fontSize: "14px" }}
													>
														Ocorreu algum erro
													</span>
												) : blob.data.ProgressStatus === 100 &&
												  !blob.data.HasError ? (
													<span
														className="text-primary"
														style={{ fontSize: "14px" }}
													>
														concluído
													</span>
												) : blob.data.HasError &&
												  blob.data.ProgressStatus !== 100 ? (
													<span
														className="text-danger"
														style={{ fontSize: "14px" }}
													>
														Ocorreu algum erro
													</span>
												) : (
													<span
														className="text-primary"
														style={{ fontSize: "14px" }}
													>
														em andamento
													</span>
												)}
											</h3>
										</>
									}
									large={true}
									content={
										<div>
											{blob.data ? <p>{blob.data.currentStatus}</p> : null}
											<div className="progress">
												<div
													className={
														blob.data.HasError &&
														blob.data.ProgressStatus !== 100
															? "progress-bar  bg-danger"
															: blob.data.HasError
															? "progress-bar  bg-warning"
															: "progress-bar  bg-primary"
													}
													role="progressbar"
													style={{
														width: blob.data.ProgressStatus + "%",
														textAlign: "right",
													}}
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
											<div className="mt-5 p-2" id="details">
												{blob.data.Items && blob.data.Items.length > 0 && (
													<a
														href={blob.data.Items[0].MainFileUrl}
														target="_blank"
														className="download-archive"
													>
														<span className="material-icons">archive</span>
														download arquivo de importação
													</a>
												)}
												<div className="import-list-log-files">
													{blob.data.Items &&
														blob.data.Items.map((item, i) => (
															<div className="row d-flex align-items-center border-bottom">
																<div className="col-3 text-left ">
																	Parte {item.PartId}
																</div>
																<div className="col-5">
																	{item.HasData && !item.HasError ? (
																		<span className="badge badge-primary stadium text-center">
																			Concluído
																		</span>
																	) : item.ValidationFileUrl ? (
																		<div className="log-erro">
																			<span className="badge badge-warning stadium text-center">
																				Arquivo com erro
																			</span>
																			<a
																				href={item.ValidationFileUrl}
																				target="_blank"
																			>
																				<i className="material-icons">
																					error_outline
																				</i>{" "}
																				log de erro
																			</a>
																		</div>
																	) : (
																		<span className="badge badge-info stadium text-center">
																			Em andamento
																		</span>
																	)}
																</div>
																<div className="col-4 text-right">
																	<a
																		href={item.PartFileUrl}
																		taget="_blank"
																		style={{ textDecoration: "underline" }}
																		className="btn btn-link text-secondary"
																	>
																		download
																	</a>
																</div>
															</div>
														))}
												</div>
											</div>
											<div className="import col-12">
												<div className="import-details-body">
													<p>
														Início:
														{moment(blob.file.startDate).format(
															"DD/MM/YYYY HH:mm"
														)}
													</p>
													<p>
														Fim:
														{moment(blob.file.timestamp).format(
															"DD/MM/YYYY HH:mm"
														)}
													</p>
													{/* {blob.data.CurrentStatus ? blob.data.HeaderErrors && blob.data.HeaderErrors.map((item, i) => */}
													<p>{blob.data.CurrentStatus}</p>
													{/* ) : <p>Não foi possível importar o Arquivo</p>} */}
												</div>
											</div>
										</div>
									}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	return renderScreen();
}
