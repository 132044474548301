import React, { useEffect, useState } from "react";

import BannerCarrosel from "./Banner/CarouselBanner";
import BannerBody from "./Banner/BodyBanner";
import BannerNew from "./Banner/New";
import AppPreview from "./Config/Index";
import Tutorial from "./Tutorial/Index";
import { Redirect } from "react-router-dom";

function Index(props) {
	const handleScreen = () => {
		let comp;
		switch (props.screen) {
			case "carousel-banner":
				comp = <BannerCarrosel url={props.url} />;
				break;
			case "body-banner":
				comp = <BannerBody url={props.url} />;
				break;
			case "new-carousel":
				comp = (
					<BannerNew url={props.url} type={0} screen={"carousel-banner"} />
				);
				break;
			case "new-body":
				comp = <BannerNew url={props.url} type={1} screen={"body-banner"} />;
				break;
			case "new-default":
				comp = (
					<BannerNew
						url={props.url}
						default={true}
						screen={"carousel-banner"}
					/>
				);
				break;
			case "/edit/:id":
				comp = <BannerNew url={props.url} />;
				break;
			case "config":
				comp = <AppPreview />;
				break;
			case "tutorial":
				comp = <Tutorial url={props.url} />;
				break;
			default:
				comp = <Redirect exact push to="/*" />;
				break;
		}
		return comp;
	};

	return <>{handleScreen()}</>;
}

export default Index;
