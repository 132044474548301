import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import OrdersContext from "./providers/OrdersContext";
import OrdersList from "./OrdersList";

//import Metrics from './components/Metrics';

export default function Index(props) {
   const [loading, setLoading] = useState(false)

   let params = new URL(document.location).searchParams;

   useEffect(() => {
			if (!props.match.params.page) {
         window.location.href = "/orders/1";
      }
   }, []);

   const renderScreen = () => {
      return (
         <OrdersContext >
            <div className="container dash-content">
               <OrdersList
                  page={props.match.params.page}
                  since={params.get("since")}
                  until={params.get("until")}
                  status={params.get("status")}
                  isDelivery={params.get("isDelivery")}
                  channel={params.get("channel")}
               />
            </div>
         </OrdersContext>
         
      );
   };
   return (
      <>
         {loading ? (
         <div className="container dash-content">
            <div className="card">
               <Loading />
            </div>
         </div>
         ) : (
         renderScreen()
         )}
      </>
   );
}
