import React, { Component, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { APIV2 } from '../../API';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

export default function Integration(props) {
    const [integrationConfig, setIntegrationConfig] = useState({})
    const [load, setLoad] = useState(true)
    const [disable, setDisable] = useState(false)
    const [processesList, setProcessList] = useState([])
    const [processes, setProcesses] = useState({})


    useEffect(() => {
        getIntegration()
    }, [])

    const getIntegration = async () => {
        try {
            const response = await APIV2.get(`/management/retailer/sellers/${props.store.id}/integrationconfig/${props.store.integrationConfig.id}`)
            setIntegrationConfig(response.data)
            getSellersProcess()
        } catch (error) {
            setLoad(false)
            console.log(error)
        }

    }


    const getSellersProcess = async () => {
        try {
            const response = await APIV2.get('/management/retailer/autocomplete/processes/sellers')
            console.log(props.store)
            let pro = response.data.filter(processesL => processesL.id === props.store.processId.toString().toLowerCase())
            console.log(pro)
            setProcessList(response.data)
            setProcesses(pro[0])
            setLoad(false)
        } catch (error) {
            setLoad(false)
            console.log(error)
        }
    }

    const onChangeIntegrationConfig = (e) => {
        setIntegrationConfig({ ...integrationConfig, [e.target.name]: e.target.value })
    }

    const handleChangeProcess = (e) => {
        const value = e.target.value
        let pro = processesList.filter(processesL => processesL.id === value)
        setProcesses(pro[0])
    }

    const save = async () => {
        setDisable(true)
        try {
            await APIV2.put(`/management/retailer/sellers/${props.store.id}/integrationconfig`, integrationConfig)
            await APIV2.put(`/management/retailer/sellers/${props.store.id}/process`, processes)
            toast.success('Integrações editadas com sucesso! 😃');
            props.save()
            setDisable(false)
        } catch (error) {
            toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
            console.log(error)
            setDisable(false)
        }
    }

    const renderForm = () => {
        return (<>
            <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">Integração</h4>
            </div>
            <div className="card-content">
                <div className="card-body pt-0">
                    <div className="row">
                        <div className="form-group col-sm-4">
                            <label htmlFor="orderIntegrationId"><strong>Identificador de pedido</strong></label>
                            <div>
                                <input type="text" className="form-control" id="orderIntegrationId" name='orderIntegrationId' defaultValue={integrationConfig ? integrationConfig.orderIntegrationId : ''} onChange={e => onChangeIntegrationConfig(e)} required />
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="paymentIntegrationId"><strong>Identificador de pagamento</strong></label>
                            <div>
                                <input type="text" className="form-control" id="paymentIntegrationId" name='paymentIntegrationId' defaultValue={integrationConfig != null ? integrationConfig.paymentIntegrationId : ''} onChange={e => onChangeIntegrationConfig(e)} required />
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="fraudIntegrationId"><strong>Identificador de anti-fraude</strong></label>
                            <div>
                                <input type="text" className="form-control" id="fraudIntegrationId" name='fraudIntegrationId' defaultValue={integrationConfig != null ? integrationConfig.fraudIntegrationId : ''} onChange={e => onChangeIntegrationConfig(e)} required />
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="orderIntegrationCredentials"><strong>Credenciais de pedido</strong></label>
                            <div>
                                <input type="text" className="form-control" id="orderIntegrationCredentials" name='orderIntegrationCredentials' defaultValue={integrationConfig != null ? integrationConfig.orderIntegrationCredentials : ''} onChange={e => onChangeIntegrationConfig(e)} required />
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="paymentIntegrationCredentials"><strong>Credenciais de pagamento</strong></label>
                            <div>
                                <input type="text" className="form-control" id="paymentIntegrationCredentials" name='paymentIntegrationCredentials' defaultValue={integrationConfig != null ? integrationConfig.paymentIntegrationCredentials : ''} onChange={e => onChangeIntegrationConfig(e)} required />
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="fraudIntegrationCredentials"><strong>Credenciais de anti-fraude</strong></label>
                            <div>
                                <input type="text" className="form-control" id="fraudIntegrationCredentials" name='fraudIntegrationCredentials' defaultValue={integrationConfig != null ? integrationConfig.fraudIntegrationCredentials : ''} onChange={e => onChangeIntegrationConfig(e)} required />
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                        <label htmlFor="fraudIntegrationCredentials"><strong>Processos de pedidos</strong></label>
                        <select className="form-control" value={processes.id} onChange={e => handleChangeProcess(e)}>
                                    {processesList.map((processes, i) =>
                                        <option key={i} value={processes.id}>{processes.name}</option>
                                    )}
                                </select>
                        </div>

                    </div>
                </div>
            </div>

            <div className="form-group row mt-20 ml-2">
                <div className="col-12 d-flex flex-row-reverse">
                    <Button loading={disable} type="button" className="btn btn-primary" onClick={save}>{props.id ? 'Editar loja' : 'Criar loja'}</Button>
                </div>
            </div>

        </>)
    }

    return (
        <>
            {load? <Loading/> : renderForm()}
        </>

    )
};

