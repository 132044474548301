import React, { Component } from "react";
import { API } from "../../../../API";
import Loading from "../../../../components/Loading";
import SearchList from "../../../../components/SearchList";
import { ToastContainer, toast } from "react-toastify";
export class Stores extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	state = {
		loading: true,
		stores: [],
		list: [],
	};

	componentDidMount() {
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("/api/sellers/list")
			.then(async (response) => {
				// If request is good...

				this.setState({
					list: response.data,
					loading: false,
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}

	onChange(i) {
		if (this.state.stores.indexOf(i) !== -1) {
			const index = this.state.stores.indexOf(i);
			this.state.stores.splice(index, 1);
		} else {
			this.state.stores.push(i);
		}
	}
	save(i) {
		var item = {
			sellers: this.state.stores,
			value: this.state.stores,
		};
		if (this.state.stores.length > 1) {
			var text = " Lojas";
		} else {
			var text = " Loja";
		}
		var itemView = {
			name: "Lojas",
			value: this.state.stores.length + text,
			itemId: "sellers",
			modal: "#conditionModalSellers",
		};
		this.props.newCondition(item, itemView);
	}

	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalSellers"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalSellers"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Lojas</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body pt-1 px-0">
							{this.state.loading ? (
								<Loading />
							) : (
								<SearchList
									list={this.state.list}
									pointSaleFirst={true}
									change={this.onChange}
									placeholder={"Procure pelo nome da Loja"}
								/>
							)}
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col-5"
								style={{ width: "120px" }}
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col-5"
								style={{ width: "120px" }}
								onClick={() => this.save()}
								data-dismiss="modal"
							>
								Pronto
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Stores;
