import React, { useState, useEffect} from 'react';
import { API, APIV2 } from '../../../../API' ;
import Loading from '../../../../components/Loading';
import RulesPromotions from './components/RulesPromotions';
import 'react-widgets/dist/css/react-widgets.css';
import Products from '../../../../components/Products'
import Modal from '../../../../components/Modal';
import { toast } from 'react-toastify';
import PromotionContext from './Providers/PromotionContext';

export default function New(props) {
    const [edit, setEdit] = useState(false)

    return (
        <PromotionContext>
            <div className="container dash-content" >
                <div id="">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{edit ? 'Editar promoção' : 'Nova promoção'}</h4>
                        </div>
                        <div className="card-content">
                            <div className="card-body p-5 pt-0">
                                <RulesPromotions id={props.match.params.id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PromotionContext>
    )
}
