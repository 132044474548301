import React, { Component } from "react";

export class Conditions extends Component {
	constructor(props) {
		super(props);
		this.changeCondition = this.changeCondition.bind(this);
	}
	state = {
		conditions: [
			{
				label: "Valor mínimo do carrinho",
				id: "minValue",
			},
			{
				label: "Categorias",
				id: "categoriesId",
			},
			{
				label: "Produtos",
				id: "products",
			},
			{
				label: "Quantidade mínima de itens",
				id: "minQuantity",
			},
			{
				label: "Tags",
				id: "tags",
			},
			{
				label: "Consumidores por CPF",
				id: "documents",
			},
			{
				label: "Modalidade de compra",
				id: "shippingType",
			},
			{
				label: "Utilização única",
				id: "oneByUser",
			},
			{
				label: "Primeira compra",
				id: "firstUse",
			},
			{
				label: "Cidade",
				id: "cities",
			},
			{
				label: "Estado",
				id: "states",
			},
			{
				label: "Loja",
				id: "sellers",
			},
			{
				label: "Meio de pagamento",
				id: "paymentMethods",
			},
			{
				label: "Não aplica desconto em produto remarcado?",
				id: "isNotApplyProductReprice",
			},
		],
		conditionsBackup: [
			{
				label: "Valor mínimo do carrinho",
				id: "minValue",
			},
			{
				label: "Categorias",
				id: "categoriesId",
			},
			{
				label: "Produtos",
				id: "products",
			},
			{
				label: "Quantidade mínima de itens",
				id: "minQuantity",
			},
			{
				label: "Coleção de produtos",
				id: "tags",
			},
			{
				label: "Consumidores por CPF",
				id: "documents",
			},
			{
				label: "Modalidade de compra",
				id: "shippingType",
			},
			{
				label: "Utilização única",
				id: "oneByUser",
			},
			{
				label: "Primeira compra",
				id: "firstUse",
			},
			{
				label: "Cidade",
				id: "cities",
			},
			{
				label: "Estado",
				id: "states",
			},
			{
				label: "Loja",
				id: "sellers",
			},
			{
				label: "Meio de pagamento",
				id: "paymentMethods",
			},
			{
				label: "Não aplica desconto em produto remarcado?",
				id: "isNotApplyProductReprice",
			},
		],
		loading: true,
	};
	componentWillMount() {
		this.setState({ loading: true });
		this.forceUpdate();
		for (var i = this.state.conditions.length - 1; i >= 0; i--) {
			for (var j = 0; j < this.props.existCondition.length; j++) {
				if (
					this.state.conditions[i].id === this.props.existCondition[j].itemId
				) {
					this.state.conditions.splice(i, 1);
				}
			}
			this.setState({ loading: false });
		}
		this.forceUpdate();
	}
	componentDidMount() {
		this.setState({ loading: true });
		this.forceUpdate();
		for (var i = this.state.conditions.length - 1; i >= 0; i--) {
			for (var j = 0; j < this.props.existCondition.length; j++) {
				if (
					this.state.conditions[i].id === this.props.existCondition[j].itemId
				) {
					this.state.conditions.splice(i, 1);
				}
			}
			this.setState({ loading: false });
		}
		this.forceUpdate();
	}
	async componentWillReceiveProps() {
		await this.setState({
			loading: true,
			conditions: this.state.conditionsBackup,
		});
		this.state.conditions = this.state.conditionsBackup;
		this.forceUpdate();

		for (var i = this.state.conditions.length - 1; i >= 0; i--) {
			for (var j = 0; j < this.props.existCondition.length; j++) {
				if (
					this.state.conditions[i].id === this.props.existCondition[j].itemId
				) {
					this.state.conditions.splice(i, 1);
				}
			}
			this.setState({ loading: false });
		}
		this.forceUpdate();
	}

	componentWillUnmount() {
		this.setState({ conditions: this.state.conditionsBackup, loading: true });
	}
	changeCondition(id) {
		this.props.selectNewCondition(id);
	}
	render() {
		return (
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title">Condições de Uso</h3>
						<button
							type="button"
							className="close text-danger"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<div className="modal-body conditions">
						{this.state.loading ? (
							"Loading"
						) : (
							<ul>
								{this.state.conditions.map((item) => (
									<li
										key={item.id}
										onClick={() => this.changeCondition(item.id)}
									>
										{item.label}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Conditions;
