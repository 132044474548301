import React, { Component } from "react";
import { API, APIV2 } from "../../../../API";
import Loading from "../../../../components/Loading";
import Empty from "../../../../components/Empty";
import VouchersList from "./VouchersList";
import { ToastContainer, toast } from "react-toastify";
import ContentLoader from "react-content-loader";
export class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exportVouchers: [],
			vouchers: [],
			maxCount: null,
			maxPage: null,
			loading: true,
			page: 1,
			rowsPerPage: 20,
		};
		this.changePage = this.changePage.bind(this);
		this.getVouchers = this.getVouchers.bind(this);
		//this.exportVouchers = this.exportVouchers.bind(this)
		this.changeRowsPerPage = this.changeRowsPerPage.bind(this);
		this.skeleton = this.skeleton.bind(this);
	}

	async changePage(e) {
		console.log("página sendo chamada", e);
		this.setState({ loading: true, page: e });
		const stateObj = { page: this.props.match.params.page };
		window.history.pushState(stateObj, "", e);
		this.forceUpdate();

		await APIV2.get(
			`management/marketing/vouchers?page=${e}&perPage=${this.state.rowsPerPage}`
		)
			.then(async (response) => {
				this.setState({
					exportVouchers: response.data.vouchers,
					vouchers: response.data.vouchers,

					maxCount: response.data.maxCount,
					maxPage: response.data.maxPage,
					page: response.data.page,
					loading: false,
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}

	async changeRowsPerPage(e) {
		await this.setState({ rowsPerPage: e });
		this.getVouchers();
	}

	componentDidMount() {
		if (!this.props.match.params.page || this.props.match.params.page === "0") {
			window.location.href = "/vouchers/1";
		}
		this.getVouchers();
	}

	async getVouchers() {
		this.setState({ loading: true, page: this.props.match.params.page });
		await APIV2.get(
			`management/marketing/vouchers?page=${this.props.match.params.page}&perPage=${this.state.rowsPerPage}`
		)
			.then(async (response) => {
				this.setState({
					exportVouchers: response.data.vouchers,
					vouchers: response.data.vouchers,
					maxCount: response.data.maxCount,
					maxPage: response.data.maxPage,
					loading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}

	skeleton() {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	}

	render() {
		return (
			// 	{this.state.loading ? (
			// 		this.skeleton()
			// 	) : (
			<>
				<VouchersList
					vouchers={this.state.vouchers}
					loading={this.state.loading}
					page={this.state.page}
					totalItems={this.state.maxCount}
					exportVouchers={this.state.exportVouchers}
					rowsPerPage={this.state.rowsPerPage}
					changePage={this.changePage}
					changeRowsPerPage={this.changeRowsPerPage}
				/>
			</>
			// 	)}
		);
	}
}
export default Index;
