import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import Dropzone from "react-dropzone";

//import { toast } from 'react-toastify';
//import { API, APIV2 } from '../../API';

export default function TableShipping(props) {
    const [loading, setLoading] = useState(true)
    const [skus, setSkus] = useState({
		id: "",
		name: "",
		codeSku: "",
		imageUrl: "",
		images: [],
		isActive: true,
		auxCode: "",
	})
    
    useEffect(() => {
        //console.log("Basic Info")
        setLoading(false)
    }, [])

    const handleNewFreight = () => {
        //console.log("Novo Frete")
    }

    const onChange = (e) => {
        //console.log(e.target.value)
    }

    const onFileDrop =  async (files) => {
    }

    const saveInfos = async (step) => {
        //console.log("SaveInfo")
        props.save(step) // essas informação vão o new freight
    }

    const renderScreen = () => {
        return (
            <div className="row m-2">
                <div className="w-100 px-3 mb-2">
                    <h3 className="card-title" style={{ fontSize: '18px'}}>Envio de Tabela (não obrigatório)</h3>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label><strong>Importar Tabela de Frete</strong></label>
                        <p className="m-0 p-0" style={{ color: '#747E8B', fontSize: '12px', lineHeight: '16px', fontWeight: 'normal', fontStyle: 'normal'}}>Se desejar, selecione um arquivo para automatizar essa modalidade de frete.</p>
                        <a href="#" className="text-secondary" style={{ marginBottom: '10px' }}>Baixar modelo de tabela</a>
                    </div>
                    
                    <div className="form-group">
                        <label><strong>Arquivo da Nota: </strong></label><br />
                        <Dropzone
                            onDrop={onFileDrop}
                            accept=".pdf, .xml"
                            multiple={true}>
                                {({ getRootProps, getInputProps }) => {
                                    return (
                                        <div className="download-pdf-xml" style={{ width: '100%' }} {...getRootProps()}>
                                            <input {...getInputProps()} />

                                                <div className="success" style={{ width: '100%' }}>
                                                    <p className="mt-2" style={{ fontWeight: '500', fontSize:'12px' }}>
                                                            {loading === true 
                                                                ? <i className="fas fa-spinner fa-spin"></i> 
                                                                :<> 
                                                                    <i className="material-icons"> note_add </i>&nbsp;&nbsp;Arraste ou escolha um arquivo XML ou PDF
                                                                </>
                                                            } 
                                                    </p>
                                                </div>
                                            
                                        </div>
                                    )
                                }}
                        </Dropzone>
                        <label style={{ color: "#A1A3A5", fontWeight: "500", fontSize: "12px" }}><em>São suportados arquivos no formato XML</em></label>
                        <div className="d-flex justify-content-center flex-column mt-2 p-0">
                                <div className="download-group m-0 p-0 mb-3">
                                    <div className="download-group-label">
                                        <i className="material-icons">insert_drive_file</i>
                                        <span>&nbsp;Arquivo XML da Nota Fiscal</span>
                                    </div>
                                    <div className="download-group-icons">
                                        <button /* onClick={() => chooseFile('xml')} */ data-toggle="modal" data-target="#deleteNF"><i className="material-icons" style={{color: '#FF5B5C', paddingRight:'10px'}}>delete_outline</i></button>
                                        <a href="#" target="_blank"><i className="material-icons">get_app</i></a> 
                                    </div>      
                                </div>
                            
                                
                                {/* <div className="download-group m-0 p-0">
                                    <div className="download-group-label">
                                        <i className="material-icons">insert_drive_file</i>
                                        <span>&nbsp;Arquivo PDF da Nota Fiscal</span>
                                    </div>
                                    <div className="download-group-icons">
                                        <button onClick={() => chooseFile('pdf')} data-toggle="modal" data-target="#deleteNF"><i className="material-icons" style={{color: '#FF5B5C', paddingRight:'10px'}}>delete_outline</i></button>
                                        <a href="#" target="_blank"><i className="material-icons">get_app</i></a>
                                    </div>      
                                </div> */}
                            
                        </div>
                    </div>

                </div>

                <div className="col-md-6">
                    <div className="card-content">
                        <div className="row">
                            <div className="form-group col-sm-11">
                                <label><strong>Informações da Tabela</strong></label>
                                <p style={{ color: '#747E8B', fontSize: '12px', lineHeight: '16px', fontWeight: 'normal', fontStyle: 'normal'}}>Ao adicionar uma tabela, seu arquivo ficará disponível para ser baixado posteriormente.</p>
                            </div>

                            {/* aqui podeentrar uma lista */}
                            <div className="form-group col-sm-11">
                                <div className="d-flex flex-row justify-content-between align-items-center p-2" style={{ border: 'solid 1px#E9ECEC', borderLeft: 'none', borderRight: 'none'}}>
                                    <label className="mt-1"><strong>Última Atualização: </strong>15/03/2021</label>
                                    <a className="suggestions w-50" href="#" target="_blank"><i className="material-icons pr-2" style={{ color: '#747E8B;', fontSize: '20px' }}>get_app</i>Baixar Planilha</a>
                                </div>
                                
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div className="form-group d-flex flex-row justify-content-between w-100 px-3">
                    <div className="d-flex justify-content-start">
                        <button type="button" className="btn btn-light mr-2" onClick={() => saveInfos(1)}>Voltar</button>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-light mr-2" onClick={() => window.location.href =  '/freight'}>Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={() => saveInfos(3)}>Salvar e Continuar</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {loading ? <Loading /> : renderScreen()}
        </>

    )
};


