import React, { Component, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { APIV2 } from '../../API';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

export function Modalities(props) {
	const [modalities, setModalities] = useState([])
	const [selects, setSelects] = useState([])
	const [load, setLoad] = useState(true)
	const [disable, setDisable] = useState(false)

	useEffect(() => {
		if (props.store.modalities.length > 0) {
			setSelects(props.store.modalities)
		}

		getModalities()
	}, [])

	const getModalities = async () => {
		try {
			const response = await APIV2.get(`/management/retailer/sellers/${props.store.id}/modalities`)
			setModalities(response.data)
			setLoad(false)
		} catch (error) {
			setLoad(false)
			console.log(error)
		}
	}

	const selectItens = (modality) => {
		const found = selects.some(el => el.id === modality.id);

		if (!found || !selects) {
			selects.push(modality)
		} else {
			const indexRemove = selects.findIndex(el => el.id === modality.id)
			selects.splice(indexRemove, 1)
		}

		props.change(selects)
	}

	const saveSelectedNodalities = () => {
		setDisable(true)
		try {
			APIV2.put(`management/retailer/sellers/${props.store.id}/modalities`, selects)
			toast.success('Modalidades editadas com sucesso! 😃');
			setDisable(false)
		} catch (error) {
			toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			console.log(error)
			setDisable(false)
		}

	}

	const renderForm = () => {
		return (
			<>
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Modalidades</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<div className="row ">
							{modalities.map((modality, index) =>
								<li className="list-group-item border-0 col-4" key={modality.id}>
									<div className="form-group col-12">
										<label className="switch ">
											<input id={modality.id} type="checkbox" name='isActive' value={selects.some(select => select.id === modality.id)}
												defaultChecked={selects.some(select => select.id === modality.id)} onChange={() => selectItens(modality)} />
											<span className="slider round"></span>
										</label>
										<label className="ml-1" htmlFor={modality.id}><strong>{modality.name}</strong></label><br />
									</div>
								</li>
							)}
						</div>
					</div>
				</div>
				<div className="form-group row mt-20 ml-2">
					<div className="col-12 d-flex flex-row-reverse">
						<Button loading={disable} type="button" className="btn-primary" onClick={saveSelectedNodalities}>{props.id ? 'Editar loja' : 'Criar loja'}</Button>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			{load ? <Loading /> : renderForm()}
		</>

	)
};

export default Modalities;
