import React, { useState, useEffect } from "react";
import PromotionsList from "./PromotionsList";
import PromotionContext from "./Providers/PromotionContext";

export default function Promotions() {

  return (
    <>
      <PromotionContext>
        <PromotionsList />
      </PromotionContext>
    </>
  );
}
