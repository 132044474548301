import React, { useState, useEffect } from "react";
import Modal from "../../../../components/Modal";
import moment from "moment";
import Axios from "axios";
import List from "../../../../components/List/List";
import Loading from "../../../../components/Loading";
import { APIV2 } from "../../../../API";
import ContentLoader from "react-content-loader";

// import { Container } from './styles';

function ImportHistory(props) {
	const [page, setPage] = useState(parseInt(props.match.params.page));
	const [maxCount, setMaxCount] = useState(null);
	const [blobStatus, setBlobStatus] = useState([]);
	const [isPopoverOpen, setIsPopoverOpen] = useState("");
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(20); //

	const columsList = [
		{
			name: "Tipo da importação",
			selector: "type",
			sortable: false,
			width: "220px",
			center: true,
			// style: {
			//   overflow: 'visible',
			//   color: 'white',
			// },
			cell: (row) => (
				<div
					className={
						!row.data.CurrentStatus
							? "text-center  text-danger font-weight-bold"
							: row.data.HasError
							? "text-center  text-warning font-weight-bold"
							: ""
					}
				>
					{row.file.type === "marketplace-categories-mapping"
						? "Mapeamento de Categorias"
						: row.file.type === "marketplace-brands-mapping"
						? "Mapeamento de Marcas"
						: "Mapeamento de Atributos"}
				</div>
			),
		},
		{
			name: "Início",
			selector: "start",
			center: true,
			cell: (row) => (
				<div
					className={
						!row.data.CurrentStatus
							? "text-center  text-danger font-weight-bold"
							: row.data.HasError
							? "text-center  text-warning font-weight-bold"
							: ""
					}
				>
					{moment(row.file.startDate).format("DD/MM/YYYY HH:mm")}
				</div>
			),
		},
		{
			name: "Fim",
			selector: "end",
			center: true,
			cell: (row) => (
				<div
					className={
						!row.data.CurrentStatus
							? "text-center  text-danger font-weight-bold"
							: row.data.HasError
							? "text-center  text-warning font-weight-bold"
							: ""
					}
				>
					{moment(row.file.timestamp).format("DD/MM/YYYY HH:mm")}
				</div>
			),
		},
		{
			name: "Status",
			width: "150px",
			center: true,
			selector: "status",
			cell: (row) => (
				<div style={{ width: "100%" }}>
					{row.data.HasError && row.data.ProgressStatus !== 100 ? (
						<span className="badge badge-danger stadium text-center">
							Não importado
						</span>
					) : blobStatus.hasError ? (
						<span className="badge badge-warning stadium text-center">
							{" "}
							Arquivo com erro
						</span>
					) : (
						<div className="progress ">
							<div
								className={
									!row.data.CurrentStatus
										? "progress-bar  bg-danger"
										: row.data.HasError
										? "progress-bar  bg-warning"
										: "progress-bar  bg-primary"
								}
								role="progressbar"
								style={{
									width: !row.data.CurrentStatus
										? "100%"
										: row.data.ProgressStatus + "%",
									textAlign: "center",
									borderRadius: "3px",
								}}
								aria-valuenow="25"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{row.data.ProgressStatus > 0 ? (
									<>{row.data.ProgressStatus} %</>
								) : row.data.CurrentStatus ? (
									<small>executando</small>
								) : (
									<>Erro</>
								)}
							</div>
						</div>
					)}
				</div>
			),
		},
		{
			name: "",
			selector: "",
			style: { textAlign: "center" },
			cell: (row) => (
				<div className="text-center " style={{ width: "100%" }}>
					<a
						href="#"
						className="btn btn-link text-secondary"
						style={{ textDecorationLine: "underline" }}
						data-toggle="modal"
						data-target={`#details-${row.id}`}
					>
						ver detalhes
					</a>
					<Modal
						id={`details-${row.id}`}
						import="true"
						titleComponent={
							<>
								<h3 className="modal-title">
									Detalhes da importação{" "}
									{row.data.HasError ? (
										<span className="text-warning" style={{ fontSize: "14px" }}>
											Ocorreu algum erro
										</span>
									) : row.data.ProgressStatus === 100 && !row.data.HasError ? (
										<span className="text-primary" style={{ fontSize: "14px" }}>
											concluído
										</span>
									) : row.data.HasError && row.data.ProgressStatus !== 100 ? (
										<span className="text-danger" style={{ fontSize: "14px" }}>
											Ocorreu algum erro
										</span>
									) : (
										<span className="text-primary" style={{ fontSize: "14px" }}>
											em andamento
										</span>
									)}
								</h3>
							</>
						}
						large={true}
						content={
							<div>
								{row.data ? <p>{row.data.currentStatus}</p> : null}
								<div className="progress">
									<div
										className={
											row.data.HasError && row.data.ProgressStatus !== 100
												? "progress-bar  bg-danger"
												: row.data.HasError
												? "progress-bar  bg-warning"
												: "progress-bar  bg-primary"
										}
										role="progressbar"
										style={{
											width: row.data.ProgressStatus + "%",
											textAlign: "right",
										}}
										aria-valuenow="25"
										aria-valuemin="0"
										aria-valuemax="100"
									></div>
								</div>
								<div className="mt-5 p-2" id="details">
									{row.data.Items && row.data.Items.length > 0 && (
										<a
											href={row.data.Items[0].MainFileUrl}
											target="_blank"
											className="download-archive"
										>
											<span className="material-icons">archive</span>download
											arquivo de importação
										</a>
									)}
									<div className="import-list-log-files">
										{row.data.Items &&
											row.data.Items.map((item, i) => (
												<div className="row d-flex align-items-center border-bottom">
													<div className="col-3 text-left">
														Parte {item.PartId}
													</div>
													<div className="col-5">
														{item.HasData && !item.HasError ? (
															<span className="badge badge-primary stadium text-center">
																Concluído
															</span>
														) : item.ValidationFileUrl ? (
															<div className="log-erro">
																<span className="badge badge-warning stadium text-center">
																	Arquivo com erro
																</span>
																<a
																	href={item.ValidationFileUrl}
																	target="_blank"
																>
																	<i className="material-icons">
																		error_outline
																	</i>{" "}
																	log de erro
																</a>
															</div>
														) : (
															<span className="badge badge-info stadium text-center">
																Em andamento
															</span>
														)}
													</div>
													<div className="col-4 text-right">
														<a
															href={item.PartFileUrl}
															taget="_blank"
															style={{ textDecoration: "underline" }}
															className="btn btn-link text-secondary"
														>
															download
														</a>
													</div>
												</div>
											))}
									</div>
								</div>
								<div className="import col-12">
									<div className="import-details-body">
										<p>
											Início:
											{moment(row.file.startDate).format("DD/MM/YYYY HH:mm")}
										</p>
										<p>
											Fim:
											{moment(row.file.timestamp).format("DD/MM/YYYY HH:mm")}
										</p>
										{/* {row.data.CurrentStatus ? row.data.HeaderErrors && row.data.HeaderErrors.map((item, i) => */}
										<p>{row.data.CurrentStatus}</p>
										{/* ) : <p>Não foi possível importar o Arquivo</p>} */}
									</div>
								</div>
							</div>
						}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = "/import/history/1";
		}
		setLoading(true);
	}, [props.match.params]);

	useEffect(() => {
		setStatus(blobStatus);
	}, [blobStatus]);

	// useEffect(() => {
	// 	getStatus();
	// }, [page]);

	useEffect(() => {
		getStatus();
	}, [page, rowsPerPage]);

	const getStatus = async () => {
		let arrayList = [];
		try {
			const response = await APIV2.get(
				// `import/importHistoric?page=${page}&perPage=10`
				`import/importHistoric?onlyCatalogMatcher=true&page=${page}&perPage=${rowsPerPage}`
			);
			arrayList = await Promise.all(
				response.data.historics.map(async (file) => {
					try {
						const resp = await Axios.get(file.urlStatus);
						return { data: resp.data, id: file.id, file: file };
					} catch (error) {
						console.log(error);
						return { data: {}, id: file.id, file: file, statusCode: null };
					}
				})
			);
			// setPage(response.data.page)
			setMaxCount(response.data.maxCount);
			setBlobStatus(arrayList);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
		const stateObj = { page: props.page };
		window.history.pushState(stateObj, "", e);
	};

	//
	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	return (
		<div className="container dash-content ">
			<div className="card  pb-5">
				<div className="card-header">
					<h4 className="card-title mb-3">
						Histórico de importação de arquivo
					</h4>
				</div>
				<div className="card-body pt-0">
					<div className="row">
						<div className="col-12">
							{loading ? (
								skeleton()
							) : (
								<List
									data={status}
									page={parseInt(page)}
									colums={columsList}
									totalRows={maxCount}
									handlePageChange={changePage}
									paginationServer={true}
									//
									rowsPerPage={rowsPerPage}
									handleRowsPerPageChange={changeRowsPerPage}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImportHistory;
