import React, { Component, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIV2 } from "../../API";
import Loading from "../../components/Loading";
import Button from "../../components/Button";

export default function Payment(props) {
	const [loading, setLoading] = useState(true);
	const [disable, setDisable] = useState(false);
	const [store, setStore] = useState({
		isEcommerce: false,
		isActive: true,
		pointSaleId: null,
		gatewayId: null,
		credentialsString: "",
		googlePlacesId: null,
		contactPhone: null,
		whatsappPhoneNumber: null,
		pickUpInstructions: null,
		contactEmail: null,
		isSellerOnline: false,
		isShowSellerInMap: false,
		integrationConfig: {
			orderIntegrationId: "",
			fraudIntegrationId: "",
			paymentIntegrationId: "",
			promotionEngineIntegrationId: null,
			userPromotionIntegrationId: null,
			orderIntegrationCredentials: "",
			fraudIntegrationCredentials: "",
			paymentIntegrationCredentials: "",
			promotionEngineIntegrationCredentials: null,
			userPromotionCredentials: null,
		},
		address: {
			id: "",
			buyerId: null,
			street: "",
			number: "",
			complement: null,
			neighborhood: "",
			cep: "",
			city: "",
			reference: null,
			label: null,
			state: "",
			country: "Brasil",
			isPrimary: false,
			isDeleted: false,
			fullAddress: "",
		},
		addressId: "",
		modalities: [],
		companyName: "",
		cnpj: "",
		fullName: "",
		email: "",
		password: "",
		facebookId: "faceid",
		facebookToken: "facetoken",
		googleId: "googleid",
		googleToken: "googletoken",
		imageUrl: null,
		clientId: localStorage.getItem("client_id"),
		isDeleted: false,
		emailConfirmed: false,
		securityStamp: null,
		phoneNumber: null,
		phoneNumberConfirmed: false,
	});

	useEffect(() => {
		APIV2.get("management/retailer/sellers/" + props.id)
			.then(async (response) => {
				setStore(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, []);

	const onChange = (e) => {
		let name = e.target.name;
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		setStore((prevState) => ({ ...prevState, [name]: value }));
	};

	const saveInfos = async () => {
		setDisable(true);
		try {
			if (store.id) {
				await APIV2.put("management/retailer/sellers/" + store.id, store);
				toast.success("Loja editada com sucesso! 😃");
				setDisable(false);
			} else {
				const response = await APIV2.post(
					"management/retailer/sellers/",
					store
				);
				toast.success("Loja criada com sucesso! 😃");
				setDisable(false);
			}
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
			setDisable(false);
		}
	};

	const renderForm = () => {
		return (
			<>
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Pagamento</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<div className="row">
							<div className="form-group col-sm-12">
								<label htmlFor="gatewayId">
									<strong>Id no gateway de pagamento</strong>
								</label>
								<div>
									<input
										type="text"
										className="form-control"
										id="gatewayId"
										name="gatewayId"
										defaultValue={
											store.gatewayId != null ? store.gatewayId : ""
										}
										onChange={onChange}
										required
									/>
								</div>
							</div>
							<div className="form-group col-sm-12">
								<label htmlFor="credentialsString">
									<strong>String de autenticação</strong>
								</label>
								<div>
									<input
										type="text"
										className="form-control"
										id="credentialsString"
										name="credentialsString"
										defaultValue={
											store.credentialsString != null
												? store.credentialsString
												: ""
										}
										onChange={onChange}
										required
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-20 ml-2">
					<div className="col-12 d-flex flex-row-reverse">
						<Button
							loading={disable}
							type="button"
							className="btn btn-primary"
							onClick={saveInfos}
						>
							{props.id ? "Editar loja" : "Criar loja"}
						</Button>
					</div>
				</div>
			</>
		);
	};

	return <>{loading ? <Loading /> : renderForm()}</>;
}
