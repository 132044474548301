import React, { useEffect, useState } from "react";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import { APIV2 } from "../../../API";
import { toast } from "react-toastify";
import axios from "axios";

export default function FilterStores(props) {
	const [runtimeStatus, setRuntimeStatus] = useState("");
	const [output, setOutput] = useState("");
	const [load, setLoad] = useState(false);
	const [query, setQuery] = useState("");
	const [isActive, setIsActive] = useState("");
	const [typeSeller, setTypeSeller] = useState("");
	const [labelEmpty, setLabelEmpty] = useState(null);
	const [labelFilter, setLabelFilter] = useState(false);
	const [labelClear, setLabelClear] = useState(false);
	const [reload, setReload] = useState(false);
	const [count, setCount] = useState(1);
	const [color, setColor] = useState(false);
	const [filter, setFilter] = useState({
		isActive: isActive,
		typeSeller: typeSeller,
	});
	const [clearfilter, setClearFilter] = useState({
		isActive: isActive,
		typeSeller: typeSeller,
	});

	useEffect(() => {
		if (isActive !== "" || typeSeller !== "") {
			setLabelFilter(true);
			setColor(true);
			setLabelClear(false);
			setOutput("");
			setRuntimeStatus("");
		} else {
			setLabelFilter(true);
			setLabelClear(false);
		}
		const a = isActive !== "" ? 1 : 0;
		const b = typeSeller !== "" ? 1 : 0;
		setCount(a + b);

		setFilter({
			...filter,
			isActive: isActive,
			typeSeller: typeSeller,
		});
	}, [isActive, typeSeller]);

	const handleClearFilter = () => {
		if (count === 0) {
			setLabelClear(false);
			setColor(false);
			props.searchFilter(filter);
		} else {
			props.searchFilter(filter);
			setLabelFilter(false);
			setLabelClear(true);
		}
	};

	useEffect(() => {
		searchInputData();
	}, [query]);

	const searchInputData = () => {
		const inputSearch = document.querySelector("#search");
		const searchProd = fromEvent(inputSearch, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => search(text));
		return searchProd.unsubscribe;
	};

	const search = (query) => {
		props.search(query);
		//setLoad(true)
	};

	const clearSearchFilter = () => {
		setColor(false);
		setCount(0);
		setFilter({ isActive: "", typeSeller: "" });
		setIsActive("");
		setTypeSeller("");
		setLabelEmpty(null);
		setLabelFilter(false);
		setLabelClear(false);
		setReload(true);
		props.searchFilter(clearfilter);
	};

	const getExportFilterStores = async () => {
		setLoad(true);
		await APIV2.get(`insights/export/sellers?isActive=${isActive}`)
			.then((response) => {
				const exportFilter = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (resp.data.runtimeStatus === "Running") {
						console.log("Análise =>", response.data.statusQueryGetUri);
					} else {
						switch (resp.data.runtimeStatus) {
							case "Completed":
								//console.log("Entrou no Completed")
								setOutput(resp.data.output);
								clearInterval(exportFilter);
								document.getElementById("exportList").click();
								setLoad(false);
								toast.success("Download realizado com sucesso!😃");
								break;
							case "Failed":
								//console.log("Entrou no Failed");
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
								break;
							default:
								//console.log("Entrou no Default");
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
						}
					}
				}, 4000);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	return (
		<div className="d-flex">
			<div className="container m-0 px-1">
				<div className="stores-container">
					<div className="stores-search col-md-9">
						<label htmlFor="search">
							<strong>Busca</strong>
						</label>
						<div className="p-0 autocomplete icon-right">
							<input
								type="text"
								className="search form-control"
								id="search"
								name="pesquisa"
								placeholder="Procure pelo nome da loja ou CNPJ"
								defaultValue={query}
								style={{ width: "100%" }}
							/>
							{props.loadQuery ? (
								<i className="fas fa-spinner fa-spin"></i>
							) : (
								<i className="material-icons">search</i>
							)}
						</div>
					</div>
					<div className="stores-filter col-md-3">
						<div className="d-flex flex-column">
							<label className="" htmlFor="isActive">
								<strong>Status da Loja</strong>
							</label>
							<select
								className="custom-select"
								id="isActive"
								name="isActive"
								value={isActive}
								onChange={(e) => {
									setIsActive(e.target.value);
								}}
							>
								<option value="">Mostrar Todos</option>
								<option value={true}>Ativas</option>
								<option value={false}>Inativas</option>
							</select>
						</div>
					</div>
				</div>

				<div className="d-flex p-3">
					<div className="d-flex align-items-center" style={{ width: "100%" }}>
						<strong>MOSTRANDO TODAS AS LOJAS ({props.maxCount})</strong>
					</div>
					<div style={{ width: "100%" }}>
						<div className="row m-0 d-flex justify-content-end">
							{/* {load === true ? (
								<>
									<a
										href={output}
										id="exportList"
										className="d-none"
										style={{ padding: "10px 20px" }}
									></a>
									<button
										disabled
										className="btn btn-outline btn-icon mr-2 d-flex justify-content-center"
										style={{
											padding: "10px",
											border: "1px solid #E9ECEC",
											background: "transparent",
											color: "#747E8B",
											width: "200px",
											cursor: "not-allowed",
										}}
									>
										<>
											<i className="fas fa-spinner fa-spin"></i>
											<span className="p-0 m-0" style={{ fontWeight: "500" }}>
												&nbsp;&nbsp;Processando...
											</span>
										</>
									</button>
								</>
							) : (
								<>
									<a
										href={output}
										id="exportList"
										className="d-none"
										style={{ padding: "10px 20px" }}
									></a>
									<button
										className="btn btn-outline btn-icon mr-2 d-flex justify-content-center"
										style={{
											padding: "10px",
											border: "1px solid #E9ECEC",
											background: "transparent",
											color: "#747E8B",
											width: "200px",
										}}
										onClick={() => getExportFilterStores()}
									>
										<>
											<i className="material-icons">get_app</i>
											<span className="p-0 m-0" style={{ fontWeight: "400" }}>
												&nbsp;&nbsp;Exportar Lojas
											</span>
										</>
									</button>
								</>
							)} */}

							{labelFilter && (
								<button
									className={
										color === true
											? "btn btn-primary btn-icon d-flex justify-content-center"
											: "btn btn-outline btn-icon d-flex justify-content-center"
									}
									onClick={() => handleClearFilter()}
									style={{ padding: "10px 20px", width: "200px" }}
								>
									Aplicar filtros ({count})
								</button>
							)}

							{labelClear && (
								<button
									className="btn btn-outline btn-icon d-flex justify-content-center"
									onClick={() => clearSearchFilter()}
									style={{
										padding: "10px 20px",
										border: "1px solid #E9ECEC",
										background: "#E9ECEC",
										width: "200px",
										color: "#747E8B",
									}}
								>
									<i className="material-icons">delete_sweep</i>
									&nbsp;&nbsp;Limpar filtros ({count})
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
