
import React, { useState, useEffect} from 'react';
import AssistantShipping from '../../../components/AssistantShipping'
import BasicInfo from './BasicInfo';
import TableShipping from './TableShipping';
import RulesShipping from './RulesShipping';
import RevisionShipping from './RevisionShipping';

export default function NewFreight(props) {
    const [step, setStep] = useState("")
    useEffect(() => {
        setStep(step)
    }, [step])

    const save = (step) => {
        setStep(step)
    } 

    const handleNewFreight = () => {
       // console.log("Novo Frete")
    }

	return (
        <div className="container">
            <div className="card mb-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h6 className="card-title">Adicionar Envio Personalizado</h6>
                </div>
                <div className="card-body">
                    <AssistantShipping step={step === "" ? 1 : step} save={save}/>
                </div>
                <div className="card-content mt-3">
                    { step === '' || step === 1 ? <BasicInfo save={save} /> : null }
                    { step === 2 ? <TableShipping save={save} /> : null }
                    { step === 3 ? <RulesShipping save={save} /> : null }
                    { step === 4 ? <RevisionShipping save={save} /> : null }
                </div>
            </div>
        </div>
	)
};
