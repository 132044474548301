import React, { Component, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import Modal from "../../components/Modal";
import List from "../../components/List/List";
import { API, APIV2 } from "../../API";
import Empty from "../../components/Empty";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import FilterStores from "./components/FilterStores";
import Button from "../../components/Button";

export default function StoreList(props) {
	const [page, setPage] = useState(1);
	const [access, setAccess] = useState("");
	const [lists, setLists] = useState([]);
	const [manager, setManager] = useState("");
	const [roles, setRoles] = useState("");
	const [sellers, setSellers] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [stores, setStores] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadQuery, setLoadQuery] = useState(true);
	const [maxCount, setMaxCount] = useState([]);
	const [query, setQuery] = useState("");
	//const [isActive, setIsActive] = useState('')
	const [filterData, setFilterData] = useState({
		isActive: "",
		typeSeller: "",
	});
	const [rowsPerPage, setRowsPerPage] = useState(20);

	const columsList = [
		{
			name: "Nome",
			selector: "fullName",
			sortable: false,
			center: false,
			// style: {
			//     whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis',
			// },
			cell: (row) => (
				<div
					className="font-weight-bold text-muted"
					style={{ width: "100%" }}
					title={row.fullName}
				>
					<p
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{row.fullName}
					</p>
				</div>
			),
		},

		{
			name: "CNPJ",
			selector: "cnpj",
			sortable: false,
			center: true,
			cell: (row) => <div className="text-muted">{row.cnpj}</div>,
		},
		{
			name: "Status",
			selector: "isActive",
			sortable: false,
			center: true,
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-primary stadium text-center">ATIVO</span>
				) : (
					<span className="badge badge-danger stadium">INATIVO</span>
				),
		},

		{
			name: "",
			sortable: true,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<a
						id="btnGroupDrop1"
						href={
							props.isAutoSetup
								? `/setup/stores/${props.page}/edit/` + row.id
								: `/stores/${props.page}/edit/` + row.id
						}
						className="btn btn-info"
					>
						Editar
					</a>

					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>

					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							href="#"
							data-toggle="modal"
							data-target="#deleteSellers"
							onClick={() => setDelete(row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		getStore();
	}, [page, query, filterData, rowsPerPage]);

	const getStore = () => {
		console.log(query);
		setLoadQuery(true);
		APIV2.get(
			`management/retailer/sellers?perPage=${rowsPerPage}&page=${page}&SortField=Id&Query=${query}&isActive=${filterData.isActive}&typeSeller=${filterData.typeSeller}`
		)
			.then(async (response) => {
				console.log(response.data);
				setStores(response.data);
				setMaxCount(response.data.maxCount);
				setLoading(false);
				setLoadQuery(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const search = (query) => {
		setQuery(query);
	};

	const filter = async (val) => {
		setFilterData(val);
	};

	const setDelete = (id) => {
		setDeleteId(id);
	};

	const deleteStore = () => {
		APIV2.delete("management/retailer/sellers/" + deleteId)
			.then(async (response) => {
				getStore();
				toast.success("Loja deletada com sucesso! 😃");
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const handlePageChange = (pageNumber) => {
		setLoading(true);
		setPage(pageNumber);
		const stateObj = { page: props.page };
		window.history.pushState(stateObj, "", pageNumber);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="25" rx="3" ry="3" width="100" height="200" />
			</ContentLoader>
		));
	};

	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Lojas</h4>

					<div className="card-actions">
						<Button
							className="btn btn-info light btn-icon justify-content-center mr-2"
							onClick={() => (window.location.href = "/import")}
						>
							<i className="material-icons">upload</i>&nbsp;&nbsp;Importar Lojas
						</Button>
						<Button
							className="btn-primary"
							onClick={() =>
								(window.location.href = props.isAutoSetup
									? `${props.page}/new`
									: `/stores/${props.page}/new`)
							}
						>
							Nova Loja
						</Button>
					</div>
				</div>
				<FilterStores
					searchFilter={filter}
					search={search}
					loadQuery={loadQuery}
					maxCount={maxCount}
				/>

				<div className="card-content">
					<div className="card-body no-padding">
						{stores.length <= 0 && loading === false ? (
							<Empty
								img={"/images/empty_sellers.svg"}
								genre="sua primeira"
								name="loja"
								link={`/stores/${page}/new`}
								import={true}
							/>
						) : loading ? (
							skeleton()
						) : (
							<List
								data={stores.sellers}
								page={page}
								colums={columsList}
								totalRows={maxCount}
								handlePageChange={handlePageChange}
								paginationServer={true}
								//
								rowsPerPage={rowsPerPage}
								handleRowsPerPageChange={changeRowsPerPage}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				id={"deleteSellers"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteStore}
			/>
		</div>
	);
}
