import React, { Component, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
//import { API, APIV2 } from '../../API';
import Loading from '../../../components/Loading';

export default function RevisionShipping(props) {
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        //console.log("Basic Info")
        setLoading(false)
    }, [])

    const handleNewFreight = () => {
        //console.log("Novo Frete")
    }

    const onChange = (e) => {
        //console.log(e.target.value)
    }

    const saveInfos = async (step) => {
        //console.log("SaveInfo")
        props.save(step) // essas informação vão o new freight
    }

    const itemsList = [
        {
            imageUrl:"local_shipping",
            name: "Envio Parque Shopping Dom Pedro",
            isActive: true,
            icon: "local_shipping"
        }
        
    ]

    const RoleShipping = [
        {
            name: "Regra de Vinhos",
            isActive: true,
            icon: "local_shipping"
        },
        {
            name: "Regra de Tênis",
            isActive: true,
            icon: "local_shipping"
        }
        
    ]

    const renderScreen = () => {
        return (
            <div className="row m-2">
                <div className="w-100 px-3 mb-2">
                    <h3 className="card-title" style={{ fontSize: '18px'}}>Tudo pronto! Agora é só revisar! :-)</h3>
                    <p className="m-0 p-0" style={{ color: '#747E8B', fontSize: '12px', lineHeight: '16px', fontWeight: 'normal', fontStyle: 'normal'}}>
                        Certifique-se de que os campos estão preenchidos corretamente antes de concluir o Envio Personalizado. <strong>Basta clicar em uma das etapas anteriores para retornar e editar as informações.</strong>
                    </p>
                </div>

                
                <div className="w-100">
                    <h3 className="card-title m-3" style={{ fontSize: '18px'}}>Informações Básicas*</h3>
                    <div className="box-table">
                        <table className="table history-import">
                            <tbody>
                                <tr>
                                    <th className='text-start'>MODALIDADE</th>
                                    <th className='text-center'></th>
                                    <th className='text-center'>STATUS</th>
                                    <th className='text-center'>AÇÕES</th>
                                </tr>
                                {itemsList.map((item, i) =>
                                    <tr key={i}>   
                                        <td className='text-center' style={{ width: '10%', height: '60px'}}>
                                            <button type="button" className="btn btn-light btn-sm btn-icon bg-white"><i className="material-icons" style={{ fontSize: '20px', fontWeight: 'bold', padding: '0'}}>{item.icon}</i></button>
                                        </td>
                                        <td className='text-start' style={{ width: '70%'}}>
                                            <h6 className='font-weight-bold mt-2'>{item.name}</h6>
                                        </td>
                                        <td className='text-end' style={{ width: '10%'}}>
                                            {item.isActive === true ? <span className="badge badge-primary stadium w-100">ATIVO</span> : <span className="badge badge-danger stadium w-100">INATIVO</span>}
                                        </td>
                                        <td className='text-center' style={{ width: '10%'}}>
                                            <div className="actions d-flex align-items-center justify-content-center">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <button type="button" className="btn btn-light btn-sm btn-icon bg-white" onClick={() => window.location.href =  'new-carousel/edit/' + item.id}><i className="material-icons text-muted" style={{ fontSize: '20px', padding: '0'}}>create</i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="w-100 mb-4">
                    <h3 className="card-title m-3" style={{ fontSize: '18px'}}>Envio de Tabela de Frete</h3>
                    <div className="d-flex justify-content-center flex-column mx-3">
                        <div className="download-group">
                            <div className="download-group-label">
                                <i className="material-icons">insert_drive_file</i>
                                <span>&nbsp;Arquivo XML da Nota Fiscal</span>
                            </div>      
                        </div>
                    </div>
                </div>

                <div className="w-100">
                    <h3 className="card-title m-3" style={{ fontSize: '18px'}}>Regras para o Frete</h3>
                    <div className="box-table">
                        <table className="table history-import">
                            <tbody>
                                <tr>
                                    <th className='text-start'>MODALIDADE</th>
                                    <th className='text-center'>AÇÕES</th>
                                </tr>
                                {RoleShipping.map((item, i) =>
                                    <tr key={i}>
                                        <td className='text-start' style={{ width: '70%'}}>
                                            <h6 className='font-weight-bold' style={{ wordBreak: 'break-word' }}>{item.name}</h6>
                                        </td>
                                        <td className='text-center' style={{ width: '10%'}}>
                                            <div className="actions d-flex align-items-center justify-content-center">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <button type="button" className="btn btn-light btn-sm btn-icon bg-white"><i className="material-icons text-muted" style={{ fontSize: '20px', padding: '0'}}>visibility</i></button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                
                
                <div className="form-group d-flex flex-row justify-content-between w-100 px-3">
                    <div className="d-flex justify-content-start">
                        <button type="button" className="btn btn-light mr-2" onClick={() => saveInfos(3)}>Voltar</button>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-light mr-2" onClick={() => window.location.href =  '/freight'}>Cancelar</button>
                        <button type="button" className="btn btn-primary" /* onClick={() => saveInfos(5)} */ onClick={() => window.location.href =  '/freight'}>Concluir</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {loading ? <Loading /> : renderScreen()}
        </>

    )
};


