import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Multiselect } from 'react-widgets';
import { API } from '../../../../../../API';

// import { Container } from './styles';

function Showcase() {
  const [orientation, setOrientation] = useState('list')
  const [tagList, setTagList] = useState([])
  const [selectTag, setSelectTag] = useState([])

  useEffect(() => {
    getTags()
  },[])

  const handleCreate = (name) => {

		let newOption = {
			name,
			id: 'teste'
		}
		setTagList(prevState => [...prevState, newOption])
		setSelectTag(prevState => [...prevState, newOption])

	}

  const getTags = async () => {
		try {
			const response = await API.get('api/tags')
				setTagList(response.data)

		} catch (error) {
			console.log(error)
			toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
		}

	}

  return (
    <>
      <div className="container">
        <div class="row">
          <div className="col-12">
            <p><strong>ORIENTAÇÃO</strong></p>
          </div>
          <div className="col-12 row form-group">
            <div className="col-3 text-center d-flex justify-content-center">
              <div className={orientation === 'list' ? "app-editor-orientation orientation-active" : "app-editor-orientation"}>
                <div className="app-editor-orientation-button" onClick={() => setOrientation('list')}>
                  <div className="app-editor-orientation-button">
                    <i class="material-icons" style={{ color: 'white' }}>view_agenda</i>
                  </div>
                </div>
                <div className="app-editor-orientation-text ">
                  Lista
            </div>
              </div>
            </div>
            <div className="col-3 text-center d-flex justify-content-center">
              <div className={orientation === 'carrousel' ? "app-editor-orientation orientation-active" : "app-editor-orientation"}>
                <div className="app-editor-orientation-button" onClick={() => setOrientation('carrousel')}>
                  <div className="app-editor-orientation-button">
                    <i class="material-icons text-center" style={{ color: 'white', fontSize: '37px', boxSizing: "border-box", paddingRight: '2px' }}>view_array</i>
                  </div>
                </div>
                <div className="app-editor-orientation-text">
                  Carrossel
            </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p><strong>INFORMAÇÕES</strong></p>
          </div>
          <div className="col-12 form-group">
            <label className="form-check-label" htmlFor="carrousel-title">
              Titulo da vitrine
          </label>
            <input type="text" className="form-control" name="carrousel-title" />
          </div>
          <div className="form-group col-12">
										<label htmlFor="productCollections"><strong>Coleções</strong></label>
										<Multiselect
											data={tagList}
											value={selectTag}
											valueField='id'
											className="form-control"
											textField={item => item.name}
											allowCreate="onFilter"
											onCreate={name => handleCreate(name)}
											onChange={value => setSelectTag(value)}
											busy={tagList.length >= 0 ? false : true}
										/>
									</div>
        </div>
      </div>
    </>
  );
}

export default Showcase;