import React, { Component, useState } from 'react';

import PreviewAppHome from './screens/PreviewAppHome'

export default function PreviewApp(props) {
  const [appBar, setAppBar] = useState(localStorage.getItem('primaryColor'))
  const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('primaryColor'))

  return (
    <div className="preview app">
      <div className="body">
        <PreviewAppHome type={props.screenType} recoveryStateLogo={props.recoveryStateLogo} />
      </div>
    </div>
  )
};
