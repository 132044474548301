import React, { Component } from 'react';
import {API} from '../../API';
import Loading from '../../components/Loading'
import Success from './Success';
import { ToastContainer, toast } from 'react-toastify';
require('dotenv/config');
const qs = require('querystring');

export class ResetPassword extends Component {

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.sendReset = this.sendReset.bind(this);
        this.state = {
            email: '',
            request: true,
            loading: false,
        }
    }


    changePass() {
        this.props.pass();

    }
    async handleChange(e) {
        await this.setState({ email: e.target.value });
       
    }
    sendReset() {
        this.setState({ loading: true })
        var body = {
            email: this.state.email
        }
        API.post('api/users/rememberpassword', body)
            .then(response => {
               this.setState({ request: false, loading: false })
                this.forceUpdate();

            })
            .catch((error) => {
                console.log(error)
                this.setState({ request: true, loading: false });
                toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
                this.forceUpdate();
            })

    }
    componentDidMount() {
        var body = {
            'client_id': 'neomode',
            'client_secret': 'eyJhbGcJhGTSUzI1NiIsImtpZCI6IjBiZTg5Y',
            "grant_type": "client_credentials",
            "scope": "lori",
        }

        API.post('connect/token', qs.stringify(body), { headers: { ContentType: 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' } })
            .then(async response => {
                // If request is good...
                localStorage.setItem( 'token', response.data.access_token )
                this.forceUpdate()

            })
            .catch((error) => {
                console.log(error);
                toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
                this.setState({ error: true, loading: false })
            })
    }

  
    render() {

        return (
            <div className="container">
                <div className="d-flex justify-content-center ">
                    <div className="row login align-items-center">
                        <div className="col a">
                            <span className="d-none d-md-block">Bem-vindo a</span><br />
                            <h2><img src="/images/logo-primary.svg" height="40" /></h2>
                            <p className="d-none d-md-block">
                                <b>Plataforma omnichannel para o varejo </b><br />Construa novos canais de venda omnichannel
                            com sistema Clique e Retire e transforme sua loja física em um
                            minicentro de distribuição
                        </p>
                        </div>
                        <div className="col b d-flex align-items-center">
                            {this.state.loading ? <Loading /> : 
                                this.state.request ? <div className="initial">

                                <div className="top-login">
                                    <div className="row">
                                        <div className="col ajuda">
                                            <a href="https://lori.neomode.com.br/" >Voltar para o login</a>
                                        </div>

                                    </div>
                                </div>
                                <h2>Esqueci minha senha</h2><br />
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Seu e-mail" onChange={this.handleChange} />
                                </div>
                                <div className="">
                                    <button type="submit" className="btn btn-primary btn-block" onClick={() => this.sendReset()}>Recuperar minha senha</button>
                                </div>
                                <div className="footer-login">
                                    <div className="row">
                                        <div className="col ajuda">
                                            <a href="https://neomode.zendesk.com/" target="_blank">Ajuda</a>
                                        </div>

                                    </div>
                                </div>
                            </div> : <Success/>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};


export default ResetPassword;