import React, { useEffect } from "react";
import VouchersForm from "./components/VouchersForm";
import VouchersContext from "./providers/VouchersContext";

export default function NewVouchers(props) {
	return (
		<div className="container dash-content">
			<div className="card">
				<VouchersContext>
					<VouchersForm id={props.match.params.id} />
				</VouchersContext>
			</div>
		</div>
	);
}
