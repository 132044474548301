import React, { Component } from "react";
export class SingleUse extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}
	state = {
		loading: false,
	};
	save(i) {
		var item = {
			oneByUser: i,
			value: i,
		};
		var itemView = {
			name: "Utilização única",
			value: i == true ? "Sim" : "Não",
			itemId: "oneByUser",
			modal: "#conditionModalOneByUser",
		};
		this.props.newCondition(item, itemView);
	}
	componentDidMount() {}
	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalOneByUser"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalOneByUser"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Utilização única?</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col"
								onClick={() => this.save(true)}
								data-dismiss="modal"
							>
								Sim
							</button>
							<button
								type="button"
								className="btn btn-danger col"
								onClick={() => this.save(false)}
								data-dismiss="modal"
							>
								Não
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SingleUse;
