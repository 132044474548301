import React, { Component, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { APIV2 } from '../../API'
import Button from '../../components/Button';
import Loading from '../../components/Loading';

export default function Address(props) {

	const [address, setAddress] = useState({})
	const [loading, setLoading] = useState(true)
	const [disable, setDisable] = useState(false)
	const [valid, setValidation] = useState(false)


	useEffect(() => {
		getAddress()
	}, [])

	const getAddress = async () => {
		try {
			const response = await APIV2.get(`/management/retailer/sellers/${props.store.id}/address/${props.data.id}`)
			console.log('Address')
			setAddress(response.data)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			console.log(error)
		}

	}

	const handleChange = (e) => {
		const name = e.target.name
		const value = e.target.value
		setAddress(prevState => ({ ...prevState, [name]: value }))
	}

	const saveAddress = async () => {
		setDisable(true)
		await validationAddress()
		if (valid) {
			let fullAddress = await `${address.street}, ${address.number} - ${address.neighborhood} - ${address.cep} | ${address.city} - ${address.state}`
			setAddress(prevState => ({...prevState, fullAddress: fullAddress}))
			try {
				await APIV2.put(`management/retailer/sellers/${props.store.id}/address`, address)
				setLoading(false)
				setDisable(false)
				toast.success('Endereço editado com sucesso! 😃');
			} catch (error) {
				setLoading(false)
				setDisable(false)
				console.log(error)
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			}
		}
		setDisable(false)
	}

	const validationAddress = () => {
		if (address.street === '' &&
			address.number === '' &&
			address.state === '' &&
			address.city === '' &&
			address.cep === '' &&
			address.neighborhood === '') {
			toast.error('Os campos de endereço não foram preenchidos');
			return
		} else if (address.street === '') {
			toast.error('O campo de Endereço não foi preenchido');
			return
		} else if (address.number === '') {
			toast.error('O campo de Número não foi preenchido');
			return
		} else if (address.state === '') {
			toast.error('O campo de Estado não foi preenchido');
			return;
		} else if (address.city === '') {
			toast.error('O campo de Cidade não foi preenchido');
			return;
		} else if (address.cep === '') {
			toast.error('O campo de CEP não foi preenchido');
			return;
		} else if (address.neighborhood === '') {
			toast.error('O campo de Bairro não foi preenchido');
			return;
		} else {
			setValidation(true)
		}
	}

	const renderForm = () => {
		return (
			<>
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Endereço</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<div className="row">
							<div className="form-group col-sm-6">
								<label htmlFor="addres"><strong>Endereço</strong></label>
								<div>
									<input required type="text" className="form-control" id="addres" name='street' disabled={address.isEcommerce} value={!address.street || address.isEcommerce ? '' : address.street} onChange={e => handleChange(e)} />
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="number"><strong>Número</strong></label>
								<div>
									<input type="text" className="form-control" id="number" name='number' disabled={address.isEcommerce} value={!address.number || address.isEcommerce ? '' : address.number} onChange={e => handleChange(e)} />
								</div>
							</div>
							<div className="form-group col-sm-4">
								<label htmlFor="cep"><strong>CEP</strong></label>
								<div>
									<InputMask mask="99999-999" className="form-control" type="text" placeholder="99999-999" id="cep" name='cep' disabled={address.isEcommerce} value={!address.cep || address.isEcommerce ? '' : address.cep} onChange={e => handleChange(e)} />
									{/* <input type="text" className="form-control" id="cep" name='cep' value={address.cep ? address.cep } onChange={e => handleChange(e)}  /> */}
								</div>
							</div>
							<div className="form-group col-sm-4">
								<label htmlFor="complement"><strong>Complemento</strong></label>
								<div>
									<input type="text" className="form-control" id="complement" name='complement' disabled={address.isEcommerce} value={!address.complement || address.isEcommerce ? '' : address.complement} onChange={e => handleChange(e)} />
								</div>
							</div>
							<div className="form-group col-sm-4">
								<label htmlFor="neighborhood"><strong>Bairro</strong></label>
								<div>
									<input type="text" className="form-control" id="neighborhood" name='neighborhood' disabled={address.isEcommerce} value={!address.neighborhood || address.isEcommerce ? '' : address.neighborhood} onChange={e => handleChange(e)} />
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="state"><strong>Estado</strong></label>
								<div>
									<input type="text" className="form-control" id="state" name='state' disabled={address.isEcommerce} value={!address.state || address.isEcommerce ? '' : address.state} onChange={e => handleChange(e)} />
								</div>
							</div>
							<div className="form-group col-sm-6">
								<label htmlFor="city"><strong>Cidade</strong></label>
								<div>
									<input type="text" className="form-control" id="city" name='city' disabled={address.isEcommerce} value={!address.city || address.isEcommerce ? '' : address.city} onChange={e => handleChange(e)} />
								</div>
							</div>

						</div>
					</div>
				</div>
				<div className="form-group row mt-20 ml-2">
					<div className="col-12 d-flex flex-row-reverse">
						<Button type="button" loading={disable} className="btn btn-primary" onClick={saveAddress}>{props.id ? 'Editar loja' : 'Criar loja'}</Button>
					</div>
				</div>

			</>
		)
	}


	return (
		<>
			{loading ? <Loading /> : renderForm()}
		</>

	)

};
