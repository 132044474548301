import React, { useState, useEffect, useCallback } from "react";

const Input = ({ ...props }) => {
    const [aprove, setAprove] = useState(true)
    const [cancel, setCancel] = useState(false)
    const handleKeyUp = useCallback((e) => {
            let value = e.currentTarget.value;
            value = value.replace(/\D/g, "");
            value = value.replace(/(\d)(\d{2})$/, "$1,$2");
            value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
            e.currentTarget.value = value;
            return e;
        }, []
  );
  useEffect(() => {
      //console.log(props.aprove)
  }, [props.aprove])
  const aproveChangePrice = () => {
        //console.log("Aprovado!!")
        props.aprove(aprove)
  }
  const cancelChangePrice = () => {
    //console.log("Cancelado!!")
    props.aprove(cancel)
  }

  return (
    <div className="edit-price">
        <div className="value-price">
            <label className="text-muted">R$</label>
            <input {...props} className="mb-1 w-100" onKeyUp={handleKeyUp} placeholder="0,01" style={{ borderRadius: '0 5px 5px 0' }} />
        </div>
        <div className="btn-price">
            <div>
                <button className="btn btn-success mr-1" onClick={aproveChangePrice}><i className="material-icons">done</i></button>
                <button className="btn btn-danger" onClick={cancelChangePrice}><i className="material-icons">clear</i></button>
            </div>
        </div>
    </div>
  );
};

export default Input;