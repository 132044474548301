import React, { Component } from 'react';
import AppPreview from './Previews/AppPreview' ;
import ShopbotPreview from './Previews/ShopbotPreview' ;
import EmailPreview from './Previews/EmailPreview' ;
export class Preview extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    preview: 'app'
  }
  handleChange(e) {
    this.setState({ preview: e })
    this.forceUpdate()
  }
  render() {
    return (
      <div>
        <div className="tab-preview">
          <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
              <a className={this.state.preview == 'app' ? "nav-link active-tab" : "nav-link"} href="#"
                onClick={() => this.handleChange('app')}>APP</a>
            </li>
            <li className="nav-item">
              <a className={this.state.preview == 'shopbot' ? "nav-link active-tab" : "nav-link"} href="#"
                onClick={() => this.handleChange('shopbot')}>Shopbot</a>
            </li>
            <li className="nav-item">
              <a className={this.state.preview == 'emails' ? "nav-link active-tab" : "nav-link"} href="#"
                onClick={() => this.handleChange('emails')}>E-mails</a>
            </li>

          </ul>
        </div>
        <div className="preview">
          {this.state.preview == 'app' ?
           <AppPreview
            primaria={this.props.primaria}
            secundaria={this.props.secundaria}
            barra={this.props.barra} /> : null}

          {this.state.preview == 'shopbot' ? 
          <ShopbotPreview
            primaria={this.props.primaria}
            secundaria={this.props.secundaria}
            barra={this.props.barra} /> : null}

          {this.state.preview == 'emails' ? 
          <EmailPreview
            primaria={this.props.primaria}
            secundaria={this.props.secundaria}
            barra={this.props.barra} /> : null}

        </div>
      </div>

    )
  }
};

export default Preview;
