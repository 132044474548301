import React, { Component, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { API, APIV2 } from "../../../API";
import Loading from "../../../components/Loading";
import "react-widgets/dist/css/react-widgets.css";
import DataProducts from "./DataProducts";
import PriceProducts from "./PriceProducts";
import SkuProducts from "./SkuProducts";
import CharacteristicsProducts from "./CharacteristicsProducts";
import Reload from "./Reload";
import { toast } from "react-toastify";
import { useConfig } from "../../../providers/ConfigProvider";
const token = localStorage.getItem("token");

export default function New(props) {
	const { managerConfigs } = useConfig();
	const [product, setProduct] = useState({});
	const [characteristicsError, setCharacteristicsError] = useState(false);
	const [skus, setSkus] = useState([]);
	const [skusError, setSkuErro] = useState(false);
	const [reloadSkus, setReloadSkus] = useState(false);
	const [sellers, setSellers] = useState([]);
	const [pricesError, setPricesError] = useState(false);
	const [reloadPrices, setReloadPrices] = useState(false);
	const [isSinglePrice, setIsSinglePrice] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (
			props.match.params.productId == null ||
			props.match.params.productId === undefined
		) {
		}
		if (
			props.match.params.productId != null ||
			props.match.params.productId !== undefined
		) {
			// APIV2.get('management/catalog/products/' + props.match.params.productId)
			// 	.then(async response => {
			// 		console.log(response.data)
			// 		setProduct(response.data)
			// 	})

			// 	.catch((error) => {
			// 		console.log(error)
			// 		setCharacteristicsError(true);
			// 		toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			// 	})

			getSkus();

			getSellers();

			// esta chamada pode ser substituida pelo localStorage
			APIV2.get("management/retailer/configurations")
				.then(async (response) => {
					setIsSinglePrice(response.data.isSinglePrice);
				})
				.catch((error) => {
					console.log(error);
					setPricesError(true);
					setReloadPrices(true);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde.",
						{
							position: "bottom-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							preventDuplicate: true,
						}
					);
				});
		}
	}, []);

	const getSellers = async () => {
		await APIV2.get(`management/retailer/autocomplete/sellers`)
			.then(async (response) => {
				setSellers(response.data);
			})
			.catch((error) => {
				console.log(error);
				setPricesError(true);
				setReloadPrices(true);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const getSkus = () => {
		setLoading(true);
		APIV2.get(
			"management/catalog/products/" + props.match.params.productId + "/skus"
		)
			.then(async (response) => {
				setSkus(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setSkuErro(true);
				setReloadSkus(true);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde.",
					{
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						preventDuplicate: true,
					}
				);
			});
	};

	const createSKU = (sku, type) => {
		setLoading(true);
		console.log(localStorage.getItem("UseCatalogProcessor"));

		let imagesUseCatalogProcessor = sku.images.map((image) => {
			return {
				originalImageUrl: image.imageUrl,
				order: image.order,
			};
		});

		APIV2.put(
			`/management/catalog/products/${props.match.params.productId}/skus/${
				sku.id ? sku.id : sku.code
			}/images`,
			localStorage.getItem("UseCatalogProcessor") === "true"
				? imagesUseCatalogProcessor
				: sku.images
		);

		if (type !== "edit") {
			APIV2.post(
				"management/catalog/products/" + props.match.params.productId + "/skus",
				sku
			)
				.then(async (response) => {
					getSkus();
					toast.success("SKU criado com sucesso!😃");
					// document.getElementById("closeSku").click()
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			APIV2.put(
				!sku.id
					? "management/catalog/products/" +
							props.match.params.productId +
							"/skus/"
					: "management/catalog/products/" +
							props.match.params.productId +
							"/skus/" +
							sku.id,
				sku
			)
				.then(async (response) => {
					getSkus();
					toast.success("SKU editado com sucesso!😃");
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	};

	const deleteSku = (sku) => {
		setLoading(true);
		APIV2.delete(
			!sku.id
				? `management/catalog/products/${props.match.params.productId}/skus/${sku.code}`
				: "management/catalog/products/" +
						props.match.params.productId +
						"/skus/" +
						sku.id
		)
			.then(async (response) => {
				toast.success("SKU deletada com sucesso!😃");
				getSkus();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	return (
		<div className="container dash-content">
			<div id="accordion">
				<DataProducts
					id={props.match.params.productId}
					page={props.match.params.page}
				/>

				{props.match.params.productId && (
					<>
						{
							<PriceProducts
								isSinglePrice={isSinglePrice}
								sellers={sellers}
								id={props.match.params.productId}
							/>
						}
						{skusError ? (
							<div className="card mt-3">
								<div className="card-header d-flex justify-content-between align-items-center">
									<h4 className="card-title">Variação do Produto</h4>
								</div>
								<div className="card-body">
									{reloadSkus ? <Reload func={() => getSkus()} /> : skeleton()}
								</div>
							</div>
						) : (
							<SkuProducts
								skus={skus}
								loading={loading}
								id={props.match.params.productId}
								delete={deleteSku}
								create={createSKU}
							/>
						)}
						<CharacteristicsProducts id={props.match.params.productId} />
					</>
				)}
			</div>
		</div>
	);
}
