import React, { Component, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
//import { API, APIV2 } from '../../API';
import NewDropImage from '../../../components/NewDropImage';
import Loading from '../../../components/Loading';

export default function BasicInfo(props) {
    const [loading, setLoading] = useState(true)
    const [skus, setSkus] = useState({
		id: "",
		name: "",
		codeSku: "",
		imageUrl: "",
		images: [],
		isActive: true,
		auxCode: "",
	})

    useEffect(() => {
        //console.log("Novo Frete")
        setLoading(false)
    }, [])

    const handleNewFreight = () => {
        //console.log("Novo Frete")
    }

    const recoveryStateImage = (e) => {
		//console.log(e)
		skus.images = e
		if (e.length > 0) {
			setSkus(prevState => ({ ...prevState, imageUrl: e.imageUrl }))
		}

		for (let i = 0; i < skus.images.length; i++) {
			skus.images[i].order = i
		}
	}

    useEffect(() => {
        //console.log(props.save)
        
    }, [])

    const onChange = (e) => {
        //console.log(e.target.value)
    }

    const saveInfos = async (step) => {
        props.save(step) // essas informação vão o new freight
    }

    const renderScreen = () => {
        return (
            <div className="row m-2">
                <div className="w-100 px-3 mb-2">
                    <h3 className="card-title" style={{ fontSize: '18px'}}>Informações Básicas*</h3>
                </div>
                
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="Status" className="text-muted">Forma de envio ativa?</label>&nbsp;&nbsp;
                        <label className="switch ">
                            <input id="Status" type="checkbox" name='isActive' value={'isActive'} checked={true} onChange={() => ("asda")} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="shippingWay"><strong>Nome da Forma de Envio</strong></label>
                        <div className="">
                            <input type="text" className="form-control" id="shippingWay" placeholder="Clique para preencher" name='shippingWay' value={""} onChange={() => ("asda")} style={{ width: '85%'}}/>
                        </div>
                        <label style={{ color: "#A1A3A5", fontWeight: "500", fontSize: "12px" }}><em>Atenção! Esse é o nome que será mostrado ao cliente no momento da escolha de frete.</em></label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card-content">
                        <div className="row">
                            <div className="form-group col-sm-11">
                                <label><strong>Imagem da forma de Envio</strong></label>
                                <p style={{ color: '#747E8B', fontSize: '12px', lineHeight: '16px', fontWeight: 'normal', fontStyle: 'normal'}}>Selecione uma imagem ou ícone para representar esta forma de envio.</p>
                            </div>
                            <div className='form-group col-12' >
                                <label><strong>Envio de Imagem</strong></label>
                                <NewDropImage
                                    type={'carrousel'}
                                    data={skus.images.sort(function (a, b) {
                                        return a.order - b.order;
                                    })}
                                    recoveryStateLogo={recoveryStateImage}
                                    height={'50px'}
                                    width={'100%'}
                                    background={'#fff'}
                                    colorText={'#747E8B'}
                                    sku={true}
                                />
                                <label style={{ color: "#A1A3A5", fontWeight: "500", fontSize: "12px" }}>São suportados arquivos no formato PNG, no tamanho 200x200px</label>
                            </div>
                            <div className="form-group col-sm-11">
                                <label><strong>Escolha um Ícone</strong></label>
                                <div className="actions d-flex align-items-center justify-content-start">
                                    <div className="btn-group mr-2" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-light outline btn-sm btn-icon d-flex justify-content-center" onClick="" style={{ width: '40px'}}><i className="material-icons text-muted" style={{ fontSize: '20px', fontWeight: '600'}}>local_shipping</i></button>
                                    </div>
                                    <div className="btn-group mr-2" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-light outline btn-sm btn-icon d-flex justify-content-center" onClick="" style={{ width: '40px'}}><i className="material-icons text-muted" style={{ fontSize: '20px', fontWeight: '600'}}>drive_eta</i></button>
                                    </div>
                                    <div className="btn-group mr-2" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-light outline btn-sm btn-icon d-flex justify-content-center" onClick="" style={{ width: '40px'}}><i className="material-icons text-muted" style={{ fontSize: '20px', fontWeight: '600'}}>moped</i></button>
                                    </div>
                                    <div className="btn-group mr-2" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-light outline btn-sm btn-icon d-flex justify-content-center" onClick="" style={{ width: '40px'}}><i className="material-icons text-muted" style={{ fontSize: '20px', fontWeight: '600'}}>directions_bike</i></button>
                                    </div>
                                    <div className="btn-group mr-2" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-light outline btn-sm btn-icon d-flex justify-content-center" onClick="" style={{ width: '40px'}}><i className="material-icons text-muted" style={{ fontSize: '20px', fontWeight: '600'}}>directions_walk</i></button>
                                    </div>
                                    <div className="btn-group mr-2" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-light outline btn-sm btn-icon d-flex justify-content-center" onClick="" style={{ width: '40px'}}><i className="material-icons text-muted" style={{ fontSize: '20px', fontWeight: '600'}}>local_airport</i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row w-100 ml-2 mt-2 mb-4">
                    <div className="col-12 d-flex flex-row d-flex justify-content-end">
                        <button type="button" className="btn btn-light mr-2" onClick={() => window.location.href =  '/freight'}>Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={() => saveInfos(2)}>Salvar e Continuar</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {loading ? <Loading /> : renderScreen()}
        </>

    )
};


