import React, { Component } from 'react';
import Colors from './Colors';
import Images from './Images';
import SplashScreen from './SplashScreen';
 export class Brand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: []
    }
  }
  componentDidMount() {
    
  }
  render() {
    return (
      <>
        {/* Verifca o step pelo parametro para retornar o componente */}
        {this.props.match.params.step === 'colors' ?
          <Colors/> : null
        }
        {this.props.match.params.step === 'images' ?
          <Images/> : null
        }
          {this.props.match.params.step === 'splashscreen' ?
          <SplashScreen/> : null
        }
      </>
    )
  }
};

export default Brand;
