import React, { Component, useEffect, useState } from "react";
import { API, APIV2 } from "../API";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import Loading from "./Loading";
import ContentLoader from "react-content-loader";

export default function AutoComplete(props) {
	const [urlGet, setUrlGet] = useState("");
	const [loadingRequest, setLoadingRequest] = useState(false);
	const [productList, setProductList] = useState([]);
	/* states para o pedidos */
	const [skus, setSkus] = useState([]);
	const [product, setProduct] = useState([]);
	const [imageUrl, setImageUrl] = useState("");
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [nameSku, setNameSku] = useState("");
	const [codeSku, setCodeSku] = useState("");
	const [idSku, setIdSku] = useState("");
	const [price, setPrice] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showSkuSelector, setShowSkuSelector] = useState("");
	const [sizeArr, setSizeArr] = useState([]);
	const [colorArr, setColorArr] = useState([]);
	const [attributes, setAttributes] = useState([]);
	const [colorActive, setColorActive] = useState(false);
	const [attrColor, setAttrColor] = useState(false);
	const [barcode, setBarcode] = useState("");
	const [activeSize, setActiveSize] = useState(false);
	const [activeColor, setActiveColor] = useState(false);

	useEffect(() => {
		const config = JSON.parse(localStorage.getItem("configurations"));
		setShowSkuSelector(config ? config[0].showSkuSelector : "");
		setUrlGet(props.apiGet);
		document.addEventListener("click", (evt) => {
			const autocomplete = document.querySelector("#autocomplete");
			let targetElement = evt.target;

			do {
				if (targetElement === autocomplete) {
					return;
				}
				targetElement = targetElement.parentNode;
			} while (targetElement);
			setProductList([]);
			if (document.querySelector("#search")) {
				document.querySelector("#search").value = "";
			}
		});
	}, []);

	useEffect(() => {
		const inputSearch = document.querySelector("#search");
		const searchProd = fromEvent(inputSearch, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => search(text));
		//return searchProd.unsubscribe()//
	}, []);

	const search = async (text) => {
		setLoadingRequest(true);
		try {
			const response = await API.get(props.apiGet + text);
			setProductList(response.data);
			setLoadingRequest(false);
		} catch (error) {
			console.log(error);
			setLoadingRequest(false);
		}
	};

	const selectProduct = (product) => {
		if (props.isActive) {
			props.function(product);
		} else {
			props.function(product);
			setProductList([]);
		}
	};

	const selectIdProduct = (product) => {
		setNameSku("");
		setProduct(product);
		setLoading(true);
		APIV2.get(`sales/catalog/products/${product.id}`)
			.then(async (response) => {
				setColorActive(false);
				if (showSkuSelector === false) {
					const newProducts = {
						id: product.id,
						imageUrl: product.imageUrl,
						price: response.data.price,
						code: response.data.cade,
						auxCode: response.data.auxCode,
						referenceCode: response.data.referenceCode,
						barcode: response.data.skus[0].barcode,
						sku: {
							name: product.name,
							id: response.data.skus[0].id,
							code: response.data.skus[0].code,
							nameSku: response.data.skus[0].name,
							barcode: response.data.skus[0].barcode,
						},
					};
					props.function(newProducts);
					setLoading(false);
				} else {
					setColorActive(false);
					setImageUrl(response.data.imageUrl);
					setName(response.data.name);
					setSkus(response.data.skus);
					setPrice(response.data.price);
					setAttributes(response.data.skus[0].attributes);
					if (response.data.skus[0].attributes.length > 0) {
						let sizeArr = [];
						let attrColor = [];
						const attr = response.data.skus.map((item) => {
							return item.attributes;
						});
						const setNameSkus = response.data.skus.map((item) => {
							return item.name;
						});
						attr.forEach((arr) => {
							const atttrColor = arr
								.filter((item) => {
									return item.type === 1;
								})
								.reduce((obj, item) => (obj[item.key] = item), {});
							attrColor.push(atttrColor);
							const sizeTitle = arr
								.filter((item) => {
									return item.type === 0;
								})
								.reduce((obj, item) => (obj[item.key] = item), {});
							sizeArr.push(sizeTitle);
						});
						sizeArr = sizeArr.filter(function (a) {
							return (
								!this[JSON.stringify(a.value)] &&
								(this[JSON.stringify(a.value)] = true)
							);
						}, Object.create(null));
						setAttrColor(attrColor[0].type);
						setSizeArr(sizeArr);
					}
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleColorSku = (item) => {
		setActiveSize(item.id);
		const nameSku = item.value.trim();
		setColorActive(true);
		let colorArr = [];
		let colorSkus = [];
		let skusColors = [];
		let colors = skus.forEach((arr) => {
			const name = arr.name.split(" - ");
			let value = name.filter((obj) => {
				return obj === nameSku;
			});
			const body = {
				attributes: arr.attributes,
				name: value.toString(),
			};
			colorArr.push(body);
		});
		let colorSku = colorArr.filter((color) => {
			return color.name === nameSku;
		});
		const attr = colorSku.map((item) => {
			return item.attributes;
		});
		attr.forEach((arr) => {
			const colorTitle = arr
				.filter((item) => {
					return item.type === 1;
				})
				.reduce((obj, item) => (obj[item.key] = item), {});
			colorSkus.push(colorTitle);
		});
		colorSkus.map((color, i) => {
			let item = color.value.split("|");
			const body = {
				id: color.skuId,
				color: item,
			};
			skusColors.push(body);
		});
		setColorArr(skusColors);
	};

	const getSku = (sku) => {
		setActiveColor(sku);
		if (attrColor == null) {
			let attrName = skus.filter((item) => item.id === sku.skuId);
			setNameSku(attrName[0].name);
			setCodeSku(attrName[0].code);
			setIdSku(attrName[0].id);
			setBarcode(attrName[0].barcode);
		} else {
			let attrName = skus.filter((item) => item.id === sku.id);
			setNameSku(attrName[0].name);
			setCodeSku(attrName[0].code);
			setIdSku(attrName[0].id);
			setBarcode(attrName[0].barcode);
		}
	};

	const getSkus = () => {
		const newProducts = {
			id: product.id,
			imageUrl: product.imageUrl,
			price: price,
			sku: {
				barcode: barcode,
				name: name,
				id: idSku,
				code: codeSku,
				nameSku: nameSku,
			},
		};
		props.function(newProducts);
	};

	const cancelSku = () => {
		setCodeSku("");
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={30}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="15" rx="3" ry="3" width="700" height="20" />
			</ContentLoader>
		));
	};

	return (
		<div className="form-group autocomplete icon-right" id="autocomplete">
			<input
				type="text"
				className="search form-control"
				id="search"
				name="pesquisa"
				placeholder={props.placeholder}
				autoComplete="off"
			/>
			<i className="material-icons">search</i>
			{productList.length > 0 && (
				<div className="result">
					<ul>
						{productList.map((i) => (
							<>
								{props.modal !== "modal" ? (
									<li key={i.id} id={i.id} onClick={() => selectProduct(i)}>
										<div className="d-flex justify-content-start">
											<span>
												<img
													src={
														!i.imageUrl ? "/images/placeholder.jpg" : i.imageUrl
													}
													alt="Produto"
													width="40px"
													className="shadow-sm mx-auto d-block"
												/>
											</span>
											<p
												style={{
													width: "390px",
													marginLeft: "20px",
													marginTop: "10px",
												}}
											>
												{i.name}
											</p>
										</div>
									</li>
								) : (
									<li
										key={i.id}
										id={i.id}
										data-toggle={props.modal}
										data-target={
											showSkuSelector === true ? `#${props.modal}` : ""
										}
										onClick={() => selectIdProduct(i)}
									>
										<div className="d-flex justify-content-start">
											<span>
												<img
													src={
														!i.imageUrl ? "/images/placeholder.jpg" : i.imageUrl
													}
													alt="Produto"
													width="40px"
													className="shadow-sm mx-auto d-block"
												/>
											</span>
											<p
												style={{
													width: "390px",
													marginLeft: "20px",
													marginTop: "10px",
												}}
											>
												{i.name}
											</p>
										</div>
									</li>
								)}
							</>
						))}
					</ul>
				</div>
			)}
			{/* MODAL SERÁ ABERTO PARA ADICIONAR SKUS AO PEDIDO */}
			<div
				className="modal fade"
				id={props.modal}
				tabindex="-1"
				role="dialog"
				aria-labelledby={props.modal}
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<div className="d-flex flex-column">
								<h5
									className="modal-title mb-1"
									id={props.modal}
									style={{ fontSize: "18px" }}
								>
									Selecione a variação do produto
								</h5>
								<span style={{ fontSize: "14px" }}>
									Escolha qual SKU adicionar ao pedido
								</span>
							</div>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body m-0">
							{loading === true ? (
								skeleton()
							) : (
								<>
									<div className="d-flex flex-row m-0 p-0">
										<div className="mr-2">
											<img
												src={!imageUrl ? "/images/placeholder.jpg" : imageUrl}
												alt="Produto"
												width="40px"
												height="40px"
												className="shadow-sm mx-auto d-block"
											/>
										</div>

										<div className="d-flex flex-column">
											<label className="m-0 p-0">{name}</label>
											<em style={{ fontSize: "11px" }} className="p-0 m-0">
												{`Cód: ${codeSku}`}&nbsp;&nbsp;{`EAN: ${barcode}`}
											</em>
										</div>
									</div>
									{attributes.length > 0 ? (
										<>
											<div className="m-0 p-0">
												<label className="my-2">
													<strong>Tamanho</strong>&nbsp;
													{nameSku === "" ? "selecione um tamanho" : nameSku}
												</label>
												<div className="d-flex flex-row">
													{sizeArr.map((item, i) =>
														attrColor == null ? (
															<button
																key={item.id}
																className={
																	item.id !== activeSize
																		? "btn-sku-default"
																		: "btn-sku-active"
																}
																onClick={() => getSku(item)}
																style={{ height: "40px" }}
															>
																{item.value}
															</button>
														) : (
															<button
																key={item.id}
																className={
																	item.id !== activeSize
																		? "btn-sku-default"
																		: "btn-sku-active"
																}
																onClick={() => handleColorSku(item)}
																style={{ height: "40px" }}
															>
																{item.value}
															</button>
														)
													)}
												</div>
											</div>
											{colorActive === false ? null : (
												<div className="m-0 p-0">
													<label className="my-2">
														<strong>Cor:</strong>&nbsp;{"selecione a cor"}
													</label>
													<div className="d-flex flex-row m-0 p-0">
														{colorArr.map((item) => (
															<div
																key={item.id}
																className={
																	item.id !== activeColor
																		? "btn-color-default"
																		: "btn-color-active"
																}
															>
																<button
																	onClick={() => getSku(item.id)}
																	style={
																		item.color[1] == null
																			? { background: `${item.color[0]}` }
																			: {
																					backgroundImage: `linear-gradient(45deg, ${item.color[0]} 50%, ${item.color[1]} 50%)`,
																			  }
																	}
																></button>
															</div>
														))}
													</div>
												</div>
											)}
										</>
									) : (
										<div className="m-0 p-0">
											<label className="my-2">
												<strong>Tamanho:</strong>&nbsp;
												{nameSku === "" ? "selecione um tamanho" : nameSku}
											</label>
											<div className="d-flex">
												{skus.map((sku) => (
													<button
														className={
															codeSku === ""
																? "btn btn-light active-btn mr-2 px-4"
																: "btn btn-primary active-btn mr-2 px-4"
														}
														onClick={() => getSku(sku)}
														style={{ height: "40px" }}
													>
														{sku.name}
													</button>
												))}
											</div>
										</div>
									)}
								</>
							)}
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-dismiss="modal"
								onClick={cancelSku}
							>
								Cancelar
							</button>
							{nameSku !== "" ? (
								<button
									type="button"
									className="btn btn-primary"
									data-dismiss="modal"
									onClick={getSkus}
								>
									Adicionar
								</button>
							) : (
								<button type="button" className="btn btn-light">
									Adicionar
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
