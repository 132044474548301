import React, { Component } from "react";
import { API } from "../API";
import Loading from "./Loading";
import AutoComplete from "./AutoComplete";
import Notifications, { notify } from "react-notify-toast";
import Modal from "../components/Modal";
import { toast } from "react-toastify";
import Button from "./Button";
export class Products extends Component {
	constructor(props) {
		super(props);
		this.addList = this.addList.bind(this);
		this.removeList = this.removeList.bind(this);
		this.change = this.change.bind(this);
		this.selectItens = this.selectItens.bind(this);
		this.selectAllItens = this.selectAllItens.bind(this);
		this.multDelete = this.multDelete.bind(this);
		this.loadProducts = this.loadProducts.bind(this);
	}
	state = {
		loading: false,
		list: [],
		lote: false,
		productsLote: "",
		selects: [],
		allCheck: false,
		progress: false,
		alert: false,
		duplicateProduct: [],
		emptyProduct: [],
		load: false,
	};

	componentDidMount() {
		// this.props.productsPromo &&
		// 	this.setState({ list: this.props.productsPromo });
		// console.log(this.props.productsPromo);
		//console.log(this.state.list);

		if (
			this.props.externalProducts != null ||
			this.props.externalProducts !== undefined
		) {
			this.setState({ list: this.props.externalProducts });
			this.forceUpdate();
			// console.log(this.props.externalProducts);
		}
	}

	async addList(e) {
		//console.log("addList", e);
		var result = this.state.list.findIndex((element) => element.id == e.id);

		//console.log(result);

		if (result == -1) {
			await this.setState({ list: this.state.list.concat(e) });
		} else {
			// notify.show("Sua lista já possui este produto! 😃", "error", 5000)
			toast.error("Sua lista já possui este produto! 😃");
		}

		this.props.getProducts(this.state.list);
	}

	async change(e) {
		await this.setState({ productsLote: e.target.value });
	}

	removeList(index) {
		this.state.list.splice(index, 1);
		this.forceUpdate();
		this.props.getProducts(this.state.list);
	}

	async multDelete() {
		this.state.selects.map((element) => {
			const indexRemove = this.state.list.findIndex(
				(el) => el.id === element.id
			);
			this.state.list.splice(indexRemove, 1);
		});

		this.props.getProducts(this.state.list);
		this.state.selects = [];
	}

	async selectAllItens(e) {
		await this.setState({
			allCheck: this.state.list.length !== this.state.selects.length,
		});

		this.props.getProducts(this.state.list);

		this.state.list.map((item, i) => {
			const found = this.state.selects.some((el) => el.id === item.id);
			if (this.state.allCheck === true && !found) {
				this.selectItens(item.id, i);
			} else if (this.state.allCheck === false && found) {
				this.selectItens(item.id, i);
			}
		});
	}

	async selectItens(id, index) {
		var item = {
			id: id,
			index: index,
		};
		const found = this.state.selects.some((el) => el.id === id);
		if (!found) {
			this.state.selects.push(item);
		} else {
			const indexRemove = this.state.selects.findIndex((el) => el.id === id);
			this.state.selects.splice(indexRemove, 1);
		}
		this.forceUpdate();
	}

	async saveLote() {
		var newArray = this.state.productsLote
			? this.state.productsLote
					.replace(/(?:\r\n|\n|\r|\s)/g, ",")
					.split(",")
					.filter((e) => {
						return e.replace(/(\r\n|\n|\r|\s)/gm, "");
					})
			: "";

		for (let i = 0; i < newArray.length; i++) {
			API.get("api/autocomplete/products/" + newArray[i])
				.then((response) => {
					//this.loadProducts()
					if (response.data.length == 0) {
						this.setState({
							emptyProduct: this.state.emptyProduct.concat(newArray[i]),
						});
					} else {
						let result = this.state.list.findIndex(
							(element) => element.code == newArray[i]
						);

						if (result == -1) {
							//Nao encontrado igual
							this.addList(response.data);
						} else {
							//Encontrado igual
							this.setState({
								duplicateProduct: this.state.duplicateProduct.concat(
									response.data
								),
							});
						}
					}
				})
				.then(() => {
					if (
						this.state.emptyProduct.length > 0 ||
						this.state.duplicateProduct.length > 0
					) {
						this.setState({ alert: true });
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		//newArray = []
		this.setState({ productsLote: null });
		// this.setState({ alert: true });
		this.loadProducts();
	}

	loadProducts() {
		this.setState({ load: true });

		setTimeout(
			function () {
				this.setState({ load: false });
			}.bind(this),
			3000
		);
	}

	async closeProductsAlert(typeAlert) {
		if (typeAlert === 1) {
			await this.setState({ duplicateProduct: [] });
			this.state.duplicateProduct.length === 0 &&
				this.state.emptyProduct.length === 0 &&
				this.setState({ alert: false });
		} else if (typeAlert === 2) {
			await this.setState({ emptyProduct: [] });
			this.state.duplicateProduct.length === 0 &&
				this.state.emptyProduct.length === 0 &&
				this.setState({ alert: false });
		}
	}

	render() {
		if (this.state.loading) {
			return <Loading />;
		} else {
			return this.renderScreen();
		}
	}
	renderScreen() {
		return (
			<>
				<Modal
					id={"addLoteproducts"}
					title={"Produtos"}
					lote={true}
					content={
						<div className="pt-0">
							{this.state.load ? (
								<div className="position-absolute w-100 products-container">
									<Loading />
									<h4 className="text-center">Carregando seus produtos</h4>
								</div>
							) : null}

							<p>
								Para adicionar vários produtos insira a lista de produtos
								contendo somente 1 código de produto por linha
							</p>
							{this.state.load ? null : (
								<div className="form-group mb-0">
									<textarea
										className="form-control"
										type="number"
										id="exampleFormControlTextarea1"
										rows="10"
										placeholder="12312312321&#10;12312312321 &#10;12312312321"
										value={this.state.productsLote}
										onChange={this.change}
									></textarea>
								</div>
							)}
						</div>
					}
					actionConfirm={() => this.saveLote()}
				/>
				<div className="container">
					<div className="row justify-content-between">
						<div className={this.props.banners ? "col-12" : "col-9 pl-0"}>
							<AutoComplete
								placeholder="Procure pelo produto"
								apiGet="api/autocomplete/products/"
								function={this.addList}
								isActive={true}
							/>
						</div>
						{this.props.banners ? null : (
							// <div className="col-3 pr-0">
							<Button
								className="col-3 btn-info text-white"
								dataToggle="modal"
								dataTarget="#addLoteproducts"
							>
								Adicionar em lote
							</Button>
							// </div>
						)}
					</div>
				</div>
				<div className="list">
					{this.state.load ? (
						<Loading />
					) : (
						<div className="products-container">
							{this.state.alert ? (
								<div className="alert alert-danger">
									{this.state.duplicateProduct.length > 0 ? (
										<>
											<span className="alert-products">
												<span className="alert-products-align">
													<a
														className="text-danger strong alert-products-align"
														href=""
														data-toggle="modal"
														data-target="#duplicateProduct"
													>
														<i className="material-icons">error_outline</i>
													</a>
													<span className="alert-products-span">
														<a
															className="text-danger strong"
															href=""
															data-toggle="modal"
															data-target="#duplicateProduct"
														>
															<strong>
																<u>
																	{this.state.duplicateProduct.length} produtos
																	duplicados
																</u>
															</strong>
														</a>
														<span>{" não foram importados!"}</span>
													</span>
												</span>
												<button
													type="button"
													className="close text-danger alert-products-align"
													onClick={() => this.closeProductsAlert(1)}
													aria-label="Close"
												>
													<i className="material-icons">close</i>
												</button>
											</span>
											{this.state.emptyProduct.length > 0 ? <hr /> : null}
										</>
									) : null}

									{this.state.emptyProduct.length > 0 ? (
										<>
											<span className="alert-products">
												<span className="alert-products-align">
													<a
														className="text-danger strong alert-products-align"
														href=""
														data-toggle="modal"
														data-target="#emptyProduct"
													>
														<i className="material-icons">error_outline</i>
													</a>
													<span className="alert-products-span">
														<a
															className="text-danger strong"
															href=""
															data-toggle="modal"
															data-target="#emptyProduct"
														>
															<strong>
																<u>
																	{this.state.emptyProduct.length} produtos não
																	encontrados
																</u>
															</strong>
														</a>
														<span>{" não foram importados!"}</span>
													</span>
												</span>
												<button
													type="button"
													className="close text-danger alert-products-align"
													onClick={() => this.closeProductsAlert(2)}
													aria-label="Close"
												>
													<i className="material-icons">close</i>
												</button>
											</span>
										</>
									) : null}
								</div>
							) : null}
							<Modal
								id={"duplicateProduct"}
								title={"Produtos duplicados"}
								confirm={false}
								content={
									<div>
										<ul className="navbar-nav">
											{this.state.duplicateProduct.map((duplicated) => (
												<li key={duplicated.id} className="nav-item">
													<strong>{duplicated.code}</strong>
													<hr />
												</li>
											))}
										</ul>
									</div>
								}
								actionConfirm={() => {}}
							/>
							<Modal
								id={"emptyProduct"}
								title={"Produtos não encontrados"}
								cofirm={false}
								content={
									<div>
										<ul className="navbar-nav">
											{this.state.emptyProduct.map((empty) => (
												<>
													<li key={empty.id} className="nav-item">
														<strong>{empty}</strong>
														<hr />
													</li>
												</>
											))}
										</ul>
									</div>
								}
							/>

							<div className="mb-3">
								{this.props.banners ? null : (
									<div className="dropdown">
										<button
											id="massiveActions"
											type="button"
											className="btn btn-light dropdown-toggle"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Ações em massa
										</button>
										<div
											className="dropdown-menu"
											aria-labelledby="massiveActions"
										>
											<a
												className="dropdown-item text-danger"
												href="#"
												data-toggle="modal"
												data-target="#modalExcluir"
											>
												Remover
											</a>
										</div>
									</div>
								)}
							</div>

							{this.state.list.length > 0 ? (
								<table className="table products-list">
									<thead>
										<tr>
											<th>
												<input
													type="checkbox"
													id="horns"
													name="horns"
													checked={
														this.state.list.length === this.state.selects.length
															? true
															: false
													}
													onChange={() => this.selectAllItens()}
												/>
											</th>
											<th scope="col" width="150">
												CÓDIGO
											</th>
											<th scope="col" className="product-th-name">
												NOME
											</th>
											<th scope="col"></th>
										</tr>
									</thead>
									<tbody>
										{this.state.list.map((item, i) => (
											<tr key={item.id} id={item.id}>
												<td className="td-product-checkbox">
													<input
														type="checkbox"
														id="horns"
														name="horns"
														onChange={() => this.selectItens(item.id, i)}
														checked={
															this.state.selects.some((el) => el.id === item.id)
																? true
																: false
														}
													/>
												</td>
												<td className="td-product-code">{item.code}</td>
												<td>{item.name}</td>
												<td className="text-right td-product-delete-button">
													<button
														type="button"
														className="btn btn-light outline btn-sm btn-icon text-right"
														onClick={() => this.removeList(i)}
													>
														<i className="material-icons text-danger">
															delete_outline
														</i>
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : null}
						</div>
					)}
				</div>

				<Modal
					id={"modalExcluir"}
					title={"Excluir"}
					delete={true}
					content={
						<div className="modal-body">
							Você está excluindo os itens permanentemente, tem certeza disso?
						</div>
					}
					actionDelete={() => this.multDelete()}
				/>
			</>
		);
	}
}

export default Products;
