import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {API} from '../../../API';

export class DropOneImage extends Component {
  constructor(props) {
    super(props);
    this.removeImage = this.removeImage.bind(this);
    this.state = {
      type: '',
      previewImage: '',
    };
  }
  componentWillMount() {
    this.setState({ previewImage: this.props.previewImage })
    this.forceUpdate()
  }
  onImageDropCarrousel(files) {
    this.setState({
      uploadedFile: files[0]
    });
    this.handleImageUploadCarrousel(files[0]);
  }
  onImageDrop(files) {
      this.setState({ type: files.type })
      this.setState({ uploadedFile: files[0] });
      this.handleImageUpload(files[0]); 
  }
  handleImageUpload(file) {
    const formData = new FormData();
    formData.append('file', file)
    API.post('api/images', formData, { headers: {  ContentType: 'multipart/form-data' } })
      .then(response => {
        this.props.recoveryStateLogo(response.data.relativeUrl)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  removeImage(e) {
        e.preventDefault()
        this.props.previewImage(this.state.previewImage)
  }
  render() {
    return (
        <div className="dropzone" >
           <Dropzone onDrop={this.onImageDrop.bind(this)} accept="image/*" multiple={false}>
                    {({ getRootProps, getInputProps }) => {
                        return (
                          <div className="download-pdf-xml" style={{ width: '100%', height: '96px' }} {...getRootProps()}>
                            <input {...getInputProps()} />
          
                            <div className="success" style={{ width: '100%' }}>
                              <p className="mt-2" style={{ fontWeight: '500', fontSize: '12px', display: "flex", flexDirection: 'column'  }}>
                                <i className="material-icons mr-2"> note_add </i>Arraste ou escolha um arquivo de imagem
                              </p>
                            </div>
                          </div>
                        )
                    }}
            </Dropzone>
      </div>
    )
  }
};

export default DropOneImage;
