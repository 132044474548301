import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { APIV2 } from "../../../API";
import { onMessageListener } from "../../../push-notification";

function LastOrders() {
	const [load, setLoad] = useState(true);
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		getOrders();
	}, []);

	onMessageListener()
		.then((payload) => {
			var audio = new Audio("/images/alert-push-01.mp3");
			audio.play();
			toast.success(
				<button
					onClick={() => (window.location.href = "/orders/1")}
					className="push-order"
				>
					{payload.notification.title}
				</button>
			);
			setTimeout(() => {
				getOrders();
			}, 3000);
		})
		.catch((err) => console.log("failed: ", err));

	const getOrders = () => {
		setLoad(true);
		APIV2.get(
			`management/commerce/orders?page=1&perPage=10&role=${localStorage.getItem(
				"role"
			)}`
		)
			.then((response) => {
				setOrders(response.data.orders);
				setLoad(false);
			})
			.catch((error) => {
				setLoad(false);
				if (error.response && error.response.data.error) {
					toast.error(error.response.data.message);
				} else {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="40" y="26" rx="3" ry="3" width="200" height="10" />
				<rect x="1000" y="26" rx="3" ry="3" width="50" height="10" />
			</ContentLoader>
		));
	};

	const renderTable = () => {
		return (
			<table className="table">
				<thead>
					<tr>
						<th scope="col">N. PEDIDO</th>
						<th scope="col" className="text-center">
							STATUS
						</th>
						{localStorage.getItem("role") !== "8" ? (
							<th scope="col" className="text-center">
								MODALIDADE
							</th>
						) : (
							<th scope="col" className="text-center">
								TIPO DE ENVIO
							</th>
						)}

						<th scope="col" className="text-center">
							VALOR TOTAL
						</th>
					</tr>
				</thead>
				<tbody>
					{orders.map((order) => (
						<tr>
							<td>
								<a
									href={`/orders/1/detail/${order.id}`}
									onClick={() =>
										localStorage.setItem("FRIENDLYCODE", order.friendlyCode)
									}
									style={{ color: "#6D4AED", fontWeight: "500" }}
								>
									{order.friendlyCode}
								</a>
							</td>
							<td
								className="text-center"
								style={
									order.status === "Canceled"
										? { color: "#FF5B5C" }
										: order.status === "InProcessing"
										? { color: "#FDAC41" }
										: { color: "#01BFB5" }
								}
							>
								{order.status === "Finalized"
									? "Pedido Finalizado"
									: order.status === "InProcessing"
									? "Pedido em andamento"
									: order.status === "Canceled"
									? "Pedido cancelado"
									: order.status === "Error"
									? "Pedido com erro"
									: order.status === "None"
									? "Não informado"
									: "Não informado"}
							</td>
							{localStorage.getItem("role") !== "8" ? (
								<td className="text-center">{order?.modality?.name}</td>
							) : (
								<td className="text-center">{order?.freight?.description}</td>
							)}
							<td className="text-center">
								{order.subTotalAmount.toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								})}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	const noIntegration = () => {
		return (
			<div className=" align-items-center py-5 empty">
				<div className="text-center">
					{/* <h3 style={{ color: '#4B5F79', fontSize: '24px', fontWeight: 'bold' }}>Ops, não temos nada por aqui ainda!</h3> */}
					<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
						{" "}
						Não há pedidios no momento
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className="card ">
			<div class="card-header d-flex justify-content-between align-items-center">
				<h4 class="card-title">Pedidos</h4>
				<div class="card-actions">
					<Link to="/orders/1" className="btn btn-new-width btn-primary">
						Ver todos
					</Link>
				</div>
			</div>
			<div className="card-body p-2">
				{load
					? skeleton()
					: orders.length === 0
					? noIntegration()
					: renderTable()}
			</div>
		</div>
	);
}

export default LastOrders;
