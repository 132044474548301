import React, { Component } from "react";
import { API } from "../../../../API";
import CheckBoxList from "../../../../components/CheckBoxList";
import Loading from "../../../../components/Loading";
import { ToastContainer, toast } from "react-toastify";
export class Tags extends Component {
	constructor(props) {
		super(props);
		this.selectTag = this.selectTag.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		loading: true,
		tagSelect: [],
	};

	componentDidMount() {
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("api/tags/")
			.then(async (response) => {
				// If request is good...
				this.setState({
					tags: response.data,
					loading: false,
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}
	async selectTag(item) {
		if (this.state.tagSelect.indexOf(item) !== -1) {
			const index = this.state.tagSelect.indexOf(item);
			this.state.tagSelect.splice(index, 1);
		} else {
			this.state.tagSelect.push(item);
		}
	}
	save(i) {
		var item = {
			tags: this.state.tagSelect,
			value: this.state.tagSelect,
		};
		var itemView = {
			name: "Coleção de produtos",
			value: this.state.tagSelect.length + " Coleção",
			itemId: "tags",
			modal: "#conditionModalTags",
		};
		this.props.newCondition(item, itemView);
	}
	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalTags"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalTags"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Coleção de produtos</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body tags checkbox-list">
							<CheckBoxList list={this.state.tags} change={this.selectTag} />
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								className="btn btn-light col-5"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-success col-5"
								onClick={() => this.save()}
								data-dismiss="modal"
							>
								Pronto
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Tags;
