import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import { Container } from './styles';

function DragDropHome(props) {
  const [plugins, setPlugins] = useState([])

  useEffect(()=>{
    setPlugins(props.plugins)
  },[props.plugins])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const changeListPosition = (elements) => {
    console.log(elements)

    if (!elements.destination) {
      return;
    }

    const items = reorder(
      plugins,
      elements.source.index,
      elements.destination.index
    );

    setPlugins(items)
    props.newOrder(items)
  }

  const listStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "white",
    padding: '8px',
    width: '100%',
    overflowX: 'auto',
    height: '600px'
  })


  const itemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    height: '54px',
    borderRadius: '5px',
    color: '#747E8B',
    // border: '1px solid #E9ECEC',
    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
  })

  const openEdit = (plugin) => {
    props.pluginSelect(plugin)
  }

  return (
    <div>
      <DragDropContext onDragEnd={changeListPosition}>
        <Droppable droppableId="dropPlugins">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={listStyle(snapshot.isDraggingOver)}
              className="app-editor-dragndrop-list"
            >{plugins &&
                plugins.map((p, index) =>
                  <Draggable key={p.id} draggableId={p.id} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={itemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="d-flex flex-row align-items-center"
                      >
                        {/* <div> */}
                        <i class="material-icons">drag_indicator</i>
                        <div className="d-flex flex-row flex-grow-1 align-content-center justify-content-between ml-2 app-editor-dragndrop" >
                          <span className="d-flex align-items-center">
                            <i class="material-icons mr-2">{p.icon}</i>
                            {p.type}
                          </span>
                          <button className="btn btn-link" style={{ padding: '0px' }} onClick={() => openEdit(p)}><i class="material-icons mr-2">edit</i></button>
                        </div>
                        {/* </div> */}
                      </div>

                    )}
                  </Draggable>
                )
              }
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DragDropHome;