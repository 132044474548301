import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import { APIV2 } from "../API";
import { toast } from "react-toastify";

export default function BannersList({ item, url, deleteBanner, idBanner }) {
	const [startIn, setStartIn] = useState("");
	const [endIn, setEndIn] = useState("");
	const [label, setLabel] = useState("");
	const [color, setColor] = useState("");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [deleteId, setDeleteId] = useState("");

	useEffect(() => {
		const today = new Date().getTime();
		//data inicial
		const dateStart = new Date(item.startIn).toLocaleDateString();
		const startDate = new Date(item.startIn).getTime();
		const startTime = new Date(startDate).toLocaleTimeString("pt-BR");
		// data final
		const dateEnd = new Date(item.endIn).toLocaleDateString();
		const endDate = new Date(item.endIn).getTime();
		const endTime = new Date(endDate).toLocaleTimeString("pt-BR");
		//construção
		const diff = Math.abs(today - endDate);
		const day = Math.sign(today - endDate);
		const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

		if (startDate > today && endDate > today) {
			setLabel("AGENDADO");
			setColor("badge badge-secondary stadium");
		} else if (day === -1 && days === 3) {
			setLabel("EXPIRA EM 3 DIAS");
			setColor("badge badge-primary stadium");
		} else if (day === -1 && days === 2) {
			setLabel("EXPIRA EM 2 DIAS");
			setColor("badge badge-warning stadium");
		} else if (day === -1 && days === 1) {
			setLabel("EXPIRA EM 1 DIAS");
			setColor("badge badge-danger stadium");
		} else if (day === 1) {
			setLabel("EXPIRADO");
			setColor("badge badge-danger stadium");
		}

		setStartIn(dateStart);
		setEndIn(dateEnd);
		setStartTime(startTime);
		setEndTime(endTime);
	}, []);

	const deleteBanners = () => {
		setTimeout(async () => {
			await APIV2.delete(`management/marketing/banners/${idBanner}`)
				.then(async (response) => {
					toast.error("Banner deletado com sucesso! 😃");
					window.location.reload();
				})
				.catch((error) => {
					console.log(error);
				});
		}, 1000);
	};

	return (
		<>
			<div className="list-banner">
				<div className="banner-img">
					<img
						src={
							item.imageUrl == null ? "/images/placeholder.jpg" : item.imageUrl
						}
						alt="Banners"
						className="shadow-sm"
					/>
				</div>

				<div className="banner-description">
					<h6
						className="font-weight-bold justify-content-start"
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{item.title}
					</h6>
					<label
						className="text-muted justify-content-start"
						style={{ color: "#C4C4C4" }}
					>
						Inicia em {startIn} {startTime}
					</label>
					<br />
					<label
						className="text-muted justify-content-start"
						style={{ color: "#C4C4C4" }}
					>
						Expira em {endIn} {endTime}
					</label>
				</div>

				<div className="banner-label">
					<span className={color}>{label}</span>
				</div>

				<div className="banner-buttons">
					<div className="btn-group" role="group" aria-label="Basic example">
						{url.match.params.screen === "carousel-banner" ? (
							<button
								type="button"
								className="btn btn-light btn-sm outline btn-icon"
								onClick={() =>
									(window.location.href = "new-carousel/edit/" + item.id)
								}
							>
								<i className="material-icons">edit</i>
							</button>
						) : (
							<button
								type="button"
								className="btn btn-light btn-sm outline btn-icon"
								onClick={() =>
									(window.location.href = "new-body/edit/" + item.id)
								}
							>
								<i className="material-icons">edit</i>
							</button>
						)}
						<button
							type="button"
							className="btn btn-light btn-sm outline btn-icon"
							data-toggle="modal"
							data-target="#deleteList"
							onClick={() => deleteBanner(item.id)}
						>
							<i className="material-icons text-danger">delete_outline</i>
						</button>
					</div>
				</div>
			</div>
			<Modal
				id={"deleteList"}
				title={"Tem certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteBanners}
			/>
		</>
	);
}
