	import firebase from 'firebase';
	import 'firebase/firestore';

	const firebaseConfig = {
		apiKey: "AIzaSyDY_B_Z0dijWVfw0tgxuoQK4qHilfGGHCM",
		authDomain: "lori-painel.firebaseapp.com",
		databaseURL: "https://lori-painel.firebaseio.com",
		projectId: "lori-painel",
		storageBucket: "lori-painel.appspot.com",
		messagingSenderId: "187146760408",
		appId: "1:187146760408:web:d69f5ae436821d36e9a7ad",
		measurementId: "G-FH135EHN2Y"
	};

	firebase.initializeApp(firebaseConfig);

	export const auth = firebase.auth();
	export const storage = firebase.storage();
	export const database = firebase.database();

	export const messaging = navigator.vendor !== 'Apple Computer, Inc.' ? firebase.messaging() : null;
	export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

	export const getToken = (setTokenFound) => {
		return messaging.getToken({vapidKey: 'BKBJfJTwWvmtjAftNMnjTvWqQydqjYBJW1uRXs33clLZjefw_KrDWA1RngchgMYSL4Lmx3CurhiMNAh5GXeaSxQ'})
			.then((currentToken) => {
				if (currentToken) {
					setTokenFound(true);
				} else {
					setTokenFound(false);
				}
		}).catch((err) => {
			console.log('Erro ao recuperar o token. ', err);
		});
	}

	export const onMessageListener = () =>{
		if (navigator.vendor !== 'Apple Computer, Inc.') {
			return  new Promise((resolve) => {
				messaging.onMessage((payload) => {
				resolve(payload);
				});
			});
		}
	}

	/* export const onMessageListener = () =>
		new Promise((resolve) => {
			messaging.onMessage((payload) => {
				console.log(payload)
				resolve(payload);
			});
	}); */

	export const getNotificationPermission = () => {
		return new Promise((resolve, reject) => {
			messaging
			.requestPermission()
			.then(() => messaging.getToken())
			.then((firebaseToken) => {
				resolve(firebaseToken);
			})
			.catch((err) => {
				reject(err);
			});
		});
	}