import React, { Component } from "react";
import Loading from "../../../../components/Loading";
import InputMask from "react-input-mask";
import CurrencyFormat from "react-currency-format";
export class MinimumValue extends Component {
	constructor(props) {
		super(props);
		this.onChangeMinValue = this.onChangeMinValue.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		loading: false,
		minValue: "",
	};
	save(i) {
		var item = {
			minValue: parseFloat(this.state.minValue),
			value: parseFloat(this.state.minValue),
		};
		var itemView = {
			name: "Valor mínimo",
			value: this.state.minValue,
			itemId: "minValue",
			modal: "#conditionModalMinValue",
		};
		var button = document.getElementById("button-close");

		this.props.newCondition(item, itemView);
		button.click();
	}

	onChangeMinValue(values) {
		this.setState({ minValue: values.floatValue });
	}

	render() {
		return (
			<div
				class="modal fade"
				id="conditionModalMinValue"
				tabindex="-1"
				role="dialog"
				aria-labelledby="conditionModalMinValue"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title">Valor da mínimo da compra</h3>
							<button
								type="button"
								className="close text-danger"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body py-0">
							<div className="form-group mb-0">
								<CurrencyFormat
									prefix={"R$"}
									className="form-control"
									decimalSeparator={","}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandSeparator={`.`}
									placeholder="R$ 00,00"
									name={this.state.minValue}
									value={
										!this.state.minValue ? "" : parseFloat(this.state.minValue)
									}
									onValueChange={this.onChangeMinValue}
								/>
							</div>
						</div>
						<div
							className="modal-footer text-center"
							style={{ justifyContent: "center" }}
						>
							<button
								type="button"
								id="button-close"
								className="btn btn-light col-5"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								type="submit"
								className="btn btn-success col-5"
								onClick={() => this.save()}
								data-dismiss={this.state.lote ? "" : "modal"}
							>
								Pronto
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MinimumValue;
