import React, { Component } from 'react';
import {API} from '../../../API';
import { connect } from 'react-redux'
import appActions from '../../../appActions';
import Loading from '../../../components/Loading'
export class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {
    content: [],
    site: '',
    loading:true,
    nome: '',
    cnpj: ''
  }


  componentWillMount() {
  
    var clientId = localStorage.getItem('client_id');
    //Consulta materiais disponíveis a partir do token do usuário.
    API.get('api/autosetup/retailers/')
      .then(async response => {
        // If request is good...
        await this.setState({
          site: response.data.domain,
          nome: response.data.companyName,
          cnpj: response.data.cnpj,
          loading:false
        })
        this.forceUpdate();
        this.setFirstData();
      })
      .catch((error) => {
        console.log(error)
      })
  }
  componentDidMount() {
    this.setNextRoute();

  }

  setNextRoute() {
    //Passa para o Redux o proximo passo do autosetup
    this.props.dispatch(appActions.setNextRoute('/setup/brand/colors', true))
  }
  setFirstData() {
    let data = {
      companyName: this.state.nome,
      domain: this.state.site,
      cnpj: this.state.cnpj
    }
    //Passa para o Redux os dados para put da API
    //ajustar o TRUe para um state 
    this.props.dispatch(appActions.sendData(data, true, 'api/autosetup/retailers/', 'content'))
  }
  async handleChange(e) {

    await this.setState({ [e.target.name]: e.target.value })
    this.forceUpdate();
    let data = {
      companyName: this.state.nome,
      domain: this.state.site,
      cnpj: this.state.cnpj
    }
    //Passa para o Redux os dados para put da API
    //ajustar o TRUe para um state 
    this.props.dispatch(appActions.sendData(data, true, 'api/autosetup/retailers/', 'content'))
  }
  render() {
    if(this.state.loading){
      return (
        <Loading/>
      )
    }
    else{
      return (
      this.renderScreen()
      )
    }
   
  }
  renderScreen() {
    return (
        <div className="basic-info d-flex align-items-center">
          <div className="col">
            <h1 className="text-center" >Informações básicas da sua empresa</h1>
            <div className="row basic-inputs d-flex justify-content-center mt-4">
              <div className="col-4 mt-3">
              <div className="form-group">
                <label htmlFor="nome">Nome de exibição da marca</label>
                <input
                  className="form-control"
                  id="nome"
                  name="nome"
                  type="text"
                  value={this.state.nome}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="site">Domínio do site principal</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="site">www.</span>
                  </div>
                  <input
                    className="form-control"
                    id="site"
                    name="site"
                    type="text"
                    value={this.state.site}
                    onChange={this.handleChange}
                  />
                </div>


              </div>
              <div className="form-group">
                <label htmlFor="cnpj">CNPJ principal</label>
                <input
                  className="form-control"
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  value={this.state.cnpj}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            </div>
          </div>
        </div>
    )
  }
};

export default connect(store => ({ text: store.text, req: store.req }))(BasicInfo);
