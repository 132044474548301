import React, { useState, useEffect } from "react";
import { API } from "../../../API";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal";
import List from "../../../components/List/List";
import Loading from "../../../components/Loading";
import Empty from "../../../components/Empty";
import Button from "../../../components/Button";

export default function CollectionsList(props) {
	const columsList = [
		{
			name: "Título",
			selector: "name",
			sortable: false,
			grow: 2,
		},
		{
			name: "Quantidade de produtos",
			selector: "name",
			sortable: false,
			center: true,
			grow: 3,
			cell: (row) => <div className="">{row.products.length}</div>,
		},
		{
			name: "",
			sortable: true,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<a
						id="btnGroupDrop1"
						href={`/collections/${page}/edit/${row.id}`}
						className="btn btn-info"
					>
						Editar
					</a>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>
					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							href="#"
							data-toggle="modal"
							data-target="#deleteCollection"
							onClick={() => setDeleteId(row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];
	const [loading, setLoading] = useState(true);
	const [collections, setCollections] = useState([]);
	const [totalCollections, setTotalCollections] = useState(null);
	const [page, setPage] = useState("1");
	const [deleteId, setDeleteId] = useState("");
	const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	const [rowsPerPage, setRowsPerPage] = useState(20);

	useEffect(() => {
		setPage(props.page);
		// getProducts();
	}, []);

	useEffect(() => {
		getProducts();
	}, [page, rowsPerPage]);

	const getProducts = () => {
		setLoading(true);
		API.get(`api/tags/paged?page=${page}&perPage=${rowsPerPage}`)
			.then((response) => {
				setCollections(response.data.data);
				setTotalCollections(response.data.recordsTotal);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	const changePage = (e) => {
		setLoading(true);
		setPage(e);
		// const stateObj = { page: page };
		const stateObj = { page: props.page };
		window.history.pushState(stateObj, "", e);
		// API.get("api/tags/paged?page=" + e + "&perPage=" + rowsPerPage)
		// 	.then(async (response) => {
		// 		await setCollections(response.data.data);
		// 		setLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		setLoading(false);
		// 		console.log(error);
		// 		toast.error(
		// 			"Parece que tivemos um erro... Tente novamente mais tarde."
		// 		);
		// 	});
	};
	const deleteCollection = () => {
		API.delete("api/tags?id=" + deleteId)
			.then(() => {
				getProducts();
				toast.error("Coleção deletada com sucesso! 😃");
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};
	const skeleton = () => {
		return skele.map(
			() => (
				// <div style={{overflow: 'hidden'}}>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%" }}
				>
					<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
					<rect x="650" y="26" rx="3" ry="3" width="52" height="10" />
					<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
				</ContentLoader>
			)
			// </div>
		);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
	};

	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Suas coleções</h4>
					<div className="card-actions text-right">
						<Button
							className="btn btn-primary"
							onClick={() =>
								(window.location.href = `/collections/${page}/new`)
							}
						>
							Nova coleção
						</Button>
					</div>
				</div>
				<div className="card-content">
					<div className="card-body no-padding">
						{collections.length <= 0 && loading === false ? (
							<Empty
								img={"/images/empty_coupon.svg"}
								genre="sua primeira"
								name="coleção"
								link="/collections/new"
								import={false}
							/>
						) : loading ? (
							skeleton()
						) : (
							<List
								data={collections}
								page={parseInt(page)}
								colums={columsList}
								totalRows={totalCollections}
								handlePageChange={changePage}
								handleRowsPerPageChange={changeRowsPerPage}
								rowsPerPage={rowsPerPage}
								paginationServer={true}
							/>
						)}
					</div>
				</div>
			</div>
			<Modal
				id={"deleteCollection"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteCollection}
			/>
		</div>
	);
}
