import React, { useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useCategory } from '../providers/CategoriesContext';

// import { Container } from './styles';
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 10px 10px 0`,

  display: "inline-flex",
  // padding: "10px",

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",
  display: "inline-flex",
  // padding: "10px",
  // margin: "0 10px 10px 0",
  // border: "1px solid grey",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // padding: grid,
  // margin: "10px 0"
});

function SonCategories(props) {
  const { editCategory } = useCategory()
  useEffect(() => {
    console.log('fiote id', props.category)
  }, [props.category])
  return (
    <Droppable droppableId={`${props.category.id}`} type="sonCategory" >
      {(provided, snapshot) => (

        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
          <div class={snapshot.isDraggingOver ? "collapse drop-body" : "collapse"} id={`categoryColapse${props.category.id}`} style={getListStyle(snapshot.isDraggingOver)} >
            {props.category.children.map((son, sonIndex) =>
              <>
                <Draggable draggableId={`${son.id}`} key={son.id} index={sonIndex}>
                  {(provided, snapshot) => (
                    <div className="col-12">
                      <div className=" d-flex align-items-center justify-content-end"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <div className="category-son d-flex align-items-center justify-content-between">
                          <span className="d-flex align-items-center justify-content-end">
                            <span class="material-icons mr-2">drag_handle</span>
                            {son.name}
                          </span>
                          <span className="d-flex align-items-center justify-content-end">
                            <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end"
                              style={{ padding: '0', textDecoration: 'none', color: '#FFF' }}
                              onClick={() => editCategory(son)}>
                              <span class="material-icons mr-2">edit</span>
                            </button>
                            {son.children.length > 0 &&
                              <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end" data-toggle="collapse" href={`#categorySonColapse${son.id}`} role="button" aria-expanded="false" aria-controls={`categorySonColapse${son.id}`} style={{ padding: '0', textDecoration: 'none', color: '#FFF' }}>
                                <span class="material-icons mr-2">expand_less</span>
                              </button>
                            }
                          </span>
                        </div>
                        <button className="add-subcategory d-flex align-items-center justify-content-center ml-2">+</button>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>

                {/* <Droppable droppableId="droppable-3" >
                  {(provided, snapshot) => (
                    <div class="collapse" id={`categorySonColapse${son.id}`} ref={provided.innerRef}
                      {...provided.droppableProps}>
                      <Draggable draggableId="draggable-3" index={0}>
                        {(provided, snapshot) => (
                          <div className="col-12 d-flex align-items-center justify-content-end" style={{ display: 'inline' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <div className="category-grandchild d-flex align-items-center justify-content-between">
                              <span className="d-flex align-items-center justify-content-end">
                                <span class="material-icons mr-2">drag_handle</span>
                                            Componente Neto
                                          </span>
                              <span className="d-flex align-items-center justify-content-end ">
                                <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end " style={{ padding: '0', textDecoration: 'none', color: '#747E8B' }}>
                                  <span class="material-icons mr-2">edit</span>
                                </button>
                                <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end" data-toggle="collapse" href="#categoryGrandchildColapse" role="button" aria-expanded="false" aria-controls="categoryGrandchildColapse" style={{ padding: '0', textDecoration: 'none', color: '#747E8B' }}>
                                  <span class="material-icons mr-2">expand_less</span>
                                </button>
                              </span>
                            </div>
                            <button className="add-subcategory d-flex align-items-center justify-content-center ml-2">+</button>
                          </div>
                        )}
                      </Draggable>
                      <Droppable droppableId="droppable-4" >
                        {(provided, snapshot) => (
                          <div class="collapse" id="categoryGrandchildColapse" ref={provided.innerRef}
                            {...provided.droppableProps}>
                            <Draggable draggableId="droppable-4" index={0}>
                              {(provided, snapshot) => (
                                <div className="col-12 d-flex align-items-center justify-content-end" style={{ display: 'inline' }} ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div className="category-great-grandchild d-flex align-items-center justify-content-between">
                                    <span className="d-flex align-items-center justify-content-end">
                                      <span class="material-icons mr-2">drag_handle</span>
                                                  Componente Bisneto
                                                </span>
                                    <span className="d-flex align-items-center justify-content-end ">
                                      <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end " style={{ padding: '0', textDecoration: 'none', color: '#747E8B' }}>
                                        <span class="material-icons mr-2">edit</span>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Droppable> */}
              </>
            )}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  )
}

export default SonCategories;