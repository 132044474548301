import React, { useState, useEffect } from "react";
import { APIV2 } from "../../../API";
import Loading from "../../../components/Loading";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from "react-toastify";

export default function New(props) {
	const [loading, setLoading] = useState(true);
	const [edit, setEdit] = useState(false);
	const [id, setId] = useState("");
	const [clientId, setClientId] = useState("");
	const [hasDynamicTutorial, setHasDynamicTutorial] = useState(false);
	const [useNewCheckout, setUseNewCheckout] = useState(false);
	const [useOnlyLocalAssets, setUseOnlyLocalAssets] = useState(false);
	const [useLegacyCatalogAPI, setUseLegacyCatalogAPI] = useState(false);
	const [useLegacyCommerceAPI, setUseLegacyCommerceAPI] = useState(false);
	const [useLegacyMarketingAPI, setUseLegacyMarketingAPI] = useState(false);
	const [useLegacyRetailerAPI, setUseLegacyRetailerAPI] = useState(false);
	const [supportsDebitCard, setSupportsDebitCard] = useState(false);
	const [useNewDealerRanking, setUseNewDealerRanking] = useState(false);
	const [minimalAppVersion, setMinimalAppVersion] = useState("");
	const [urlBaseLori, setUrlBaseLori] = useState("");
	const [productPageType, setProductPageType] = useState("");
	const [marketplaceName, setMarketplaceName] = useState("");
	const [catalogProductCodeMarketplace, setCatalogProductCodeMarketplace] =
		useState(""); /* Code = 0, AuxCode = 1, ReferenceCode = 2, */
	const [catalogSkuCodeMarketplace, setCatalogSkuCodeMarketplace] =
		useState(""); /* Code = 0, AuxCode = 1, ReferenceCode = 2, EAN = 3 */
	const [useCatalogProcessor, setUseCatalogProcessor] = useState(false);
	const [useGeoCatalog, setUseGeoCatalog] = useState(false);
	const [useOrderEditor, setUseOrderEditor] = useState(false);
	const [useFilter, setUseFilter] = useState(false);
	const [useCatalogMatcher, setUseCatalogMatcher] = useState(false);

	useEffect(() => {
		if (props.match.params.id != null || props.match.params.id !== undefined) {
			setEdit(true);
			setLoading(false);
			const id = props.match.params.id;
			APIV2.get(`management/retailer/configurations/internal/${id}`)
				.then(async (response) => {
					console.log(response.data);
					setId(response.data.id);
					setClientId(response.data.clientId);
					setHasDynamicTutorial(response.data.hasDynamicTutorial);
					setUseNewCheckout(response.data.useNewCheckout);
					setUseOnlyLocalAssets(response.data.useOnlyLocalAssets);
					setUseLegacyCatalogAPI(response.data.useLegacyCatalogAPI);
					setUseLegacyCommerceAPI(response.data.useLegacyCommerceAPI);
					setUseLegacyMarketingAPI(response.data.useLegacyMarketingAPI);
					setUseLegacyRetailerAPI(response.data.useLegacyRetailerAPI);
					setSupportsDebitCard(response.data.supportsDebitCard);
					setUseNewDealerRanking(response.data.useNewDealerRanking);
					setMinimalAppVersion(response.data.minimalAppVersion);
					setUrlBaseLori(response.data.urlBaseLori);
					setProductPageType(response.data.productPageType);
					setMarketplaceName(response.data.marketplaceName);
					setCatalogProductCodeMarketplace(
						response.data.catalogProductCodeMarketplace
					);
					setCatalogSkuCodeMarketplace(response.data.catalogSkuCodeMarketplace);
					setUseCatalogProcessor(response.data.useCatalogProcessor);
					setUseGeoCatalog(response.data.useGeoCatalog);
					setUseOrderEditor(response.data.useOrderEditor);
					setUseFilter(response.data.useFilter);
					setUseCatalogMatcher(response.data.useCatalogMatcher);
				})
				.catch((error) => {
					if (id.length > 0) {
						console.log(error);
					} else {
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
					}
				});
		} else {
			setLoading(false);
		}
	}, []);
	const handleCreate = (e) => {
		e.preventDefault();
		if (edit) {
			var body = {
				id: id,
				clientId: clientId,
				hasDynamicTutorial: hasDynamicTutorial,
				useNewCheckout: useNewCheckout,
				useOnlyLocalAssets: useOnlyLocalAssets,
				useLegacyCatalogAPI: useLegacyCatalogAPI,
				useLegacyCommerceAPI: useLegacyCommerceAPI,
				useLegacyMarketingAPI: useLegacyMarketingAPI,
				useLegacyRetailerAPI: useLegacyRetailerAPI,
				supportsDebitCard: supportsDebitCard,
				useNewDealerRanking: useNewDealerRanking,
				minimalAppVersion: minimalAppVersion,
				urlBaseLori: urlBaseLori,
				productPageType: productPageType,
				marketplaceName: marketplaceName,
				catalogProductCodeMarketplace: catalogProductCodeMarketplace,
				catalogSkuCodeMarketplace: catalogSkuCodeMarketplace,
				useCatalogProcessor: useCatalogProcessor,
				useGeoCatalog: useGeoCatalog,
				useOrderEditor: useOrderEditor,
				useFilter: useFilter,
				useCatalogMatcher: useCatalogMatcher,
			};
			APIV2.put("management/retailer/configurations/internal/", body)
				.then(async (response) => {
					toast.success("Configurações internas editado com sucesso! 😃");
					window.location.href = "/retailerconfigurations";
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			var body = {
				clientId: clientId,
				hasDynamicTutorial: hasDynamicTutorial,
				useNewCheckout: useNewCheckout,
				useOnlyLocalAssets: useOnlyLocalAssets,
				useLegacyCatalogAPI: useLegacyCatalogAPI,
				useLegacyCommerceAPI: useLegacyCommerceAPI,
				useLegacyMarketingAPI: useLegacyMarketingAPI,
				useLegacyRetailerAPI: useLegacyRetailerAPI,
				supportsDebitCard: supportsDebitCard,
				useNewDealerRanking: useNewDealerRanking,
				minimalAppVersion: minimalAppVersion,
				urlBaseLori: urlBaseLori,
				productPageType: productPageType,
				marketplaceName: marketplaceName,
				catalogProductCodeMarketplace: catalogProductCodeMarketplace,
				catalogSkuCodeMarketplace: catalogSkuCodeMarketplace,
				useCatalogProcessor: useCatalogProcessor,
				useGeoCatalog: useGeoCatalog,
				useOrderEditor: useOrderEditor,
				useFilter: useFilter,
				useCatalogMatcher: useCatalogMatcher,
			};
			APIV2.put("management/retailer/configurations/internal/", body)
				.then((response) => {
					toast.success("Configurações internas editado com sucesso! 😃");
					window.location.href = "/retailerconfigurations";
				})
				.catch((error) => {
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde error."
					);
				});
		}
	};
	const renderScreen = () => {
		return (
			<div className="container dash-content">
				<div id="">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">
								{edit
									? "Editar cofiguração varejista"
									: "Configuração do varejista"}
							</h4>
						</div>
						<div className="card-content">
							<div className="card-body">
								<form className="row" onSubmit={handleCreate}>
									<section className="section-step">
										{!edit ? (
											<div className="row mt-2">
												{/* CLIENT ID */}
												<div className="form-group col-sm-3 flex-column">
													<div className="">
														<label className="mt-2">
															<strong>ClientID</strong>
														</label>
													</div>
													<div className="">
														<input
															type="text"
															className="form-control w-75"
															name="clientId"
															value={clientId}
															onChange={(e) => {
																setClientId(e.target.value);
															}}
														/>
													</div>
												</div>
												{/* MarketplaceName => */}
												<div className="form-group col-sm-3 flex-column">
													<div className="">
														<label className="mt-2">
															<strong>Nome do Marketplace</strong>
														</label>
													</div>
													<div className="">
														<input
															type="text"
															className="form-control w-75"
															name="clientId"
															value={marketplaceName}
															onChange={(e) => {
																setMarketplaceName(e.target.value);
															}}
														/>
													</div>
												</div>
											</div>
										) : null}
										<div className="row">
											{/* RETAILER LEGADA */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>API Retailer legada</strong>
													</label>
												</div>
												<div className="">
													<label className="switch ">
														<input
															type="checkbox"
															name="useLegacyRetailerAPI"
															checked={useLegacyRetailerAPI}
															value={useLegacyRetailerAPI}
															onChange={(e) => {
																setUseLegacyRetailerAPI(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* CATALOG LEGADA */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>API Catalog legada</strong>
													</label>
												</div>
												<div className="">
													<label className="switch ">
														<input
															type="checkbox"
															name="useLegacyCatalogAPI"
															checked={useLegacyCatalogAPI}
															onChange={(e) => {
																setUseLegacyCatalogAPI(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* COMMERCE LEGADA */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>API Commerce legada</strong>
													</label>
												</div>
												<div className="">
													<label className="switch ">
														<input
															type="checkbox"
															name="useLegacyCommerceAPI"
															checked={useLegacyCommerceAPI}
															onChange={(e) => {
																setUseLegacyCommerceAPI(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* MARKETING LEGADA */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>API Marketing legada</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useLegacyMarketingAPI"
															checked={useLegacyMarketingAPI}
															onChange={(e) => {
																setUseLegacyMarketingAPI(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
										</div>

										<div className="row mt-2">
											{/* TUTORIAL DINÂMICO */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza tutorial dinâmico?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch ">
														<input
															id="Status"
															type="checkbox"
															name="hasDynamicTutorial"
															checked={hasDynamicTutorial}
															onChange={(e) => {
																setHasDynamicTutorial(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* ASSETS DINÂMICO */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza assets dinâmicos?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch ">
														<input
															id="Status"
															type="checkbox"
															name="useOnlyLocalAssets"
															checked={useOnlyLocalAssets}
															onChange={(e) => {
																setUseOnlyLocalAssets(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* NOVO CHECKOUT */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza o novo checkout?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useNewCheckout"
															checked={useNewCheckout}
															onChange={(e) => {
																setUseNewCheckout(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* useOrderEditor */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Editar Pedido?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useOrderEditor"
															checked={useOrderEditor}
															onChange={(e) => {
																setUseOrderEditor(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
										</div>

										<div className="row mt-2">
											{/* CARTÃO DEBITO */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Suporta venda com cartão de débito?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch ">
														<input
															type="checkbox"
															name="supportsDebitCard"
															checked={supportsDebitCard}
															onChange={(e) => {
																setSupportsDebitCard(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* RANKING VENDEDOR */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza novo Ranking de vendedor?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useNewDealerRanking"
															checked={useNewDealerRanking}
															onChange={(e) => {
																setUseNewDealerRanking(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* UseCatalogProcessor */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza CatalogProcessor?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useCatalogProcessor"
															checked={useCatalogProcessor}
															onChange={(e) => {
																setUseCatalogProcessor(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											{/* UseGeoCatalog */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza GeoCatalog?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useGeoCatalog"
															checked={useGeoCatalog}
															onChange={(e) => {
																setUseGeoCatalog(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
										</div>
										<div className="row mt-2">
											{/* UseFilter */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Utiliza Filtro?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useFilter"
															checked={useFilter}
															onChange={(e) => {
																setUseFilter(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>

											{/* Mapeamento de Catálogo */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Mapeamento de Catálogo?</strong>
													</label>
												</div>
												<div className="">
													<label className="switch">
														<input
															type="checkbox"
															name="useCatalogMatcher"
															checked={useCatalogMatcher}
															onChange={(e) => {
																setUseCatalogMatcher(e.target.checked);
															}}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
										</div>

										<div className="row mt-2">
											{/* BASE URL */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>URL base da Lori</strong>
													</label>
												</div>
												<div className="">
													<input
														type="text"
														className="form-control"
														name="urlBaseLori"
														value={urlBaseLori}
														onChange={(e) => {
															setUrlBaseLori(e.target.value);
														}}
													/>
												</div>
											</div>
											{/* DETALHE DO PRODUTO */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Tela detalhe de produto</strong>
													</label>
												</div>
												<div className="">
													<div className="input-group-prepend">
														<label className="w-75">
															<select
																className="custom-select btn-light"
																name="productPageType"
																value={productPageType}
																onChange={(e) => {
																	setProductPageType(parseInt(e.target.value));
																}}
															>
																<option defaultValue="selected">
																	Selecione
																</option>
																<option value={0}>Clássico</option>
																<option value={1}>Moda</option>
																<option value={2}>Luxo</option>
															</select>
														</label>
													</div>
												</div>
											</div>
											{/* código do produto marketplace  */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Código do produto marketplace</strong>
													</label>
												</div>
												<div className="">
													<div className="input-group-prepend">
														<label className="w-75">
															<select
																className="custom-select btn-light"
																name="catalogProductCodeMarketplace"
																value={catalogProductCodeMarketplace}
																onChange={(e) => {
																	setCatalogProductCodeMarketplace(
																		parseInt(e.target.value)
																	);
																}}
															>
																<option defaultValue="selected">
																	Selecione
																</option>
																<option value={0}>Código</option>
																<option value={1}>Código Auxiliar</option>
																<option value={2}>Código de Referência</option>
															</select>
														</label>
													</div>
												</div>
											</div>
											{/* código do sku do marketplace  */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Código do sku marketplace</strong>
													</label>
												</div>
												<div className="">
													<div className="input-group-prepend">
														<label className="w-75">
															<select
																className="custom-select btn-light"
																name="catalogSkuCodeMarketplace"
																value={catalogSkuCodeMarketplace}
																onChange={(e) => {
																	setCatalogSkuCodeMarketplace(
																		parseInt(e.target.value)
																	);
																}}
															>
																<option defaultValue="selected">
																	Selecione
																</option>
																<option value={0}>Código</option>
																<option value={1}>Código Auxiliar</option>
																<option value={3}>EAN</option>
															</select>
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="row mt-2">
											{/* VERSÃO APP */}
											<div className="form-group col-sm-3 flex-column">
												<div className="">
													<label className="mt-2">
														<strong>Versão mínima suportada do APP</strong>
													</label>
												</div>
												<div className="">
													<input
														type="text"
														className="form-control w-50"
														name="minimalAppVersion"
														value={minimalAppVersion}
														onChange={(e) => {
															setMinimalAppVersion(e.target.value);
														}}
													/>
												</div>
											</div>
										</div>
									</section>

									<div className="form-group col-12">
										<button
											className="btn btn-light mr-1"
											onClick={() =>
												(window.location.href = "/retailerconfigurations")
											}
										>
											Cancelar
										</button>
										<button className="btn btn-success" type="submit">
											Salvar
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return (
		<>
			{loading ? (
				<div className="container dash-content">
					<div className="card">
						<Loading />
					</div>
				</div>
			) : (
				renderScreen()
			)}
		</>
	);
}
