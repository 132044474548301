import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const type = "Image"; // Need to pass which type element can be draggable

const Image = ({ image, index, moveImage, removeImage }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: type,
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            moveImage(dragIndex, hoverIndex);
            // Update the index for dragged item directly to avoid flickering when half dragged
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type, id: image.id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    // initialize drag and drop into the element
    drag(drop(ref));

    return (
        <div ref={ref} className='upload-img ' style={{ backgroundImage: 'url(' + image.imageUrl + ')'}}>
            <button style={{ marginLeft: '100px' }} type='button' onClick={() => removeImage(index)} className="close-img-carrousel"></button>
            {/*   <button style={{ marginLeft: '100px' }} type='button' onClick={() => this.removeItemCarrousel(image.id)} className="close-img-carrousel"></button> */}
        </div>
    )
}

const ImageList = ({ images, moveImage, removeImage }) => {
    const renderImage = (image, index) => {
        return (
            <Image
                image={image}
                index={index}
                key={`${index}-image`}
                moveImage={moveImage}
                removeImage={removeImage}
            />
        );
    };

    return images.length > 0 ? images.map(renderImage) : null;
};

export default ImageList;
