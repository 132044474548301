import React, { Component, useState, useEffect } from 'react';
import { API } from '../../../API';
import EditorHtml from '../../../assets/EditorHtml';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading'



function TermsOfUse(props) {
	const [loading, setLoading] = useState(true)
	const [content, setContent] = useState('')

	useEffect(() => {
		API.get('api/userterms/')
			.then(async response => {
				if (response.data == null) {
					setLoading(false)
				} else {
					setContent(response.data.content)
					setLoading(false)
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
				setLoading(false)
			})
	}, [])

	const handleChange = e => {
		setContent(e)
	}

	const onSend = () => {
		setLoading(true)

		let body = { content: content }
		//Consulta materiais disponíveis a partir do token do usuário.
		API.put('api/userterms/', body)
			.then(() => {
				setLoading(false)
				toast.success('Os termos de uso foram atualizados com sucesso! 😃');
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const renderScreen = () => {
		return (
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Termos de Uso</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<EditorHtml data={content} change={handleChange} />
						<div className="d-flex justify-content-between mt-3">
							<button className='btn btn-light btn-icon-l outline'
								data-container="body"
								data-toggle="popover"
								title="Não esqueça"
								data-placement="top"
								data-content="Valide o conteúdo previamente junto ao jurídico de sua empresa."
								onClick={() => window.open('https://loriprd.blob.core.windows.net/lori-static/841699b5-5f05-4236-ac42-09dc65e89799.docx')}>
								<i className="material-icons"
									style={{ fontSize: '24', float: 'left', marginRight: '10px' }}>
									vertical_align_bottom
		            </i>
		            Fazer download do modelo
		          </button>
							<button className='btn btn-success' style={{ width: '150px' }} onClick={onSend}>Salvar</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			{
				loading ? <Loading /> : renderScreen()
			}
		</>
	)

}

export default TermsOfUse;
