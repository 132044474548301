import React, { Component } from 'react';
import DropImage from '../../../components/DropImage';
import { connect } from 'react-redux'
import appActions from '../../../appActions';
import {API} from '../../../API';
import Loading from '../../../components/Loading'
import { toast } from 'react-toastify';
export class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: '',
      logoEscuraUrl: '',
      iconeUrl: '',
      loading:true,
      logo: false,
    };
    this.recoveryStateLogo = this.recoveryStateLogo.bind(this);
    this.recoveryStateLogoEscura = this.recoveryStateLogoEscura.bind(this);
    this.recoveryStateIcone = this.recoveryStateIcone.bind(this);
  }

    //Função para recuperar url do DropImage
    recoveryStateLogo(item) {
        var type = item.split('.').pop()
        type.toUpperCase()
        if(type === 'jpg' || type === 'jpeg') {
            toast.error('Imagem precisa ser png. 😃')
        }else {
            var image = new Image(); 
            image.src = item;
            var _this = this
            image.onload = function() {
                if(this.width > 1023) {
                    _this.setState({ logoUrl: item });
                }else {
                    toast.error('Imagem precisa ser maior 1024px de largura. 😃')
                }
            }
        } 
    }
    recoveryStateLogoEscura(item) {
        var type = item.split('.').pop()
        type.toUpperCase()
        if(type === 'jpg' || type === 'jpeg') {
            toast.error('Imagem precisa ser png. 😃')
        }else {
            var image = new Image(); 
            image.src = item;
            var _this = this
            image.onload = function() {
                if(this.width > 1023) {
                    _this.setState({ logoEscuraUrl: item })
                }else {
                    toast.error('Imagem precisa ser maior 1024px de largura. 😃')
                }
            }
        }
    }
    //icone com 1024X1024
    recoveryStateIcone(item) {
        var type = item.split('.').pop()
        type.toUpperCase()
        if(type === 'png') {
            toast.error('Imagem precisa ser jpeg ou jpg. 😃')
        }else {
            var image = new Image(); 
            image.src = item;
            var _this = this
            image.onload = function() {
                if(this.width === 1024 && this.height === 1024) {
                    _this.setState({ iconeUrl: item });
                }else {
                    toast.error('Imagem precisa ser 1024 x 1024px. 😃')
                }
            }
        }
    }
    componentDidUpdate(){
        this.setFirstData();
    }
    componentDidMount() {
        this.setNextRoute()
    }
    setFirstData(){
        //Configs API PUT DATA
        let data = {
            logoHorizontalPrimaryImageUrl: this.state.logoUrl,
            logoHorizontalSecondaryImageUrl: this.state.logoEscuraUrl,
            brandIconUrl: this.state.iconeUrl
    }
    //Next Route
    this.props.dispatch(appActions.setNextRoute('/setup/brand/splashscreen'))
    //Passa para o Redux os dados para put da API
    //ajustar o TRUe para um state (Se os campos foram preenchidos)
    this.props.dispatch(appActions.sendData(data , true, 'api/autosetup/brand/images/', 'content'))
    }
    setNextRoute() {
        //Passa para o Redux o proximo passo do autosetup
        this.props.dispatch(appActions.setNextRoute('/setup/brand/splashscreen'))
    }
    componentWillMount(){
        
        var clientId = localStorage.getItem('client_id');
        //Consulta materiais disponíveis a partir do token do usuário.
        API.get('api/autosetup/retailers/')
        .then(async response => {
            // If request is good...
            await this.setState({ 
            logoUrl: response.data.logoHorizontalPrimaryImageUrl, 
            logoEscuraUrl:response.data.logoHorizontalSecondaryImageUrl,
            iconeUrl: response.data.brandIconUrl,
            loading:false
            })
            this.forceUpdate();
            this.setFirstData();
        })
        .catch((error) => {
            console.log(error)
        })
    }

    render() {
        if(this.state.loading){
        return (
            <Loading/>
        )
        }
        else{
        return (
        this.renderScreen()
        )
        }
    
    }
    renderScreen() {

        return (
            <div className="row logos d-flex align-items-center">
            <div className="col">
                <h1 className="text-center">Imagens da sua marca</h1>
                <div className="container sm">
                <div className="row mt-20" >
                    {/* RecoveryStateLogo é uma fuction setState 
                    criada no pai e passada para o filho, que retorna a url da imagem carregada. */}
                    <div className="col-6">
                        <DropImage style={{ margin: 'auto' }} previewImage={this.state.logoUrl} recoveryStateLogo={this.recoveryStateLogo} height={'90px'} width={'300px'} background={'#fff'} />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                    <div>
                        <h4><b>Logo horizontal</b></h4>
                        <p>para fundos claros</p>
                    </div>
                    </div>
                </div>
                <div className="row mt-20" >
                    <div className="col-6">
                    <DropImage style={{ margin: 'auto' }} previewImage={this.state.logoEscuraUrl} recoveryStateLogo={this.recoveryStateLogoEscura} height={'90px'} width={'300px'} background={'#4A4A4A'} colorText={'#80868B'} />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                    <div>
                        <h4><b>Logo horizontal</b></h4>
                        <p>para fundos escuros</p>
                    </div>
                    </div>
                </div>
                <div className="row mt-20" >
                    <div className="col-6">
                    <DropImage style={{ margin: 'auto' }} previewImage={this.state.iconeUrl} recoveryStateLogo={this.recoveryStateIcone} height={'300px'} width={'300px'} background={'#fff'}  />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                    <div>
                        <h4><b>Ícone da empresa</b></h4>
                        <p>Tamanho 1024x1024px</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        )
    }
};

export default connect(store => ({ text: store.text }))(Images);
