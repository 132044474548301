import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import ProgressOrders from "./components/ProgressOrders";
import OrderEditing from "./OrderEditing";
import { useOrders } from "./providers/OrdersContext";
import moment from "moment";

export default function OrderDetails({ id, historic }) {
	const {
		loading,
		order,
		buyer,
		seller,
		delivery,
		paymentInfo,
		newPaymentStatus,
		paymentMethodType,
		paymentStatus,
		fraudStatus,
		getOrders,
		freight,
		role,
	} = useOrders();

	useEffect(() => {
		getOrders(id);
	}, []);

	const skeleton = () => {
		const skele = [0, 1, 2, 4, 5, 6];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={400}
				height={20}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="5" y="25" rx="3" ry="3" width="400" height="100" />
				<rect x="410" y="26" rx="3" ry="3" width="400" height="100" />
			</ContentLoader>
		));
	};

	return (
		<>
			<div className="detail-container">
				<div className="card box-one">
					{loading ? (
						skeleton()
					) : (
						<>
							<div className="box-title">
								<h2 className="card-title">PEDIDO</h2>
								<label>nº {order.friendlyCode}</label>
							</div>
							<span className="card-subtitle">
								<strong>Data de criação: </strong>
								{order.createDate !== "" &&
									moment(order.createDate).format("DD/MM/YYYY HH:mm")}
							</span>
							<span className="card-subtitle">
								<strong>Última atualização: </strong>{" "}
								{order.lastUpdate !== "" &&
									moment(order.lastUpdate).format("DD/MM/YYYY HH:mm")}
							</span>
							<span className="card-subtitle">
								<strong>Código de integração: </strong>
								{order.externalId !== "" && order.externalId}
							</span>
							{role === 8 ? (
								<span className="card-subtitle">
									<strong>Tipo de envio: </strong>
									{freight.description}
								</span>
							) : (
								<span className="card-subtitle">
									<strong>Modalidade: </strong>
									{order.isDelivery === false
										? "Retire na Loja"
										: "Receba em Casa"}
								</span>
							)}
							<span className="card-subtitle">
								<strong>Canal de origem: </strong>
								{order.channel !== "" && order.channel}
							</span>
							<span className="card-subtitle">
								<strong>Código do vendedor:</strong>{" "}
								{order.dealerCode !== "" && order.dealerCode}{" "}
							</span>
						</>
					)}
				</div>
				<div className="card box-two">
					<ProgressOrders historic={historic} id={id} />
				</div>
			</div>

			<div className="detail-container">
				<div className="card box-one">
					{loading ? (
						skeleton()
					) : (
						<>
							<div className="box-title">
								<h2 className="card-title">DADOS DO CLIENTE</h2>
							</div>
							<span className="card-subtitle">
								<strong>{buyer.fullName}</strong>
							</span>
							<span className="card-subtitle">
								<strong>CPF: </strong>
								{buyer.cpf}
							</span>
							<span className="card-subtitle">
								<strong>E-mail:&nbsp;&nbsp;</strong>
								{role === 8 ? `****@******.com` : buyer.email}
							</span>

							<span className="card-subtitle">
								<strong>Telefone:&nbsp;&nbsp;</strong>
								{role === 8 ? `(**)*****-*****` : buyer.phoneNumber}
							</span>
						</>
					)}
				</div>
				<div className="card box-one">
					{loading ? (
						skeleton()
					) : (
						<>
							<div className="box-title">
								<h2 className="card-title">
									{order.isDelivery === false ? "RETIRADA" : "ENTREGA"}
								</h2>
							</div>
							<span className="card-subtitle">
								<strong>
									{order.isDelivery === false
										? seller.fullName
										: buyer.fullName}
								</strong>
							</span>
							<span className="card-subtitle">
								{order.isDelivery === false
									? seller.address &&
									  `${
											seller.address.street == null ? "" : seller.address.street
									  }
								${seller.address.number == null ? "" : seller.address.number}
								${seller.address.complement == null ? "" : seller.address.complement}`
									: `${delivery.street == null ? "" : delivery.street}
								${delivery.number == null ? "" : delivery.number}
								${delivery.complement == null ? "" : delivery.complement}`}
							</span>
							<span className="card-subtitle">
								{order.isDelivery === false
									? seller.address &&
									  `${
											seller.address.neighborhood == null
												? ""
												: seller.address.neighborhood
									  }
								${seller.address.city == null ? "" : seller.address.city}
								${seller.address.state == null ? "" : seller.address.state}`
									: `${
											delivery.neighborhood == null ? "" : delivery.neighborhood
									  }
								${delivery.city == null ? "" : delivery.city}
								${delivery.state == null ? "" : delivery.state}`}
							</span>
							<span className="card-subtitle">
								{order.isDelivery === false
									? seller.address &&
									  `${seller.address.cep == null ? "" : seller.address.cep} `
									: `${delivery.cep == null ? "" : delivery.cep} `}
							</span>
						</>
					)}
				</div>

				<div className="card box-one">
					{loading ? (
						skeleton()
					) : (
						<>
							<div className="box-title m-0 p-0">
								<h2 className="card-title">PAGAMENTO</h2>
								<span
									className={`payment-order ${newPaymentStatus.color}`}
								></span>
								<div className={`tooltipOrders ${newPaymentStatus.color}`}>
									{newPaymentStatus.title}
									<span>{newPaymentStatus.name}</span>
								</div>
							</div>
							{
								<span className="text-orders">
									<strong>Método de pagamento: </strong> {paymentMethodType}
								</span>
							}
							{paymentInfo.paymentMethod != null && (
								<span className="text-orders">
									{paymentInfo.paymentMethod.info}
								</span>
							)}
							{paymentInfo.quantityInstallments != null && (
								<span className="text-orders">
									<strong>Parcelas: </strong>
									{paymentInfo.quantityInstallments}
								</span>
							)}
							{paymentInfo.acquirerMessage != null && (
								<span className="text-orders">
									<strong>Mensagem do adquirente: </strong>
									{paymentInfo.acquirerMessage}
								</span>
							)}
							<span className="text-orders">
								<strong>Status anti-fraude: </strong>
								{fraudStatus}
							</span>
							{paymentInfo.acquirerApprovalCode != null && (
								<span className="text-orders">
									<strong>Código de aprovação: </strong>
									{paymentInfo.acquirerApprovalCode}
								</span>
							)}
							{paymentInfo.nsu != null && (
								<span className="text-orders">
									<strong>NSU: </strong>
									{paymentInfo.nsu}
								</span>
							)}
						</>
					)}
				</div>
			</div>

			<div className="detail-container">
				<div className="card box-three">
					<div className="box-container-orders">
						<div className="box-header">
							<div className="box-img-orders ml-3">
								{localStorage.getItem("primaryLogo") !== "undefined" && (
									<img
										src={localStorage.getItem("primaryLogo")}
										alt="imageSeller"
										className="rounded"
									/>
								)}
							</div>
							<div className="d-flex justify-content-center align-items-start flex-column mt-3 pl-1">
								<h2 className="card-title" style={{ fontSize: "20px" }}>
									PEDIDO
								</h2>
								<span>
									Pedido nº <strong>{order.friendlyCode}</strong>
								</span>
								<span>
									Data do pedido:
									<strong>
										{loading === true
											? ""
											: moment(order.createDate).format("DD/MM/YYYY HH:mm")}
									</strong>
								</span>
							</div>
						</div>
						<div className="box-body">
							<div className="">
								{loading ? (
									skeleton()
								) : (
									<>
										<p className="m-0 pb-1">Pedido de</p>
										<h2 className="card-title my-0 py-0">{buyer.fullName}</h2>
										<span className="py-1">{buyer.cpf && buyer.cpf}</span>
										{/* <span className="py-1">{role === 8 ? `****@******.com` : buyer.email}</span> */}
										<span className="py-1">
											{buyer.address &&
												` ${
													buyer.address.street == null
														? ""
														: buyer.address.street
												}
											${buyer.address.number == null ? "" : buyer.address.number}
											${buyer.address.complement == null ? "" : buyer.address.complement}
										`}
										</span>
										<span className="py-1">
											{buyer.address &&
												`	${
													buyer.address.neighborhood == null
														? ""
														: buyer.address.neighborhood
												}
											${buyer.address.city == null ? "" : buyer.address.city}
											${buyer.address.state == null ? "" : buyer.address.state}
										`}
										</span>
										<span className="py-1">
											{buyer.address &&
												`${
													buyer.address == null
														? ""
														: "CEP: " + buyer.address.cep
												}`}
										</span>
									</>
								)}
							</div>
							<div className="">
								{loading ? (
									skeleton()
								) : (
									<>
										<p className="m-0 pb-1">Pedido para</p>
										<h2 className="card-title my-0 py-0">{seller.fullName}</h2>
										<span className="py-1">{seller.cnpj}</span>
										<span className="py-1">{seller.email}</span>
										<span className="py-1">
											{seller.address &&
												`	${
													seller.address.street == null
														? ""
														: seller.address.street
												}
											${seller.address.number == null ? "" : seller.address.number}
											${seller.address.complement == null ? "" : seller.address.complement}
										`}
										</span>
										<span className="py-1">
											{seller.address &&
												`	${
													seller.address.neighborhood == null
														? ""
														: seller.address.neighborhood
												}
											${seller.address.city == null ? "" : seller.address.city}
											${seller.address.state == null ? "" : seller.address.state}
										`}
										</span>
										<span className="py-1">
											{seller.address &&
												`${
													seller.address.cep == null ? "" : seller.address.cep
												} `}
										</span>
									</>
								)}
							</div>
						</div>
					</div>
					<OrderEditing />
				</div>
			</div>
		</>
	);
}
