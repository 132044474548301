import React, { useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import OrderDetails from "./OrderDetails";
import OrdersContext from "./providers/OrdersContext";
import OrderHistory from "./OrderHistory";
import OrderInvoice from "./OrderInvoice";
import CreateInvoice from "./CreateInvoice";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Orders(props) {
	
	const [id, setId] = useState(props.match.params.id);
	const [detail, setDetail] = useState(true);
	const [revenues, setRevenues] = useState(false);
	const [historic, setHistoric] = useState(false);
	const [invoice, setInvoice] = useState("");

	const getDetail = () => {
		setDetail(true);
		setRevenues(false);
		setHistoric(false);
	};
	const getRevenues = () => {
		setDetail(false);
		setRevenues(true);
		setHistoric(false);
	};
	const getHistoric = (bool) => {
		setDetail(false);
		setRevenues(false);
		setHistoric(true);
	};

	return (
		<OrdersContext>
			<div className="container">
				<div className="detail">
					<div className="detail-tabs">
						<button onClick={getDetail} className={detail === true ? "actived" : null}>Detalhes</button>
						<button onClick={getRevenues} className={revenues === true ? "actived" : null}>Faturamento</button>
						<button onClick={getHistoric} className={historic === true ? "actived" : null}>Histórico</button>
					</div>
					<CreateInvoice />
				</div>
				{detail === true && <OrderDetails id={id} historic={getHistoric} />}
				{revenues === true && <OrderInvoice  ordersId={id} invoice={invoice} /> }
				{historic === true && <OrderHistory /> }
			</div>
		</OrdersContext>
	);
}
