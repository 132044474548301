import React, { Component, useEffect, useState } from "react";
import { API, APIV2 } from "../../API";
import Initial from "./Initial";
import ResetPassword from "./ResetPassword";
import RoleList from "./RoleList";
import Success from "./Success";
import Loading from "../../components/Loading";
import { connect } from "react-redux";
import appActions from "../../appActions";
import { ToastContainer, toast } from "react-toastify";
import { useConfig } from "../../providers/ConfigProvider";
const qs = require("querystring");

require("dotenv/config");

export default function Index(props) {
	const { setManagerConfigs } = useConfig();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState(false);
	const [dev, setDev] = useState(false);
	const [roles, setRoles] = useState([]);
	const [prevRoute, setPrevRoute] = useState("");
	const [config, setConfig] = useState([]);
	const [credentials, setCredentials] = useState({});
	const [error, setErro] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			props.dispatch(appActions.authUser(localStorage.getItem("token")));
		}
		if (props.match.params == "reset-password") {
			setPassword(true);
		}
		if (
			props.match.params.route != null ||
			props.match.params.route != undefined
		) {
			setPrevRoute(atob(props.match.params.route));
		}
	}, []);

	useEffect(() => {
		setLoading(false);
		localStorage.setItem("config", JSON.stringify(roles));
		if (roles.length === 1) {
			selectId(roles[0]);
		}
	}, [roles]);

	const devOption = () => {
		setDev(!dev);
	};

	const passOption = () => {
		setPassword(!password);
	};

	const login = (email, pass) => {
		setLoading(true);
		setCredentials({ email: email, pass: pass });
		localStorage.clear();
		var body = {
			client_id: "neomode",
			client_secret: "eyJhbGcJhGTSUzI1NiIsImtpZCI6IjBiZTg5Y",
			grant_type: "password",
			username: email,
			password: pass,
		};

		API.post("connect/token", qs.stringify(body), {
			headers: {
				ContentType: "application/x-www-form-urlencoded",
				"Access-Control-Allow-Origin": "*",
			},
		})
			.then(async (response) => {
				localStorage.setItem("token", response.data.access_token);
				getAccess();
			})
			.catch((error) => {
				setErro(true);
				setLoading(false);
				if (
					error.response &&
					error.response.data.error_description ===
						"invalid_username_or_password"
				) {
					toast.error("Usuário ou senha incorretos");
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const getAccess = () => {
		API.get("api/access")
			.then(async (resp) => {
				setRoles(resp.data);
				setLoading(false);
				localStorage.setItem("config", JSON.stringify(resp.data));
			})
			.catch((error) => {
				setErro(true);
				setLoading(false);
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const selectId = (item) => {
		setLoading(true);
		var body = {
			client_id: item.clientId,
			client_secret: "eyJhbGcJhGTSUzI1NiIsImtpZCI6IjBiZTg5Y",
			grant_type: "password",
			username: credentials.email,
			password: credentials.pass,
		};

		API.post("connect/token", qs.stringify(body), {
			headers: { ContentType: "application/x-www-form-urlencoded" },
		})
			.then(async (response) => {
				// If request is good...
				localStorage.setItem("token", response.data.access_token);
				localStorage.setItem("client_id", item.clientId);
				localStorage.setItem("retailerName", item.retailerName);
				localStorage.setItem("role", item.role);
				localStorage.setItem("retailerIconUrl", item.retailerIconUrl);

				setUserParams();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const setUserParams = () => {
		API.get("api/autosetup/retailers/")
			.then((response) => {
				/* check if the edition is available */
				var clientId = response.data.clientId;
				APIV2.get("management/retailer/configurations/internal")
					.then(async (response) => {
						const editOrder = response.data;
						localStorage.setItem("useOrderEditor", editOrder.useOrderEditor);
						localStorage.setItem(
							"UseCatalogProcessor",
							editOrder.useCatalogProcessor
						);
						localStorage.setItem(
							"UseCatalogMatcher",
							editOrder.useCatalogMatcher
						);
						setManagerConfigs(response.data.useCatalogProcessor);
						APIV2.get("management/retailer/configurations/")
							.then(async (response) => {
								setConfig(response.data);
								console.log(new Array(response.data));
								var configurations = config;
								configurations = new Array(response.data);
								localStorage.setItem(
									"configurations",
									JSON.stringify(configurations)
								);
								localStorage.setItem(
									"EnableUserPromotion",
									response.data.enableUserPromotion
								);
								if (prevRoute != null || prevRoute != undefined) {
									window.location.href = prevRoute;
								} else {
									window.location.href = "dashboard";
								}
								//If request is good...
							})
							.catch((error) => {
								console.log(error);
								toast.error(
									"Parece que tivemos um erro... Tente novamente mais tarde."
								);
							});
					})
					.catch((error) => {
						console.log(error);
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
					});
				//localStorage.setItem('barColor', response.data.colorHome);
				localStorage.setItem("primaryColor", response.data.primaryColor);
				localStorage.setItem("secondaryColor", response.data.secondaryColor);
				localStorage.setItem(
					"primaryLogo",
					response.data.logoHorizontalPrimaryImageUrl
				);
				localStorage.setItem(
					"secondaryLogo",
					response.data.logoHorizontalSecondaryImageUrl
				);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<div className="container">
			<div className="d-flex justify-content-center ">
				<div className="login align-items-center">
					<div className="col a">
						<span className="d-none d-md-block">Bem-vindo a</span>
						<br />
						<h2>
							<img src="/images/logo-primary.svg" height="40" />
						</h2>
						<p className="d-none d-md-block">
							<b>Plataforma omnichannel para o varejo </b>
							<br />
							Construa novos canais de venda omnichannel com sistema Clique e
							Retire e transforme sua loja física em um minicentro de
							distribuição
						</p>
					</div>
					<div className="col b d-flex align-items-center">
						{loading ? <Loading /> : null}
						{roles.length > 0 && !loading ? (
							<RoleList role={roles} select={selectId} />
						) : null}
						{!loading && !dev && !password && roles.length === 0 ? (
							<Initial
								pass={passOption}
								dev={devOption}
								login={login}
								error={error}
							/>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
