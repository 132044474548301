import React, { Component, useEffect, useState } from 'react';
import { API, APIV2 } from '../../API';
import 'react-widgets/dist/css/react-widgets.css';
import { toast } from 'react-toastify';
import BasicInfo from './BasicInfo';
import Address from './Address';
import Integration from './Integration';
import Payment from './Payment';
import Loading from '../../components/Loading';
import Modalities from './Modalities';
import SideNav from './components/SideNav';
import WorkHour from './components/WorkHour';




export default function New(props) {
	const [loading, setLoading] = useState(true)
	const [componentValue, setComponentValue] = useState('basic')
	const [store, setStore] = useState({
		isEcommerce: true,
		isActive: true,
		pointSaleId: null,
		gatewayId: null,
		credentialsString: "",
		googlePlacesId: null,
		contactPhone: null,
		whatsappPhoneNumber: null,
		pickUpInstructions: null,
		contactEmail: null,
		isSellerOnline: false,
		isShowSellerInMap: false,
		integrationConfig: {
			orderIntegrationId: "",
			fraudIntegrationId: "",
			paymentIntegrationId: "",
			promotionEngineIntegrationId: null,
			userPromotionIntegrationId: null,
			orderIntegrationCredentials: "",
			fraudIntegrationCredentials: "",
			paymentIntegrationCredentials: "",
			promotionEngineIntegrationCredentials: null,
			userPromotionCredentials: null,

		},
		address: {
			id: "",
			buyerId: null,
			street: "",
			number: "",
			complement: null,
			neighborhood: "",
			cep: "",
			city: "",
			reference: null,
			label: null,
			state: "",
			country: "Brasil",
			isPrimary: false,
			isDeleted: false,
			fullAddress: ""
		},
		addressId: "",
		modalities: [],
		companyName: "",
		cnpj: "",
		fullName: "",
		email: "",
		password: "",
		facebookId: "faceid",
		facebookToken: "facetoken",
		googleId: "googleid",
		googleToken: "googletoken",
		imageUrl: null,
		clientId: localStorage.getItem('client_id'),
		isDeleted: false,
		emailConfirmed: false,
		securityStamp: null,
		phoneNumber: null,
		phoneNumberConfirmed: false,
		processId:""
	})


	useEffect(() => {
		getStore()
	}, [])

	/* useEffect(() => {
		APIV2.get('management/retailer/sellers/' + props.match.params.id)
			.then(async response => {
				setStore(prevState => ({ ...prevState, address: response.data.address }))
				setLoading(false)
				console.log(response.data)
			})
			.catch((error) => {
				console.log(error);
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			})
	}, []) */


	const getStore = () => {
		if (props.match.params.id) {
			APIV2.get('/management/retailer/sellers/' + props.match.params.id)
				.then(async response => {	
					setStore(prevState => ({ ...prevState, address: response.data.address }))
					setStore(response.data)
					setLoading(false)
				})
				.catch((error) => {
					setLoading(false)
					console.log(error);
					toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
				})
		} else {
			setLoading(false)
		}
	}

	const onChangeModalities = (e) => {
		setStore(prevState => ({ ...prevState, address: e }))
	}

	const onChangeAddress = (e) => {
		let name = e.target.name
		let value = e.target.value
		setStore(prevState => ({ ...prevState, address: { ...prevState.address, [name]: value } }))

	}

	const activeComponent = (e) => {
		setComponentValue(e)
	}

	const onChange = (e) => {
		let name = e.target.name
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		setStore(prevState => ({ ...prevState, [name]: value }))
	}

	const renderComponent = () => {
		switch (componentValue) {
			case 'basic':
				return <BasicInfo id={props.match.params.id} change={onChange} save={getStore} store={store} />;
			case 'address':

				return <Address data={store.address} id={props.match.params.id} onChange={onChangeAddress} isEcommerce={store.isEcommerce} store={store} />
			case 'modalities':

				return <Modalities store={store} change={onChangeModalities} id={props.match.params.id}/>
			case 'payment':

				return <Payment id={props.match.params.id} store={store} />
			case 'integration':

				return <Integration store={store} save={getStore} id={props.match.params.id}/>
			case 'workingHour':

				return <WorkHour data={store.integrationConfig} id={props.match.params.id}/>

			default:
				break;
		}
	}

	return (
		<div className="container dash-content" >
			<div className="row">
				<div className="col-4">
					<SideNav isEcommerce={store.isEcommerce} isLoading={loading} activeComponent={activeComponent} id={props.match.params.id} />
				</div>
				<div className='card col-8'>
					{loading ? <Loading /> :
						renderComponent()
					}
				</div>
			</div>
		</div>
	)

};
