import React from "react";
import Button from "./Button";

export default function Modal(props) {
	return (
		<div
			className="modal fade"
			id={props.id}
			tabIndex="-1"
			role="dialog"
			aria-labelledby={props.id}
			aria-hidden="true"
		>
			<div
				className={
					props.large
						? "modal-dialog modal-lg"
						: props.small
						? "modal-dialog modal-sm"
						: "modal-dialog"
				}
				role="document"
			>
				<div className="modal-content">
					<div className="modal-header">
						{props.titleComponent ? (
							props.titleComponent
						) : (
							<h3 className="modal-title">{props.title}</h3>
						)}

						<button
							type="button"
							className="close text-danger"
							data-toggle="modal"
							data-target={`#${props.id}`}
							id="close"
							aria-label="Close"
						>
							<span aria-hidden="true">×</span>
						</button>
					</div>
					{props.content ? (
						<div className="modal-body">{props.content}</div>
					) : null}

					<div className="modal-footer">
						{props.delete ? (
							<Button
								className="btn btn-danger glow"
								type="submit"
								id={props.buttonId}
								// dataDismiss="modal"
								dataToggle="modal"
								dataTarget={`#${props.id}`}
								onClick={() => props.actionDelete()}
							>
								Excluir
							</Button>
						) : null}
						{props.confirm ? (
							<Button
								className="btn btn-success glow"
								type="submit"
								id={props.buttonId}
								dataDismiss="modal"
								onClick={() => props.actionConfirm()}
							>
								Confirmar
							</Button>
						) : null}

						{props.lote ? (
							<Button
								className="btn btn-success glow"
								type="submit"
								id={props.buttonId}
								// dataDismiss="modal"
								dataToggle="modal"
								dataTarget={`#${props.id}`}
								onClick={() => props.actionConfirm()}
							>
								Importar lote
							</Button>
						) : null}

						{props.create ? (
							<Button
								className="btn btn-success glow"
								type="submit"
								disable={props.disable}
								loading={props.disable}
								id={props.buttonId}
								dataDismiss="modal"
								onClick={() => props.actionCreate()}
							>
								Criar
							</Button>
						) : null}
						{props.edit ? (
							<Button
								className="btn btn-success glow"
								type="submit"
								id={props.buttonId}
								dataDismiss="modal"
								onClick={() => props.actionEdit()}
							>
								Editar
							</Button>
						) : null}
						{props.banners ? (
							<Button className="btn btn-success glow" dataDismiss="modal">
								Salvar
							</Button>
						) : null}
						{!props.import && (
							<Button
								className="btn btn-light ml-2"
								dataToggle="modal"
								dataTarget={`#${props.id}`}
							>
								Cancelar
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
