import React from 'react';

// import { Container } from './styles';

function Contact() {
  return (
    <>
      {/* <div className="container mb-2">
        <div class="row">
          <div className="col-12">
            <p><strong>INFORMAÇÕES</strong></p>
          </div>
          <div className="col-6">
            <label className="form-check-label" htmlFor="carrousel-title">
              <strong>
                Tipo de contato
              </strong>
            </label>
            <div className="input-group col-sm-12 p-0 m-0">
              <div className="input-group-prepend">
                <label htmlFor="inputGroupSelect01">
                  <select className="custom-select btn-light" id="inputGroupSelect01"
                  //  defaultValue={this.state.action} value={this.state.action} onChange={this.changeAction}
                  >
                    <option value="none">Não fazer nada</option>
                    <option value="open_product">Ir para o produto</option>
                    <option value="open_category">Ir para a categoria</option>
                    <option value="open_url">Abrir URL fora do APP</option>
                    <option value="open_tag">Ir para a coleção</option>
                    <option value="open_webview">Abrir URL dentro do APP</option>
                    <option value="open_promotion">Ir para a promoção</option> 
                    <option value="zoom">Zoom</option>
                  </select>
                </label>
              </div>

              {this.state.action === "open_webview" ? <input type="text" className="form-control" placeholder='Abrir URL dentro do APP' value={this.state.open_webview} onChange={this.handleChangeWebview} /> : null}
                                                    {this.state.action === "open_url" ? <input type="text" className="form-control" placeholder='Abrir URL fora do APP' value={this.state.open_url} onChange={this.handleChangeUrl} /> : null}
                                                    {this.state.action === "open_category" ? <input type="text" className="form-control" placeholder='Categoria' defaultValue={this.state.open_category == null ? null : 1 + ' Categoria'} data-toggle="modal" data-target="#selectCategories" /> : null}
                                                    {this.state.action === "open_product" ? <input type="text" className="form-control" placeholder='Produto' value={this.state.open_product == null ? null : 1 + ' Produto'} data-toggle="modal" data-target="#selectProduct" /> : null}
                                                    {this.state.action === "open_tag" ?
                                                        <select className="form-control" placeholder="Coleções" name='referenceId' defaultValue={this.state.open_tag} value={this.state.open_tag} onChange={this.handleTags}>
                                                            {this.state.collectionList.map(tag =>
                                                                <option key={tag.id} value={tag.url}>{tag.name}</option>
                                                            )}
                                                        </select> : null}

                                                    {this.state.action === "zoom" || this.state.action === "none" || this.state.referenceId == null ? null : null}

            </div>
          </div>
          <div className="col-6">
            <label className="form-check-label" htmlFor="carrousel-title">
              <strong>
                Endereço
              </strong>
            </label>
            <input type="text" className="form-control" name="carrousel-title" />
          </div>
          <div className="col-7 row">
            <div className="col-12 m-0 p-0">
            <label className="form-check-label" htmlFor="carrousel-title">
              <strong>
                Escolha um ícone
              </strong>
            </label>
            </div>
            <div className="col-12 m-0 p-0">
            <button type="button" class="btn btn-outline-primary m-1">P</button>
            <button type="button" class="btn btn-outline-primary m-1">P</button>
            <button type="button" class="btn btn-outline-primary m-1">P</button>
            <button type="button" class="btn btn-outline-primary m-1">P</button>
            </div>
          </div>
          <div className="col-5">
            <label className="form-check-label" htmlFor="carrousel-title">
              <strong>
                Cor do botão
              </strong>
            </label>
            <input type="text" className="form-control" name="carrousel-title" />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Contact;