import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { APIV2 } from "../../API";
import List from "../../components/List/List";
import { toast } from "react-toastify";
import Empty from "../../components/Empty";
import { fromEvent } from "rxjs";
import { map, debounceTime } from "rxjs/operators";
import ContentLoader from "react-content-loader";
import FilterOrders from "./components/FilterOrders";
import moment from "moment";
import MultDatePiker from "../../components/MultDatePiker";
import { onMessageListener } from "../../push-notification";
import { useOrders } from "./providers/OrdersContext";
import FilterOrdersModal from "./components/FilterOrdersModal";
import axios from "axios";

import boleto from "../../assets/payments-flags/icon-pag-boleto.svg";
import americanexpress from "../../assets/payments-flags/icon-americanexpress-24px.svg";
import mastercard from "../../assets/payments-flags/icon-master-24px.svg";
import creditcard from "../../assets/payments-flags/credit_card.svg";
import visa from "../../assets/payments-flags/icon-visa-24px.svg";
import receive from "../../assets/payments-flags/icon-pag-entrega.svg";
import paypal from "../../assets/payments-flags/icon-paypal-24px.svg";
import pix from "../../assets/payments-flags/icon-pag-pix.svg";
import withdrawal from "../../assets/payments-flags/icon-pag-retirada.svg";
import voucher from "../../assets/payments-flags/icon-pag-voucher.svg";
import cardMachine from "../../assets/payments-flags/icon-pag-maquininha.svg";
import awaitMethod from "../../assets/payments-flags/update_black_24dp.svg";

export default function OrdersList(props) {
	const { openModal, role } = useOrders();
	const columsList = [
		{
			name: "CLIENTE",
			selector: "name",
			sortable: false,
			grow: 8,
			cell: (row) => (
				<div className="order-list-client">
					<p className="order-list-client-name">{row?.fullName}</p>
					<p>
						CPF:{" "}
						{row?.cpfBuyer?.replace(
							/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
							"$1.$2.$3-$4"
						)}
					</p>
					<p>
						<small>
							Última Atualização:{" "}
							{moment(row?.lastUpdate).format("DD/MM/YYYY - HH:mm:ss")}
						</small>
					</p>
				</div>
			),
		},
		{
			name: "Nº DE PEDIDO",
			selector: "name",
			sortable: false,
			grow: 4,
			cell: (row) => (
				<div className="order-list-order">
					<p className="order-list-order-code">{row?.friendlyCode}</p>
					<p>{moment(row?.createDate).format("DD/MM/YYYY")}</p>
					<p>{moment(row?.createDate).format("HH:mm:ss")}</p>
				</div>
			),
		},
		{
			name: "STATUS",
			selector: "name",
			color: "#01BFB5",
			sortable: false,
			center: true,
			grow: 2,
			cell: (row) => (
				<div className={`order-list-status order-list-status-${row?.status}`}>
					<i className="material-icons-outlined">
						{row?.status === "Finalized"
							? "check_circle_outline"
							: row?.status === "InProcessing"
							? "pending"
							: row?.status === "Canceled"
							? "highlight_off"
							: row?.status === "Error"
							? "more_horiz"
							: row?.status === "None"
							? "add_circle_outline"
							: "Não informado"}
					</i>
					<p>
						{row?.status === "Finalized"
							? "Pedido Finalizado"
							: row?.status === "InProcessing"
							? "Pedido em Andamento"
							: row?.status === "Canceled"
							? "Pedido Cancelado"
							: row?.status === "Error"
							? "Pedido com erro"
							: row?.status === "None"
							? "Não informado"
							: "Não informado"}
					</p>
				</div>
			),
		},
		{
			name: "PRODUTOS",
			selector: "name",
			color: "#01BFB5",
			sortable: false,
			center: true,
			grow: 3,
			cell: (row) => (
				<div className="order-list-products">
					<span>
						<p>{row?.quantity}</p>
					</span>
				</div>
			),
		},
		(localStorage.getItem("role") !== "8" ||
			localStorage.getItem("role") !== "3") && {
			name: "MODALIDADE",
			selector: "name",
			sortable: false,
			center: true,
			grow: 4,
			cell: (row) => (
				<div className="order-list-modalities">
					<p className="order-list-modalities-title">{row?.modality?.name}</p>
					{/* <p><small>{row.isDelivery === false ? "Retire em Loja" : "Receba em Casa"}</small></p> */}
				</div>
			),
		},
		(localStorage.getItem("role") === "8" ||
			localStorage.getItem("role") === "3") && {
			name: "TIPO DE ENVIO",
			selector: "sendType",
			sortable: false,
			center: true,
			grow: 4,
			cell: (row) => (
				<div className="order-list-modalities">
					<p className="order-list-modalities-title">
						{row?.freight?.description}
					</p>
					{row?.tracking?.carrierName && <p>{row?.tracking?.carrierName}</p>}
				</div>
			),
		},
		{
			name: "ORIGEM",
			selector: "name",
			sortable: false,
			// center: true,
			grow: 4,
			cell: (row) => (
				<div className="order-list-origin">
					<p className="order-list-origin-title">{row?.salePointName}</p>
					{row?.channel && (
						<p>
							<small>{row?.channel}</small>
						</p>
					)}
				</div>
			),
		},
		{
			name: "VALOR",
			selector: "name",
			sortable: false,
			center: false,
			grow: 2,
			cell: (row) => (
				<div className="order-list-total">
					{row?.totalAmount.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})}
				</div>
			),
		},
		{
			name: "PAGAMENTO",
			selector: "name",
			sortable: false,
			center: true,
			grow: 4,

			cell: (row) => (
				<div className="order-list-payment">
					<span>
						<img
							src={paymentIcon(
								row?.paymentInfo?.paymentMethod?.type,
								row?.paymentInfo?.paymentMethod?.brand
							)}
							alt=""
						/>
					</span>
					<p
						style={{
							textOverflow: "ellipsis",
							// overflowX: "hidden",
							width: "100px",
						}}
					>
						{statusPayment(
							row?.paymentInfo?.paymentStatus,
							row?.paymentInfo?.paymentMethod?.type
						)}
					</p>
				</div>
			),
		},
		{
			name: "",
			sortable: true,
			width: "100px",
			center: true,
			grow: 1,
			cell: (row) => (
				<a
					className="order-list-details"
					onClick={() =>
						localStorage.setItem("FRIENDLYCODE", row?.friendlyCode)
					}
					href={`/orders/${page}/detail/${row?.id}`}
				>
					Ver detalhes
					{/* <span class="material-icons-outlined">more_vert</span> */}
				</a>
			),
		},
	];

	const [load, setLoad] = useState(false);
	const [output, setOutput] = useState("");
	const history = useHistory();
	const [dates, setDates] = useState([null, null]);
	const [filtered, setFiltered] = useState([]);
	const [startDate, setStartDate] = useState(
		props.since == null ? null : props.since
	);
	const [endDate, setEndDate] = useState(
		props.until == null ? null : props.until
	);
	const [clientId, setClientId] = useState(localStorage.getItem("client_id"));
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [maxCount, setMaxCount] = useState([]);
	const [page, setPage] = useState(props.page);
	const [friendlyCode, setFriendlyCode] = useState(null);
	const [buyerCpf, setBuyerCpf] = useState(null);
	const [buyerName, setBuyerName] = useState(null);
	const [type, setType] = useState(3);
	const [query, setQuery] = useState("");
	const [filterBackground, setFilterBackGround] = useState(false);
	const [status, setStatus] = useState("");
	const [filterData, setFilterData] = useState({
		status: props.status === null ? "" : props.status,
		isDelivery: props.isDelivery === null ? "" : props.isDelivery,
		channel: props.channel === null ? "" : props.channel,
	});
	const [updatefilterData, setUpdateFilterData] = useState({
		status: false,
		isDelivery: false,
		channel: false,
	});
	const [rowsPerPage, setRowsPerPage] = useState(20);

	const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	const paymentIcon = (type, brand) => {
		if (type === 1 || type === 5) {
			switch (brand.toLowerCase()) {
				case "mastercard":
					return mastercard;
				case "visa":
					return visa;
				case "hipercard":
					return creditcard;
				case "elo":
					return creditcard;
				case "amex":
					return americanexpress;
				case "diners":
					return creditcard;
				case "sorocred":
					return creditcard;
				default:
					return creditcard;
			}
		} else if (type === 2) {
			return withdrawal;
		} else if (type === 3) {
			return boleto;
		} else if (type === 4) {
			return voucher;
		} else if (type === 6) {
			return pix;
		} else {
			return awaitMethod;
		}
	};

	const statusPayment = (paymentStatus, paymentType) => {
		switch (paymentStatus) {
			case 1:
				return "Aguardando pré-aprovação";
			case 2:
				return "Aguardando captura";
			case 3:
				return "Aguardando pagamento direto";
			case 4:
				return "Aguardando estorno";
			case 5:
				return "Pré-aprovado";
			case 6:
				return "Pago";
			case 7:
				return "Estornado";
			case 8:
				return "Erro de pré-aprovação";
			case 9:
				return "Erro de captura";
			case 10:
				return "Erro de pagamento";
			case 11:
				return "Erro de estorno";
			case 12:
				return "Pagamento na criação do pedido";
			case 13:
				return paymentType === 6 ? "Pix criado" : "Boleto criado";
			case 14:
				return "Aguardando nova tentativa";
			case 15:
				return "Retentativas expiradas";
			case 16:
				return "Aguardando pagamento na retirada";
			case 17:
				return "Pagamento na retirada pré-aprovado";
			case 18:
				return "Pagamento na retirada aprovado";
			default:
				return "Não iniciado";
		}
	};

	onMessageListener()
		.then((payload) => {
			var audio = new Audio("/images/alert-push-01.mp3");
			audio.play();
			toast.success(
				<button
					onClick={() => (window.location.href = "/orders/1")}
					className="push-order"
				>
					{payload.notification.title}
				</button>
			);
			setTimeout(() => {
				getOrders();
			}, 3000);
		})
		.catch((err) => console.log("failed: ", err));

	useEffect(() => {
		localStorage.removeItem("PARAMS");
		const inputSearch = document.querySelector("#search");
		const searchProd = fromEvent(inputSearch, "input")
			.pipe(
				debounceTime(500),
				map((event) => event.target.value)
			)
			.subscribe((text) => setQuery(text));
		// return searchProd.unsubscribe();
	}, []);

	useEffect(() => {
		const el = document.querySelector("#dropdownMenuButton");
		if (el) {
			el.addEventListener("click", function () {
				getDate(4);
			});
		}
	}, [document.getElementById("dropdownMenuButton")]);

	useEffect(() => {
		const dataFilter = JSON.parse(localStorage.getItem("filterOrders"));
		dataFilter && setFiltered(dataFilter);
		history.push(
			`${page}?since=${startDate === null ? "" : startDate}&until=${
				endDate === null ? "" : endDate
			}&status=${filterData.status}&isDelivery=${
				filterData.isDelivery
			}&channel=${filterData.channel}&friendlyCode=${
				friendlyCode == null ? "" : friendlyCode
			}&buyerCpf=${buyerCpf == null ? "" : buyerCpf}&buyerName=${
				buyerName == null ? "" : buyerName
			}&timezone=${new Date().getTimezoneOffset() / 60}`
		);
		getOrders(page, {
			status: filterData?.status ? filterData?.status : "",
			isDelivery: filterData?.isDelivery ? filterData?.isDelivery : "",
			channel: filterData?.channel ? filterData?.channel : "",
		});
	}, [page, rowsPerPage, startDate, endDate, filterData]);

	useEffect(() => {
		getOrders(page, {
			status: filterData?.status ? filterData?.status : "",
			isDelivery: filterData?.isDelivery ? filterData?.isDelivery : "",
			channel: filterData?.channel ? filterData?.channel : "",
		});
	}, [query]);

	const getOrders = async (pg = 1, filter) => {
		setLoading(true);
		setPage(pg);
		try {
			const response = await APIV2.get(
				`management/commerce/orders?page=${pg}&perPage=${rowsPerPage}&role=${localStorage.getItem(
					"role"
				)}&since=${startDate}&until=${endDate}&status=${
					filter?.status ? filter.status : ""
				}&isDelivery=${filter?.isDelivery ? filter.isDelivery : ""}
				&channel=${
					filter?.channel ? filter.channel : ""
				}&friendlyCode=${query}&buyerCpf=&buyerName=&timezone=${
					new Date().getTimezoneOffset() / 60
				}`
			);
			setOrders(response.data.orders);
			setMaxCount(response.data.maxCount);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
		const stateObj = { page: page };
		if (
			props.status == null ||
			props.isDelivery == null ||
			props.channel == null
		) {
			window.history.pushState(stateObj, "", `${e}`);
		} else {
			window.history.pushState(
				stateObj,
				"",
				`${e}?status=${props.status}&isDelivery=${props.isDelivery}&channel=${
					props.channel
				}&since=${startDate}&until=${endDate}&timezone=${
					new Date().getTimezoneOffset() / 60
				}`
			);
		}
	};

	const changeRowsPerPage = (e) => {
		setLoad(true);
		setRowsPerPage(e);
	};

	const getDate = (type) => {
		setPage(1);
		if (type == 1) {
			setType(1);
			setStartDate(
				new Date(
					new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate(),
						1,
						-60,
						0,
						0,
						0
					)
				).toISOString()
			);
			setEndDate(new Date().toISOString());
		} else if (type == 2) {
			setType(2);
			setStartDate(
				new Date(
					new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate() - 6,
						-3,
						0,
						0,
						0
					)
				).toISOString()
			);
			setEndDate(
				new Date(new Date().setTime(new Date().getTime())).toISOString()
			);
		} else if (type == 3) {
			setType(3);
			setStartDate(
				new Date(
					new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						new Date().getDate() - 29,
						-3,
						0,
						0,
						0
					)
				).toISOString()
			);
			setEndDate(
				new Date(new Date().setTime(new Date().getTime())).toISOString()
			);
		} else {
			setType(4);
		}
	};

	const skeleton = () => {
		return skele.map((index) => (
			// <div style={{overflow: 'hidden'}}>
			<ContentLoader
				key={index}
				speed={5}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="30" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="200" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="370" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="540" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="710" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="880" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="1000" y="28" rx="3" ry="3" width="20" height="10" />
			</ContentLoader>
		));
	};

	const onChangeDates = async (dates) => {
		setPage("1");
		setDates(dates);
		if (dates[1] === "") {
			setEndDate(dates[0]);
		} else {
			setEndDate(dates[1]);
		}
		setStartDate(dates[0]);
	};

	const getFilterSelect = useCallback((filter, filterApi) => {
		setPage("1");
		localStorage.setItem("filterOrders", JSON.stringify(filter));
		setFilterData({
			status: filterApi.status,
			isDelivery: filterApi.isDelivery,
			channel: filterApi.channel,
		});
		setFiltered(filter);
		setUpdateFilterData({ status: false, isDelivery: false, channel: false });
	}, []);

	const clearOneFilter = (data) => {
		setPage("1");
		let filter = filtered.filter((filter) => filter.name !== data.name);
		setFiltered(filter);
		localStorage.setItem("filterOrders", JSON.stringify(filter));
		data.value === filterData.status &&
			setFilterData((prevState) => ({ ...prevState, status: "" }));
		data.value === filterData.status &&
			setUpdateFilterData((prevState) => ({ ...prevState, status: true }));
		data.value === filterData.isDelivery &&
			setFilterData((prevState) => ({ ...prevState, isDelivery: "" }));
		data.value === filterData.isDelivery &&
			setUpdateFilterData((prevState) => ({ ...prevState, isDelivery: true }));
		data.value === filterData.channel &&
			setFilterData((prevState) => ({ ...prevState, channel: "" }));
		data.value === filterData.channel &&
			setUpdateFilterData((prevState) => ({ ...prevState, channel: true }));
		data.value === filterData.seller &&
			setFilterData((prevState) => ({ ...prevState, seler: "" }));
	};

	const clearAllFilter = () => {
		setPage("1");
		setFiltered([]);
		localStorage.setItem("filterOrders", JSON.stringify(...filtered));
		setFilterData({ status: "", isDelivery: "", channel: "" });
		setUpdateFilterData({
			status: true,
			isDelivery: true,
			channel: true,
			seler: "",
		});
	};

	const getExportFilterProducts = async () => {
		setLoad(true);
		await APIV2.get(
			`insights/export/orders?clientId=${localStorage.getItem(
				"clientId"
			)}&&since=${startDate}&until=${endDate}&status=${
				filterData.status
			}&isDelivery=${filterData.isDelivery}&channel=${
				filterData.channel
			}&friendlyCode=${friendlyCode == null ? "" : friendlyCode}&buyerCpf=${
				buyerCpf == null ? "" : buyerCpf
			}&buyerName=${buyerName == null ? "" : buyerName}&timezone=${
				new Date().getTimezoneOffset() / 60
			}`
		)
			.then((response) => {
				const exportFilter = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (resp.data.runtimeStatus === "Running") {
						console.log(resp.data.runtimeStatus);
					} else {
						console.log(resp.data.runtimeStatus);
						switch (resp.data.runtimeStatus) {
							case "Completed":
								setOutput(resp.data.output);
								clearInterval(exportFilter);
								document.getElementById("exportList").click();
								setLoad(false);
								toast.success("Download realizado com sucesso!😃");
								break;
							case "Failed":
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
								break;
							default:
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
						}
					}
				}, 4000);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	return (
		<div className="card">
			<FilterOrdersModal filterSelect={getFilterSelect} />
			<div className="card-header d-flex justify-content-between align-items-center py-3">
				<h4 className="card-title">Pedidos</h4>
				{load === true ? (
					<>
						<a
							href={output}
							id="exportList"
							className="d-none"
							style={{ padding: "10px 20px" }}
						></a>
						<button
							disabled
							className="btn btn-outline btn-icon mx-1 d-flex justify-content-center"
							style={{
								padding: "10px",
								border: "1px solid #E9ECEC",
								background: "transparent",
								color: "#747E8B",
								width: "190px",
								height: "40px",
								cursor: "not-allowed",
							}}
						>
							<>
								<i className="fas fa-spinner fa-spin"></i>
								<span className="p-0 m-0" style={{ fontWeight: "500" }}>
									&nbsp;&nbsp;Processando...
								</span>
							</>
						</button>
					</>
				) : (
					<>
						<a
							href={output}
							id="exportList"
							className="d-none"
							style={{ padding: "10px 20px" }}
						></a>
						<button
							className="btn btn-outline btn-icon mx-1 d-flex justify-content-center"
							style={{
								padding: "10px",
								border: "1px solid #E9ECEC",
								background: "transparent",
								color: "#747E8B",
								width: "190px",
								height: "40px",
							}}
							onClick={() => getExportFilterProducts()}
						>
							<>
								<i className="material-icons">get_app</i>
								<span className="p-0 m-0" style={{ fontWeight: "400" }}>
									&nbsp;&nbsp;Exportar Pedidos
								</span>
							</>
						</button>
					</>
				)}
			</div>
			<div className="card-header d-flex flex-column flex-lg-row justify-content-between">
				{/* <div className="w-90"> */}
				<div className="filter-orders mr-3">
					<div className="filter-date">
						<label className="card-title pb-2" style={{ fontSize: "13px" }}>
							PERÍODO DE PEDIDOS:
						</label>
						<div className="group-btn">
							<button
								className={type === 1 && "active-data-btn"}
								onClick={() => getDate(1)}
							>
								hoje
							</button>
							<button
								className={type === 2 && "active-data-btn"}
								onClick={() => getDate(2)}
							>
								últimos 07 dias
							</button>
							<button
								className={type === 3 ? "active-data-btn" : ""}
								onClick={() => getDate(3)}
							>
								últimos 30 dias
							</button>
							{/* <button
								onClick={() => }

							> */}
							<MultDatePiker
								useDatePiker={type !== 4 ? true : false}
								date={(e) => onChangeDates(e)}
								className={type === 4 ? "active-data-btn " : ""}
							/>
							{/* </button> */}
						</div>
					</div>
				</div>
				{/* </div> */}

				<div
					className={
						localStorage.getItem("role") === "8"
							? `filter-search-spdp`
							: `filter-search w-100`
					}
				>
					<label htmlFor="search">
						<strong>Busca</strong>
					</label>
					<div className="autocomplete icon-right">
						<input
							type="text"
							className="search form-control"
							id="search"
							name="pesquisa"
							placeholder="Número do pedido"
							// onChange={handleChange}
							style={{ width: "100%" }}
						/>
						{loading ? (
							<i className="fas fa-spinner fa-spin"></i>
						) : (
							<i className="material-icons">search</i>
						)}
					</div>
				</div>
			</div>
			<div className="card-header d-flex flex-column py-3">
				{filtered.length > 0 && (
					<>
						<label className="card-title" style={{ fontSize: "13px" }}>
							Filtros Adicionados
						</label>
						<div className="d-flex flex-row my-3">
							{filtered.map((data, i) => (
								<button
									className="btn-filtered"
									style={
										loading ? { cursor: "not-allowed	" } : { cursor: "pointer" }
									}
									disabled={loading ? true : false}
									onClick={() => clearOneFilter(data)}
								>
									{data.name} <i className="material-icons">close</i>
								</button>
							))}
						</div>
					</>
				)}
				<div className="d-flex flex-column flex-lg-row justify-content-start">
					<button
						className="btn btn-outline d-flex justify-content-center mr-1"
						style={{
							background: "transparent",
							border: "1px solid #00BFB5",
							color: "#00BFB5",
							width: "190px",
							height: "40px",
						}}
						type="button"
						onClick={() => openModal(updatefilterData, filterData)}
						data-toggle="modal"
						data-target="#filterOrders"
					>
						<i className="material-icons-outlined">filter_alt</i>
						&nbsp;&nbsp;Filtrar resultados (
						{filtered.length == null ? "0" : filtered.length})
					</button>
					{filtered.length > 0 && (
						<button
							className="btn btn-outline btn-icon d-flex justify-content-center mx-1"
							onClick={clearAllFilter}
							style={{
								border: "1px solid #747E8B",
								background: "#747E8B",
								color: "#FFFFFF",
								width: "190px",
								height: "40px",
							}}
						>
							<i className="material-icons">delete_outline</i>&nbsp;&nbsp;Limpar
							filtros ({filtered.length})
						</button>
					)}
				</div>
				<div
					className="d-flex align-items-center pt-3"
					style={{ width: "100%" }}
				>
					<strong>MOSTRANDO {loading ? "0" : maxCount} RESULTADOS</strong>
				</div>
			</div>
			{/* <FilterOrders searchFilter={filter} totalOrders={maxCount} since={startDate} until={endDate} status={props.status} isDelivery={props.isDelivery} channel={props.channel} clientId={clientId} statusFilter={status} /> */}

			<div className="card-content pt-4">
				<div className="card-body no-padding">
					{loading ? (
						skeleton()
					) : orders.length > 0 ? (
						<List
							data={orders}
							page={parseInt(page)}
							colums={columsList
								.filter((colum) =>
									localStorage.getItem("role") === "8" ||
									localStorage.getItem("role") === "3"
										? colum.name !== "MODALIDADE"
										: colum
								)
								.filter((colum) =>
									localStorage.getItem("role") === "8" ||
									localStorage.getItem("role") === "3"
										? colum.name !== "ORIGEM"
										: colum
								)}
							totalRows={maxCount}
							handlePageChange={changePage}
							paginationServer={true}
							//
							rowsPerPage={rowsPerPage}
							handleRowsPerPageChange={changeRowsPerPage}
						/>
					) : (
						<Empty img={"/images/empty_coupon.svg"} orders={true} />
					)}
				</div>
			</div>
		</div>
	);
}
