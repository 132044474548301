import React, { Component } from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class DownloadExcel extends Component {
    constructor(props) {
        super(props);
    
    }


    render() {

        return (
            <ExcelFile element={this.props.button}>
                <ExcelSheet data={this.props.data} name="Questions">
                    <ExcelColumn label="question" value="question" />
                    <ExcelColumn label="answer" value="answer" />
                </ExcelSheet>
            </ExcelFile>
        )
    }
};

export default DownloadExcel;
