/* eslint-disable react/no-direct-mutation-state */
import React, { useEffect, useState } from 'react';
import ContentLoader from "react-content-loader"
import { APIV2 } from '../../../../API';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { useConfig } from '../../../../providers/ConfigProvider';


export default function SearchList(props) {
	const { managerConfigs } = useConfig()
	const [sellers, setSellers] = useState([])
	const [list, setList] = useState([])
	const [quantity, setQuantity] = useState(null)
	//const [displayedContacts, setDisplayedContacts] = useState(null)
	const [deleteId, setDeleteId] = useState(null)
	const [editStock, setEditStock] = useState(null)
	const [sellerId, setSellerId] = useState(null)
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(true)
	const [skuId, setSkuId] = useState(null)


	useEffect(() => {
		setSkuId(props.skuId)
		setList(props.listSeller)
		setLoading(false)
	}, [props.listSeller])

	useEffect(() => {}, [list])

	const handleChange = (e) => {
		setQuantity(e.target.value)
	}

	const searchHandler = (event) => {
		const sellerName = event.target.value.toLowerCase()
		const listSeller = props.listSeller.filter((seller) => {
				const sellerValue = seller.sellerName.toLowerCase();
				return sellerValue.indexOf(sellerName) !== -1;
		})
		setList([...listSeller])
	}

	/* const selectItem = (id) => {
		for (var i in list) {
			if (list[i].id == id) {
				list[i].select = !list[i].select;

				if (props.change != undefined && props.change != null && list[i].select == true) {
					props.change(list[i])
				}
			}
		}
	} */

	const getIdDelete = (e) => {
		setDeleteId(e)
	}

	const deleteItem = () => {
		props.delete(deleteId)
	}

	const getEdit = (id, stock) => {
		setEdit(true)
		setEditStock(id)
		setQuantity(stock.quantity)
		setSellerId(id ? stock.sellerId : stock.sellerCode)
	}

	const editItem = () => {
		var body = {
			quantity: parseInt(quantity),
			skuId: skuId,
			sellerId: sellerId,
			id: editStock
		}


		var bodyCatalog = {
			quantity: parseInt(quantity),
			skuId: skuId,
			sellerCode: sellerId
		}
		setEdit(false)

		props.edit(!editStock ? bodyCatalog : body)
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		}, 3000);
	}

	const skeleton = () => {
		const skele = [0, 1, 2,]
		return (
			skele.map(() =>
				<ContentLoader
					speed={2}
					width={1100}
					height={60}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: '100%' }}

				>
					<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
					<rect x="500" y="26" rx="3" ry="3" width="52" height="10" />
					<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
				</ContentLoader>
			)

		)
	}

	return (
		// <div className="container dash-content">
		<>
			<div className="container">
				<div className="card-header pl-0">
					<h4 className="card-title">{!skuId ? null : `Estoques de ${props.name ? props.name : '...'}`}</h4>
				</div>
				<div className="holder col-12 search-list pl-0 pr-0">
					<form className='row'>
						<div className="form-group col-11 autocomplete icon-right">
							<input type="text" className="search form-control" name='pesquisa' onChange={searchHandler} placeholder={props.placeholder} />
							<i className="material-icons" style={{ right: '25px' }}>search</i>
						</div>
						<button className='btn btn-primary btn-icon' style={{ padding: '8px', height: '40px'}}   onClick={() => props.change()}>
							<i className="material-icons">add</i>
						</button>
					</form>
				</div>
				{
					list <= 0 && props.loading === false ?
						<div className='col-12 text-center mt-5 mb-5'>
							<div className='mt-1 '>
								<h3>Nenhum estoque</h3>
							</div>
							<div className='mt-2'>
								<Button className='btn-primary' onClick={() => props.change()}>Adicionar novo</Button>
							</div>
						</div>
						:
						props.loading || loading ? skeleton() :
							<table className="table">
								<thead>
									<tr>
										<th scope="col">Ponto de venda</th>
										<th scope="col">Quantidade</th>
										<th scope="col"></th>
									</tr>
								</thead>
								{list.map((stock, index) => {
									return (
										<tbody>
											<tr key={index}>
												<td>{!stock.sellerName ? stock.sellerCode : stock.sellerName}</td>
												{editStock == stock.id && edit == true ?
													<td>
														<input type="number" className="form-control" id="quantity" name="quantity" defaultValue={stock.quantity} onChange={handleChange} />
													</td> :
													<td> {stock.quantity}</td>
												}
												<td className='text-right'>
													<div className="btn-group" role="group">
														{editStock == stock.id && edit == true ?
															<button type="button" className="btn btn-light btn-icon outline btn-sm" onClick={() => editItem()}>
																<i className="material-icons">save</i>
															</button> : null
														}
														<button type="button" className="btn btn-light btn-icon outline btn-sm" onClick={() => getEdit(stock.id, stock)}>
															<i className="material-icons">create</i>
														</button>
														<button type="button" className="btn btn-light outline btn-icon btn-sm text-danger" data-toggle="modal" data-target="#deleteSku" onClick={() => getIdDelete(stock)}><i className="material-icons">
															delete_outline</i></button>
													</div>
												</td>
											</tr>
										</tbody>
									)
								})}
							</table>
				}

			</div>
			<Modal
				id={'deleteSku'}
				title={'Certeza que deseja excluir este item?'}
				delete={true}
				actionDelete={deleteItem}
			/>
			{/* // </div> */}
		</>
	)
};
