import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./screens/Login/Index";
import Dashboard from "./screens/Dashboard/Index";
import Uikit from "./screens/Ui-kit/Index";
import ChannelManagement from "./screens/ChannelManagement/Index";
import AppConfig from "./screens/ChannelManagement/App/Config/Index";
import Vouchers from "./screens/Catalog/Benefits/Vouchers/Index";
import Promotions from "./screens/Catalog/Benefits/Promotions/Promotions";
import NewPromotions from "./screens/Catalog/Benefits/Promotions/New";
import Import from "./screens/Import/Index";
import ImportOld from "./screens/ImportOld/Index";
import Products from "./screens/Catalog/Products/Index";
import NewProduct from "./screens/Catalog/Products/New";
import NewSku from "./screens/Catalog/Products/NewSku";
import NewStocks from "./screens/Catalog/Products/Stocks/Index";
import Skus from "./screens/Catalog/Sku/Index.jsx";
import NewSkus from "./screens/Catalog/Sku/NewSku.jsx";
import Collections from "./screens/Catalog/Collections/Index";
import NewCollection from "./screens/Catalog/Collections/New.jsx";
import NewVoucher from "./screens/Catalog/Benefits/Vouchers/NewVouchers";
import Welcome from "./screens/AutoSetup/Welcome";
import Onboarding from "./screens/AutoSetup/Onboarding/Index";
import Brand from "./screens/AutoSetup/Brand/Index";
import Stores from "./screens/AutoSetup/Stores/Index";
import NewStore from "./screens/AutoSetup/Stores/NewStore";
import AppStore from "./screens/AutoSetup/AppStore/Index";
import Redirects from "./Redirect";
import Content from "./screens/AutoSetup/Content/Index";
import Confirm from "./screens/AutoSetup/Confirm";
import ResetPassword from "./screens/Login/ResetPassword";
import Error from "./screens/404/Index";
import StoresPanel from "./screens/Stores/Index";
import NewStoresPanel from "./screens/Stores/New";
import Retailers from "./screens/Settings/Retailers/Index";
import NewRetailers from "./screens/Settings/Retailers/New";
import Categories from "./screens/Catalog/Categories/Categories";
import Orders from "./screens/Orders/Index";
import DetailsOrders from "./screens/Orders/Orders";
import Painel from "./layouts/Painel";
import Status from "./screens/Status/Status";
import ImportHistory from "./screens/Import/ImportHistory";
import AppEditor from "./screens/ChannelManagement/AppEditor/AppEditor";
import FreightList from "./screens/Settings/Freight/Index.jsx";
import NewFreight from "./screens/Settings/Freight/NewFreight.jsx";
import Dealers from "./screens/Stores/SalesPeople/Dealers";
import NewDealers from "./screens/Stores/SalesPeople/NewDealers";
import Matcher from "./screens/Catalog/Matcher/Matcher";
import MatcherHistory from "./screens/Catalog/Matcher/components/MatcherHistory";

//Admin = 1                     //NeomodeAdmin
//Retailer = 2                  //Varejista
//Seller = 3                    //Lojista
//Marketing = 4                 //Marketing
//SalesManagement = 5           //Gestão de vendas (somente leitura)
//SalesManagementComplete = 6   //Gestão de vendas (acesso completo)
//Marketplace = 7               //Marketplace
//MarketplaceRetailer = 8       //Varejista de shopping

function RoleAccess(roles, component) {
	if (
		roles.findIndex((element) => element == localStorage.getItem("role")) != -1
	) {
		return component;
	} else {
		if (
			localStorage.getItem("token") != null ||
			localStorage.getItem("token") != undefined ||
			localStorage.getItem("token") != ""
		) {
			return Error;
		} else {
			window.location.href = "/login";
		}
	}
}

export const openRoutes = [
	{ path: "/login/:route", name: "Login", Component: Login },
	{ path: "/login", name: "Login", Component: Login },
	{ path: "/reset-password", name: "Reset Password", Component: ResetPassword },
	{ path: "/ui-kit", name: "UI Kit ", Layout: Painel, Component: Uikit },
];

export const closedRoutes = [
	{
		path: "/",
		name: <i className="material-icons">home</i>,
		Layout: Painel,
		Component: RoleAccess([1, 2, 3, 4, 5, 6, 7, 8], Dashboard),
	},
	{
		path: "/dashboard",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Dashboard
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([1, 2, 3, 4, 5, 6, 7, 8], Dashboard),
	},

	{
		path: "/categories",
		name: "Categorias",
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], Categories),
	},

	{
		path: "/products",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], Products),
	},
	{
		path: "/products/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Produtos
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], Products),
	},
	{
		path: "/products/:page/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Novo Produto
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], NewProduct),
	},
	{
		path: "/products/:page/edit/:productId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Produto
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], NewProduct),
	},
	{
		path: "/products/:page/edit/:productId/sku/:skuId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Variação
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], NewSku),
	},
	{
		path: "/products/:page/edit/:productId/stocks/:skuId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Estoque
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], NewStocks),
	},

	{
		path: "/sku",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], Skus),
	},
	{
		path: "/sku/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				SKUs
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], Skus),
	},
	{
		path: "/sku/:page/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo SKU
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], NewSkus),
	},
	{
		path: "/sku/:page/edit/:skuId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar SKU
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], NewSkus),
	},

	{
		path: "/orders",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], Orders),
	},
	{
		path: "/orders/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Pedidos
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], Orders),
	},
	{
		path: "/orders/:page/detail/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Detalhes do Pedido
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], DetailsOrders),
	},

	{
		path: "/matcher",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Mapeamento de Catalogo
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 8], Matcher),
	},
	{
		path: "/matcher/history/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Histórico de Mapeamento
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], MatcherHistory),
	},
	{
		path: "/collections",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 7], Collections),
	},
	{
		path: "/collections/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Coleções
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7], Collections),
	},
	{
		path: "/collections/:page/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Nova Coleção
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7], NewCollection),
	},
	{
		path: "/collections/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Coleção
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7], NewCollection),
	},

	{
		path: "/stores",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 7, 3, 8], StoresPanel),
	},
	{
		path: "/stores/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Lojas
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 3, 8], StoresPanel),
	},
	{
		path: "/stores/:page/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Nova Loja
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 3, 8], NewStoresPanel),
	},

	{
		path: "/stores/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Loja
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 3, 8], NewStoresPanel),
	},

	{
		path: "/salespeople",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 7, 3], Dealers),
	},
	{
		path: "/salespeople/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Vendedores
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 3], Dealers),
	},
	{
		path: "/salespeople/:page/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo Vendedor
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 3], NewDealers),
	},
	{
		path: "/salespeople/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Vendedor
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7, 3], NewDealers),
	},

	{
		path: "/retailerconfigurations",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Configurações dos varejistas
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([1], Retailers),
	},
	{
		path: "/retailerconfigurations/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo Varejistas
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([1], NewRetailers),
	},
	{
		path: "/retailerconfigurations/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Configurações do Varejista
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([1], NewRetailers),
	},

	{
		path: "/ui-kit",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				UI Kit
			</span>
		),
		Layout: Painel,
		Component: Uikit,
	},

	{
		path: "/channel-management/app-editor",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Gestão do Canal
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7], AppEditor),
	},
	{
		path: "/channel-management/app-editor",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				App Editor
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 7], AppEditor),
	},
	// {
	// 	path: "/channel-management/app-editor/new-questionnaire",
	// 	name: (
	// 		<span className="link-breadcrumb">
	// 			<i className="material-icons ml-2">keyboard_arrow_right</i>
	// 			Novo Questionário
	// 		</span>
	// 	),
	// 	Layout: Painel,
	// 	Component: RoleAccess([2, 7], NewQuestionnaire),
	// },

	{
		path: "/channel-management/:type/:screen",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Gestao de Canal
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], ChannelManagement),
	},
	{
		path: "/channel-management/:type/:screen/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Banner
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], ChannelManagement),
	},

	{
		path: "/vouchers",
		name: "",
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], Vouchers),
	},
	{
		path: "/vouchers/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Cupons
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], Vouchers),
	},
	{
		path: "/vouchers/:page/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo Cupom
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], NewVoucher),
	},
	{
		path: "/vouchers/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Cupom
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], NewVoucher),
	},

	{
		path: "/promotions",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Promoções
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], Promotions),
	},
	{
		path: "/promotions/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Nova Promoção
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], NewPromotions),
	},
	{
		path: "/promotions/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Promoção
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 4, 7], NewPromotions),
	},

	{
		path: "/import",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Importação
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], Import),
	},
	//{ path: "/import/history", name: "Todas Importações", Layout: Painel, Component: RoleAccess([2, 3, 7, 8], ImportHistory) },
	{
		path: "/import/history/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Histórico de Importação
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], ImportHistory),
	},
	{
		path: "/import-old",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Importação
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], ImportOld),
	},

	{
		path: "/setup/welcome",
		name: "Welcome",
		Component: RoleAccess([2], Welcome),
	},
	{
		path: "/setup/onboarding/:step",
		name: "Onboarding",
		Component: RoleAccess([2], Onboarding),
	},
	{
		path: "/setup/brand/:step",
		name: "Brand",
		Component: RoleAccess([2], Brand),
	},
	{ path: "/setup/stores", name: "Stores", Component: RoleAccess([2], Stores) },
	{
		path: "/setup/stores/:page",
		name: "Stores",
		Component: RoleAccess([2], Stores),
	},
	{
		path: "/setup/stores/:page/new",
		name: "Stores",
		Component: RoleAccess([2], NewStoresPanel),
	},
	{
		path: "/setup/stores/:page/edit/:id",
		name: "Stores",
		Component: RoleAccess([2], NewStoresPanel),
	},
	// { path: "/setup/store/new", name: "Nova Loja", Component: RoleAccess([2], NewStore) },
	// { path: "/setup/store/edit/:id", name: "Editar Loja", Component: RoleAccess([2], NewStore) },
	// { path: "/setup/store/edit/:id", name: "Editar Loja", Component: RoleAccess([2], NewStore) },
	{
		path: "/setup/app-store",
		name: "App Store",
		Component: RoleAccess([2], AppStore),
	},
	{
		path: "/setup/content/:step",
		name: "Conteúdo",
		Component: RoleAccess([2], Content),
	},
	{
		path: "/setup/confirm",
		name: "Confirmação",
		Component: RoleAccess([2], Confirm),
	},

	{
		path: "/status",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Status
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([8], Status),
	},

	{
		path: "/freight",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Gestão de Frete
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], FreightList),
	},
	//{ path: "/freight/:page", name: "Todos Fretes", Layout: Painel, Component: RoleAccess([2, 7, 3], FreightList) },
	{
		path: "/freight/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Adicionar novo frete personalizado
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], NewFreight),
	},
	{
		path: "/freight/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editando frete personalizado
			</span>
		),
		Layout: Painel,
		Component: RoleAccess([2, 3, 7, 8], NewFreight),
	},

	// { path: "*", name: "404", Component: Error },
];
