import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import moment from "moment";
import { API } from "../../../../API";
import Loading from "../../../../components/Loading";
import Modal from "../../../../components/Modal";
import List from "../../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Empty from "../../../../components/Empty";
import Button from "../../../../components/Button";
import VouchersExport from "./components/VouchersExport";
export class VouchersList extends Component {
	constructor(props) {
		super(props);
		this.delete = this.delete.bind(this);
		this.setData = this.setData.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			page: 1,
			vouchers: [],
			loading: true,
			today: moment().format(),
			item: "",
			data: null,
			deleteItem: {},
			columsList: [
				{
					name: "Código",
					selector: "code",
					sortable: false,
					grow: 2,
				},

				{
					sortable: false,
					name: "Status",
					selector: "isActive",
					cell: (row) =>
						moment(row.endDate).isAfter(this.state.today) ? (
							row.isActive ? (
								<span className="badge badge-primary stadium text-center">
									ATIVO
								</span>
							) : (
								<span className="badge badge-secondary stadium">INATIVO</span>
							)
						) : (
							<span className="badge badge-danger stadium">ENCERRADO</span>
						),
				},
				{
					sortable: false,
					name: "Nº de utilizações",
					selector: "quantityApplied",
					center: true,
					cell: (row) => row.quantityApplied,
				},
				{
					name: "",
					sortable: false,
					width: "150px",
					cell: (row) => (
						<div className="btn-group" role="group">
							<Link
								to={`/vouchers/${this.state.page}/edit/${row.id}`}
								className="btn btn-info"
								id="btnGroupDrop1"
							>
								Editar
							</Link>
							<button
								id="btnGroupDrop1"
								type="button"
								className="btn btn-sm border-left border-white btn-info rounded-right"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span className="material-icons" style={{ fontSize: "15px" }}>
									keyboard_arrow_down
								</span>
							</button>
							<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
								<a
									className="dropdown-item"
									href="#"
									data-toggle="modal"
									data-target="#viewVoucher"
									onClick={() => this.setState({ data: row })}
								>
									Visualizar
								</a>
								<a
									className="dropdown-item text-danger"
									href="#"
									data-toggle="modal"
									data-target="#deleteVoucher"
									onClick={() => this.setState({ deleteItem: row })}
								>
									Excluir
								</a>
							</div>
						</div>
					),
				},
			],
		};
	}
	componentDidMount() {
		this.setState({ vouchers: this.props.vouchers });
		this.forceUpdate();
	}
	async setData(item) {
		await this.setState({ data: item });
		this.forceUpdate();
	}
	async handlePageChange(pageNumber) {
		await this.props.changePage(pageNumber);
	}
	delete() {
		console.log(this.state.deleteItem);
		API.post("api/vouchers/delete", this.state.deleteItem)
			.then(async (response) => {
				window.location.reload();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Não foi possível remover o cupom de desconto... Tente novamente mais tarde."
				);
			});
	}

	skeleton() {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	}

	render() {
		return (
			<div className="container dash-content">
				<div className="card">
					<div className="card-header d-flex justify-content-between align-items-center">
						<h4 className="card-title">Cupons de desconto</h4>
						<div className="card-actions d-flex flex-row">
							<Link to={`/vouchers/${this.state.page}/new`}>
								<Button className=" btn-primary mr-3">Novo Cupom</Button>
							</Link>
							<VouchersExport />
						</div>
					</div>
					<div className="card-content">
						<div className="card-body no-padding">
							{this.props.totalItems === 0 && this.props.loading === false ? (
								<Empty
									img={"/images/empty_coupon.svg"}
									genre="seu primeiro"
									name="cupom de desconto"
									link="/vouchers/new"
									import={false}
								/>
							) : this.props.loading ? (
								this.skeleton()
							) : (
								<List
									data={this.props.vouchers}
									page={this.props.page}
									colums={this.state.columsList}
									totalRows={this.props.totalItems}
									rowsPerPage={this.props.rowsPerPage}
									handlePageChange={this.handlePageChange}
									handleRowsPerPageChange={this.props.changeRowsPerPage}
									paginationServer={true}
								/>
							)}
						</div>
					</div>
				</div>

				<Modal
					id={"viewVoucher"}
					title={"Desempenho do cupom"}
					content={
						this.state.data != null ? (
							<div>
								<div className="form-group  p-3 card col-12">
									<h2 className="text-primary font-weight-bold">
										{this.state.data.amountOrders}
									</h2>
									<h4>Pedidos Fechados</h4>
								</div>
								<div className="form-group p-3 card col-12">
									<h2 className="text-primary font-weight-bold">
										R${" "}
										{this.state.data.subTotalOrders.toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</h2>
									<h4>Faturamento</h4>
								</div>

								<div className="form-group p-3 card col-12">
									<h2 className="text-primary font-weight-bold">
										R${" "}
										{this.state.data.ticketAverage.toLocaleString("pt-br", {
											minimumFractionDigits: 2,
										})}
									</h2>
									<h4>Ticket Médio</h4>
								</div>
								<div className="form-group p-3 card col-12">
									<h2 className="text-primary font-weight-bold">
										R${" "}
										{this.state.data.discountTotalOrders.toLocaleString(
											"pt-br",
											{ minimumFractionDigits: 2 }
										)}
									</h2>
									<h4>Desconto Total Aplicado</h4>
								</div>
							</div>
						) : (
							<Loading />
						)
					}
				/>

				<Modal
					id={"deleteVoucher"}
					title={"Certeza que deseja excluir este item?"}
					delete={true}
					actionDelete={this.delete}
				/>
			</div>
		);
	}
}

export default VouchersList;
