import React from "react";
import { APIV2 } from "../../../../API";
import Axios from "axios";
import { useState } from "react";
import { useMatcher } from "../providers/MatcherContext";

// import { Container } from './styles';

function MatcherUploadComponent({
	icon,
	tooltip,
	type,
	downloadLink,
	importDocumentType,
}) {
	const [output, setOutput] = useState("");
	const [importValue, setImportValue] = useState("");
	const [loadingImport, setLoadingImport] = useState(false);
	const {
		getStatus,
		setSuccess,
		setError,
		loadingDownload,
		setLoadingDownload,
	} = useMatcher();

	const download = async () => {
		try {
			setLoadingDownload(true);
			setSuccess("Iniciando o download da planilha de mapeamento. Aguarde!");
			const response = await APIV2.get(downloadLink);
			let statusResponse = {};
			const apiStatusInterval = setInterval(async () => {
				statusResponse = await Axios.get(response.data.statusQueryGetUri);
				if (statusResponse.data.runtimeStatus === "Completed") {
					setOutput(statusResponse.data.output);
					clearInterval(apiStatusInterval);
					document.getElementById(type).click();
					setLoadingDownload(false);
					return false;
				}
			}, 3000);
		} catch (error) {
			setLoadingDownload(false);
			console.log(error);
		}
	};

	const importDocument = () => async (event) => {
		setLoadingImport(true);
		console.log("importando");
		let formData = new FormData();
		formData.append("file", event.target.files[0]);
		try {
			const response = await APIV2.post(
				`import/file/excel/${importDocumentType}?integrationCode=omnik`,
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);
			setLoadingImport(false);
			setSuccess(
				"Planilha de mapeamento de (categoria, marca ou atributos) enviada com sucesso!"
			);
			// console.log(response.data);
			getStatus();
		} catch (error) {
			setLoadingImport(false);
			setError(
				"Não foi possível concluír o envio da planilha de mapeamento de (categoria, marca ou atributos)"
			);
			console.log(error);
		}
	};

	return (
		<div className="import-matcher">
			<span className="import-matcher-icon">
				<i className="material-icons">{icon}</i>
			</span>
			<div className="import-matcher-description">
				<p>
					Mapear {type}
					{"   "}
					<span className="tooltipNeoMatcher ml-1">
						<i className="material-icons">help_outline</i>
						<span>{tooltip}</span>
					</span>
				</p>

				<a className="d-none" id={type} href={output}></a>
				<button
					className="btn btn-link link p-0"
					disabled={loadingDownload}
					onClick={() => download()}
				>
					Baixar a última planilha mapeada
				</button>
			</div>
			<div className="import-matcher-upload">
				<label className="btn btn-secondary btn-block mb-0">
					<input type="file" onChange={importDocument()} value={importValue} />
					{loadingImport === true ? (
						<i className="fas fa-spinner fa-spin"></i>
					) : (
						"Atualizar Mapeamento"
					)}
				</label>
			</div>
		</div>
	);
}

export default MatcherUploadComponent;
