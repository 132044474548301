import React, { Component } from 'react';
import { connect } from 'react-redux'
import appActions from '../../../appActions';
import TermsOfUse from './TermsOfUse'
import PrivacyPolicy from './PrivacyPolicy'
export class Content extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: [],
    }
  }
 
  render() {
    return (
      <>
        {
          this.props.match.params.step === 'terms-of-use' ?
            <TermsOfUse /> : null
        }
        {
          this.props.match.params.step === 'privacy-policy' ?
            <PrivacyPolicy /> : null
        }
      </>
    )
  }
};

export default connect(store => ({ text: store.text, req: store.req }))(Content);
