import React, { useContext, useState } from 'react';
import {useCategory} from '../providers/CategoriesContext';
import CreateNewCategory from './CreateNewCategory';
import NewCategoryForm from './NewCategoryForm';

// import { Container } from './styles';



function NewCategory() {
  const {openForm} = useCategory()

  return (
    <div className="card" >
      <div class="card-body" >
        {!openForm ?
          <CreateNewCategory/>
          :
          <NewCategoryForm />
        }
      </div>
    </div>
  );
}

export default NewCategory;