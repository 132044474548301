import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment, { months } from "moment";

// import { Container } from './styles';

function MultiDatePiker(props) {
	const [formatCalendar, setFormatCalendar] = useState("month");
	const [period, setPeriod] = useState("month");
	const [showCalendar, setShowCalendar] = useState(false);
	const [date, setDate] = useState(["", ""]);
	const [isWeek, setIsWeek] = useState(false);
	const [byPeriod, setByPeriod] = useState(false);
	const [formattedDate, setFormattedDate] = useState([]);

	useEffect(() => {
		if (props.useDatePiker) {
			setDate("", "");
		}
	}, [props.useDatePiker]);

	const selectType = (type) => {
		switch (type) {
			case "period":
				setPeriod("period");
				setShowCalendar(true);
				setIsWeek(false);
				setByPeriod(true);
				setFormatCalendar("month");
				break;
			case "week":
				setPeriod("week");
				setShowCalendar(true);
				setByPeriod(false);
				setIsWeek(true);
				setFormatCalendar("month");
				break;
			case "today":
				setPeriod("today");
				setFormatCalendar("month");
				setShowCalendar(false);
				setDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate(),
							1,
							-60,
							0,
							0,
							0
						)
					),
					new Date(new Date().setTime(new Date().getTime())),
				]);
				setFormattedDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate(),
							-3,
							0,
							0,
							0
						)
					).toISOString(),
					new Date(new Date().setTime(new Date().getTime())).toISOString(),
				]);
				break;
			case "lastWeek":
				setPeriod("lastWeek");
				setFormatCalendar("month");
				setShowCalendar(false);
				setByPeriod(false);
				setIsWeek(true);
				setDate([
					new Date(
						new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 6)
					),
					new Date(new Date().setTime(new Date().getTime() - 3600 * 1000)),
				]);
				setFormattedDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate() - 6,
							-3,
							0,
							0,
							0
						)
					).toISOString(),
					new Date(new Date().setTime(new Date().getTime())).toISOString(),
				]);
				break;
			case "lastThirtyDays":
				setPeriod("lastThirtyDays");
				setFormatCalendar("month");
				setShowCalendar(false);
				setDate([
					new Date(
						new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 29)
					),
					new Date(new Date().setTime(new Date().getTime() - 3600 * 1000)),
				]);
				setFormattedDate([
					new Date(
						new Date(
							new Date().getFullYear(),
							new Date().getMonth(),
							new Date().getDate() - 29,
							-3,
							0,
							0,
							0
						)
					).toISOString(),
					new Date(new Date().setTime(new Date().getTime())).toISOString(),
				]);

				break;
			case "year":
				setPeriod("year");
				setShowCalendar(true);
				setFormatCalendar(type);
				setByPeriod(false);
				setIsWeek(false);
				break;
			default:
				setPeriod("month");
				setShowCalendar(true);
				setFormatCalendar(type);
				setByPeriod(false);
				setIsWeek(false);
				break;
		}
	};

	const handleDate = (value) => {
		const newDate = new Date();

		setDate(["", ""]);
		if (isWeek) {
			setDate([
				value,
				new Date(
					newDate.setTime(new Date(value).getTime() + 3600 * 1000 * 24 * 6)
				),
			]);
			//props.date([value.toISOString(), new Date(value).getTime() + 3600 * 1000 * 24 * 7.toISOString()])
			setFormattedDate([
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours() - 3,
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate() + 7,
					new Date(value).getHours() - 3,
					new Date(value).getMinutes(),
					new Date(value).getSeconds() - 1,
					0
				).toISOString(),
			]);
		} else if (formatCalendar === "year" && !byPeriod) {
			setDate([
				value,
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth() + 1,
					0
				),
			]);
			setFormattedDate([
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours() - 3,
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth() + 1,
					new Date(value).getDate(),
					new Date(value).getHours() - 3,
					new Date(value).getMinutes(),
					new Date(value).getSeconds() - 1,
					0
				).toISOString(),
			]);
		} else if (!byPeriod && formatCalendar === "month") {
			setDate([value, value]);
			setFormattedDate([
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours() - 3,
					new Date(value).getMinutes(),
					new Date(value).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value).getFullYear(),
					new Date(value).getMonth(),
					new Date(value).getDate(),
					new Date(value).getHours() + 21,
					new Date(value).getMinutes(),
					new Date(value).getSeconds() - 1,
					0
				).toISOString(),
			]);
		} else if (byPeriod && formatCalendar === "month") {
			setDate([value[0], value[1]]);
			setFormattedDate([
				new Date(
					new Date(value[0]).getFullYear(),
					new Date(value[0]).getMonth(),
					new Date(value[0]).getDate(),
					new Date(value[0]).getHours() - 3,
					new Date(value[0]).getMinutes(),
					new Date(value[0]).getSeconds(),
					0
				).toISOString(),
				new Date(
					new Date(value[1]).getFullYear(),
					new Date(value[1]).getMonth(),
					new Date(value[1]).getDate(),
					new Date(value[1]).getHours() - 3,
					new Date(value[1]).getMinutes(),
					new Date(value[1]).getSeconds() - 1,
					0
				).toISOString(),
			]);
		}
	};

	const confirmDate = () => {
		props.date(formattedDate);
		closeCalendar();
	};

	const closeCalendar = () => {
		document.querySelector("#dropCalendar").classList.remove("show");
	};

	return (
		<>
			<div className={`dropdown ${props.className}`}>
				<button
					className="btn dropdown-toggle calendar-toggle btn-icon-r datepicker-orders"
					type="button"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					{!date[0] && !date[1] ? (
						<span>PERSONALIZADO</span>
					) : (
						<>
							{date[0] ? moment(date[0]).format("DD/MM/YYYY") : date[0]}:
							{date[1] ? moment(date[1]).format("DD/MM/YYYY") : date[1]}
						</>
					)}
					<i className="material-icons">event_note</i>
				</button>
				<form
					id="dropCalendar"
					className="dropdown-menu "
					style={{ padding: "0", borderRadius: "5px", overflow: "hidden" }}
					aria-labelledby="dropdownMenuButton"
				>
					<div className="datepicker">
						<div className="datepicker-options">
							<p className="datepicker-options-title">
								Selecione um intervalo desejado
							</p>
							<ul className="list-group">
								<li
									onMouseEnter={() => selectType("today")}
									className={
										period === "today"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Hoje
								</li>
								<li
									onMouseEnter={() => selectType("lastWeek")}
									className={
										period === "lastWeek"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Últimos 7 dias
								</li>
								<li
									onMouseEnter={() => selectType("lastThirtyDays")}
									className={
										period === "lastThirtyDays"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Últimos 30 dias
								</li>
							</ul>
							<hr />
							<p className="datepicker-options-title">
								Insira um intervalo especifico
							</p>
							<ul className="list-group">
								<li
									onMouseEnter={() => selectType("month")}
									className={
										period === "month"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por dia
								</li>
								<li
									onMouseEnter={() => selectType("week")}
									className={
										period === "week"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por semana
								</li>
								<li
									onMouseEnter={() => selectType("year")}
									className={
										period === "year"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por mês
								</li>
								<li
									onMouseEnter={() => selectType("period")}
									className={
										period === "period"
											? "list-group-item list-group-item-action datepicker-options-item active"
											: "list-group-item list-group-item-action datepicker-options-item"
									}
								>
									Por período
								</li>
							</ul>
						</div>
						<div className="datepicker-calendar">
							{/* {showCalendar ? */}
							<div className="datepicker-header-text">
								<div className=" btn datepicker-header-text-from btn-icon-r">
									<i className="material-icons">event_note</i>
									De: {moment(date[0]).format("DD/MM/YYYY")}
								</div>
								<div className="btn datepicker-header-text-to">
									{date[1] && <> Até: {moment(date[1]).format("DD/MM/YYYY")}</>}
								</div>
								{/* <p> </p> */}
							</div>
							<div className="datepicker-calendar-view">
								{(period === "lastThirtyDays" ||
									period === "lastWeek" ||
									period === "today") && (
									<div className="block-navigation"></div>
								)}
								<Calendar
									rangeDivider={"Á"}
									clearIcon={""}
									closeCalendar={false}
									disabled={false}
									disableCalendar={false}
									maxDetail={formatCalendar}
									minDetail={formatCalendar}
									selectRange={byPeriod}
									showNavigation={true}
									prev2Label=""
									next2Label=""
									// rangeType
									value={date}
									onChange={(value) => handleDate(value)}
								/>
							</div>
							<div className="datepicker-footer">
								<button
									type="button"
									onClick={() => closeCalendar()}
									className="btn"
								>
									Cancelar
								</button>
								<button
									type="button"
									onClick={() => confirmDate()}
									className="btn btn-primary ml-2"
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default MultiDatePiker;
