import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { APIV2ServerUrl, APIV2 } from '../API';

// import { Container } from './styles';
export const ConfigContext = createContext({})

export default function ConfigProviders(props) {
  const [managerConfigs, setManagerConfigs] = useState(false)
  let token;


  const getManagerConfigs = async () => {
    token = localStorage.getItem('token')
    localStorage.getItem('UseCatalogProcessor')
    try {
      if (token) {
        const response = await APIV2.get('management/retailer/configurations/internal');
        // if(localStorage.getItem('UseCatalogProcessor') !== response.data.useCatalogProcessor.toString()) localStorage.removeItem('token')
        setManagerConfigs(response.data.useCatalogProcessor)
        localStorage.setItem('UseCatalogProcessor', response.data.useCatalogProcessor)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // useMemo(() => {
  //   getManagerConfigs()
  // }, [token])

  return (
    <ConfigContext.Provider
      value={{
        getManagerConfigs,
        setManagerConfigs,
        managerConfigs
      }}>
      {props.children}
    </ConfigContext.Provider>
  );
}

export const useConfig = () => useContext(ConfigContext);