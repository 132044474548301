import React from "react";
import { Invoice } from "./components/Invoice";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useOrders } from "./providers/OrdersContext";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function CreateInvoice() {
	const { amount, order, buyer, seller, delivery, paymentInfo, items } = useOrders()
	const information = {
		image: localStorage.getItem("primaryLogo"),
		buyer: buyer,
		amount: amount,
		order: order,
		paymentInfo: paymentInfo,
		seller: seller,
		delivery: delivery,
	};

	const print = async () => {
		const classPrint = new Invoice(information, items)
		const document = await classPrint.CreateDocument()
		pdfMake.createPdf(document).open({}, window.open("", "_blank"))
	};

	return (
        <div className="detail-invoice">
            <button onClick={print}>
                <i className="material-icons">print</i>
            </button>	
        </div>
	);
}
