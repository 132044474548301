import React, { useCallback, useEffect, useState } from "react";
import { Multiselect } from "react-widgets";
import { API, APIV2 } from "../../../API";
import EditorHtml from "../../../assets/EditorHtml";
import NewDropImage from "../../../components/NewDropImage";
import DatePickerNeo from "../../../components/DatePickerNeo";
import "react-widgets/dist/css/react-widgets.css";
import Loading from "../../../components/Loading";
import CategoriesList from "./CategoriesList";
import Modal from "../../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import CurrencyFormat from "react-currency-format";
import { useConfig } from "../../../providers/ConfigProvider";
import Button from "../../../components/Button";
import ContentLoader from "react-content-loader";

export default function DataProducts(props) {
	const { managerConfigs } = useConfig();
	const [categoriesList, setCategoriesList] = useState([]);
	const [selectCategories, setSelectCategories] = useState([]);
	const [tagList, setTagList] = useState([]);
	const [selectTag, setSelectTag] = useState([]);
	const [releaseDate, setReleaseDate] = useState();
	const [disabledButton, setDisablebutton] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingImages, setLoadingImages] = useState(false);
	const [images, setImages] = useState([]);
	const [useCatalogImages, setUseCatalogImages] = useState([]);
	//product precisa ser enviando dentro de um array e.g. [{}]
	const [product, setProduct] = useState({
		name: "",
		code: "",
		url: null,
		imageUrl: "",
		hexColor: null,
		description: "",
		categories: [],
		images: [],
		isActive: true,
		keywords: "",
		releaseDate: "",
		height: 0,
		width: 0,
		lenght: 0,
		weight: 0,
		auxCode: "",
		referenceCode: "",
		complementaryName: "",
		maxInstallments: 0,
		brand: "",
	});

	useEffect(() => {
		if (props.id) {
			getProducts();
		} else {
			setLoading(false);
		}
		// getCategories()
	}, []);

	useEffect(() => {}, [useCatalogImages]);

	const getProducts = async () => {
		try {
			const response = await APIV2.get(
				"management/catalog/products/" + props.id
			);
			setSelectCategories(response.data.categories);
			setSelectTag(response.data.tags);
			setProduct(response.data);
			//criação de array de imagens
			setUseCatalogImages(
				response.data.images
					? response.data.images.sort(function (a, b) {
							return a.order - b.order;
					  })
					: []
			);
			setImages(
				response.data.images
					? response.data.images.sort(function (a, b) {
							return a.order - b.order;
					  })
					: []
			);
			if (response.data.releaseDate) {
				setReleaseDate(new Date(response.data.releaseDate));
			}
			await getTags();
			setLoading(false);
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	// const getCategories = async () => {
	// 	try {
	// 		const response = await APIV2.get('management/catalog/categories')

	// 		if (props.edit) {
	// 			setCategoriesList(response.data)
	// 		} else {
	// 			setCategoriesList(response.data)
	// 			setLoading(false)
	// 		}

	// 	} catch (error) {
	// 		console.log(error)
	// 		toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
	// 	}
	// }

	const getTags = async () => {
		try {
			const response = await API.get("api/tags");
			if (props.edit) {
				setTagList(response.data);
			} else {
				setTagList(response.data);
				// setLoading(false)
			}
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const createProd = (e) => {
		e.preventDefault();
		let categories = selectCategories;
		product.categories = categories;
		product.tags = selectTag;
		let imagesProduct = product.images ? product.images : [];
		for (let i = 0; i < imagesProduct.length; i++) {
			product.images[i].order = i;
		}
		create(product);
	};

	const changeBool = useCallback(() => {
		setProduct((prevState) => ({ ...prevState, isActive: !product.isActive }));
	}, [product.isActive]);

	const handleCreate = (name) => {
		let newOption = {
			name,
			id: "teste",
		};
		setTagList((prevState) => [...prevState, newOption]);
		setSelectTag((prevState) => [...prevState, newOption]);
	};

	const changeAnwser = (e) => {
		product.description = e;
	};

	const recoveryStateImage = (e) => {
		let newCatalogArrayImages = [];
		let arrayImages = [];
		// if (localStorage.getItem('UseCatalogProcessor') === 'true') {
		newCatalogArrayImages = e.map((image, index) => {
			return {
				originalImageUrl: image.imageUrl,
				order: index + 1,
				uriHash: !image.uriHash ? "" : image.uriHash,
			};
		});
		setUseCatalogImages(newCatalogArrayImages);
		arrayImages = e.map((image, index) => {
			return {
				imageUrl: image.imageUrl,
				order: index + 1,
				uriHash: !image.uriHash ? "" : image.uriHash,
			};
		});
		setImages(arrayImages);
		// }
		setProduct((prevState) => ({ ...prevState, images: e }));
	};

	const handleChange = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setProduct((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleChangeDate = async (date) => {
		var json = product["releaseDate"];
		json = date;
		product["releaseDate"] = json;
		setReleaseDate(json);
	};

	const newCondition = (item) => {
		setSelectCategories(item);
	};

	const getImagesCatalog = async () => {
		setLoadingImages(true);
		try {
			const response = await APIV2.get(
				`management/catalog/products/${product.code}/images`
			);
			setUseCatalogImages(response.data);
			setLoadingImages(false);
		} catch (error) {
			console.log(error);
		}
	};

	const create = (product) => {
		setDisablebutton(true);
		product.weight = !product.weight ? 0 : product.weight;
		product.width = !product.width ? 0 : product.width;
		product.lenght = !product.lenght ? 0 : product.lenght;
		product.height = !product.height ? 0 : product.height;
		if (localStorage.getItem("UseCatalogProcessor") === "true") {
			APIV2.put(
				`/management/catalog/products/${product.code}/images`,
				localStorage.getItem("UseCatalogProcessor") === "true"
					? useCatalogImages
					: product.images
			);

			APIV2.put(
				`/management/catalog/products/${product.code}/categories`,
				selectCategories
			);
		}
		if (props.id) {
			APIV2.put(`management/catalog/products/${props.id}`, product)
				.then(async (response) => {
					//notify.show("Produto editado com sucesso! 😃", "success", 5000)
					toast.success("Produto editado com sucesso! 😃");
					if (localStorage.getItem("UseCatalogProcessor") === "true") {
						getImagesCatalog();
					}
					setLoading(false);
					setDisablebutton(false);
				})
				.catch((error) => {
					setLoading(false);
					setDisablebutton(false);
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		} else {
			setLoading(true);
			APIV2.post("management/catalog/products", product)
				.then(async (response) => {
					!response.data
						? (window.location.href =
								`/products/${props.page}/edit/` + product.code)
						: (window.location.href =
								`/products/${props.page}/edit/` + response.data.id);
					if (localStorage.getItem("UseCatalogProcessor") === "true") {
						getImagesCatalog();
					}
					setDisablebutton(false);
				})
				.catch((error) => {
					setLoading(false);
					setDisablebutton(false);
					console.log(error);
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				});
		}
	};

	const renderScreen = () => {
		return (
			<div className="card">
				<div className="card-header" id="headingOne">
					<div className="mb-0">
						<h4 className="card-title">Dados do Produto</h4>
					</div>
				</div>
				{/* data-parent="#accordion" // className="collapse" */}
				<form onSubmit={createProd}>
					<div id="dadosProdutos" className="#" aria-labelledby="headingOne">
						<div className="card-content">
							<div className="card-body py-0">
								{loading ? (
									<div className="row">{skeletonData()}</div>
								) : (
									<div className="row">
										<div className="form-group col-sm-6">
											<label htmlFor="productName">
												<strong>Nome do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="productName"
													name="name"
													value={product.name}
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="form-group col-sm-5">
											<label htmlFor="complementaryName">
												<strong>Nome complementar</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="complementaryName"
													name="complementaryName"
													value={product.complementaryName}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="form-group col-sm-1">
											<label htmlFor="productActive">
												<strong>Ativo?</strong>
											</label>
											<br />
											<label className="switch ">
												<input
													id="Status"
													type="checkbox"
													name="isActive"
													value={product.isActive}
													defaultChecked={product.isActive}
													onChange={changeBool}
													disabled={disabledButton}
												/>
												<span className="slider round"></span>
											</label>
										</div>
										<div className="form-group col-sm-4">
											<label htmlFor="productCode">
												<strong>Código do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													disabled={props.id ? true : false}
													id="productCode"
													placeholder="0"
													name="code"
													value={product.code}
													required
													onChange={handleChange}
												/>
											</div>
										</div>

										<div className="form-group col-sm-4">
											<label htmlFor="productCode">
												<strong>Código auxiliar do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="auxCode"
													placeholder="0"
													name="auxCode"
													value={product.auxCode}
													onChange={handleChange}
												/>
											</div>
										</div>

										<div className="form-group col-sm-4">
											<label htmlFor="productCode">
												<strong>Código de referência do produto</strong>
											</label>
											<div>
												<input
													type="text"
													className="form-control"
													id="referenceCode"
													placeholder="0"
													name="referenceCode"
													value={product.referenceCode}
													onChange={handleChange}
												/>
											</div>
										</div>

										<div className="form-group col-sm-4">
											<label htmlFor="productCategories">
												<strong>Categorias</strong>
											</label>
											<br />
											<div className="input-group ">
												<div className="input-group-prepend">
													<div className="input-group-text">Categorias</div>
												</div>
												{/** Realizar testes quando encontrar uma categoria vazia */}
												<input
													id="productCategories"
													type="button"
													className="form-control"
													value={selectCategories.length + " Selecionadas"}
													data-toggle="modal"
													data-target={
														selectCategories === 0
															? "#noCategorie"
															: "#categorie"
													}
												/>
												{/** modal não há categorias */}
												<Modal
													id={"noCategorie"}
													title={"Categorias"}
													content={
														<>
															<h1>Desculpe!</h1>
															<h3>Não há categorias cadastradas.</h3>
														</>
													}
												/>
											</div>
										</div>
										<div className="form-group col-sm-4">
											<label htmlFor="productKeywords">
												<strong>Parcelamento máximo</strong>
											</label>
											<CurrencyFormat
												value={
													product.maxInstallments
														? product.maxInstallments
														: String(product.maxInstallments)
												}
												displayType={"input"}
												name="discountValue"
												className="form-control"
												allowNegative={false}
												// suffix={'Kg'}
												decimalScale={0}
												fixedDecimalScale={true}
												onValueChange={(values) => {
													const { formattedValue, value } = values;
													// formattedValue = $2,223
													// value ie, 2223
													product.maxInstallments = parseInt(value);
												}}
											/>
										</div>
										<div className="form-group col-sm-3">
											<DatePickerNeo
												className="form-control"
												label="Data de lançamento"
												dateFormat="dd/MM/yyyy"
												time={false}
												selected={releaseDate}
												onChange={handleChangeDate}
											/>
										</div>

										<div className="form-group col-6">
											<label htmlFor="productBrand">
												<strong>Marca</strong>
											</label>
											<input
												type="text"
												className="form-control"
												id="productBrand"
												name="brand"
												value={product.brand}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group col-6">
											<label htmlFor="productCollections">
												<strong>Coleções</strong>
											</label>
											<Multiselect
												data={tagList}
												value={product.tags}
												valueField="id"
												className="form-control"
												textField={(item) => item.name}
												allowCreate="onFilter"
												onCreate={(name) => handleCreate(name)}
												onChange={(value) =>
													setProduct((prevState) => ({
														...prevState,
														tags: value,
													}))
												}
												busy={tagList.length >= 0 ? false : true}
											/>
										</div>

										<div className="form-group col-12">
											<label htmlFor="productKeywords">
												<strong>Palavras chave</strong>
											</label>
											<input
												type="text"
												className="form-control"
												id="productKeywords"
												name="keywords"
												value={product.keywords}
												onChange={handleChange}
											/>
										</div>
									</div>
								)}
							</div>

							<div className="card-header">
								<h4 className="card-title">Medidas e peso do produto</h4>
							</div>
							<div className="card-content">
								<div className="card-body py-0">
									{loading ? (
										<div className="row">{skeletonMeasure()}</div>
									) : (
										<div className="row">
											<div className="form-group col-sm-3">
												<label htmlFor="productKeywords">
													<strong>Peso</strong>
												</label>
												<CurrencyFormat
													value={
														product.weight
															? product.weight
															: String(product.weight)
													}
													displayType={"input"}
													name="discountValue"
													className="form-control"
													thousandSeparator={"."}
													decimalSeparator={","}
													suffix={"Kg"}
													decimalScale={3}
													fixedDecimalScale={true}
													onValueChange={(values) => {
														const { formattedValue, value } = values;
														// formattedValue = $2,223
														// value ie, 2223
														product.weight = parseFloat(value);
													}}
												/>
											</div>
											<div className="form-group col-sm-3">
												<label htmlFor="productKeywords">
													<strong>Largura</strong>
												</label>
												<CurrencyFormat
													value={
														product.width
															? product.width
															: String(product.width)
													}
													name="price"
													className="form-control"
													decimalSeparator={","}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandSeparator={`.`}
													suffix={"cm"}
													onValueChange={(values) => {
														const { formattedValue, value, floatValue } =
															values;
														// formattedValue = $2,223
														// value ie, 2223
														product.width = floatValue;
													}}
												/>
											</div>
											<div className="form-group col-sm-3">
												<label htmlFor="productKeywords">
													<strong>Comprimento</strong>
												</label>
												<CurrencyFormat
													value={
														product.lenght
															? product.lenght
															: String(product.lenght)
													}
													displayType={"input"}
													decimalScale={2}
													fixedDecimalScale={true}
													name="discountValue"
													className="form-control"
													decimalSeparator={","}
													suffix={"cm"}
													onValueChange={(values) => {
														const { formattedValue, value } = values;
														// formattedValue = $2,223
														// value ie, 2223
														product.lenght = parseFloat(value);
													}}
												/>
											</div>
											<div className="form-group col-sm-3">
												<label htmlFor="productKeywords">
													<strong>Altura</strong>
												</label>
												<CurrencyFormat
													value={
														product.height
															? product.height
															: String(product.height)
													}
													displayType={"input"}
													name="discountValue"
													className="form-control"
													decimalSeparator={","}
													suffix={"cm"}
													decimalScale={2}
													fixedDecimalScale={true}
													onValueChange={(values) => {
														const { formattedValue, value } = values;
														// formattedValue = $2,223
														// value ie, 2223
														product.height = parseFloat(value);
													}}
												/>
											</div>
										</div>
									)}

									<div className="card-header">
										<h4 className="card-title">Descrição do produto</h4>
									</div>
									<div className="card-content">
										<div className="card-body p-0 m-0">
											{loading ? (
												<div className="row">{skeletonDescription()}</div>
											) : (
												<div className="row">
													<div className="form-group col">
														<EditorHtml
															change={changeAnwser}
															data={product.description}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="card-header">
									<h4 className="card-title">Imagens do produto</h4>
								</div>
								<div className="card-content">
									<div className="card-body py-0">
										<div className="row">
											{loading || loadingImages ? (
												skeletonImage()
											) : (
												<div className="form-group col-12">
													<NewDropImage
														type={"carrousel"}
														data={images}
														recoveryStateLogo={recoveryStateImage}
														height={"180px"}
														width={"100%"}
														background={"#fff"}
														colorText={"#747E8B"}
													/>
												</div>
											)}
											<div className="form-group col-12 mt-3 text-right">
												<Button
													className="btn-success"
													type="submit"
													id="submit"
													loading={disabledButton}
												>
													{props.id ? "Editar produto" : "Criar produto"}
												</Button>
												{/*
													<button className="btn btn-light ml-3" type='button' onClick={() => window.location.href = '/products'}>
														Cancelar
													</button>
												*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				{!loading && (
					<CategoriesList
						newCondition={newCondition}
						editValue={product.categories}
					/>
				)}
			</div>
		);
	};

	const skeletonData = () => {
		return (
			<>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="520" height="40" />
					<rect x="550" y="25" rx="3" ry="3" width="430" height="40" />
					<rect x="990" y="25" rx="3" ry="3" width="40" height="24" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="360" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="710" y="25" rx="3" ry="3" width="335" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="360" y="25" rx="3" ry="3" width="335" height="40" />
					<rect x="710" y="25" rx="3" ry="3" width="190" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="1025" height="40" />
				</ContentLoader>
				<ContentLoader
					speed={2}
					width={1100}
					height={"60"}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 60"
					style={{ width: "100%", margin: "0 0 16px 0" }}
				>
					<rect x="10" y="25" rx="3" ry="3" width="1025" height="40" />
				</ContentLoader>
			</>
		);
	};

	const skeletonMeasure = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={"60"}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%", margin: "0 0 16px 0" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="245" height="40" />
				<rect x="280" y="25" rx="3" ry="3" width="245" height="40" />
				<rect x="540" y="25" rx="3" ry="3" width="245" height="40" />
				<rect x="800" y="25" rx="3" ry="3" width="245" height="40" />
			</ContentLoader>
		);
	};

	const skeletonDescription = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={"380"}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 380"
				style={{ width: "100%", margin: "0 0 16px 0" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="1025" height="340" />
			</ContentLoader>
		);
	};

	const skeletonImage = () => {
		return (
			<ContentLoader
				speed={2}
				width={1100}
				height={200}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 200"
				style={{ width: "100%", margin: "0 0 16px 0" }}
			>
				<rect x="10" y="25" rx="3" ry="3" width="1025" height="180" />
			</ContentLoader>
		);
	};

	return <>{renderScreen()}</>;
}
