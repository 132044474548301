import React, {  forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { API } from '../API';
import Collapse from './Collapse'
import Tutorial from './Tutorial'

// a little function to help us with reordering the result
const reorder = async (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return await result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '5px',
  border: '1px solid #E9ECEC',
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: '0',
  width: '100%'
});

const  DragDropList = forwardRef((props, ref) => {
  const { 
    itens, 
    setItem, 
    changeQuestion, 
    deleteFaq, 
    changeTutorial
  } = props

  const memo = useMemo(() => {
    if (itens) {
      setItem(itens)
    }
  }, [])

  useEffect(() => { 
    //console.log(props.icons)
    setItem(itens)
  }, [itens])

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = await reorder(
      itens,
      result.source.index,
      result.destination.index,

    );
    setItem(items)
  }

  const updateList = () => {
    setItem(itens)
  }

  const changeItem = (question, answer, order) => {
    changeQuestion(question, answer, order)
  }

  const changeItemTutorial = (tutorial) => {
    changeTutorial(tutorial)
  }

  useImperativeHandle(ref, () => {
    return {
      updateList: updateList
    };
  });


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {itens != null && itens.map((item, index) => (
              <Draggable key={props.tutorial ? item.order.toString() : item.id} draggableId={props.tutorial ? item.order.toString() : item.id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    {props.tutorial ? <Tutorial item={item} setItemPreview={props.setItemPreview} index={index} changeItem={changeItemTutorial} setOrder={props.setOrder} icons={props.icons} modal={props.modal} /> 
                    : <Collapse item={item} changeItem={changeItem} deleteFaq={deleteFaq} />}
                  </div>

                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
});


export default DragDropList