import React, { useState, useEffect } from "react";
import { APIV2 } from "../../API";
import { toast } from "react-toastify";
import MaskEditPrice from "../../components/MaskEditPrice";
import { useOrders } from "./providers/OrdersContext";
import AutoComplete from "../../components/AutoComplete";
import ContentLoader from "react-content-loader";
import Modal from "../../components/Modal";

export default function OrderEditing() {
	const {
		loading,
		amount,
		setAmount,
		items,
		orderId,
		setItems,
		status,
		setLoading,
		productDisplayCodeName,
		getOrders,
	} = useOrders();
	const [newItems, setNewItems] = useState([]);
	const [edit, setEdit] = useState(false);
	const [itemDeleted, setItemDeleted] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [nameSku, setNameSku] = useState("");
	const [codeSku, setCodeSku] = useState("");
	const [imageSku, setImageSku] = useState("");
	const [priceId, setPriceId] = useState("");
	const [discountPriceId, setDiscountPriceId] = useState("");
	const [quantity, setQuantity] = useState("");
	const [unitPrice, setUnitPrice] = useState("");
	const [discountPrice, setDiscountPrice] = useState("");
	const [promotion, setpromotion] = useState(0);
	const [totalValue, setTotalValue] = useState(0);

	const getItemAutoComplete = (e) => {
		let products = items;
		const hasInList = items.some((product) => product.sku.id === e.sku.id);
		if (hasInList) {
			toast.error("Sua lista já possui este produto! 😃");
		} else {
			const newItem = {
				id: e.id,
				unitPrice: e.price,
				quantity: 1,
				discountPrice: 0,
				totalAmount: e.price,
				sku: {
					id: e.sku.id,
					code: e.sku.code,
					barcode: e.sku.barcode,
					product: {
						code: e.sku.code,
						auxCode: e.sku.code,
						referenceCode: e.sku.code,
						barcode: e.sku.barcode,
					},
					productSku: `${e.sku.name} - ${e.sku.nameSku}`,
					imageUrl: e.imageUrl,
				},
			};
			products.push(newItem);
		}
		addNewItem(products);
	};
	// verificado
	const addNewItem = (item) => {
		let product = item.map((prd) => {
			if (prd.id) {
				return {
					id: prd.id,
					unitPrice: prd.unitPrice,
					discountPrice: prd.discountPrice,
					quantity: prd.quantity,
					sku: {
						Id: prd.sku.id,
						code: prd.sku.code,
						imageUrl: prd.sku.imageUrl,
					},
				};
			} else {
				return prd;
			}
		});
		setNewItems(product);
	};

	const changeQuantity = async (idSku, type) => {
		var arr = items.filter((obj) => {
			return obj.sku.id === idSku;
		});
		if (type === "less") {
			arr[0].quantity = arr[0].quantity <= 1 ? 1 : arr[0].quantity - 1;
			setQuantity(arr[0].quantity);
		} else {
			arr[0].quantity = arr[0].quantity + 1;
			setQuantity(arr[0].quantity);
		}
		arr[0].totalAmount = arr[0].quantity * arr[0].unitPrice;
		arr[0].totalAmount =
			arr[0].discountPrice > arr[0].totalAmount
				? 0
				: arr[0].totalAmount - arr[0].discountPrice;
		getValuesOrders();
	};

	const changePrice = (id, code) => {
		if (code === undefined) {
			setPriceId(id);
		} else {
			setDiscountPriceId(code);
		}
	};

	const aprovePrice = (type) => {
		if (type === true) {
			setPriceId("");
			var arr = items.filter((obj) => {
				return obj.sku.id === priceId;
			});
			const value =
				unitPrice === ""
					? arr[0].unitPrice
					: unitPrice.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2");
			arr[0].unitPrice = parseFloat(value);
			arr[0].totalAmount = arr[0].quantity * arr[0].unitPrice;
			arr[0].totalAmount =
				arr[0].discountPrice > arr[0].totalAmount
					? 0
					: arr[0].totalAmount - arr[0].discountPrice;
			getValuesOrders();
		} else {
			setPriceId("");
		}
	};

	const aproveDiscount = (type) => {
		if (type === true) {
			setDiscountPriceId("");
			var arr = items.filter((obj) => {
				return obj.sku.code === discountPriceId;
			});
			const value =
				discountPrice === ""
					? arr[0].discountPrice
					: discountPrice.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2");
			arr[0].discountPrice = parseFloat(value);
			arr[0].totalAmount = arr[0].quantity * arr[0].unitPrice;
			arr[0].totalAmount =
				arr[0].discountPrice > arr[0].totalAmount
					? 0
					: arr[0].totalAmount - arr[0].discountPrice;
			getValuesOrders();
		} else {
			setDiscountPriceId("");
		}
	};

	const getValuesOrders = () => {
		const discountValue = items.map((item) => {
			return item.discountPrice;
		});
		let discount = discountValue.reduce(
			(total, currentElement) => total + currentElement
		);
		setAmount((oldAmount) => ({ ...oldAmount, discountAmount: discount }));
		const totalValue = items.map((item) => {
			return item.totalAmount;
		});
		let total = totalValue.reduce(
			(total, currentElement) => total + currentElement
		);
		setAmount((oldAmount) => ({
			...oldAmount,
			totalAmount: total + amount.freightAmount + amount.feeAmount,
		}));
		setAmount((oldAmount) => ({
			...oldAmount,
			subTotalAmount: total + discount,
		}));
	};

	// verificado
	const removeList = (id, nameSku, codeSku, imageSku) => {
		setDeleteId(id);
		setNameSku(nameSku);
		setCodeSku(codeSku);
		setImageSku(imageSku);
	};
	// verificado
	const deleteItemOrder = async () => {
		let deleteOrder = items.filter((order) => order.id !== deleteId);
		setItems(deleteOrder);
		const removeItem = [];
		if (itemDeleted.length > 0) {
			itemDeleted.forEach((arr) => {
				if (arr.id === deleteId) {
					var body = {
						...arr,
						quantity: 0,
					};
					removeItem.push(body);
				} else {
					var body = {
						...arr,
					};
					removeItem.push(body);
				}
			});
		} else {
			items.forEach((arr) => {
				if (arr.id === deleteId) {
					var body = {
						...arr,
						quantity: 0,
					};
					removeItem.push(body);
				} else {
					var body = {
						...arr,
					};
					removeItem.push(body);
				}
			});
		}
		setItemDeleted(removeItem);
		toast.success("Produto deletado com sucesso! 😃");
	};

	const saveOrder = () => {
		if (itemDeleted.length > 0) {
			editItems(itemDeleted);
		} else {
			editItems(items);
		}

		setEdit(false);
		const subTotal = { value: items.map((obj) => obj.totalAmount) };
		var total = 0;
		for (var i = 0; i < subTotal.value.length; i++) {
			total += subTotal.value[i];
		}
		setAmount((oldAmount) => ({
			...oldAmount,
			subTotalAmount: total + amount.discountAmount,
		}));
		setAmount((oldAmount) => ({
			...oldAmount,
			totalAmount: total + amount.feeAmount + amount.freightAmount,
		}));
	};

	const editItems = async (newItems) => {
		setLoading(true);
		let body = newItems.map((prd) => {
			return {
				unitPrice: prd.unitPrice,
				discountPrice: prd.discountPrice,
				quantity: prd.quantity,
				skuId: prd.sku.id,
				skuCode: prd.sku.code,
			};
		});
		await APIV2.put(`management/commerce/orders/${orderId}/items`, body)
			.then((response) => {
				toast.success("Pedido editado com sucesso! 😃");
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const cancelOrder = async () => {
		setLoading(true);
		const statusOrder = "CanceledByAdmin";
		await APIV2.put(
			`management/commerce/orders/${orderId}/status/${statusOrder}`
		)
			.then((response) => {
				//setLoading(false)
				toast.success("Pedido cancelado com sucesso!");
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const cancelEdit = () => {
		setLoading(true);
		getOrders(orderId);
		setEdit(false);
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={50}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 40"
				style={{ width: "100%" }}
			>
				<rect x="10" y="1" rx="1" ry="2" width="50" height="100" />
				<rect x="80" y="8" rx="1" ry="2" width="450" height="10" />
				<rect x="80" y="20" rx="3" ry="3" width="100" height="10" />
				<rect x="570" y="8" rx="3" ry="3" width="30" height="120" />
				<rect x="650" y="8" rx="3" ry="3" width="120" height="120" />
				<rect x="800" y="8" rx="3" ry="3" width="120" height="120" />
				<rect x="950" y="8" rx="3" ry="3" width="120" height="120" />
			</ContentLoader>
		));
	};

	return (
		<div className="box-table">
			<div className="form-group w-100">
				{edit && (
					<>
						<label>
							<strong>Adicionar novo produto</strong>
						</label>
						<div>
							<AutoComplete
								placeholder="Procure pelo produto"
								apiGet="api/autocomplete/products/"
								getItemAutoComplete={getItemAutoComplete}
								modal={"modal"}
							/>
						</div>
					</>
				)}
				<div>
					<div className="list m-0 p-0">
						{items.length > 0 ? (
							<table className="table history-import m-0 p-0 border-bottom">
								<thead>
									<tr>
										<th scope="col" height="50px"></th>
										<th className="text-start">ITENS DO PEDIDO</th>
										<th className="text-center">QTD</th>
										<th className="text-center" style={{ width: "150px" }}>
											PREÇO UN.
										</th>
										<th className="text-center" style={{ width: "150px" }}>
											DESC.
										</th>
										<th className="text-center" style={{ width: "150px" }}>
											TOTAL
										</th>
										{edit === true && (
											<th className="text-center" style={{ width: "5%" }}></th>
										)}
									</tr>
								</thead>
								{loading === true ? null : (
									<tbody>
										{items.map((item, i) => (
											<tr key={item.id} id={item.id}>
												<td className="py-2">
													<div>
														<img
															src={
																!item.sku.imageUrl
																	? "/images/placeholder.jpg"
																	: item.sku.imageUrl
															}
															alt="Produto"
															width="40px"
															className="shadow-sm mx-auto d-block"
														/>
													</div>
												</td>

												<td className="py-2">
													<div className="d-flex flex-column ">
														<label className="m-0 p-0">
															{item.sku.productSku}
														</label>
														<em
															style={{ fontSize: "12px" }}
															className="p-0 m-0"
														>
															{productDisplayCodeName === "Code"
																? `Cód: ${
																		item.sku.product.code === null
																			? ""
																			: item.sku.product.code
																  }`
																: productDisplayCodeName === "ReferenceCode"
																? `Cód: ${
																		item.sku.product.referenceCode === null
																			? ""
																			: item.sku.product.referenceCode
																  }`
																: productDisplayCodeName === "AuxCode"
																? `Cód: ${
																		item.sku.product.auxCode === null
																			? ""
																			: item.sku.product.auxCode
																  }`
																: ""}
															&nbsp;&nbsp;
															<em
																style={{ fontSize: "12px", color: "#BCBEC0" }}
															>
																{`EAN: ${item.sku.barcode}`}{" "}
															</em>
														</em>
													</div>
												</td>

												{edit === true ? (
													<td className="">
														<div className="counter-price">
															<button
																onClick={() =>
																	changeQuantity(item.sku.id, "less")
																}
															>
																<i
																	className="material-icons"
																	style={
																		item.quantity <= 1
																			? { color: "#C4C4C4" }
																			: null
																	}
																>
																	remove
																</i>
															</button>
															<input className="mb-1" value={item.quantity} />
															<button
																onClick={() =>
																	changeQuantity(item.sku.id, "more")
																}
															>
																<i className="material-icons">add</i>
															</button>
														</div>
													</td>
												) : (
													<td className="pt-3 text-center">{item.quantity}</td>
												)}
												{edit === true ? (
													<td className="pt-2 text-center">
														{priceId === item.sku.id ? (
															<div className="button-input-price">
																<MaskEditPrice
																	defaultValue={item.unitPrice.toLocaleString(
																		"pt-br",
																		{ minimumFractionDigits: 2 }
																	)}
																	name="unitPrice"
																	onChange={(e) =>
																		setUnitPrice(e.currentTarget.value)
																	}
																	aprove={aprovePrice}
																/>
															</div>
														) : (
															<div className="button-edit-price">
																<button
																	onClick={() => changePrice(item.sku.id)}
																	className="btn btn-light outline border-0"
																>
																	{item.unitPrice.toLocaleString("pt-br", {
																		style: "currency",
																		currency: "BRL",
																	})}
																</button>
															</div>
														)}
													</td>
												) : (
													<td className="pt-3 text-center">
														{item.unitPrice.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
														})}
													</td>
												)}
												{edit === true ? (
													<td className="pt-2 text-center">
														{discountPriceId === item.sku.code ? (
															<div className="m-0 p-0">
																<MaskEditPrice
																	defaultValue={item.discountPrice.toLocaleString(
																		"pt-br",
																		{ minimumFractionDigits: 2 }
																	)}
																	name="discountPrice"
																	onChange={(e) =>
																		setDiscountPrice(e.currentTarget.value)
																	}
																	aprove={aproveDiscount}
																/>
															</div>
														) : (
															<button
																onClick={() =>
																	changePrice(item.id, item.sku.code)
																}
																className="btn btn-light outline border-0 m-0 py-2"
															>
																{item.discountPrice.toLocaleString("pt-br", {
																	style: "currency",
																	currency: "BRL",
																})}
															</button>
														)}
													</td>
												) : (
													<td className="pt-3 text-center">
														{item.discountPrice.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
														})}
													</td>
												)}

												{/* <td className="pt-3 text-center">{item.totalAmount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td> */}
												<td className="pt-3 text-center">
													{Math.sign(item.totalAmount) === -1
														? totalValue.toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
														  })
														: item.totalAmount.toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
														  })}
												</td>

												{edit === true && (
													<td>
														<button
															type="button"
															className="btn btn-light btn-sm outline btn-icon border-0"
															data-toggle="modal"
															data-target="#cancelProduct"
															onClick={() =>
																removeList(
																	item.id,
																	item.sku.productSku,
																	item.sku.code,
																	item.sku.imageUrl
																)
															}
														>
															<i className="material-icons text-danger">
																delete_outline
															</i>
														</button>
													</td>
												)}

												<div
													className="modal fade"
													id="cancelProduct"
													tabindex="-1"
													role="dialog"
													aria-labelledby="cancelProduct"
													aria-hidden="true"
												>
													<div className="modal-dialog" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<div className="d-flex flex-column">
																	<h5
																		className="modal-title mb-2"
																		id="cancelProduct"
																	>
																		Deseja excluir o produto do pedido?
																	</h5>
																	<span style={{ color: "#4B5F79" }}>
																		Atenção! Essa ação não poderá ser revertida!
																	</span>
																</div>
																<button
																	type="button"
																	className="close text-danger"
																	data-dismiss="modal"
																	aria-label="Close"
																>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body ml-0">
																<div className="d-flex align-items-center justify-content-start m-0 p-0">
																	<div className="mr-4">
																		<img
																			src={
																				!imageSku
																					? "/images/placeholder.jpg"
																					: imageSku
																			}
																			alt="Produto"
																			width="40px"
																			className="shadow-sm mx-auto d-block"
																		/>
																	</div>
																	<span className="text-start pt-2 pb-1">
																		{nameSku}
																		<br />
																		<em
																			style={{ fontSize: "11px" }}
																			className="p-0 m-0"
																		>{`Cód: ${codeSku}`}</em>
																	</span>
																</div>
															</div>
															<div className="modal-footer">
																<button
																	type="button"
																	className="btn btn-danger"
																	data-dismiss="modal"
																	onClick={deleteItemOrder}
																>
																	Sim, excluir
																</button>
																<button
																	type="button"
																	className="btn btn-light"
																	data-dismiss="modal"
																>
																	Cancelar
																</button>
															</div>
														</div>
													</div>
												</div>
											</tr>
										))}
									</tbody>
								)}
							</table>
						) : null}

						{loading === true ? null : (
							<>
								<div className="w-100 d-flex justify-content-end">
									<div className="total mt-3">
										<div className="">
											{amount.subTotalAmount !== 0 && (
												<span className="title-1">SubTotal:</span>
											)}
											{promotion === "" && (
												<span className="title-1">Código promocional:</span>
											)}
											{amount.discountAmount !== 0 && (
												<span className="title-1">Desconto:</span>
											)}
											{amount.feeAmount !== 0 && (
												<span className="title-1">Taxas:</span>
											)}
											{amount.freightAmount !== 0 && (
												<span className="title-1">Frete:</span>
											)}
										</div>
										<div className="">
											{amount.subTotalAmount !== 0 && (
												<span className="title-2">
													<strong>
														{amount.subTotalAmount.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
														})}
													</strong>
												</span>
											)}
											{promotion === "" && (
												<span className="title-2">{promotion}</span>
											)}
											{amount.discountAmount !== 0 && (
												<span className="title-2">
													<strong>
														{amount.discountAmount.toLocaleString("pt-br", {
															style: "currency",
															currency: "BRL",
														})}
													</strong>
												</span>
											)}
											{amount.feeAmount !== 0 && (
												<span className="title-2">
													<strong>{`${amount.feeAmount.toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}`}</strong>
												</span>
											)}
											{amount.freightAmount !== 0 && (
												<span className="title-2">
													<strong>{`${amount.freightAmount.toLocaleString(
														"pt-br",
														{ style: "currency", currency: "BRL" }
													)}`}</strong>
												</span>
											)}
										</div>
									</div>
								</div>
								{amount.totalAmount !== 0 && (
									<div className="d-flex justify-content-end mr-4 mt-2">
										<h2
											style={{
												fontWeight: "normal",
												fontSize: "18px",
												lineHeight: "21px",
												color: "#4B5F79",
											}}
										>
											Total do Pedido:{" "}
											<strong style={{ paddingLeft: "100px" }}>
												{amount.totalAmount !== 0 &&
													`${amount.totalAmount.toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}`}
											</strong>
										</h2>
									</div>
								)}
							</>
						)}
						{loading === true && <div className="m-0 p-0">{skeleton()}</div>}

						<div className="m-0 mt-4 p-0">
							{status === 4 &&
								localStorage.getItem("useOrderEditor") === "true" && (
									<div className=" d-flex justify-content-between">
										<button
											type="button"
											className="btn btn-danger outline"
											data-toggle="modal"
											data-target="#cancelOrder"
										>
											Cancelar Pedido
										</button>
										{edit === true ? (
											<div>
												<button
													type="button"
													className="btn btn-titles outline mr-2"
													onClick={cancelEdit}
												>
													Cancelar Edição
												</button>
												<button
													type="button"
													className="btn btn-success"
													onClick={saveOrder}
												>
													Salvar Pedido
												</button>
											</div>
										) : (
											<button
												type="button"
												className="btn btn-titles"
												onClick={() => setEdit(true)}
											>
												Editar Pedido
											</button>
										)}
									</div>
								)}
						</div>
						<Modal
							id={"cancelOrder"}
							title={"Deseja deletar este pedido pedido?"}
							confirm={true}
							actionDelete={cancelOrder}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
