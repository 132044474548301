import React, { Component, useEffect, useState } from 'react';
import Sidebar from './Sidebar'
import Content from './Content/Index'
import App from './App/Index'

export function Index(props) {

	const content = {
		title: 'Gestão do conteúdo',
		conteudo: [
			{
				icon: 'chrome_reader_mode',
				title: 'Termos de uso',
				link: '/channel-management/content/terms-of-use',
				active: props.match.params.screen === 'terms-of-use' ? true : false,
				EnableUserPromotion: true,
			},
			{
				icon: 'chrome_reader_mode',
				title: 'Política de Privacidade',
				link: '/channel-management/content/privacy-policy',
				active: props.match.params.screen === 'privacy-policy' ? true : false,
				EnableUserPromotion: true,
			},
			{
				icon: 'chrome_reader_mode',
				title: 'Regulamento',
				link: '/channel-management/content/promotion-policy',
				active: props.match.params.screen === 'promotion-policy' ? true : false,
				EnableUserPromotion: localStorage.getItem('EnableUserPromotion') === 'true' ? true : false,
			},
			{
				icon: 'live_help',
				title: 'Perguntas frequentes',
				link: '/channel-management/content/faq',
				active: props.match.params.screen === 'faq' ? true : false,
				EnableUserPromotion: true,
			}

		]
	}

	const app = {
		title: 'Áreas do APP',
		conteudo: [
			{
				icon: 'burst_mode',
				title: 'Banner de Carrossel',
				link: '/channel-management/app/carousel-banner',
				active: props.match.params.screen === 'carousel-banner' ? true : false,
				EnableUserPromotion: true,
			},
			{
				icon: 'view_day',
				title: 'Banner de Corpo',
				link: '/channel-management/app/body-banner',
				active: props.match.params.screen === 'body-banner' ? true : false,
				EnableUserPromotion: true,
			},
			/* {
					icon: 'view_stream',
					title: 'Vitrine de produtos',
					link: '',
					active: props.match.params.screen === '' ? true : false,
					EnableUserPromotion: true,
			}, */
			/* {
					icon: 'settings',
					title: 'Configurações do APP',
					link: '/channel-management/app/config',
					active: props.match.params.screen === 'config' ? true : false,
					EnableUserPromotion: true,
			}, */
			{
				icon: 'live_help',
				title: 'Tutorial',
				link: '/channel-management/app/tutorial',
				active: props.match.params.screen === 'tutorial' ? true : false,
				EnableUserPromotion: true,
			}
		],
	}


	

	return (
		<div className="container dash-content" >
			<div className="row">
				<div className="col-sm-4">
					<Sidebar menu={props.match.params.type === 'content' ? content : app} />
				</div>
				<div className="col-sm-8">
					{props.match.params.type === 'content' ?
						<Content screen={props.match.params.screen} /> :
						<App screen={props.match.params.screen} url={props} />
					}
				</div>
			</div>
		</div>
	)
};

export default Index;
