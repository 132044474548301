import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs }, props) => {
  const [params, setParams] = useState('')
  useEffect(() => {
    //console.log(localStorage.getItem('PARAMS'))
    setParams(localStorage.getItem('PARAMS'))
    //console.log(localStorage.getItem('PARAMS'))
    
  }, [props.crumbs])
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {crumbs.map(({ name, path }, key) =>
            key + 1 === crumbs.length ? (
              <Link key={key} className="breadcrumb-item d-flex align-items-center active" to={`${path}${params? `?${params}` : '' }`}>
                {name}
              </Link>
            ) : (
                <>
                  <Link key={key} className="breadcrumb-item d-flex align-items-center" to={`${path}${params? `?${params}` : '' }`}>
                    {name}
                  </Link>

                </>
              )
          )}

        </ol>
      </nav>

    </div>
  );
};

export default Breadcrumbs;