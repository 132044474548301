import React, { Component, useState } from "react";
import { API, APIV2 } from "../API";
//import CheckBoxTree from '../../../components/CheckBoxTree';
import Loading from "./Loading";
import Modal from "./Modal";
import { Tree } from "antd";
import { ToastContainer, toast } from "react-toastify";
const { TreeNode } = Tree;
const treeData = [
	{
		title: "parent 1",
		key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f11",
		children: [
			{
				title: "parent 1-0",
				key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f1",
				disabled: true,
				children: [
					{
						title: "leaf",
						key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f124",
						disableCheckbox: true,
					},
					{
						title: "leaf",
						key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f2",
					},
				],
			},
			{
				title: "parent 1-1",
				key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f",
				children: [
					{
						title: <span style={{ color: "#1890ff" }}>sss</span>,
						key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f123",
					},
				],
			},
		],
	},
];
const newTree = [
	{ title: "Bicicleta", key: "755f88a3-02ed-47ee-8eb6-0ee33784252a" },
	{ title: "Eletrodomésticos", key: "8129f502-eaf4-435a-af51-11f6945d0671" },
	{ title: "Informática", key: "ff06d9e1-ee4a-4569-b310-1e04bf847ebf" },
	{
		title: "Instrumentos Musicais",
		key: "d8cb69f6-a7f3-4068-9878-2153ebfa1fe9",
	},
	{ title: "Eletroportáteis", key: "ad3cb45a-6533-4244-bcd9-3660a9f950ca" },
	{ title: "Quarto", key: "2502bf7c-9e12-4850-8c1f-46b282ccd9d6" },
	{ title: "Home", key: "3216c0c7-7294-42ee-9753-4b1f9eb0c97c" },
	{ title: "Automotivo", key: "33154d23-b59c-40c5-bcfb-5ed1447f589e" },
	{ title: "", key: "0e13674b-f3e1-4d84-a2fe-7cf0c24bfe20" },
	{ title: "Telefonia", key: "b2ac66e4-f0ca-4671-9f9a-8a688a927c3f" },
	{ title: "Cama box e Colchões", key: "efe2e291-6d89-48f0-b722-be2cd2074476" },
	{ title: "Eletrônicos", key: "f3848206-62ac-4883-84d1-c34dcc9dca0f" },
	{ title: "Beleza e Saúde", key: "9b4d2a22-3cc1-4d4b-ba55-ca7144c30656" },
	{ title: "Esporte e Lazer", key: "35a859e7-9db6-4301-933b-d03d1175a6ca" },
	{ title: "Ferramentas", key: "7611e93d-1ce9-4558-89bf-d5ddd5f6b170" },
	{ title: "Infantil", key: "637ee66f-e683-4244-851f-ebe779147af5" },
	{ title: "Móveis", key: "24776c27-e01b-4770-b57f-f22c5f49c5e0" },
	{
		title: "Utilidades Domésticas",
		key: "f24b36c8-50c5-4ee5-85cf-faf3afd193e5",
	},
];

export class CategoriesList extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.selectCategorie = this.selectCategorie.bind(this);
		this.save = this.save.bind(this);
	}
	state = {
		loading: true,
		list: [],
		categoriesSelect: [],
		newList: [],
	};

	UNSAFE_componentWillReceiveProps() {
		this.setState({ categoriesSelect: this.props.editValue });
		this.forceUpdate();
	}

	componentWillMount() {
		//Consulta materiais disponíveis a partir do token do usuário.
		API.get("api/v2/categories/getlist/")
			.then(async (response) => {
				// If request is good...
				this.setState({
					list: response.data,
					loading: false,
				});
				if (this.state.list.length > 0) {
					let newListItem = this.state.list.map((item) => {
						return {
							title: item.name,
							key: item.id,
							children: item.children.map((subitem) => {
								return {
									title: subitem.name,
									key: subitem.id,
									children: subitem.children.map((subitemChildren) => {
										return {
											title: subitemChildren.name,
											key: subitemChildren.id,
										};
									}),
								};
							}),
						};
					});

					this.setState({ newList: newListItem });
				}
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}
	componentDidMount() {
		this._isMounted = true;
		this.setState({ categoriesSelect: this.props.editValue });
		this.forceUpdate();
	}
	async selectCategorie(item) {
		await this.setState({ categoriesSelect: item });
	}
	save() {
		let categ = [];
		for (let i = 0; i < this.state.categoriesSelect.length; i++) {
			var item = {
				id: this.state.categoriesSelect[i],
			};
			categ.push(item);
		}
		this.props.newCondition(categ);
	}
	render() {
		if (this.state.loading) {
			return <Loading />;
		} else {
			return this.renderScreen();
		}
	}
	renderScreen() {
		const onCheck = async (checkedKeys, info) => {
			let checked;
			if (this.props.singleChecked === true) {
				if (checkedKeys.checked.length > 1) {
					checked = await checkedKeys.checked.shift();
				} else {
					checked = checkedKeys.checked;
				}
				this.setState({ categoriesSelect: checked });
				this.props.newCondition(checkedKeys.checked[0]);
				this.forceUpdate();
			} else {
				this.setState({ categoriesSelect: checkedKeys.checked });
				this.props.newCondition(checkedKeys.checked);
				this.forceUpdate();
			}
		};

		return (
			<div className="categories-list">
				<Tree
					checkable
					checkStrictly={true}
					defaultCheckedKeys={this.state.categoriesSelect}
					defaultExpandedKeys={this.state.categoriesSelect}
					onCheck={onCheck}
					treeData={this.state.newList}
				/>
			</div>
		);
	}
}

export default CategoriesList;
