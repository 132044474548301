export class Invoice {

	constructor(information, items) {
		console.log(information)
		this.items = items
		this.state = {
			image: information.image,
			friendlyCode: information.order.friendlyCode,
			createDate: information.order.createDate,
			isDelivery: information.order.isDelivery,
			
			feeAmount: information.amount.feeAmount,
			discountAmount: information.amount.discountAmount,
			freightAmount: information.amount.freightAmount,
			totalAmount: information.amount.totalAmount,
			
			sellerFullName: information.seller.fullName,
			sellerCnpj: information.seller.cnpj,
			sellerEmail: information.seller.email,
			sellerAdressStreet: information.seller.address.street,
			sellerAdressNumber: information.seller.address.number,
			sellerAdressComplement: information.seller.address.complement,
			sellerAdressNeighborhood: information.seller.address.neighborhood,
			sellerAdressCity: information.seller.address.city,
			sellerAdressState: information.seller.address.state,
			sellerAdressCep: information.seller.address.cep,
			
			buyerFullName: information.buyer.fullName,
			buyerCpf: information.buyer.cpf,
			buyerEmail: information.buyer.email,
			buyerAddressStreet: information.buyer.address.street,
			buyerAddressNumber: information.buyer.address.number,
			buyerAddressComplement: information.buyer.address.complement,
			buyerAddressNeighborhood: information.buyer.address.neighborhood,
			buyerAddressCity: information.buyer.address.city,
			buyerAddressState: information.buyer.address.state,
			buyerAddressCep: information.buyer.address.cep,
			
			info: information.paymentInfo.paymentMethod.info,
			acquirerMessage: information.paymentInfo.acquirerMessage,
			fraudStatus: information.paymentInfo.fraudStatus,
			acquirerApprovalCode: information.paymentInfo.acquirerApprovalCode,
			nsu: information.paymentInfo.nsu,
			paymentStatus: information.paymentInfo.paymentStatus,
			quantityInstallments: information.paymentInfo.quantityInstallments
		}
	}
	async CreateDocument() {
		const bodyDocument = this.createBodyDocument();
		const documento = this.createDocument(bodyDocument);
		return documento;
	}
	createBodyDocument() {

		const header = [
			
			{ text: 'Nº', bold: true, color: '#747E8B', fontSize: 9, margin: [0, 4, 0, 4] },
			/* { text: 'CATEGORIA', bold: true, color: '#747E8B', fontSize: 9, margin: [0, 4, 0, 4] }, */
			{ text: 'ITEM', bold: true, color: '#747E8B', alignment: 'left', fontSize: 9, margin: [0, 4, 0, 4] },
			{ text: 'QTD', bold: true, color: '#747E8B', alignment: 'center', fontSize: 9, margin: [0, 4, 0, 4] },
			{ text: 'PREÇO UNIT', bold: true, color: '#747E8B', alignment: 'center', fontSize: 9, margin: [0, 4, 0, 4] },
			{ text: 'TOTAL', bold: true, color: '#747E8B', alignment: 'right', fontSize: 9, margin: [0, 4, 0, 4] },
			
		];
		const body = this.items.map((item, i) => {
			return [
				{ text:`\n${i+1}\n\n`, color: '#BCBEC0', fontSize: 8, border: [false, true, false, false] },
				/* { text:'\nLojas\n', color: '#747E8B', alignment: 'center', fontSize: 8, border: [false, true, false, false] }, */
				{ text:`\n${item.sku.productSku}\n EAN: ${'7898963108580'} CÓD: ${'475022'}\n`, color: '#747E8B', alignment: 'left', fontSize: 8, border: [false, true, false, false] },
				{ text:`\n${item.quantity}\n`, color: '#747E8B', bold: true, alignment: 'center', fontSize: 8, border: [false, true, false, false] },
				{ text:`\n${item.unitPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`, alignment: 'center', color: '#BCBEC0', fontSize: 8, border: [false, true, false, false] },
				{ text:`\n${item.totalPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`, alignment: 'right', color: '#747E8B', fontSize: 8, border: [false, true, false, false] },
			];
		});

		let content = [header];
		content = [...content, ...body];
		return content;
	}
	createDocument(bodyDocument) {
		const documento = {
			pageSize: 'A4',
			pageMargins: [30, 30, 30, 0],
			content: [
				/* header pdf */
				{columns: [
						{ image: 'snow', alignment: 'left', width: 120, height: 50 },
						{ text: [
								{ text: [
										{ alignment: 'right',text: 'Pedido\n', bold: true, color: '#4B5F79', fontSize: 16 },
										{ alignment: 'right', text: { alignment: 'right', text: `Pedido nº ${this.state.friendlyCode}\n`, bold: true, color: '#4B5F79', fontSize: 13 }},
										{ alignment: 'right', text: {alignment: 'right',text: `Data do pedido: ${this.state.createDate}\n\n\n`, bold: true,color: '#4B5F79',fontSize: 13 }
										},
									]
								},
							],
						},
					]
				},
				/* body pdf */
				{ text: 'Dados do Cliente\n',bold: true, color: '#4B5F79',fontSize: 12 },
				{ text: '__________________________________________________________________________________________________\n\n',bold: true,color: '#E0E3E7',fontSize: 12 },
				{columns:[
					{text:[
						{text:[
							{ alignment: 'left',text: `Data:  ${this.state.createDate}\n`,bold: true,color: '#747E8B',fontSize: 10,},
							{ alignment: 'left',text: `Total:  ${this.state.totalAmount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`,bold: true,color: '#747E8B',fontSize: 10,},
							{ alignment: 'left',text: `Modalidade: ${this.state.isDelivery === false ? "Retire na Loja" : "Receba em Casa"}\n`,bold: true,color: '#747E8B',fontSize: 10,},
						]},
					]},
					{text: [
						{text:[
							/* {alignment: 'left',text:`Transportadora: ${''}\n`,bold: true,color: '#747E8B',fontSize: 10,},
							{alignment: 'left',text:`Estoque: ${''}\n`,bold: true,color: '#747E8B',fontSize: 10,}, */
							{alignment: 'left',text: `Pagamento: ${
									this.state.paymentStatus === 0  ? "NÃO INICIADO" : 
									this.state.paymentStatus === 1  ? "EM ANÁLISE" : //Aguardando pré-aprovação 
									this.state.paymentStatus === 2  ? "EM ANÁLISE" : //Aguardando captura
									this.state.paymentStatus === 3  ? "EM ANÁLISE" : //Aguardando pagamento direto
									this.state.paymentStatus === 4  ? "EM ANÁLISE" : //Aguardando estorno
									this.state.paymentStatus === 5  ? "PRÉ-APROVADO" :
									this.state.paymentStatus === 6  ? "PAGO" :
									this.state.paymentStatus === 7  ? "ESTORNADO" :
									this.state.paymentStatus === 8  ? "ERRO" : //Erro de pré-aprovação
									this.state.paymentStatus === 9  ? "ERRO" : //Erro de captura
									this.state.paymentStatus === 10 ? "ERRO" : //Erro de pagamento
									this.state.paymentStatus === 11 ? "ERRO" : //Erro de estorno
									this.state.paymentStatus === 12 ? "Pagamento na criação do pedido" :
									this.state.paymentStatus === 13 ? "BOLETO CRIADO" :
									this.state.paymentStatus === 14 ? "EM ANÁLISE" : //Aguardando nova tentativa
									this.state.paymentStatus === 15 ? "Retentativas expiradas" :
									this.state.paymentStatus === 16 ? "AGUARDANDO PAGAMENTO" :
									this.state.paymentStatus === 17 ? "PRÉ-APROVADO" :
									this.state.paymentStatus === 18 ? "APROVADO" :
									null
							}\n`, bold: true,color: '#747E8B',fontSize: 10,},
						]},
					]},
				]},
				{text: '__________________________________________________________________________________________________\n\n',bold: true,color: '#E0E3E7',fontSize: 12,},
				{
					alignment: 'left',
					columns: [
						{text: 
							[
								{ text: `PEDIDO PARA: \n\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.sellerFullName}\n`, bold: true, color: '#4B5F79', fontSize: 14, },
								{ text: `${this.state.sellerCnpj}\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.sellerEmail}\n`, bold: true, color: '#7A9FEB', fontSize: 10, },
								{ text: `${this.state.sellerAdressStreet} ${this.state.sellerAdressNumber == null ? "" : ( '- '+this.state.sellerAdressNumber+' -') }  ${this.state.addressComplement == null ? "" : this.state.addressComplement == null ? "" : this.state.addressComplement }\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.sellerAdressNeighborhood} ${this.state.sellerAdressCity === "" ? "" : ('- '+this.state.sellerAdressCity+' -')} ${this.state.sellerAdressState}\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.sellerAdressCep == null ? "" : this.state.sellerAdressCep}\n\n`, bold: true, color: '#747E8B', },
							]
						},
						{ text: 
							[
								{ text: `PEDIDO DE: \n\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.buyerFullName}\n`, bold: true,color: '#4B5F79', fontSize: 14, },
								{ text: `${this.state.buyerCpf }\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.buyerEmail}\n`, bold: true, color: '#7A9FEB', fontSize: 10, },
								{ text: `${this.state.buyerAddressStreet} ${this.state.buyerAddressNumber === "" ? "" : ('- '+this.state.buyerAddressNumber+' -')} ${this.state.buyerAddressComplement == null ? "" : this.state.buyerAddressComplement}\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.buyerAddressNeighborhood } ${this.state.buyerAddressCity === "" ? "" : ('- '+this.state.buyerAddressCity+' -')} ${this.state.buyerAddressState}\n`, bold: true, color: '#747E8B', fontSize: 10, },
								{ text: `${this.state.buyerAddressCep}\n\n`, bold: true, color: '#747E8B', fontSize: 10, },
							]
						},
					]
				},
				{
					text:`Itens do Pedido (${'2'})\n\n`,
					bold: true,
					color: '#4B5F79',
					fontSize: 12,
				},
	
				/* table pdf */
				{
					table: {
						headerRows: 1,
						widths: [25, 320, 25, 60, 50],
						body: bodyDocument
					},
					layout: {
						hLineColor: function (i, node) {
							return (i === 0 || i === node.table.body.length) ? '#E0E3E7' : '#E0E3E7';
						},
						vLineColor: function (i, node) {
							return (i === 0 || i === node.table.widths.length) ? '#ffffff' : '#ffffff';
						},
					}
					
				},

				{
					text: '---------------------------------------------------------------------------------------------------------------------------------------\n',
					bold: true,
					color: '#E0E3E7',
					fontSize: 12,
				},

				{
					defaultStyle: {alignment: 'right'},
					layout: 'noBorders',
					alignment: 'right',
					table: {
						widths: ['*', 50],
						body: [
							[
								{text: `Frete:`, fontSize: 10, bold: true, color: '#747E8B', alignment: 'right'}, 
								{text: `${this.state.freightAmount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, fontSize: 10, color: '#747E8B', noWrap: true}
							],
							[
								{text: `Desconto:`, fontSize: 10, bold: true, color: '#747E8B', alignment: 'right'}, 
								{text: `${this.state.discountAmount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, fontSize: 10, color: '#747E8B', noWrap: true}
							],
							[
								{text: `Taxas:`, fontSize: 10, bold: true, color: '#747E8B', alignment: 'right'}, 
								{text: `${this.state.feeAmount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, fontSize: 10, color: '#747E8B', noWrap: true}
							],
							[
								{text: `------------------`, fontSize: 10, bold: true, color: '#E0E3E7', alignment: 'right'},
								{text: `------------------`, fontSize: 10, bold: true, color: '#E0E3E7', noWrap: true}
							],
							[
								{text: `Valor Total:`, fontSize: 12, bold: true, color: '#747E8B', alignment: 'right'}, 
								{text: `${this.state.totalAmount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`, fontSize: 12, color: '#747E8B', noWrap: true}
							],
						]
					}
				},
				/* payment pdf */
				{ text:`\n\nPagamento\n`,bold: true,color: '#4B5F79',fontSize: 12 },
				{text: '__________________________________________________________________________________________________\n\n',bold: true,color: '#E0E3E7',fontSize: 12 },
				{
					alignment: 'left',
					columns:[
						{text:[
							{text:[
								{text: `Método de pagamento: `, bold: true, color: '#747E8B', fontSize: 12},
								{text: `${this.state.info}\n`, color: '#747E8B', fontSize: 11},
							]},
							{ text:[
								{text:`Parcelas: `, bold: true, color: '#747E8B', fontSize: 12, },
								{text:`${this.state.quantityInstallments}\n`, color: '#747E8B', fontSize: 11, },	
							]},
							{text: [
								{ text: `Mensagem do adquirente: `, bold: true, color: '#747E8B', fontSize: 12, },
								{ text: `${this.state.acquirerMessage == null ? "" : this.state.acquirerMessage}\n`, color: '#747E8B', fontSize: 11, },

							]},
							{text: [
								{ text: `Status anti-fraude: `, bold: true, color: '#747E8B', fontSize: 12, },
								{ text: `${this.state.fraudStatus}\n`, color: '#747E8B', fontSize: 11, },
							]},
							{text: [
								{ text: `Código de aprovação: `, bold: true, color: '#747E8B', fontSize: 12, },
								{ text: `${this.state.acquirerApprovalCode == null ? "" : this.state.acquirerApprovalCode}\n`, color: '#747E8B', fontSize: 11, },
							]},
							{text: [
								{ text: `NSU: `, bold: true, color: '#747E8B', fontSize: 12, },
								{ text: `${this.state.nsu == null ? "" : this.state.nsu}\n`, color: '#747E8B', fontSize: 11, },
							]},
						]},
					]
				},

				
			],

			images: {
				snow: `${this.state.image}`
			},
			layout: {
				defaultBorder: false,
			},
			
			footer(currentPage, pageCount) {
				console.log(currentPage, pageCount)
				return {
					layout: 'noBorders',
					margin: [14, 0, 14, 22],
					table: {
						widths: ['auto'],
						body: [
							[{
								text: '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
								alignment: 'center',
								fontSize: 5,
							}, ],
							[
								[{
										text: `Página ${currentPage.toString()} de ${pageCount}`,
										fontSize: 7,
										alignment: 'right',
										/* horizontal, vertical */
										margin: [3, 0],
									},
								],
							],
						],
					},
				};
			},
			styles: {
				reportName: {
					fontSize: 9,
					bold: true,
					alignment: 'center',
					margin: [0, 4, 0, 0],
				},
				tableExample: {
					margin: [0, 5, 0, 15]
				},
				tableHeader: {
					bold: true,
					fontSize: 13,
					color: 'black'
				}
			},

		};
		return documento;
	}
}