import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { toast } from 'react-toastify';
import IntegrationState from './IntegrationState';

// import { Container } from './styles';

function AllIntegrationsState() {

  const [integration, setIntegrations] = useState([])
  const [reload, setReload] = useState(true)
  const [load, setLoad] = useState(true)
  const [error, setError] = useState([])

  useEffect(() => {
    getIntegration()

    setInterval(async () => {
      getIntegration()
    }, 60000);
  }, [])


  const getIntegration = async () => {
    // let list;
    try {
      const response = await Axios.get(`${process.env.REACT_APP_API_MICROSOFT}api/triggers`)
      let list = response.data
      let reducedList = []
      let foundedErrorList = []

    list.forEach(item => {
      if (item.isActive && !(item.message === 'Finalized' || item.message === 'Running')  ) {
        foundedErrorList.push(item)
      }
    })

    setError(foundedErrorList)


      list.forEach((item) => {
        let duplicated = reducedList.findIndex(redItem => {
          return item.clientId === redItem.clientId
        }) > -1
        if (!duplicated) {
          reducedList.push(item)
        }
      })
      await setIntegrations(reducedList)
      setReload(!reload)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      console.log(error)
      toast.error('Parece que tivemos um erro... Tente novamente mais tarde.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        preventDuplicate: true,
      });
    }
  }

  const skeleton = () => {
    const skele = [0, 1, 2, 3, 4]
    return (

      skele.map(() =>
        <ContentLoader
          speed={2}
          width={1100}
          height={60}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 1100 60"
          style={{ width: '100%' }}

        >
          <rect x="40" y="26" rx="3" ry="3" width="200" height="10" />
          <rect x="1000" y="26" rx="3" ry="3" width="50" height="10" />
        </ContentLoader>
      )

    )
  }


  const renderItemList = (client) => (
    <div class="card card-body col-12 mb-2" style={{ padding: '10px' }}>
      <a className="col-12 d-flex justify-content-between" data-toggle="collapse" style={{ padding: '0px', color: '#343a40' }} href={`#${client.clientId}`} role="button" aria-expanded="false" aria-controls={client.clientId}>
        <span className="d-flex justify-content-between">
          <span style={{ marginRight: 10 }}>

              {client.isActive && !(client.message === 'Finalized' || client.message === 'Running')? <i className="material-icons" style={{color: '#FF5B5C'}}>error</i> : <i className="material-icons" style={{color: '#6CD691'}}>check_circle</i>}

          </span>
          {client.clientId}
        </span>
        <span>
          <i className="material-icons">add</i>
        </span>
      </a>
      <div class="collapse mt-3" id={client.clientId}>
        <IntegrationState reload={reload} clientId={client.clientId} />
      </div>
    </div>
  )

  return (
    <div className="card "  >
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4 class="card-title">Integrações</h4>
        <div class="card-actions">
          <button className="btn-sm btn-info light d-flex align-items-center" onClick={() => getIntegration()}>
            <i className="material-icons">
              autorenew
              </i>
          </button>
        </div>
      </div>
      <div className="card-body p-2">
        {load ?
          skeleton()
          :
          <div className="conditions" style={{ height: '304px', overflowX: 'auto', padding: '10px' }}>
            {integration.map((client) => renderItemList(client))}
          </div>
        }
      </div>
    </div>
  );
}

export default AllIntegrationsState;