import React, { Component } from 'react';

class PreviewAppBottomNavigationTutorial extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="bottom-navigation">
                <div className="action-item" >
                    CANCELAR
                </div>
                <div className="action-item">
                  1 de 3
                </div>
                <div className="action-item" style={{color: localStorage.getItem('primaryColor')}}>
                    PRÓXIMO
                </div>
            </div>
        )
    }
};

export default PreviewAppBottomNavigationTutorial;
