import React, { Component } from 'react';
import {API} from '../API';


class HorarioAtendimento extends Component {

  constructor(props) {
    super(props)
    this.state = {
      workingHours: [
        {
          "sellerId": this.props.workingId,
          "dayWeek": "0",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        },
        {
          "sellerId": this.props.workingId,
          "dayWeek": "1",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        },
        {
          "sellerId": this.props.workingId,
          "dayWeek": "2",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        },
        {
          "sellerId": this.props.workingId,
          "dayWeek": "3",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        },
        {
          "sellerId": this.props.workingId,
          "dayWeek": "4",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        },
        {
          "sellerId": this.props.workingId,
          "dayWeek": "5",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        },
        {
          "sellerId": this.props.workingId,
          "dayWeek": "6",
          "lunchOpenHour": "string",
          "lunchCloseHour": "string",
        }
      ]
    }
    this.onChange = this.onChange.bind(this);
  }
  componentWillMount() {
  
    var id = this.props.id

    //Consulta materiais disponíveis a partir do token do usuário.
    if (this.props.id != undefined) {
      API.get('api/workinghours?sellerId=' + id)
        .then(async response => {
          // If request is good...
          await this.setState({
            workingHours: response.data
          })
          this.forceUpdate();
         

        })
        .catch((error) => {
          console.log(error)
        })
    }

  }

  onChange(e) {

    var dia = e.target.name.split('-')[0];
    var tipo = e.target.name.split('-')[1];
    var result = this.state.workingHours.findIndex(element => element.dayWeek == dia); //Index correspondente ao dia digitado

    if (tipo == 'open') {
   
      this.state.workingHours[result] = { ...this.state.workingHours[result], openHour: e.target.value }
      this.forceUpdate();
    } else {
   
      this.state.workingHours[result] = { ...this.state.workingHours[result], closeHour: e.target.value }
      this.forceUpdate();
    }
    this.props.change(this.state.workingHours)


  }
  render() {

    return (
      <div className="row mt-20 second">
        <div className='col-12'>
          <b>Horário de funcionamento</b>
          <div className="">

             <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="0">
                  Domingo
                </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='0-open' defaultValue={this.state.workingHours[0].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='0-close' defaultValue={this.state.workingHours[0].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="1">
                  Segunda-feira
     </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='1-open' defaultValue={this.state.workingHours[1].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='1-close' defaultValue={this.state.workingHours[1].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="2">
                  Terça-feira
     </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='2-open' defaultValue={this.state.workingHours[2].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='2-close' defaultValue={this.state.workingHours[2].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="3">
                  Quarta-feira
     </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='3-open' defaultValue={this.state.workingHours[3].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='3-close' defaultValue={this.state.workingHours[3].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="4">
                  Quinta-feira
     </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='4-open' defaultValue={this.state.workingHours[4].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='4-close' defaultValue={this.state.workingHours[4].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="5">
                  Sexta-feira
     </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='5-open' defaultValue={this.state.workingHours[5].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='5-close' defaultValue={this.state.workingHours[5].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group row mt-20">
              <div className="col-sm-3">

                <label className="form-check-label" htmlFor="6">
                  Sábado
     </label>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='6-open' defaultValue={this.state.workingHours[6].openHour || ''} onChange={this.onChange} />
              </div>
              <div className="col-sm-1">
                <i className="material-icons">
                  arrow_forward
</i>
              </div>
              <div className="col-sm-4">
                <input type="text" className="form-control" name='6-close' defaultValue={this.state.workingHours[6].closeHour || ''} onChange={this.onChange} />
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}
export default HorarioAtendimento