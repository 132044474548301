import React, { Component } from 'react';

class PreviewAppBottomNavigation extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="bottom-navigation">
                <div className="action-item" style={{color: localStorage.getItem('primaryColor')}}>
                    <i className="material-icons">home</i>
                    <span className="label">
                        Início
                    </span>
                </div>
                <div className="action-item">
                    <i className="material-icons">view_list</i>
                    <span className="label">
                        Produtos
                    </span>
                </div>
                <div className="action-item">
                    <i className="material-icons">shopping_cart</i>
                    <span className="label">
                        Carrinho
                    </span>
                </div>
                <div className="action-item">
                    <i className="material-icons">more_horiz</i>
                    <span className="label">
                        Opções
                    </span>
                </div>
            </div>
        )
    }
};

export default PreviewAppBottomNavigation;
