import React, { useEffect, useState } from "react";
import { API } from "../../../../../API";
import Loading from "../../../../../components/Loading";
import { Tree } from "antd";
import { toast } from "react-toastify";

export default function CategoriesList({ newCondition }) {
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [categoriesSelect, setCategoriesSelect] = useState([]);
	const [newList, setNewList] = useState([]);
	const [selectAllCategory, setSelectAllCategory] = useState([]);
	const [categories, setCategories] = useState([]);
	const [checkedAll, setCheckedAll] = useState(false);

	useEffect(() => {
		API.get("api/v2/categories/getlist/")
			.then((response) => {
				const allCategories = [];
				response.data.forEach((category) => {
					category.children.forEach((childCategory) => {
						allCategories.push(childCategory.id);
					});
					allCategories.push(category.id);
				});
				setCategories(allCategories);
				setSelectAllCategory(allCategories);
				setList(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	}, []);

	useEffect(() => {
		if (list.length > 0 && newList.length <= list.length) {
			for (let i = 0; i < list.length; i++) {
				list[i]["title"] = list[i].name;
				list[i]["key"] = list[i].id;
				let newItem = {
					title: list[i].name,
					key: list[i].id,
				};
				setNewList((prevState) => [...prevState, newItem]);
				if (list[i].children.length > 0) {
					for (let e = 0; e < list[i].children.length; e++) {
						list[i].children[e]["key"] = list[i].children[e].id;
						list[i].children[e]["title"] = list[i].children[e].name;
						if (list[i].children[e].children.length > 0) {
							for (let h = 0; h < list[i].children[e].children.length; h++) {
								list[i].children[e].children[h]["key"] =
									list[i].children[e].children[h].id;
								list[i].children[e].children[h]["title"] =
									list[i].children[e].children[h].name;
							}
						}
					}
				}
			}
		}
	}, [list, checkedAll]);

	const save = () => {
		let item = {
			categoriesId: selectAllCategory,
			value: selectAllCategory,
		};
		let itemView = {
			name: "Categorias",
			value:
				categoriesSelect.length > 0
					? categoriesSelect.length
					: selectAllCategory.length + " Categorias",
			itemId: "categoriesId",
			modal: "#conditionModalCategories",
		};
		newCondition(item, itemView);
	};

	const onCheck = (checkedKeys, info) => {
		if (categoriesSelect.length > 0) {
			setCategoriesSelect(checkedKeys.checked);
		} else {
			setSelectAllCategory(checkedKeys.checked);
		}
	};

	const getAllCheckboxes = (e) => {
		if (e.target.checked) {
			setSelectAllCategory(categories);
		} else {
			setSelectAllCategory([]);
		}
		setCheckedAll(e.target.checked);
		setLoading(true);
		setInterval(() => {
			setLoading(false);
		}, 2000);
	};

	/* const renderScreen = () => { */
	return (
		<div
			class="modal fade"
			id="conditionModalCategories"
			tabindex="-1"
			role="dialog"
			aria-labelledby="conditionModalCategories"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title">Categorias</h3>
						<button
							type="button"
							className="close text-danger"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<span className="mb-2">
						Selecione abaixo a(s) categoria(s) que participarão do cupom:
					</span>
					<div className="custom-control custom-checkbox ml-2">
						<input
							type="checkbox"
							className="custom-control-input"
							id="checkedAll"
							onChange={(e) => getAllCheckboxes(e)}
						/>
						<label className="custom-control-label" for="checkedAll">
							Selecione todos
						</label>
					</div>

					<div className="modal-body tags checkbox-list">
						{loading ? (
							<div style={{ height: "200px" }}>
								<Loading />
							</div>
						) : (
							list.length > 0 &&
							(checkedAll ? (
								<Tree
									checkable
									defaultExpandAll={true}
									checkStrictly={true}
									onCheck={onCheck}
									treeData={list}
									defaultCheckedKeys={selectAllCategory}
								/>
							) : (
								<Tree
									checkable
									defaultExpandAll={false}
									checkStrictly={true}
									onCheck={onCheck}
									treeData={list}
									defaultCheckedKeys={categoriesSelect}
								/>
							))
						)}
					</div>

					<div
						className="modal-footer text-center"
						style={{ justifyContent: "center" }}
					>
						<button
							type="button"
							className="btn btn-light col-5"
							data-dismiss="modal"
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success col-5"
							data-dismiss="modal"
							onClick={save}
						>
							Pronto
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

/* return <>{renderScreen()}</>;
}*/
