import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { APIV2 } from "../../../../API";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";
import InputMask from "react-input-mask";
import SelectStores from "../../../../components/SelectStores";
import { useDealers } from "../providers/DealersContext";
import Select from "react-select";

export default function FormDealers(props) {
	const {
		dealers,
		setDealers,
		loading,
		updateDealer,
		createDealer,
		edit,
		setEdit,
	} = useDealers();
	const [storeCnpj, setStoreCnpj] = useState(null);
	const [positions, setPositions] = useState([
		{ label: "Nenhum", value: "" },
		{ label: "Vendedor(a)", value: "Salesperson" }, //0
		{ label: "Vendedor(a) Responsável", value: "ResponsibleSeller" }, //1
		{ label: "Supervisor(a)", value: "Supervisor" }, //2
		{ label: "Coordenador(a)", value: "Coordinator" }, //3
		{ label: "Subgerente", value: "AssistantManager" }, //4
		{ label: "Gerente", value: "Manager" }, //5
		{ label: "Sócio Proprietário", value: "Partner" }, //6
	]);

	const handleChangeCpf = (e) => {
		var filtro = e.target.value;
		var cpf = filtro.replace(/\D/gm, "");
		setDealers((prevState) => ({ ...prevState, salesmanCpf: cpf }));
	};

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setDealers((prevState) => ({ ...prevState, [name]: value }));
	};

	const createNewDealer = (e) => {
		if (edit) {
			var body = {
				clientId: localStorage.getItem("client_id").toLowerCase(),
				fullName: dealers.fullName,
				salesmanCpf: dealers.salesmanCpf,
				storeCnpj: storeCnpj == null ? dealers.storeCnpj : storeCnpj,
				dealerCode: dealers.dealerCode,
				id: dealers.id,
				isActive: dealers.isActive,
				role:
					dealers.role == "Vendedor(a)"
						? "Salesperson"
						: dealers.role == "Vendedor(a) Responsável"
						? "ResponsibleSeller"
						: dealers.role == "Supervisor(a)"
						? "Supervisor"
						: dealers.role == "Coordenador(a)"
						? "Coordinator"
						: dealers.role == "Subgerente"
						? "AssistantManager"
						: dealers.role == "Gerente"
						? "Manager"
						: dealers.role == "Sócio Proprietário"
						? "Partner"
						: "Partner",
			};
			updateDealer(body);
		} else {
			var body = {
				clientId: localStorage.getItem("client_id").toLowerCase(),
				fullName: dealers.fullName,
				salesmanCpf: dealers.salesmanCpf,
				storeCnpj: storeCnpj,
				dealerCode: dealers.dealerCode,
				isActive: dealers.isActive,
				role: dealers.role,
			};
			createDealer(body);
			setEdit(false);
		}
	};

	const customStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 17,
			},
		}),
	};

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">
					{edit ? "Editar Vendedor" : "Novo Vendedor"}
				</h4>
			</div>
			{loading ? (
				<Loading />
			) : (
				<div className="card-content">
					<div className="card-body pt-0">
						<div className="d-flex flex-column">
							<div className="row">
								<div className="form-group col-2">
									<label htmlFor="dealerCode">
										<strong>Nº de matrícula</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="dealerCode"
											placeholder="ABC123456"
											name="dealerCode"
											value={dealers.dealerCode}
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>
								<div className="form-group col-4">
									<label htmlFor="fullName">
										<strong>Nome completo</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="fullName"
											placeholder="John Doe"
											name="fullName"
											defaultValue={dealers.fullName}
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="form-group col-3">
									<label htmlFor="salesmanCpf">
										<strong>CPF</strong>
									</label>
									<div>
										<InputMask
											mask="999.999.999-99"
											className="form-control"
											type="text"
											placeholder="999.999.999-99"
											id="salesmanCpf"
											name="salesmanCpf"
											value={dealers.salesmanCpf}
											onChange={handleChangeCpf}
										/>
									</div>
								</div>
								<div className="form-group col-3">
									<label htmlFor="role">
										<strong>Cargo</strong>
									</label>
									<div>
										<Select
											styles={customStyles}
											className="react-select-container"
											placeholder="Mostrando Todos"
											value={
												!dealers.role
													? dealers.role
													: positions[
															positions.findIndex(
																(position) => position.label === dealers.role
															)
													  ]
											}
											options={positions}
											onChange={(event) =>
												setDealers({ ...dealers, role: event.value })
											}
										/>
										{/* <select
											className="form-control"
											id="role"
											placeholder="John Doe"
											name="role"
											value={dealers.role}
											onChange={(e) => handleChange(e)}
										>
											<option>Selecione</option>
											<option value="Vendedor(a)">
												{dealers.role == "Salesperson"
													? "Vendedor(a)"
													: "Vendedor(a)"}
											</option>
											<option value="Vendedor(a) Responsável">
												{dealers.role == "ResponsibleSeller"
													? "Vendedor(a) Responsável"
													: "Vendedor(a) Responsável"}
											</option>
											<option value="Supervisor(a)">
												{dealers.role == "Supervisor"
													? "Supervisor(a)"
													: "Supervisor(a)"}
											</option>
											<option value="Coordenador(a)">
												{dealers.role == "Coordinator"
													? "Coordenador(a)"
													: "Coordenador(a)"}
											</option>
											<option value="Subgerente">
												{dealers.role == "AssistantManager"
													? "Subgerente"
													: "Subgerente"}
											</option>
											<option value="Gerente">
												{dealers.role == "Manager" ? "Gerente" : "Gerente"}
											</option>
											<option value="Sócio(a)/Proprietário(a)">
												{dealers.role == "Partner"
													? "Sócio Proprietário"
													: "Sócio Proprietário"}
											</option>
										</select> */}
									</div>
								</div>
							</div>
							{localStorage.getItem("role") == 2 && (
								<div className="row">
									<div className="form-group col-6">
										<label htmlFor="storeCnpj">
											<strong>Loja</strong>
										</label>
										<div>
											<SelectStores
												storeCnpj={dealers.storeCnpj}
												setStoreCnpj={(e) => setStoreCnpj(e)}
											/>
										</div>
									</div>
								</div>
							)}
							<div className="row">
								<div className="form-group col-12">
									<button
										className="btn btn-success mr-3"
										type="submit"
										onClick={createNewDealer}
									>
										{edit ? "Editar vendedor" : "Criar vendedor"}
									</button>
									<button
										className="btn btn-light"
										onClick={() => (window.location.href = "/salespeople/1")}
									>
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
