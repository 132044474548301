import React from 'react';
import { useCategory } from '../providers/CategoriesContext';

// import { Container } from './styles';

function CreateNewCategory() {
  const { setOpenForm } = useCategory()
  
  return (
    <>
      <div class="d-flex align-items-center justify-content-center text-center" style={{ maxHeight: '820px', minHeight: '820px' }}>
        <div className="row">
          <div className="col-12" style={{ padding: '0.5em 8em' }}>
            <p>Crie uma nova categoria clicando abaixo ou, para editar, selecione uma categoria ao lado clicando em cima da opção desejada</p>
          </div>
          <div className="col-12">
            <button className="btn btn-primary" onClick={() => setOpenForm(true)}>
              Nova Categoria
              </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewCategory;