import React from "react";
import MatcherImportList from "./components/MatcherImportList";
import MatcherUploadComponent from "./components/MatcherUploadComponente";
import MatcherToast from "./components/MatcherToast";
import { APIV2 } from "../../../API";

import "./importMatcher.scss";
import MatcherContext, { useMatcher } from "./providers/MatcherContext";

function Matcher() {
	return (
		<MatcherContext>
			<div className="container dash-content">
				<MatcherToast />
				<div class="card">
					<div className="card-header pb-2">
						<h4 className="card-title">Mapeamento de Catálogo</h4>
					</div>
					<div className="card-content">
						<div class="card-body">
							<div className="row">
								<div className="col-12">
									<p>
										A área de Mapeamento de Catálogo permite com que você
										integre facilmente o seu Catálogo da Lori com o catálogo do
										Marketplace com o qual está sendo realizada a integração.
										Abaixo, seguem as opções possíveis de mapeamento para seu
										catálogo.
									</p>
									<br />
									<p>
										Para realizar o mapeamento, primeiramente baixe a planilha
										base da opção desejada (categoria, atributo ou marca),
										realize o mapeamento seguindo estas instruções e, em
										seguida, envie a planilha mapeada para combinar corretamente
										os catálogos de ambas as plataformas.
									</p>
								</div>
								<div className="col-12 mt-3">
									<div className="row">
										<div className="col-lg-6 col-md-12">
											<MatcherUploadComponent
												icon="format_list_bulleted"
												tooltip="Mapeie a árvore de categorias do seu catálogo em comparação com o catálogo do marketplace"
												type="Categorias"
												downloadLink="insights/export/catalog-matcher/categories?integrationCode=omnik"
												importDocumentType={"marketplace-categories-mapping"}
											/>
										</div>
										<div className=" mobile-space col-lg-6 col-md-12">
											<MatcherUploadComponent
												icon="looks"
												tooltip="Mapeie as marcas do seu catálogo com as marcas disponíveis no catálogo do marketplace"
												type="Marcas"
												downloadLink="insights/export/catalog-matcher/brands?integrationCode=omnik"
												importDocumentType={"marketplace-brands-mapping"}
											/>
										</div>
									</div>
								</div>
								<div className="col-12 mt-3">
									<div className="row">
										<div className="col-lg-6 col-md-12">
											<MatcherUploadComponent
												icon="invert_colors"
												tooltip="Mapeie os atributos do seu catálogo (tamanho, voltagem, etc) com os atributos disponíveis no catálogo do marketplace"
												type="Atributos"
												downloadLink="insights/export/catalog-matcher/attributes?integrationCode=omnik"
												importDocumentType={"marketplace-attributes-mapping"}
											/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<h4 className="card-title mt-5 import-status-titles">
										Status do Mapeamento
									</h4>
									<p>
										Acompanhe o status dos seus mapeamentos abaixo. Clique aqui
										para ver o seu{" "}
										<a
											class=""
											style={{ textDecorationLine: "underline" }}
											href="matcher/history/1"
										>
											Histórico de Mapeamentos
										</a>
									</p>
									<MatcherImportList />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MatcherContext>
	);
}

export default Matcher;
