import React, { useEffect, useState } from 'react';

// import { Container } from './styles';

function EditPlugin(props) {
  const [openPluginEdit, setOpenPluginEdit] = useState('')
  const [index, setIndex] = useState(null)

  useEffect(() => {
    console.log(props.plugin.id)
    setOpenPluginEdit(props.plugin.id)
  }, [props.plugin.id])

  useEffect(() => {
    console.log(props.index)
    setIndex(props.index)
  }, [props.index])

  const closeEdit = () => {
    setOpenPluginEdit('')
    props.close()
  }

  const removePlugin = (plugin) => {
    // console.log(plugin)
    props.remove({plugin: plugin, index : index})
    closeEdit()
  }

  return (
    <div className={!openPluginEdit ? "edit-plugin" : "edit-plugin active-plugin"} >
      <div className="d-flex justify-content-between align-items-center"><h5 className="card-title d-flex align-items-center">
        <i class="material-icons mr-2">{props.plugin.icon}</i> Card title</h5>
        <button onClick={() => closeEdit()} className="btn btn-link"><i class="material-icons">close</i></button>
      </div>
        
      {props.children}

      <div className="d-flex justify-content-end align-items-center">
        <button type="button" class="btn btn-link text-danger" onClick={() => removePlugin(props.plugin.id)}>Remover</button>
        <button type="button" class="btn btn-secondary mr-1">Atualizar</button>
      </div>
    </div>
  );
}

export default EditPlugin;