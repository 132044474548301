import React, { Component } from 'react';
export class AppPreview extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="preview-shopbot">
        <div className="barra" style={{ backgroundColor: this.props.barra }}>
        </div>
        <div className="banner-bot">
        </div>
      
        <div className="chat">
        </div>
        <div className="chat chat-right" style={{backgroundColor: this.props.primaria}}>
          </div>
          <div className="chat">
        </div>
        <div className="chat chat-right" style={{backgroundColor: this.props.primaria}}>
          </div>
        <div className="footer-shopbot">
          <div className="row">
           
           
            <div className="col offset-10 mt-08">
              <i className="material-icons send-icon">
              send
              </i>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default AppPreview;
