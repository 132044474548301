import React, { Component } from 'react';
import {API} from '../API'


export class UploadCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.handleChange = this.handleChange.bind(this)

  }

  async handleChange(event) {

    
    await this.setState({
      csv: event.target.files[0]
    })
    let formData = new FormData();
    formData.append('file', this.state.csv);
    await API.post('api/autosetup/sellers/import', formData)
      .then(response => {
        // If request is good...
      
      })
      .catch((error) => {
        console.log(error)
      })
   
  }
  render() {


    return (

      <div className="" >
        <input
        type="file"
        ref={input => {
          this.filesInput = input;
        }}
        name="file"
        placeholder={null}
        onChange={this.handleChange}
        />
      </div>
    )
  }
};

export default UploadCsv;
