import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { useOrders } from './providers/OrdersContext';

export default function OrderHistory() {
	const { actionLogs } = useOrders()
	const [newList, setNewList] = useState([])

	useEffect(() => {
		var newListing = []
		if(actionLogs.length > 0) {
			for(let i = 0; i < actionLogs.length; i++){
					var newItem = {
						'id': actionLogs[i].id,
						'title': actionLogs[i].title,
						'displayMessage': actionLogs[i].displayMessage,
						'lastUpdate': actionLogs[i].lastUpdate,
						'createDate': actionLogs[i].createDate,
						'level': actionLogs[i].level,
						'issuer': actionLogs[i].issuer,
						"urlLog": actionLogs[i].urlLog
					}
					
					newListing.push(newItem)
			}
		}
		setNewList(newListing)
	}, [actionLogs])
	
	return (
		<div className="detail-box">
			<div className="card box-two mb-3">
				<div className="container dash-content" >
					<div className="card-header ">
						<h4 className="card-title">HISTÓRICO DO PEDIDO</h4>
					</div>
					<div className="card-content mb-3">
						<div className="card-body">
							<div style={{ borderLeft: '3px solid #E9ECEC', marginLeft: '-3px'}}>
									{newList.map((item) =>
										<section key={item.id} className='section-step'>
											<span className={item.level === 0 ? "number bg-secondary" : item.level === 3 ? 'number bg-success' : item.level === 4 ? "number bg-danger" : item.level === 1 ? "number bg-secondary" : null}>
													<i className="material-icons d-flex align-items-center pt-1" style={{ fontSize: '20px', paddingLeft: '6px'}}>
														{((item.level === 3 || item.level === 4) && item.title == null) ? "local_atm" : "shopping_cart"}
													</i>
											</span>
											
											<h2 className="card-title">{ item.level === 0 ? "Pedido recebido" : (item.level === 3 && item.title == null) ? "Pagamento" : (item.level === 4 && item.title == null) ? "Pagamento com erro" : item.title }
													<label className="card-historic-description">{ !item.lastUpdate ? moment(item.createDate).format('DD/MM/YYYY HH:mm') : moment(item.lastUpdate).format('DD/MM/YYYY HH:mm')}</label>
											</h2>
											
											<div className='card' style={{width: "400px", boxShadow: 'none'}}>
													<div className='card-historic'>
														<label className="card-historic-title" dangerouslySetInnerHTML={{ __html:item.displayMessage}}></label>
														<hr style={{ width: "90%", margin: "0 auto"}} />
														
														<div className="d-flex justify-content-between pr-2">
															<label className="card-historic-description">via {item.issuer == null ? 'Plataforma Lori' : item.issuer}</label>
															{!item.urlLog ? null :
																	<button className="btn btn-link btn-icon-r pt-0" style={{ color:'#c4c4c4', fontSize:'12px' }} onClick={() => window.location.href = `${item.urlLog}`}>
																		<i className="material-icons" style={{ fontSize:'16px' }}>get_app</i>Informações Adicionais
																	</button>
															}
														</div>

													</div>
											</div>
										</section>
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

