import React, { createContext, useContext, useEffect, useState } from "react";
import { APIV2 } from "../../../API";

export const OrdersProvider = createContext({});

export default function OrdersContext(props) {
	const [orderId, setOrderId] = useState("");
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState("");
	const [fraudStatus, setFraudStatus] = useState("");
	const [paymentStatus, setPaymentStatus] = useState("");
	const [paymentMethodType, setPaymentMethodType] = useState("");
	const [buttonColors, setButtonColors] = useState("");
	const [buyer, setBuyer] = useState("");
	const [seller, setSeller] = useState("");
	const [delivery, setDelivery] = useState("");
	const [freight, setFreight] = useState("");
	const [paymentInfo, setPaymentInfo] = useState("");
	const [items, setItems] = useState([]);
	const [actionLogs, setActionLogs] = useState([]);
	const [levels, setLevels] = useState([]);
	const [positions, setPositions] = useState([]);
	const [descriptions, setDescriptions] = useState([]);
	const [productDisplayCodeName, setProductDisplayCodeName] = useState("");
	const [invoice, setInvoice] = useState([]);
	const [role, setRole] = useState(0);
	const [reload, setReload] = useState(false);
	const [openFilterModal, setOpenFilterModal] = useState(false);
	const [updateFilterData, setUpdateFilterData] = useState([]);
	const [reloadFilter, setReloadFilter] = useState([]);
	const [order, setOrder] = useState({
		friendlyCode: "",
		createDate: "",
		lastUpdate: "",
		channel: "",
		dealerCode: "",
		externalId: "",
		isDelivery: "",
	});
	const [amount, setAmount] = useState({
		discountAmount: "",
		subTotalAmount: "",
		totalAmount: "",
		feeAmount: "",
		freightAmount: "",
		voucher: "",
	});
	const [actionsButtons, setActionsButtons] = useState([]);
	const [newPaymentStatus, setNewPaymentStatus] = useState({
		title: "",
		name: "",
		color: "",
	});

	useEffect(() => {
		const config = JSON.parse(localStorage.getItem("configurations"));
		setProductDisplayCodeName(config[0].productDisplayCodeName);
		setRole(Number(localStorage.getItem("role")));
	}, [reload]);

	useEffect(() => {}, [openFilterModal]);

	const getOrders = async (id) => {
		setOrderId(id);
		await APIV2.get(`management/commerce/orders/${id}`)
			.then((response) => {
				getFraudStatus(response.data.paymentInfo.fraudStatus);
				getPaymentMethodType(response.data?.paymentInfo?.paymentMethod?.type);
				getPaymentStatus(
					response.data.paymentInfo.paymentStatus,
					response.data?.paymentInfo?.paymentMethod?.type
				);
				setActionLogs(response.data.actionLogs);
				getSteps(response.data.steps);
				setBuyer((prevState) => ({ ...prevState, ...response.data.buyer }));
				setFreight((prevState) => ({ ...prevState, ...response.data.freight }));
				setSeller((prevState) => ({ ...prevState, ...response.data.seller }));
				setDelivery((prevState) => ({
					...prevState,
					...response.data.deliveryAddress,
				}));
				setPaymentInfo((prevState) => ({
					...prevState,
					...response.data.paymentInfo,
				}));
				setInvoice((prevState) => ({ ...prevState, ...response.data.invoice }));
				setStatus(response.data.status);
				setItems(response.data.items);
				setOrder({
					friendlyCode: response.data.friendlyCode,
					createDate: response.data.createDate,
					lastUpdate: response.data.lastUpdate,
					channel: response.data.channel,
					dealerCode: response.data.dealerCode,
					externalId: response.data.externalId,
					isDelivery: response.data.isDelivery,
				});
				setAmount({
					discountAmount: response.data.discountAmount,
					subTotalAmount: response.data.subTotalAmount,
					totalAmount: response.data.totalAmount,
					feeAmount: response.data.feeAmount,
					freightAmount: response.data.freightAmount,
					voucher: response.data.voucher,
				});
				getActionsButtons(response?.data?.buttons[0]?.endpoint);
				response.data.buttons.map((button) => {
					setActionsButtons((prevState) => [
						...prevState,
						{
							name: button.name,
							endpoint: button.endpoint,
							action: button.action,
						},
					]);
				});
			})
			.catch((error) => {
				console.log(error);
			});
		setLoading(false);
	};

	const getFraudStatus = (fraudStatus) => {
		fraudStatus === 1
			? setFraudStatus("Em análise")
			: fraudStatus === 2
			? setFraudStatus("Aprovado")
			: fraudStatus === 3
			? setFraudStatus("Negado")
			: fraudStatus === 4
			? setFraudStatus("Em análise manual")
			: fraudStatus === 5
			? setFraudStatus("Aguardando criação")
			: fraudStatus === 6
			? setFraudStatus("Erro na análise de fraude")
			: setFraudStatus("Não informado");
	};

	const getPaymentMethodType = (paymentMethod) => {
		paymentMethod === 1
			? setPaymentMethodType("Cartão de crédito")
			: paymentMethod === 2
			? setPaymentMethodType("Na retirada")
			: paymentMethod === 3
			? setPaymentMethodType("Boleto bancário")
			: paymentMethod === 4
			? setPaymentMethodType("Voucher")
			: paymentMethod === 5
			? setPaymentMethodType("Cartão de débito")
			: paymentMethod === 6
			? setPaymentMethodType("PIX")
			: setPaymentMethodType("Não Informado");
	};

	const getPaymentStatus = (paymentStatus, type) => {
		paymentStatus === 1
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando pré-aprovação",
					color: "bg-warning",
			  })
			: paymentStatus === 2
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando captura",
					color: "bg-warning",
			  })
			: paymentStatus === 3
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando pagamento direto",
					color: "bg-warning",
			  })
			: paymentStatus === 4
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando estorno",
					color: "bg-warning",
			  })
			: paymentStatus === 5
			? setNewPaymentStatus({
					title: "Pré-aprovado",
					name: "Pré-aprovado",
					color: "bg-primary",
			  })
			: paymentStatus === 6
			? setNewPaymentStatus({
					title: "Pago",
					name: "Pago",
					color: "bg-primary",
			  })
			: paymentStatus === 7
			? setNewPaymentStatus({
					title: "Estornado",
					name: "Estornado",
					color: "bg-secondary",
			  })
			: paymentStatus === 8
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de pré-aprovação",
					color: "bg-danger",
			  })
			: paymentStatus === 9
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de captura",
					color: "bg-danger",
			  })
			: paymentStatus === 10
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de pagamento",
					color: "bg-danger",
			  })
			: paymentStatus === 11
			? setNewPaymentStatus({
					title: "Erro",
					name: "Erro de estorno",
					color: "bg-danger",
			  })
			: paymentStatus === 12
			? setNewPaymentStatus({
					title: "Pagamento",
					name: "Pagamento na criação do pedido",
					color: "bg-primary",
			  })
			: paymentStatus === 13
			? setNewPaymentStatus({
					title: type === 3 ? "Boleto bancário" : type === 6 ? "PIX" : null,
					name: type === 3 ? "Boleto bancário" : type === 6 ? "PIX" : null,
					color: "bg-primary",
			  })
			: paymentStatus === 14
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando nova tentativa",
					color: "bg-warning",
			  })
			: paymentStatus === 15
			? setNewPaymentStatus({
					title: "Retentativas",
					name: "Retentativas expiradas",
					color: "bg-danger",
			  })
			: paymentStatus === 16
			? setNewPaymentStatus({
					title: "Aguardando...",
					name: "Aguardando pagamento na retirada",
					color: "bg-warning",
			  })
			: paymentStatus === 17
			? setNewPaymentStatus({
					title: "Pré-aprovado",
					name: "Pagamento na retirada pré-aprovado",
					color: "bg-primary",
			  })
			: paymentStatus === 18
			? setNewPaymentStatus({
					title: "Pagamento",
					name: "Pagamento na retirada aprovado",
					color: "bg-primary",
			  })
			: setNewPaymentStatus({
					title: "Não iniciado",
					name: "Não iniciado",
					color: "bg-secondary",
			  });
	};

	const getActionsButtons = (buttonColor) => {
		switch (buttonColor) {
			case "CanceledByAdmin":
				setButtonColors({
					backgroundColor: "btn btn-danger",
					textColor: "text-danger",
				});
				return;
			case "CanceledBySeller":
				setButtonColors({
					backgroundColor: "btn btn-danger",
					textColor: "text-danger",
				});
				return;
			case "CanceledByBuyer":
				setButtonColors({
					backgroundColor: "btn btn-danger",
					textColor: "text-danger",
				});
				return;
			case "CanceledByProcessor":
				setButtonColors({
					backgroundColor: "btn btn-danger",
					textColor: "text-danger",
				});
				return;
			case "Separate":
				setButtonColors({
					backgroundColor: "btn btn-titles",
					textColor: "text-secondary",
				});
				return;
			case "InDelivery":
				setButtonColors({
					backgroundColor: "btn btn-titles",
					textColor: "text-info",
				});
				return;
			case "ApprovedExternal":
				setButtonColors({
					backgroundColor: "btn btn-titles",
					textColor: "text-warning",
				});
				return;
			case "Finalized":
				setButtonColors({
					backgroundColor: "btn btn-titles",
					textColor: "text-success",
				});
				return;
			case "ReadyForPickup":
				setButtonColors({
					backgroundColor: "btn btn-titles",
					textColor: "text-info",
				});
				return;
			default:
				setButtonColors({ backgroundColor: "btn btn-titles", textColor: "" });
		}
	};

	const getSteps = (steps) => {
		const progress = [];
		steps.forEach((step) => {
			var body = {
				position: step.position,
				level: step.level,
				description: step.description,
				status: step.status,
			};
			progress.push(body);
		});
		/* get positions */
		const positions = progress.map((step) => step.position);
		setPositions(positions);
		/* get descriptions */
		const descriptions = progress.map((step) => step.description);
		setDescriptions(descriptions);
		/* get levels */
		const levels = progress.map((step) => step.level);
		setLevels(levels);
	};

	const openModal = (updatefilterData, filterData) => {
		setReloadFilter(filterData);
		setUpdateFilterData(updatefilterData);
		setOpenFilterModal(true);
	};

	return (
		<OrdersProvider.Provider
			value={{
				loading,
				amount,
				order,
				buyer,
				seller,
				delivery,
				paymentInfo,
				fraudStatus,
				paymentStatus,
				positions,
				descriptions,
				levels,
				buttonColors,
				actionsButtons,
				status,
				items,
				orderId,
				actionLogs,
				productDisplayCodeName,
				newPaymentStatus,
				invoice,
				freight,
				role,
				reload,
				updateFilterData,
				reloadFilter,
				paymentMethodType,
				setButtonColors,
				setLoading,
				setItems,
				setAmount,
				setOrder,
				getOrders,
				setReload,
				openModal,
			}}
		>
			{props.children}
		</OrdersProvider.Provider>
	);
}

export const useOrders = () => useContext(OrdersProvider);
