import React, { useEffect, useState } from 'react';
import NewDropImage from '../../../components/NewDropImage';
import Loading from '../../../components/Loading';

import 'react-widgets/dist/css/react-widgets.css';
import Stocks from './Stocks';
import { APIV2 } from '../../../API';

export default function NewSkus(props) {
	const [edit, setEdit] = useState(false)
	//const [sellers, setSellers ] = useState([])
	const [disabledButton, setDisablebutton] = useState(false)
	const [loading, setLoading] = useState(true)
	const [skus, setSkus] = useState({
		id: "",
		name: "",
		codeSku: "",
		imageUrl: "",
		images: [],
		isActive: true,
		auxCode: "",
	})

	useEffect(() => {
		setLoading(false)
		if( props.match.params.skuId === undefined || props.match.params.skuId === null || props.match.params.skuId === ""){
			
		}else {
			setEdit(true)
		}
		
	}, [])
	
	const recoveryStateImage = (e) => {
		skus.images = e
		if (e.length > 0) {
			setSkus(prevState => ({ ...prevState, imageUrl: e.imageUrl }))
		}

		for (let i = 0; i < skus.images.length; i++) {
			skus.images[i].order = i
		}
	}

	const handleChange = (e) => {
		let name = e.target.name
		let value = e.target.value
		setSkus(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const createProd = (e) => {
		e.preventDefault();
		
	}
	
	const changeBool = (e) => {
		skus.isActive = !skus.isActive

		if (props.id) {
			setDisablebutton(true)
			//create(sku)
		}
	}

	const create = (product) => {
		if (props.id) {
			/* APIV2.put(`management/catalog/products/${props.id}`, product)
				.then(async response => {
					toast.success('Produto editado com sucesso! 😃', {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						preventDuplicate: true,
					});
					setLoading(false)
					setDisablebutton(false)
				})
				.catch((error) => {

					setLoading(false)
					setDisablebutton(false)
					console.log(error)
					toast.error('Parece que tivemos um erro... Tente novamente mais tarde.', {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						preventDuplicate: true,
					});
				}) */

		} else {
			setLoading(true)
			/* APIV2.post('management/catalog/products', product)
				.then(async response => {
					window.location.href = `/products/${props.page}/edit/` + response.data.id
					setDisablebutton(false)
				})
				.catch((error) => {
					setLoading(false)
					setDisablebutton(false)
					console.log(error)
					toast.error('Parece que tivemos um erro... Tente novamente mais tarde.', {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						preventDuplicate: true,
					});
				}) */
		}
	}

	const renderScreen = () => {

		return (
			<>
			<div className="container dash-content" >
				<div className="card">
					<div className="card-header" id="headingOne">
						<div className="mb-0">
							<h4 className="card-title">{ edit === true ? `Editor de SKU` : `Novo SKU`}</h4>
						</div>
					</div>
					
					<form onSubmit={createProd}>
						<div id="dadosProdutos" className="#" aria-labelledby="headingOne" >
							<div className="card-content">
								<div className="card-body py-0">
									<div className="row">
										<div className="form-group col-10">
											<label htmlFor="productName"><strong>Nome da Variação</strong></label>
											<div >
												<input type="text" className="form-control" id="productName" name='name' value={skus.name} onChange={handleChange} required />
											</div>
										</div>
										<div className="form-group col-2 d-flex align-items-center align-content-center flex-column">
											<label htmlFor="productActive"><strong>Variação Visível?</strong></label>
											<label className="switch mt-2">
												<input 
													id="Status" 
													type="checkbox" 
													name='isActive' 
													value={skus.isActive}
													defaultChecked={skus.isActive} 
													onChange={changeBool} 
													disabled={disabledButton} 
												/>
												<span className="slider round"></span>
											</label>

										</div>
										<div className="form-group col-sm-6">
											<label htmlFor="productCode"><strong>Código da Variação</strong></label>
											<div >
												<input type="text" className="form-control" disabled={props.id ? true : false} id="productCode" placeholder="0" name='code' value={skus.code} required onChange={handleChange} />
											</div>
										</div>

										<div className="form-group col-sm-6">
											<label htmlFor="productCode"><strong>Código de Barras</strong></label>
											<div >
												<input type="text" className="form-control" id="auxCode" placeholder="0" name='auxCode' value={skus.auxCode} onChange={handleChange} />
											</div>
										</div>

									</div>
								</div>

								
								<div className="card-content">
									
									<div className="card-header">
										<h4 className="card-title">Envio de Imagem</h4>
									</div>
									<div className="card-content">
										<div className="card-body py-0">
											<div className="row">
												<div className='form-group col-12' >
													<NewDropImage type={'carrousel'}
														data={skus.images.sort(function (a, b) {
															return a.order - b.order;
														})}
														recoveryStateLogo={recoveryStateImage}
														height={'50px'}
														width={'100%'}
														background={'#fff'}
														colorText={'#747E8B'}
														sku={true}
													/>
													<label style={{ color: "#A1A3A5", fontWeight: "500", fontSize: "12px" }}><em>São suportados arquivos no formato PNG e JPG com até 2MB</em></label>
												</div>
												
												<div className="form-group col-12 mt-3 text-right">
													<button className="btn btn-success" type='submit' id='submit' disabled={disabledButton}>Salvar</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<Stocks />
			</>
			
		)
	}

	return (
		<>
			{loading ? <Loading /> : renderScreen()}
		</>
	)
};