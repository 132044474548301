import React, { useEffect, useState } from 'react';
import ProductList from './ProductList';

export default function Index(props) {

	let params = (new URL(document.location)).searchParams;

	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = `/products/1`
		}
	}, [props.match.params]);


	return (
		<>
			<ProductList query={params.get("query")} page={props.match.params.page} hasStock={params.get("hasStock")} hasPrice={params.get("hasPrice")} isAvailable={params.get("isAvailable")} isActive={params.get("isActive")} hasImage={params.get("hasImage")}/>
		</>
	)
};
