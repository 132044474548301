import React, { Component, useEffect, useState } from 'react';
import { API, APIV2 } from '../../API'
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import StoresList from './StoresList';
import { toast } from 'react-toastify';

export default function Index(props) {


    useEffect(() => {
        localStorage.removeItem("PARAMS")
        if (!props.match.params.page) {
            window.location.href = `/stores/1`

        }
    }, [props.match.params])


    return (
        <StoresList page={parseInt(props.match.params.page)} />
    )
};


// stores={stores}
//             loading={loading}
// numberPages={parseInt(maxCount / 10)}
// totalItems={maxCount}