import React, { Component } from "react";
import { connect } from "react-redux"
import appActions from "../../../appActions";
import {API} from "../../../API";
export class AppStore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: [],
      confirm: false,
    }
    this.confirm = this.confirm.bind(this);
  }
  componentDidMount() {
    this.setNextRoute();

  }
  confirm() {
    
    API.get("api/autosetup/email/stepappstoresettingscomplete")
      .then(async response => {
        await this.setState({ confirm: true })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  setNextRoute() {
    //Passa para o Redux o proximo passo do autosetup
    this.props.dispatch(appActions.setNextRoute("/setup/confirm", true))

    this.props.dispatch(appActions.sendData(null, null, null, "no-content"))
  }
  render() {
    return (
          <div className="app-store d-flex align-items-center">
            <div className="container sm">
              <h1 className="text-center">APP Store e Google Play</h1>
              <p className="text-center mt-4"> Para a disponibilização dos seus aplicativos nas lojas é necessário seguir alguns passos, abaixo explicamos cada um e como você pode fazer ;)</p>
                <div className="card-store ">
                  <div className="row">
                    <div className="col-2 number-store">1</div>
                    <div className="col">
                      <h4>Libere acesso para a Neomode na Google Play</h4>
                      <p>Adicione o e-mail <b className="email-app">app@neomode.com.br</b> como administrador
                        na sua conta da Google Play. Caso não saiba como fazer, acesse&nbsp;
                        <a href="https://neomode.zendesk.com/hc/pt-br/articles/360020055454-Como-criar-sua-loja-no-Google-Play" target="_blank">nosso guia<i className="material-icons" style={{ fontSize: "10px" }}>open_in_new</i></a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-store ">
                  <div className="row">
                    <div className="col-2 number-store">2</div>
                    <div className="col">
                      <h4>Libere acesso para a Neomode na App Store</h4>
                      <p>Adicione o e-mail <b className="email-app">app@neomode.com.br</b> como administrador
                        na sua conta da App Store. Caso não saiba como fazer, acesse&nbsp;
                        <a href="https://neomode.zendesk.com/hc/pt-br/articles/360048066613-Criando-conta-de-desenvolvedor-na-Apple" target="_blank">nosso guia<i className="material-icons" style={{ fontSize: "10px" }}>open_in_new</i></a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-store ">
                  <div className="row">
                    <div className="col-2 number-store">
                      <i className="material-icons" style={{ color: this.state.confirm ? "#01BFB5" : "" }}>
                        done_all
                      </i>
                    </div>
                    <div className="col">
                      <h4>{this.state.confirm ? "Pronto!" : "Confirmação"}</h4>
                      <p>{this.state.confirm ? "Em breve estaremos disponibilizando o beta dos seus aplicativos e realizando a plublicação nas lojas 😁" :
                        "Caso tenha realizado todos os passos acima, confirme que realizou todos os passos e iremos disponibilizar seus aplicativos."}
                      </p>
                    </div>
                    {this.state.confirm ? null :
                      <div className="col-3 d-flex align-items-center">
                        <button className="btn btn-primary" onClick={this.confirm}>Confirmar</button>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
    )
  }
};

export default connect(store => ({ text: store.text, req: store.req }))(AppStore);
