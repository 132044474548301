import React, { useState, useEffect } from "react";
import { API } from "../../../API";
import Loading from "../../../components/Loading";
import Products from "../../../components/Products";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Toast from "../../../components/toast/Toast";

export default function New(props) {
	const [loading, setLoading] = useState(true);
	const [edit, setEdit] = useState(false);
	const [products, setProducts] = useState([]);
	const [id, setId] = useState(props.match.params.id);
	const [name, setName] = useState("");
	const [url, setUrl] = useState(null);
	const [disable, setDisable] = useState(false);
	const [err, setErr] = useState("");

	useEffect(() => {
		setLoading(false);
		if (id) {
			getNewColections(id);
		}
	}, []);

	const getNewColections = (id) => {
		setLoading(true);
		setEdit(true);
		API.get(`api/tags/${id}`)
			.then(async (response) => {
				for (let i = 0; i < response.data.products.length; i++) {
					products[i] = response.data.products[i].product;
				}
				setId(response.data.id);
				setName(response.data.name);
				setUrl(response.data.url);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const handleChange = (e) => {
		setName(e.target.value.replace("/", ""));
	};
	const getProducts = (e) => {
		setProducts(e);
	};

	const create = (e) => {
		e.preventDefault();

		if (!name || products.length <= 0) {
			!name && setErr("Para criar uma coleção, insira o nome");
			products.length <= 0 &&
				setErr("Para criar uma coleção, adicione os produtos desejados!");
			!name &&
				products.length <= 0 &&
				setErr(
					"Para criar uma coleção, insira o nome e os produtos desejados!"
				);
		} else {
			setDisable(true);
			const body = {
				id: id,
				name: name,
				products: products,
				url: name.replace(/\s/g, "-"),
			};

			for (let i = 0; i < products.length; i++) {
				body.products[i]["productId"] = products[i].id;
			}

			if (edit) {
				API.put("api/tags", body)
					.then(async (response) => {
						toast.success("Coleção editada com sucesso! 😃");
						setDisable(false);
					})
					.catch((error) => {
						console.log(error);
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
						setDisable(false);
					});
			} else {
				API.post("api/tags", body)
					.then(async (response) => {
						toast.success("Coleção criada com sucesso! 😃");
						setDisable(false);
						window.location.href = `/collections/${props.match.params.page}/edit/${response.data.id}`;
					})
					.catch((error) => {
						console.log(error);
						setDisable(false);
					});
			}
		}
	};

	const closeToast = () => {};

	const renderScreen = () => {
		return (
			<>
				<div className="card-header">
					<h4 className="card-title">Dados da coleção</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<form onSubmit={create} className="row">
							<div className="col-12">
								<div className="form-group col-12">
									<label htmlFor="codigoCupom">
										<strong>Nome da coleção</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="codigoCupom"
											placeholder="Exemplo coleção"
											name="name"
											value={name}
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="form-group col-12 mt-2">
									<Products
										getProducts={getProducts}
										externalProducts={products}
									/>
								</div>
								<div className="form-group col-12">
									<Button
										className="btn btn-success"
										loading={disable}
										type="submit"
									>
										Salvar
									</Button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	};
	return (
		<>
			<div className="container dash-content">
				<Toast error={err} closeToast={closeToast} dealer={true} />
				<div className="card">{loading ? <Loading /> : renderScreen()}</div>
			</div>
		</>
	);
}
