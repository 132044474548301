import React, { useEffect, useState } from "react";
import Select from "react-select";

function SelectInputNeo({ inputsArraySelect, inputValue, inputChange }) {
	const [arrInputs, setArrInputs] = useState([
		{
			value: "",
			label: "Nenhum",
		},
	]);

	useEffect(() => {
		inputsArraySelect.forEach((data) => {
			setArrInputs((prevState) => [...prevState, { value: data, label: data }]);
		});
	}, []);

	const customStyles = {
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "#747E8B",
			paddingLeft: 5,
			svg: {
				width: 17,
			},
		}),
	};

	return (
		<>
			<Select
				styles={customStyles}
				className="react-select-container"
				placeholder="Escolha uma opção"
				value={
					arrInputs[arrInputs.findIndex((input) => input.value === inputValue)]
				}
				options={arrInputs}
				onChange={(e) => inputChange(e.value)}
			/>
		</>
	);
}

export default SelectInputNeo;
