import React, { Component } from 'react';
import Delete from './Delete';
import UploadCsv from '../../../components/UploadCsv'
import Loading from '../../../components/Loading'
export class List extends Component {
  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this)
    this.state = {
      delete: null,
      loading:true,
    }
  
  }
  async componentDidMount() {
    await this.setState({ loj: this.props.lojas, loading:false, })
  }
  async deleteItem(id) {
   await  this.setState({ delete: id })
  }
  render() {
    if (this.state.loading) {
      return (
        <Loading />
      )
    }
    else {
      return (
        this.renderScreen()
      )
    }

  }
  renderScreen() {
    var _this = this
    return (
      <div className='card list'>
        <div className='card-body '>

          <div className=" ">
            <div className='d-flex justify-content-end'>

              {/* <button className='secundary-button'>Importar arquivo csv</button> */}
              <button className='add-outlet-button' onClick={() => window.location.href = '/setup/store/new'}>Novo ponto de venda</button>
            </div>
            <br />
            <div className='col-12'>
              <table className="table table-crud">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col" className='text-center'>Cidade</th>
                    <th scope="col" className='text-center'>Estado</th>
                    <th scope="col" className='text-center'>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.lojas != null && this.props.lojas != undefined ?
                    this.props.lojas.map(function (item, i) {
                      return (
                        <tr key={i}>
                          <td scope="row">{item.pointSaleId}</td>
                          <td>{item.fullName}</td>
                          {item.address != null || item.address != undefined ?
                            <>
                              <td className='text-center'>{item.address.city}</td>
                              <td className='text-center'>{item.address.state}</td>
                            </>
                            :
                            <>
                              <td className='text-center'> - </td>
                              <td className='text-center'> - </td>
                            </>
                          }


                          <td key={i} className='text-center'>
                            <div className="btn-group" role="group" aria-label="Basic example">
                              <button type="button" className="btn btn-group-crud btn-sm" onClick={() => window.location.href = 'store/edit/' + item.id}>
                                <i className="material-icons">create</i>
                              </button>
                              <button type="button" className="btn btn-group-crud btn-sm text-danger" data-toggle="modal" data-target="#modalExemplo" onClick={() => _this.deleteItem(item.id)}><i className="material-icons">
                                delete</i></button>
                            </div></td>

                        </tr>
                      )
                    }) : null}



                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Delete id={this.state.delete} />
      </div>


    )
  }
};

export default List;
