import React, { Component } from 'react';

export class Index extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="page-404 text-center">
                <h1>Ooops!</h1>
                <h5>A página que você está procurando não está <br />disponível</h5>
                {/* verifica se existe token, e exibe botao para login ou dashboard */}
                {localStorage.getItem('token') ?
                    <button className='btn btn-secondary' onClick={() => window.location.href = '/'}>Ir para o dashboard</button>
                    :
                    <button className='btn btn-secondary' onClick={() => window.location.href = '/login'}>Ir para o login</button>
                }

                <br />
                <img src='/images/404.png' />
            </div>


        )
    }
};

export default Index;
