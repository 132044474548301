import React, { Component } from "react";
import {API, APIV2} from '../API';
import { toast } from 'react-toastify';
//import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BannersList from './BannersList'

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: '2px',
    margin: `0 0 ${grid}px 0`,
    borderRadius: '5px',
    border: '1px solid #E9ECEC',
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
})

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "white",
    padding: '0',
    width: '100%'
})

class DragDropBanners extends Component {
    constructor(props) {
        super(props)
        this.state = {
        items: [],
        reorder: null
        }
        this.onDragEnd = this.onDragEnd.bind(this)
        this.changeItem = this.changeItem.bind(this);
    }

    async onDragEnd(result) {
        
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index,
        )

        items.forEach((item, i) => {
            if(item.order !== i) {
                item.order = i
                var body = {
                    "action": item.action,
                    "clientId": item.clientId,
                    "endIn": item.endIn,
                    "id": item.id,
                    "imageUrl": item.imageUrl,
                    "isDefault": item.isDefault,
                    "isDeleted": item.isDeleted,
                    "order": item.order,
                    "referenceId": item.referenceId,
                    "startIn": item.startIn,
                    "title": item.title,
                    "type": item.type
                }
            }
        })
        this.setState({items})
        this.props.newItemBanners(this.state.items)

    }
    componentWillReceiveProps() {
        if (this.props.banners != null && this.props.banners != undefined) {
            this.setState({ items: this.props.banners })
        }
    }

    changeItem(title, imageUrl, startIn, endIn, order){
        this.props.changeBanners(title, imageUrl, startIn, endIn, order)
        //console.log(this.props.changeBanners)
    }
    
    componentWillMount(){
    }

    render() {
        return (
        <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                >
                {this.state.items != null ? this.state.items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>
                            <BannersList item={item} reorder={this.state.reorder} changeItem={this.changeItem} deleteBanner={this.props.deleteBanner} idBanner={this.props.idBanner} url={this.props.url}/>
                        </div>

                    )}
                    </Draggable>
                )) : null}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>
        );
    }
}



export default DragDropBanners