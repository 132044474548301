import React, { Component, useEffect, useState } from "react";
import { API, APIV2 } from "../../../API";
import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";
import { Tree } from "antd";
import { toast } from "react-toastify";

export default function CategoriesList(props) {
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [categoriesSelect, setCategoriesSelect] = useState([]);
	const [newList, setNewList] = useState([]);
	const [item, setItem] = useState({});

	useEffect(() => {
		APIV2.get(
			"management/catalog/categories?page=1&perPage=100&includeChildren=true"
		)
			.then(async (response) => {
				await setList(response.data.categories);
				console.log(response.data.categories);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
				setLoading(false);
			});
		var selects = [];
		for (let i = 0; i < props.editValue.length; i++) {
			selects.push(
				localStorage.getItem("UseCatalogProcessor") === "true"
					? props.editValue[i].categoryCode
					: props.editValue[i].id
			);
		}
		setCategoriesSelect(selects);
	}, []);

	useEffect(() => {
		if (list.length > 0 && newList.length <= list.length) {
			for (let i = 0; i < list.length; i++) {
				list[i]["title"] = list[i].name;
				list[i]["key"] =
					localStorage.getItem("UseCatalogProcessor") === "true"
						? list[i].code
						: list[i].id;
				let newItem = {
					title: list[i].name,
					key:
						localStorage.getItem("UseCatalogProcessor") === "true"
							? list[i].code
							: list[i].id,
				};
				setNewList((prevState) => [...prevState, newItem]);
				if (list[i]?.children?.length > 0) {
					for (let e = 0; e < list[i]?.children?.length; e++) {
						list[i].children[e]["key"] =
							localStorage.getItem("UseCatalogProcessor") === "true"
								? list[i]?.children[e]?.code
								: list[i]?.children[e]?.id;
						list[i].children[e]["title"] = list[i]?.children[e]?.name;
						if (list[i]?.children[e]?.children?.length > 0) {
							for (let h = 0; h < list[i]?.children[e]?.children?.length; h++) {
								list[i].children[e].children[h]["key"] =
									localStorage.getItem("UseCatalogProcessor") === "true"
										? list[i]?.children[e]?.children[h]?.code
										: list[i]?.children[e]?.children[h]?.id;
								list[i].children[e].children[h]["title"] =
									list[i]?.children[e]?.children[h]?.name;
							}
						}
					}
				}
			}
		}
	}, [list]);

	const save = () => {
		let categ = [];
		let categoryCP = {};
		for (let i = 0; i < categoriesSelect.length; i++) {
			var item = {
				id: categoriesSelect[i],
			};
			categoryCP = {
				categoryCode: categoriesSelect[i],
			};
			categ.push(
				localStorage.getItem("UseCatalogProcessor") === "true"
					? categoryCP
					: item
			);
		}
		console.log(categ);
		props.newCondition(categ);
	};

	const renderScreen = () => {
		const onCheck = (checkedKeys, info) => {
			console.log(checkedKeys);
			setCategoriesSelect(checkedKeys.checked);
		};
		return (
			<Modal
				id={"categorie"}
				title={"Categorias"}
				confirm={true}
				content={
					newList.length === list.length && loading ? (
						<Loading />
					) : (
						<div className="categories-list">
							<Tree
								checkable
								checkStrictly={true}
								defaultCheckedKeys={categoriesSelect}
								defaultExpandedKeys={categoriesSelect}
								onCheck={onCheck}
								treeData={list}
							/>
						</div>
					)
				}
				actionConfirm={save}
				edit={false}
			/>
		);
	};

	return <>{renderScreen()}</>;
}
