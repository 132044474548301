import React, { useCallback } from "react";

const Input = ({ ...props }) => {
	const handleKeyUp = useCallback((e) => {
		let value = e.currentTarget.value;
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d)(\d{2})$/, "$1,$2");
		value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
		e.currentTarget.value = value;
		return e;
	}, []);

	return (
		<div className="input-group">
			<input
				{...props}
				onKeyUp={handleKeyUp}
				placeholder="0,01"
				style={{ borderRadius: "0 5px 5px 0" }}
			/>
		</div>
	);
};

export default Input;
