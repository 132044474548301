import React, { useEffect, useState } from 'react';
import FreightList from './FreightList';

export default function Index(props) {

    useEffect(() => {
        localStorage.removeItem("PARAMS")
    }, [])

	return (
        <div className="container dash-content">
            <FreightList />
        </div>
	)
};
