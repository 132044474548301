import React, { Component } from 'react';
import HorarioAtendimento from '../../../components/HorarioAtendimento';
import {API} from '../../../API';
import GoogleMapReact from 'google-map-react';
import axios from 'axios'
require('dotenv/config');
export class NewStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      codigo: '',
      cnpj: '',
      email: '',
      telefone: '',
      vendaOnline: false,
      address: null,
      latitude: -23.466295,
      longitude: -46.660446,
      instrucoes: '',
      buscaEndereco: '',
      enderecoVisualizacao: '',
      viewMap: false,
      workingId: '',
      workingHours: [],
      result: []
    }
    this.changeEndereco = this.changeEndereco.bind(this)
    this.onChange = this.onChange.bind(this)
    this.changeBool = this.changeBool.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.selectEndereco = this.selectEndereco.bind(this);
    this.changeWorkingHours = this.changeWorkingHours.bind(this)
  }
  async changeEndereco(e) {
    await this.setState({ buscaEndereco: e.target.value });

    await axios.get(process.env.REACT_APP_API_GOOGLE + 'api/google/places/autocomplete?query=' + this.state.buscaEndereco,
      { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': '*' } })
      .then(async response => {
        // If request is good...

        await this.setState({ result: response.data })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
  }
  async changeBool(e) {
    await this.setState({ viewMap: !this.state.viewMap });

  }
  selectEndereco(id) {
    axios.get(process.env.REACT_APP_API_GOOGLE + 'api/google/places/' + id,
      { headers: { Accept: '*/*', 'Access-Control-Allow-Origin': '*' } })
      .then(async response => {
        // If request is good...

        await this.setState({
          address: response.data.address,
          buscaEndereco: response.data.fullName,
          result: [],
          latitude: response.data.address.latitude,
          longitude: response.data.address.longitude
        });
        this.forceUpdate();

      })
      .catch((error) => {
        console.log(error)
      })
  }
  onSubmit(e) {
    e.preventDefault();
   
    if (this.props.match.params.id != undefined) {
      var body = {
        id: this.props.match.params.id,
        fullName: this.state.nome,
        pointSaleId: this.state.codigo,
        retailerId: localStorage.getItem('retailerId'),
        cnpj: this.state.cnpj,
        contactEmail: this.state.email,
        contactPhone: this.state.telefone,
        isSellerOnline: this.state.vendaOnline,
        pickUpInstructions: this.state.instrucoes,
        isShowSellerInMap: this.state.viewMap,
        address: this.state.address,

      }
    } else {
      var body = {
        fullName: this.state.nome,
        pointSaleId: this.state.codigo,
        retailerId: localStorage.getItem('retailerId'),
        cnpj: this.state.cnpj,
        contactEmail: this.state.email,
        contactPhone: this.state.telefone,
        isSellerOnline: this.state.vendaOnline,
        pickUpInstructions: this.state.instrucoes,
        isShowSellerInMap: this.state.viewMap,
        address: this.state.address,


      }
    }



    if (this.props.match.params.id != undefined) {
      API.put('api/autosetup/sellers/' + this.props.match.params.id, body)
        .then(async response => {
          // If request is good...

          await API.put('api/workinghours/', this.state.workingHours)
            .then(response => {
              // If request is good...

              window.location.href = '/setup/stores'

            })
            .catch(error => {
              console.log(error)


            })

        })
        .catch(error => {
          console.log(error)
        })
    } else {

      API.post('api/autosetup/sellers/', body)
        .then(async response => {
          // If request is good...

          this.setState({ workingId: response.data.id });
          this.forceUpdate();

          for (var i in this.state.workingHours) {
            if (this.state.workingHours[i].dayWeek == i) {
              this.state.workingHours[i].sellerId = response.data.id;

            }
          }


          await API.post('api/workinghours/', this.state.workingHours)
            .then(response => {
              // If request is good...
              window.location.href = '/setup/stores'

            })
            .catch(error => {
              console.log(error)
              alert(error)

            })

        })
    }
  }

  async componentWillMount() {
    var id = this.props.match.params.id
  
    //Consulta materiais disponíveis a partir do token do usuário.
    API.get('api/autosetup/sellers/' + id)
      .then(async response => {

        // If request is good...
        await this.setState({
          nome: response.data.fullName,
          codigo: response.data.pointSaleId,
          cnpj: response.data.cnpj,
          email: response.data.contactEmail,
          telefone: response.data.contactPhone,
          vendaOnline: response.data.isSellerOnline,
          instrucoes: response.data.pickUpInstructions,
          address: response.data.address,
          buscaEndereco: response.data.address.street + response.data.address.number,
          enderecoVisualizacao: response.data.address.fullAddress,
          latitude: response.data.address.latitude,
          longitude: response.data.address.longitude,
          viewMap: response.data.isShowSellerInMap,

        })
        this.forceUpdate();
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async changeWorkingHours(e) {

    await this.setState({ workingHours: e })

  }
  static defaultProps = {

    zoom: 18
  };
  render() {
    return (

      <div className="container">
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <h4 className="card-title">Novo ponto de venda</h4>
          </div>
          <div className="card-content">
            <div className="card-body pt-0">
                    <form onSubmit={this.onSubmit}>
                      <h5 className="font-weight-bold">Informações básicas</h5>
                      <div className="form-group row mt-3">
                        <label htmlFor="nome" className="col-sm-3 col-form-label">Nome ponto de venda</label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="nome" name='nome' value={this.state.nome} onChange={this.onChange} />
                        </div>
                        <label htmlFor="codigo" className="col-sm-1 col-form-label" >Código</label>
                        <div className="col-sm-2">
                          <input type="teste" className="form-control" id="codigo" name='codigo' value={this.state.codigo} onChange={this.onChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="cnpj" className="col-sm-3 col-form-label">CNPJ</label>
                        <div className="col-sm-4">
                          <input type="text" className="form-control" id="cnpj" name='cnpj' value={this.state.cnpj} onChange={this.onChange} />
                        </div>
                        <label htmlFor="email" className="col-sm-2 col-form-label">E-mail de contato</label>
                        <div className="col-sm-3">
                          <input type="email" className="form-control" id="email" name='email' value={this.state.email} onChange={this.onChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="telefone" className="col-sm-3 col-form-label">Telefone de contato</label>
                        <div className="col-sm-4">
                          <input type="tel" className="form-control" id="telefone" name='telefone' value={this.state.telefone} onChange={this.onChange} />
                        </div>
                        <label htmlFor="vendaOnline" className="col-sm-3 col-form-label">Habilitar venda para loja online?</label>
                        <div className="col-sm-2 d-flex align-items-center">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="vendaOnline" id="vendaOnline1" value={true} onChange={this.onChange} />
                            <label className="form-check-label" htmlFor="vendaOnline1">Sim</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="vendaOnline" id="vendaOnline2" value={false} onChange={this.onChange} />
                            <label className="form-check-label" htmlFor="vendaOnline2">Não</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="instrucoes" className="col-sm-3 col-form-label">Instruções de retirada</label>
                        <div className="col-sm-9">
                          <input type="teste" className="form-control" id="instrucoes" name='instrucoes' value={this.state.instrucoes} onChange={this.onChange} />
                          <small className="form-text text-muted">Texto informativo para o seu cliente a respeito da retirada de produtos.</small>
                        </div>
                      </div>
                      <h5 className="font-weight-bold mt-5">Endereço e mapa</h5>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group row mt-20">
                            <label htmlFor="buscaEndereco" className="col-sm-4 col-form-label">Buscar endereço</label>
                            <div className="col-sm-8">
                              <div className="input-group">
                                <input type="text" className="form-control" id="buscaEndereco" name='buscaEndereco' value={this.state.buscaEndereco} onChange={this.changeEndereco} autoComplete="off" />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="material-icons">search</i>
                                  </span>
                                </div>
                              </div>
                              {this.state.result.length != 0 ?
                                <div className='result'>
                                  <ul>
                                    {this.state.result.map((value, index) => (
                                      <li key={index} id={value.placeId} onClick={() => this.selectEndereco(value.placeId)}>{value.fullName}</li>
                                    ))}

                                  </ul>
                                </div> : null
                              }
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="">Mostrar ponto de venda no mapa?</label>
                            <label className="switch ml-2">
                              <input type="checkbox" name='viewMap' value={this.state.viewMap} onChange={this.changeBool} />
                              <span className="slider round"></span>
                            </label>
                          </div>

                        </div>
                        <div className="col-sm-6">
                          <div className="store-map">
                          <GoogleMapReact
                            style={{ height: '300px', width: '100%' }}
                            bootstrapURLKeys={{ key: 'AIzaSyChklOgYg9meKvfH6ZZAwmbqRuHTp9ZgyE' }}
                            center={{
                              lat: this.state.latitude,
                              lng: this.state.longitude
                            }}


                            defaultZoom={this.props.zoom}
                          >
                            <img src='/images/map-marker.png'
                              width='50px'
                              lat={this.state.latitude}
                              lng={this.state.longitude}
                            />
                          </GoogleMapReact>
                          </div>
                        </div>
                      </div>
                      {this.state.workingHours.length > 0 ?

                        <HorarioAtendimento change={this.changeWorkingHours} working={this.state.workingHours} id={this.props.match.params.id} /> :
                        <HorarioAtendimento change={this.changeWorkingHours} id={this.props.match.params.id} />}
                      <div className="form-group row mt-20">
                        <div className="col-sm-12">
                          <button type="submit" className="btn btn-primary">Salvar ponto de venda</button>
                        </div>
                      </div>
                      <br /><br />
                      <br /><br />

                    </form>
                  </div>
                </div>
              </div>
            </div>
    )
  }
};

export default NewStore;



