import React, { Component } from 'react';
import {API} from '../../API';
import Loading from '../../components/Loading'
export class Index extends Component {
    constructor(props) {
        super(props);
        this.changeProduct = this.changeProduct.bind(this);
        this.changeStock = this.changeStock.bind(this);
        this.changeAttributes = this.changeAttributes.bind(this);
        this.state = {
            show: false,
            loading: false,
        }
    }
    changeProduct(e) {
        var token = localStorage.getItem('token');
        var formData = new FormData();
        formData.append("file", e.target.files[0]);
        this.setState({ loading: true })
        API.post('api/products/import', formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token } })
            .then(async response => {
                // If request is good...
                this.setState({ show: true, title: 'Sucesso!', message: response.data, loading: false });
                this.forceUpdate();

            })
            .catch((error) => {
                console.log(error)
                this.setState({ show: true, loading: false, title: 'Erro!', message: "Infelizmente tivemos um problema, tente novamente ou contate o suporte." });
                this.forceUpdate();
            })
    }
    changeStock(e) {
        var token = localStorage.getItem('token');
        var formData = new FormData();
        this.setState({ loading: true })
        formData.append("file", e.target.files[0]);

        API.post('api/products/skus/stocks/import', formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token } })
            .then(async response => {
                // If request is good...
                this.setState({ show: true, title: 'Sucesso!', loading: false, message: response.data });
                this.forceUpdate();

            })
            .catch((error) => {
                console.log(error)
                this.setState({ show: true, title: 'Erro!', loading: false, message: "Infelizmente tivemos um problema, tente novamente ou contate o suporte." });
                this.forceUpdate();
            })
    }
    changeAttributes(e) {
        var token = localStorage.getItem('token');
        var formData = new FormData();
        this.setState({ loading: true })
        formData.append("file", e.target.files[0]);

        API.post('api/products/skus/attributes/import', formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token } })
            .then(async response => {
                // If request is good...
                this.setState({ show: true, title: 'Sucesso!', loading: false, message: response.data });
                this.forceUpdate();

            })
            .catch((error) => {
                console.log(error)
                this.setState({ show: true, title: 'Erro!', loading: false, message: "Infelizmente tivemos um problema, tente novamente ou contate o suporte." });
                this.forceUpdate();
            })
    }

    render() {
        if (this.state.loading) {
            return (
                <Loading />
            )
        }
        else {
            return (
                this.renderScreen()
            )
        }
    }
    renderScreen() {
        return (
            <div className="container dash-content ">
                <div className="card  pb-5">
                    <div className="card-header">
                        <h4 className="card-title mb-3">Importação de arquivo</h4>
                        <p className='mb-0'>Escolha abaixo qual o tipo de importação deseja realizar</p>
                    </div>
                    <div className='card-body pt-0'>
                        <div className='row'>
                            <div className='col-12'>
                                <h5 className='text-bold mb-3'>Catálogo</h5>
                            </div>
                            <div className='col-sm-4'>
                                <div className="importItem">
                                    <div className='header'>
                                        <div className="icon">
                                            <i className="material-icons">ballot</i>
                                        </div>
                                        <div>
                                            <span className="title">Importar produtos <a className='help' target="_blank" href="https://neomode.zendesk.com/hc/pt-br/articles/360045637073-Como-importar-produtos-para-Lori"><i className='material-icons'>help_outline</i></a></span>
                                            <a className="attachment" target='_blank' href="https://loriprd.blob.core.windows.net/loriprd/neomode_importacao_produtos.xlsx">baixe o modelo aqui</a>
                                        </div>
                                    </div>
                                    <div className="file-button">
                                        <label className="btn btn-primary btn-block mb-0">
                                            <input type="file" onChange={this.changeProduct} />Enviar arquivos</label>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className="importItem">
                                    <div className='header'>
                                        <div className="icon">
                                            <i className="material-icons">invert_colors</i>
                                        </div>
                                        <div>
                                            <span className="title">Importar Atributos do SKU <a className='help' target="_blank" href="https://neomode.zendesk.com/hc/pt-br/articles/360047673894-Como-importar-atributos-SKUs"><i className='material-icons'>help_outline</i></a></span>
                                            <a className="attachment" target='_blank' href="https://loriprd.blob.core.windows.net/loriprd/neomode_importacao_atributos_sku.xlsx">baixe o modelo aqui</a>
                                        </div>
                                    </div>
                                    <div className="file-button">
                                        <label className="btn btn-primary btn-block mb-0">
                                            <input type="file" onChange={this.changeAttributes} />Enviar arquivos</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className="importItem">
                                    <div className='header'>
                                        <div className="icon">
                                            <i className="material-icons">move_to_inbox</i>
                                        </div>
                                        <div>
                                            <span className="title">Importar estoque <a className='help' target="_blank" href="https://neomode.zendesk.com/hc/pt-br/articles/360047506814-Como-importar-estoque"><i className='material-icons'>help_outline</i></a></span>
                                            <a className="attachment" target='_blank' href="https://loriprd.blob.core.windows.net/loriprd/neomode_importacao_estoque.xlsx">baixe o modelo aqui</a>
                                        </div>
                                    </div>
                                    <div className="file-button">
                                        <label className="btn btn-primary btn-block mb-0">
                                            <input type="file" onChange={this.changeStock} />Enviar arquivos</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={this.state.show ? { display: 'block' } : { display: 'none' }} className={this.state.show ? 'modal fade show' : 'modal fade'} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ show: false })}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body text-center">
                                <h3>{this.state.title}</h3>
                                <p>{this.state.message}</p>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.setState({ show: false })}>Fechar</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
};

export default Index;
