import React, { Component } from 'react';
import DropImage from '../../../components/DropImage';
import { connect } from 'react-redux'
import appActions from '../../../appActions';
import {API} from '../../../API';
import { ChromePicker } from 'react-color';
import SplashPreview from './Previews/SplashPreview';
import EditorHtml from '../../../assets/EditorHtml';
import Loading from '../../../components/Loading'
export class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFull: false,
      logoUrl: '',
      corScreen: '',
      fullImageHome: false,
      displayColorPickerBarra: false,
      loading:true,
    };
    this.recoveryStateLogo = this.recoveryStateLogo.bind(this);

  }

  //Função para recuperar url do DropImage
  async recoveryStateLogo(item) {
    await this.setState({
      logoUrl: item
    })
    this.forceUpdate();
  }
  handleClick = () => {
    this.setState({ displayColorPickerBarra: !this.state.displayColorPickerBarra })
  };
  handleChangeCompleteBarra = (color) => {
    this.setState({ corScreen: color.hex });
    this.forceUpdate();
  };
  async handleChange(e){
   await this.setState({
      fullImageHome: !this.state.fullImageHome
    });
    this.forceUpdate();
  }
  componentDidUpdate() {
    this.setFirstData();
    
  }
  componentDidMount() {
    
    this.setNextRoute()
    this.setFirstData();
  }
  setFirstData() {
    //Configs API PUT DATA
    let data = {
      imageHome: this.state.logoUrl,
      colorHome: this.state.corScreen,
      isFullImageHome: this.state.fullImageHome
    }
    //Next Route
    this.props.dispatch(appActions.setNextRoute('/setup/stores'))
    //Passa para o Redux os dados para put da API
    //ajustar o TRUe para um state (Se os campos foram preenchidos)
    this.props.dispatch(appActions.sendData(data, true, 'api/autosetup/brand/splashscreen/', 'content', 'api/autosetup/email/stepbrandcomplete'))
  }
  setNextRoute() {
    //Passa para o Redux o proximo passo do autosetup
    this.props.dispatch(appActions.setNextRoute('/setup/stores'))
  }
  componentWillMount() {
  
    var clientId = localStorage.getItem('client_id');
    //Consulta materiais disponíveis a partir do token do usuário.
    API.get('api/autosetup/retailers/')
      .then(async response => {
        // If request is good...
        await this.setState({
          logoUrl: response.data.imageHome,
          corScreen: response.data.colorHome,
          fullImageHome: response.data.isFullImageHome,
          loading:false,
        })

        this.forceUpdate();
        this.setFirstData();
      })
      .catch((error) => {
        console.log(error)
      })
  }
  render() {
    if(this.state.loading){
      return (
        <Loading/>
      )
    }
    else{
      return (
      this.renderScreen()
      )
    }
   
  }

  renderScreen() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    return (
        <div className="row logos d-flex align-items-center justify-content-center">
          <div className="col-5">
            <h1 className="text-center">Tela de abertura do APP</h1>
            <div className="tab-preview2 mt-4">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a className={!this.state.fullImageHome ? "nav-link active-tab" : "nav-link"} 
                    onClick={() => this.handleChange()}>Logo da empresa</a>
                </li>
                <li className="nav-item">
                  <a className={this.state.fullImageHome ? "nav-link active-tab" : "nav-link"} 
                    onClick={() => this.handleChange()}>Imagem customizada</a>
                </li>
              </ul>
            </div>
            <div className="row mt-4 d-flex justify-content-center" >
              <div className="col-3">
                <div onClick={this.handleClick}>
                  <div className="card-colorpicker" style={{ backgroundColor: this.state.corScreen }}>
                    <div className="color-name">
                      {this.state.corScreen}
                    </div>
                    <div className="color-label">
                      Cor de fundo
                    </div>
                  </div>
                </div>
                {this.state.displayColorPickerBarra ? <div style={popover}>
                  <div onClick={this.handleClose} />
                  <ChromePicker color={this.state.corScreen}
                    onChangeComplete={this.handleChangeCompleteBarra} />
                </div> : null}
              </div>
              {/* RecoveryStateLogo é uma fuction setState 
              criada no pai e passada para o filho, que retorna a url da imagem carregada. */}
              <div className="col-5">
                <div className="card-logo">
                  <DropImage previewImage={this.state.logoUrl} recoveryStateLogo={this.recoveryStateLogo} height={'114px'} width={'300px'} background={this.state.corScreen} />
                  <div className="logo-label">
                  {this.state.fullImageHome ? "Imagem" : "Logo da empresa"}
                    </div>
                </div>
               
              </div>
              {this.state.fullImageHome ? <a href="#" className="baixe-modelo help-text">Baixe nosso modelo aqui <i className="material-icons">
open_in_new
</i></a> : null}
            </div>


          </div>
          <div className='col-3'>
          <h2 className="text-center">Pré-visualização</h2>
            <SplashPreview cor={this.state.corScreen} imagem={this.state.logoUrl} full={this.state.fullImageHome} />
          </div>
        </div>
    )
  }
};

export default connect(store => ({ text: store.text }))(SplashScreen);
