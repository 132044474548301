import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import Modal from "../../../components/Modal";
import List from "../../../components/List/List";
import { APIV2 } from "../../../API";
import Empty from "../../../components/Empty";
import { toast } from "react-toastify";
import FilterDealers from "./components/FilterDealers";
import Toast from "../../../components/toast/Toast";
import { useDealers } from "./providers/DealersContext";

export default function DealersList(props) {
	const { dealersList, loading, roleClient, messageToast } = useDealers();

	const [succs, setSuccs] = useState("");
	const [page, setPage] = useState(1);
	const [dealers, setDealers] = useState([]);
	//const [loading, setLoading] = useState(true)
	const [deleteId, setDeleteId] = useState("");
	const [loadQuery, setLoadQuery] = useState(true);
	const [maxCount, setMaxCount] = useState([]);
	const [access, setAccess] = useState();
	const [filterRole, setFilterRole] = useState("");
	const [filterDealer, setFilterDealer] = useState("");
	const [query, setQuery] = useState("");
	const [filterData, setFilterData] = useState({
		cnpj: "",
		role: "",
	});
	const columListRetailer = [
		{
			name: "Matrícula",
			selector: "name",
			sortable: false,
			center: true,
			width: "150px",
			cell: (row) => row.dealerCode,
		},

		{
			name: "Nome",
			selector: "name",
			sortable: false,
			center: true,
			cell: (row) => row.fullName,
		},

		{
			name: "Cargo",
			selector: "name",
			sortable: false,
			center: true,
			cell: (row) => row.role,
		},
		{
			name: "Loja",
			selector: "name",
			sortable: false,
			center: true,
			cell: (row) => row.companyName,
		},
		{
			name: "",
			sortable: false,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<a
						id="btnGroupDrop1"
						href={`/salespeople/${page}/edit/` + row.id}
						className="btn btn-info"
					>
						Editar
					</a>

					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>

					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							href="#"
							data-toggle="modal"
							data-target="#deleteSalespeople"
							onClick={() => setDeleteId(row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];
	const columsList = [
		{
			name: "Matrícula",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => <div className="">{row.dealerCode}</div>,
		},
		{
			name: "Nome e CPF",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => (
				<div className="d-flex flex-column">
					<span>
						<strong>{row.fullName}</strong>
					</span>
					<span style={{ fontSize: "12px" }}>CPF: {row.salesmanCpf}</span>
					<span></span>
				</div>
			),
		},

		{
			name: "Cargo",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => (
				<div className="">
					{filterDealer
						? row.role === filterRole
							? row.role
							: null
						: row.role}
				</div>
			),
		},
		{
			name: "Loja",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => (
				<div className="">
					<span>
						<strong>{row.companyName}</strong>
					</span>
				</div>
			),
		},
		{
			name: "Ações",
			sortable: false,
			center: true,
			width: "170px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						to={`/salespeople/${page}/edit/` + row.id}
						className="btn btn-info"
						id="btnGroupDrop1"
					>
						Editar
					</Link>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>

					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<button
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deleteSalespeople"
							onClick={() => setDeleteId(row.id)}
						>
							Excluir
						</button>
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		setLoadQuery(true);
	}, [page, query, filterData]);

	useEffect(() => {
		localStorage.getItem("createDealer") === "true" &&
			setSuccs(
				"Vendedor cadastrado com sucesso! Por favor, solicite ao vendedor que efetue o login/logout pelo aplicativo!"
			);
		localStorage.getItem("updateDealer") === "true" &&
			setSuccs(
				"Cadastro atualizado com sucesso! Por favor, solicite ao vendedor que efetue o login/logout pelo aplicativo!"
			);
		localStorage.getItem("deleteDealer") === "true" &&
			setSuccs(
				"Vendedor excluído com sucesso! Por favor, solicite ao vendedor que efetue o logout pelo aplicativo!"
			);
		setTimeout(() => {
			localStorage.removeItem("createDealer");
			localStorage.removeItem("updateDealer");
			localStorage.removeItem("deleteDealer");
		}, 4000);
	}, [dealersList, messageToast]);

	const importSelles = (e) => {
		var formData = new FormData();
		formData.append("file", e.target.files[0]);
		this.setState({ loading: true });
		APIV2.post("management/marketing/dealers/import", formData, {
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(async (response) => {
				this.setState({
					show: true,
					title: "Sucesso!",
					message:
						"Estamos importando seus produtos em segundo plano. Em breve eles estarão disponíveis no painel.",
					loading: false,
				});
				this.forceUpdate();
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					show: true,
					loading: false,
					title: "Erro!",
					message:
						"Infelizmente tivemos um problema, tente novamente ou contate o suporte.",
				});
				this.forceUpdate();
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const deleteDealer = () => {
		console.log(deleteId);
		APIV2.delete(`management/marketing/dealers/${deleteId}`)
			.then(async (response) => {
				window.location.reload();
				localStorage.setItem("deleteDealer", true);
				//toast.error('Vendedor deletado com sucesso! 😃');
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const search = (query) => {
		setQuery(query);
	};

	const filter = async (val) => {
		console.log(val);
		setFilterData(val);
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="40" y="25" rx="3" ry="3" width="60" height="10" />
				<rect x="250" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="550" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="800" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	const closeToast = () => {
		console.log("close");
	};

	return (
		<div className="container dash-content">
			<Toast success={succs} closeToast={closeToast} dealer={true} />
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Vendedores</h4>
					<div className="d-flex flex-column flex-lg-row justify-content-end">
						<button
							className="btn btn-info light btn-icon justify-content-center m-1"
							style={{ width: "190px" }}
						>
							<input
								type="file"
								onChange={importSelles}
								style={{
									opacity: 0,
									position: "absolute",
									zIndex: 1,
									cursor: "pointer",
								}}
							/>
							<i className="material-icons-outlined">upload</i>
							&nbsp;&nbsp;Importar Vendedores
						</button>
						<button
							className="btn btn-primary m-1"
							onClick={() =>
								(window.location.href = `/salespeople/${page}/new`)
							}
							style={{ width: "190px" }}
						>
							Novo Vendedor
						</button>
					</div>
				</div>
				<FilterDealers
					searchFilter={filter}
					search={search}
					loadQuery={loadQuery}
					dealers={dealers.length}
				/>
				<div className="card-content">
					<div className="card-body no-padding">
						{loading ? (
							skeleton()
						) : (
							<>
								{dealersList.length <= 0 ? (
									<Empty
										img={"/images/empty_dealer.svg"}
										onChange={importSelles}
										genre="seu primeiro"
										name="vendedor"
										link={`/salespeople/${page}/new`}
										importIn={true}
									/>
								) : roleClient === "2" ? (
									<List
										data={dealersList}
										colums={columsList}
										paginationServer={false}
									/>
								) : (
									<List
										data={dealersList}
										colums={columListRetailer}
										paginationServer={false}
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			<Modal
				id={"deleteSalespeople"}
				title={"Tem certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteDealer}
			/>
		</div>
	);
}
