import React, { useEffect, useState } from 'react';

import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Breadcrumbs from '../components/layout/Breadcrumb';

// import { Container } from './styles';

function Painel(props) {
  const [params, setParams] = useState('')

  useEffect(() => {
    if(window.location.href.split('?')[1]) {
      localStorage.setItem('PARAMS', window.location.href.split('?')[1])
      setParams(window.location.href)
    }

    //console.log(window.location.href.split('?')[1])
    //setParams(window.location.href.split('?')[1])
  }, [window.location.href])

  return (
    <>
    <Header />
    <Breadcrumbs crumbs={props.crumbs} queryParams={params} />
      {props.children}
    <Footer />
    </>
  );
}

export default Painel;