import React, { useEffect, useState } from 'react';
import { API } from '../../../API';
import EditorHtml from '../../../assets/EditorHtml';
import Notifications, { notify } from 'react-notify-toast';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading'
const qs = require('querystring');

function PrivacyPolicy(props) {
	const [loading, setLoading] = useState(true)
	const [content, setContent] = useState('')

	useEffect(() => {
		// Consulta materiais disponíveis a partir do token do usuário.
		API.get('api/privacypolicy/')
			.then(response => {
				// If request is good...
				if (response.data === null) {
					setLoading(false)
				} else {
					setContent(response.data.content)
					setLoading(false)
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			})
	}, [])


	const handleChange = (e) => {
		setContent(e)
	}

	const onSend = () => {
		setLoading(true)
		var body = { content: content }


		API.put('api/privacypolicy/', body)
			.then(async response => {
				setLoading(false)
				toast.success('As poliíticas de privacidade foram atualizadas com sucesso! 😃');
			})
			.catch((error) => {
				console.log(error)
				setLoading(false)
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
			})
	}

	const renderScreen = () => {
		return (
			<div className="card" >
				<div className="card-header d-flex align-items-center">
					<h4 className="card-title">Política de Privacidade</h4>
				</div>
				<div className="card-content">
					<div className="card-body pt-0">
						<EditorHtml data={content} change={handleChange} />
						<div className="d-flex justify-content-between mt-3">
							<button className='btn btn-light btn-icon-l outline'
								data-container="body"
								data-toggle="popover"
								title="Não esqueça"
								data-placement="top"
								data-content="Valide o conteúdo previamente junto ao jurídico de sua empresa."
								onClick={() => window.open('https://loriprd.blob.core.windows.net/lori-static/f8b7bfb4-c62a-48d1-ae57-d53d03d3b326.docx')}>
								<i className="material-icons"
									style={{ fontSize: '24', float: 'left', marginRight: '10px' }}>
									vertical_align_bottom
								</i>
								Fazer download do modelo
							</button>
							<button className='btn btn-success' style={{ width: '150px' }} onClick={onSend}>Salvar</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			{loading ? <Loading /> : renderScreen()}
		</>
	)

}

export default PrivacyPolicy;
