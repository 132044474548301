import React, { Component } from 'react';


export class Footer extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <footer className="container">
                    <div className="row">
                        <div className="col text-left">
                            2020 - Neomode
                        </div>
                        <div className="col text-right">
                            Feito com 💜 por Neomode
                        </div>
                    </div>
            </footer>


        )
    }
};

export default Footer;
