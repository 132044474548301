import React, { useEffect, useState } from 'react';
import moment from 'moment'
import ContentLoader from 'react-content-loader';
import Axios from 'axios';
import { API, APIV2 } from '../../../API';

// import { Container } from './styles';

export default function Suggestion(props) {

  const [loading, setLoading] = useState(true)
  const [quantity, setQuantity] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)

  useEffect(() => {
    getSuggestions()
    /* setInterval(async () => {
        getSuggestion()
    }, 60000); */
  }, [props.reload])

  /* Suggestions
  denied
  accepted */

  const getSuggestions = () => {
    APIV2.get(`vtex/marketplace/sku/sugestions?sellerId=${localStorage.getItem('client_id')}&status=Denied`)
        .then(async response => {
            setLoading(false)
            await setQuantity(response.data.quantity)
            await setFileUrl(response.data.fileUrl)
            //console.log(response.data.quantity)
            //console.log(response.data.fileUrl)
        })
  }

  const skeleton = () => {
    const skele = [0, 1]
    return (

      skele.map(() =>
        // <div style={{overflow: 'hidden'}}>
        <ContentLoader
          speed={2}
          width={1100}
          height={70}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 1100 100"
          style={{ width: '100%' }}

        >
          <rect x="150" y="20" rx="3" ry="3" width="200" height="20" />
          <rect x="150" y="51" rx="3" ry="3" width="80" height="6" />
          <rect x="900" y="20" rx="3" ry="3" width="1500" height="20" />
          <rect x="1040" y="51" rx="3" ry="3" width="59" height="8" />
          <circle cx="70" cy="51" r="50" />
        </ContentLoader>
        // </div>
      )

    )
  }



  const renderScreen = () => {
    return (
      <>
          <div className="row mb-2">
            <div className="col-12">
              <div className="row">
                <div className="col-1">
                  <div className="rounded-circle d-flex align-items-center justify-content-center" style={{backgroundColor: '#FF5B5C', width: '40px', height: '40px'}}>
                    <i className="material-icons" style={{ color: '#FFFFFF', fontSize: '24px' }}>error_outline</i>
                  </div>
                </div>
                <div className="col-6 ml-3">
                  <div>
                    <div className="mb-3">
                      <h2 className="card-title" style={{ fontSize: '14px' }}>Produtos em Sugestão Recusados</h2>
                    </div>
                    <a className="suggestions" href={fileUrl} target="_blank"><i className="material-icons pr-2" style={{ color: '#747E8B;', fontSize: '20px' }}>get_app</i>Baixar Planilha</a>
                  </div>
                  
                </div>
                <div className="col-4">
                  <h2 className="card-title text-right" style={{ fontSize: '14px', color: '#FF5B5C' }}>{`${quantity} produtos`}</h2>
                  <h6 className="card-subtitle mb-2 text-muted text-right" style={{ fontSize: '12px' }}>{`recusados`}</h6>
                </div>
              </div>
            </div>
          </div>
      </>
    )
  }

  const noIntegration = () => {
    return (

      <div className=" align-items-center py-5 empty">
        <div className="text-center">
          <p style={{ color: '#C4C4C4', fontSize: '14px' }}> Você não possui itens negados no momento.</p>
        </div>
      </div>
    )
  }


  return (
    <>
      <div className="card" style={{ height: '350px' }}>
        <div className="card-body">
          {localStorage.getItem('role') !== '1' &&
            <>
              <h2 className="card-title" style={{ fontSize: '18px' }}>Status da Sugestão</h2>
              <h6 className="card-subtitle mb-3 text-muted mb-2" style={{ fontSize: '14px' }}>Último Status de Sugestão de Produtos</h6>
            </>
          }
          {loading ? skeleton() :
            quantity > 0 && loading === false ?
              renderScreen() : noIntegration()}
        </div>
      </div>
    </>
  );
}

