import React, { Component } from 'react';
import ButtonPrimary from '../../assets/ButtonPrimary';

export class Confirm extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  componentWillMount() {
   
  }
  render() {
    return (
        <div className="welcome d-flex align-items-center justify-content-center">
          <div className="col-xl-2 col-lg-4 col-md-6 align-self-center justify-content-end lori confirm">
            <img src="/images/lori-done.png" alt="Lori"/>
          </div>
          <div className="col-5 text-align-center">
            <h1>As etapas do Setup foram <br/>completadas!</h1>
            <p>A nossa equipe irá coletar as informações e disponibilizar o seu aplicativo nas APP Stores, assim que estiver tudo ok enviaremos um e-mail para você com os links para download.<br/>
            <br/>
O próximo passo será as configurações das suas integrações… Mas deixa isso com a gente, em breve iremos entrar em contato para coletar os dados e realizar as integrações dos seus sistemas :)
              </p>
              <br/>
              {/* Botão primario presente em assets */}
              {/* <ButtonPrimary title='Ir para o painel' icon='arrow_forward' link={'/dashboard'}/> */}
          </div>
        </div>

    )
  }
};

export default Confirm;
