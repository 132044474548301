import React, { Component } from 'react';
import {API} from '../../../API';
import { connect } from 'react-redux'
import appActions from '../../../appActions';
import Empty from './Empty';
import List from './List';
import Loading from '../../../components/Loading';
import StoresList from '../../Stores/StoresList'


export class Stores extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    lojas: [],
    loading:true,
  }
  componentWillMount() {

    if (!this.props.match.params.page) {
      window.location.href = `/setup/stores/1`

  }
   
    var clientId = localStorage.getItem('client_id');
    //Consulta materiais disponíveis a partir do token do usuário.
    API.get('api/autosetup/sellers')
      .then(async response => {
        // If request is good...
        await this.setState({
          lojas: response.data,
          loading:false,
        })
        this.forceUpdate();
        this.setNextRoute();

      })
      .catch((error) => {
        console.log(error)
      })
  }
  componentDidMount() {
    this.setNextRoute();
  }
  async setNextRoute() {
    if (this.state.lojas.length == 0) {
      var required = true
    }
    else {
      var required = false
    }
    //Passa para o Redux o proximo passo do autosetup
    this.props.dispatch(appActions.setNextRoute('/setup/content/terms-of-use', required));
    this.props.dispatch(appActions.sendData(null, null, null, 'no-content', 'api/autosetup/email/stepsellercomplete'))
  }
  render() {
    if (this.state.loading) {
      return (
        <Loading />
      )
    }
    else {
      return (
        this.renderScreen()
      )
    }

  }

  renderScreen() {
    return (
        <div className="stores">
          <div className="container sm">
            <h1 className="text-center" >Seus pontos de venda</h1>
            <p className="text-center"> Aqui você pode inserir quais os pontos de venda estarão disponíveis nos seus canais de venda</p>
          </div>
          <div className="container my-5">
            {this.state.lojas.length == 0 ?
              <Empty /> :
              <StoresList page={parseInt(this.props.match.params.page)} isAutoSetup={true}/>
            }
          </div>
        </div>
    )
  }
};

export default connect(store => ({ text: store.text, req: store.req }))(Stores);
