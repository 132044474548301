import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import Axios from "axios";
import { APIV2 } from "../../../../API";

// import { Container } from './styles';

export const MatcherProviders = createContext({});

export default function MatcherContext(props) {
	const [blobStatus, setBlobStatus] = useState([]);
	const [status, setStatus] = useState([]);
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");
	const [loadingDownload, setLoadingDownload] = useState(false);

	useEffect(() => {
		setStatus(blobStatus);
	}, [blobStatus]);

	const getStatus = async () => {
		let arrayList = [];
		const response = await APIV2.get(
			"import/importHistoric?page=1&perPage=5&onlyCatalogMatcher=true"
		);

		if (response.status == 502) {
			// Status 502 is a connection timeout error,
			// may happen when the connection was pending for too long,
			// and the remote server or a proxy closed it
			// let's reconnect
			await getStatus();
		} else if (response.status != 200) {
			// Reconnect in one second
			await new Promise((resolve) => setTimeout(resolve, 5000));
			await getStatus();
		} else {
			console.log("Entrou no getStatus");
			// Call subscribe() again to get the next message
			arrayList = await Promise.all(
				response.data.historics.map(async (file) => {
					try {
						const resp = await Axios.get(file.urlStatus);
						return { data: resp.data, id: file.id, file: file };
					} catch (error) {
						console.log(error);
						return { data: {}, id: file.id, file: file };
					}
				})
			);
			setBlobStatus(arrayList);
			setLoading(false);
			if (
				arrayList.some(
					(item) => item.data.ProgressStatus !== 100 && !item.data.message
				)
			) {
				await new Promise((resolve) => setTimeout(resolve, 2500));
				await getStatus();
			} else {
				return;
			}
		}
	};

	return (
		<MatcherProviders.Provider
			value={{
				getStatus,
				blobStatus,
				setBlobStatus,
				status,
				setStatus,
				loading,
				setLoading,
				success,
				setSuccess,
				error,
				setError,
				loadingDownload,
				setLoadingDownload,
			}}
		>
			{props.children}
		</MatcherProviders.Provider>
	);
}

export const useMatcher = () => useContext(MatcherProviders);
