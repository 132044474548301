import React, { Component } from 'react';

import { API } from '../../../API';

import PreviewAppBar from '../blocks/PreviewAppBar';
import PreviewAppBottomNavigationTutorial from '../blocks/PreviewAppBottomNavigationTutorial';
import PreviewAppTutorialTitle from '../blocks/PreviewAppTutorialTitle';
import PreviewAppTutorialContent from '../blocks/PreviewAppTutorialContent';



export default function PrevTutorial(props) {
  return (
    <>
      <PreviewAppBar logo={true} />
      <div className="app-content">
        <PreviewAppTutorialTitle title={props.itens.title} icon={props.itens.iconName} />
        <PreviewAppTutorialContent content={props.itens.content} />
      </div>
      <PreviewAppBottomNavigationTutorial />
    </>
  )
};
