import React, { Component } from "react";

export class CheckBoxList extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.state = {
			list: [],
		};
	}
	onChange(i) {
		//console.log(i);
		this.props.change(i);
	}
	componentWillMount() {
		this.setState({ list: [] });
		this.setState({ list: this.props.list });
		//console.log(this.props.list);
	}
	componentWillReceiveProps() {
		this.setState({ list: this.props.list });
	}
	render() {
		return (
			<ul>
				{this.state.list != null
					? this.state.list.map((item) => (
							<li className="list-group-item" key={item.id}>
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id={item.id}
										onChange={() => this.onChange(item)}
									/>
									<label className="custom-control-label" for={item.id}>
										{item.name}
									</label>
								</div>
							</li>
					  ))
					: null}
			</ul>
		);
	}
}

export default CheckBoxList;
