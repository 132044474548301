
import * as axios from 'axios';
import { notify } from 'react-notify-toast';
import { ToastContainer, toast } from 'react-toastify';
require('dotenv/config');


//Temos duas APIs, por isso fazemos duas vezes as validaçoes, e criamos esse componente global para as requisiçoes.
let API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT, //setamos a API do ambiente pelo env.
  responseType: "json"
});



API.interceptors.request.use(async function (config) {
  const token = localStorage.getItem('token'); //pegamos o token ativo no localstorage
  const role = localStorage.getItem('role');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  if (!role) {
    localStorage.clear()
  }
  return config;
});

API.interceptors.response.use((response) => response, (error) => {
    // whatever you want to do with the error

    //verificamos se o usuario nao caiu em 401 (Sem autorizaçao)
    if (error.response.status === 401) {



        //Sem autorizacao damos uma mensagem e redirecionamos o usuario para o login, limpando o Storage de token.
        if (window.location.pathname !== "/login" || window.location.pathname !== "/") {
            // setTimeout(
                //     function () {
                    localStorage.removeItem('token')
                    window.location.href = "/login/" + btoa(window.location.pathname)
                    toast.error('Sua sessão expirou, estamos redirecionando para tela de Login. 😃');
            //     }
            //         .bind(this),
            //     1000
            // )

    }
  }
  throw error;
});

//Igual a parte de cima, porém com o endpoint da V2

let APIV2 = axios.create({
  baseURL: process.env.REACT_APP_APIV2_ENDPOINT,
  responseType: "json"
});
APIV2.interceptors.request.use(async function (config) {
  const token = localStorage.getItem('token');
  config.baseURL = await APIV2ServerUrl();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

APIV2.interceptors.response.use((response) => response, (error) => {


  if (error.response.status === 401) {

        if (window.location.pathname !== "/login" || window.location.pathname !== "/") {
            // setTimeout(
                //     function () {
                    localStorage.removeItem('token')
                    window.location.href = "/login/" + btoa(window.location.pathname)
                    toast.error('Sua sessão expirou, estamos redirecionando para tela de Login. 😃');
            //     }
            //         .bind(this),
            //     1000
            // )
    }
  }
  throw error;
});

const APIV2ServerUrl = () => {
  if(localStorage.getItem('UseCatalogProcessor') !== 'true' || !localStorage.getItem('token')) {
    return process.env.REACT_APP_APIV2_ENDPOINT
  } else {
    return process.env.REACT_APP_APIV2_ENDPOINT_CATALOG_PROCESSOR
  }
}


export {
  API,
  APIV2,
  APIV2ServerUrl
};
