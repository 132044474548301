import React, { Component, useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import { API, APIV2 } from "../../../../API";
import { ToastContainer, toast } from "react-toastify";
import { useConfig } from "../../../../providers/ConfigProvider";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom";

export default function Create(props) {
	const { managerConfigs } = useConfig();
	const [sellers, setSellers] = useState([]);
	const [quantity, setQuantity] = useState("");
	const [sellerId, setSellerId] = useState("");
	const [useNewCatalog, setUseNewCatalog] = useState("");
	const [loading, setLoading] = useState(true);
	const [onSave, setOnSave] = useState(false);

	useEffect(() => {
		setUseNewCatalog(localStorage.getItem("UseCatalogProcessor"));
		getSellers();
	}, []);

	const getSellers = async () => {
		setLoading(true);
		try {
			const response = await APIV2.get(
				"management/retailer/autocomplete/sellers"
			);
			setSellers(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
			setLoading(false);
		}
	};

	const changeSelect = (e) => {
		let value = e.target.value;
		setSellerId(value);
	};

	const handleChange = (e) => {
		setQuantity(e.target.value);
	};

	const create = (e) => {
		if (sellerId === "null" || !sellerId) {
			setOnSave(true);
			return;
		}
		if (!quantity) {
			setOnSave(true);
			return;
		}
		var button = document.getElementById("submit");
		button.setAttribute("disabled", "disabled");
		var body = props.edit
			? {
					quantity: parseInt(quantity),
					sellerId: !sellerId ? "" : sellerId,
					skuId: props.skuId,
					id: props.item.id,
			  }
			: {
					quantity: parseInt(quantity),
					sellerId: !sellerId ? "" : sellerId,
					skuId: props.skuId,
			  };

		var bodyCatalog = props.edit
			? {
					quantity: parseInt(quantity),
					sellerCode: !sellerId ? "" : sellerId,
					skuId: props.skuId,
					id: props.item.id,
			  }
			: {
					quantity: parseInt(quantity),
					sellerCode: !sellerId ? "" : sellerId,
					skuId: props.skuId,
			  };
		props.create(useNewCatalog === "true" ? bodyCatalog : body);
	};

	const renderScreen = () => (
		<>
			<div className="row">
				<div className="col-12 mb-4">
					{localStorage.getItem("role") === "8" ? (
						<>
							Caso a loja desejada não esteja entre as opções listadas, entre em
							contato com nosso time de suporte.
						</>
					) : (
						<>
							Selecione abaixo uma loja para alterar ou adicionar um estoque do
							produto. Para que um ponto de vendas apareça na listagem, é
							necessário que o id da loja esteja configurado na opção
							"Informações Básicas", que pode ser acessada{" "}
							<Link className="link" to="/stores/1">
								aqui
							</Link>{" "}
							e, em seguida, clicando em "editar".
							{/* Selecione abaixo uma loja para alterar ou adicionar um estoque do produto. Para que um ponto de vendas apareça na listagem, é necessário que o id da loja esteja configurado no <Link className="link" to="/stores/1">Cadastro de Lojas */}
						</>
					)}
				</div>
				<div
					className={
						onSave && !quantity
							? "form-group icon-right-error-select col-8"
							: "form-group col-8"
					}
				>
					<label htmlFor="seller" className="font-weight-bold">
						Ponto de venda{" "}
					</label>
					<select
						className={
							onSave && !sellerId ? "custom-select is-invalid" : "custom-select"
						}
						id="seller"
						value={sellerId}
						onChange={(e) => changeSelect(e)}
					>
						<option value="">Selecione um ponto de venda</option>
						{sellers.map((i, index) =>
							useNewCatalog === "true" && i.pointSaleId ? (
								<option
									value={useNewCatalog === "true" ? `${i.pointSaleId}` : i.id}
									key={index}
								>
									{i.fullName}
								</option>
							) : (
								(useNewCatalog === "false" || useNewCatalog === "null") && (
									<option
										value={useNewCatalog === "true" ? `${i.pointSaleId}` : i.id}
										key={index}
									>
										{i.fullName}
									</option>
								)
							)
						)}
					</select>
					{onSave && !sellerId && (
						<>
							<i className="material-icons">error_outline</i>
							<small className="text-danger">
								Selecione pelo menos uma loja
							</small>
						</>
					)}
				</div>
				<div
					className={
						onSave && !quantity
							? "form-group icon-right-error col-4"
							: "form-group col-4"
					}
				>
					<label htmlFor="quantity" className="font-weight-bold">
						Quantidade{" "}
					</label>
					<input
						type="number"
						className={
							onSave && !quantity ? "form-control is-invalid" : "form-control"
						}
						id="quantity"
						name="quantity"
						value={quantity}
						onChange={handleChange}
					/>
					{onSave && !quantity && (
						<>
							<i className="material-icons">error_outline</i>
							<small className="text-danger">Campo não preenchido</small>
						</>
					)}
				</div>

				<dis className="col-12 d-flex justify-content-end">
					<Button
						className="btn btn-success mr-2"
						type="submit"
						id="submit"
						onClick={() => create()}
					>
						{props.edit ? "Editar estoque" : "Criar estoque"}
					</Button>
					<Button
						onClick={
							props.edit ? () => props.changeEdit() : () => props.change()
						}
						className="btn-light"
					>
						Cancelar
					</Button>
				</dis>
			</div>
		</>
	);

	return (
		<>
			<div className="container">
				<div className="card-header pl-0">
					<h4 className="card-title">
						{props.name
							? `Novo estoque para ${props.name}`
							: "Novo estoque para ..."}
					</h4>
				</div>
				{loading ? <Loading /> : renderScreen()}
			</div>
		</>
	);
}
