import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default class EditorHtml extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      //link do botao
      <CKEditor
        editor={ClassicEditor}
        className='editor'
        height='200'
        style={{height: '100px'}}
        data={this.props.data != null || this.props.data != undefined ? this.props.data : ''} //valor se ja tiver conteudo
        onInit={editor => {
          // You can store the "editor" and use when it is needed.
       
        }}

        onChange={(event, editor) => {
          const data = editor.getData();
       
          this.props.change(data)
        
        }}

        onBlur={(event, editor) => {
         
        }}
        onFocus={(event, editor) => {
         
        }}
      />

    );
  }
}
