import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API, APIV2 } from '../../../../API';

// import { Container } from './styles';

export const CategoryContext = createContext({})



export default function CategoriesContext(props) {
  const [openForm, setOpenForm] = useState(false)
  const [loading, setLoading] = useState(true)
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [openDeleteModal, setOpenModal] = useState(false)
  const [isSubCategory, setIsSubCategory] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')


  useEffect(() => {
    getCategoryList()
  }, [])
  
  useEffect(() => {
    console.log(openDeleteModal)
  }, [openDeleteModal])

  const getCategoryList = () => {
    API.get('/api/categories/getlist')
      .then(async response => {
        setCategoryList(response.data)
        console.log(response.data)
        setLoading(false)
        // setSuccess('Carregado lista de categorias')
      })
      .catch((error) => {
        console.log(error)
        setError('Parece que tivemos um erro na listagem das categorias...');
      })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const editCategory = (category) => {
    setIsSubCategory(false)
    setSelectedCategory(category)
    setOpenForm(true)
  }

  const cancelCategory = () => {
    setOpenForm(false)
    setSelectedCategory({})
  }

  const openDelete = () => {
    setOpenModal(true)
  }

  const createSonCategory = (category) => {
    setIsSubCategory(true)
    setSelectedCategory(category)
    setOpenForm(true) 
  }

  const deleteCategory = async (category) => {
    try {
      await APIV2.delete(`management/catalog/categories/${category.id}`)
      setSuccess("Categoria Removida com sucesso")
      getCategoryList()
      cancelCategory()
    } catch (error) {
      setError('Não foi possível apagar a categoria')
    }
  }

  return (
    <CategoryContext.Provider value={{
      openForm,
      categoryList,
      loading,
      selectedCategory,
      openDeleteModal,
      isSubCategory,
      success,
      error,
      setOpenModal,
      getCategoryList,
      setOpenForm,
      setCategoryList,
      editCategory,
      reorder,
      cancelCategory,
      openDelete,
      deleteCategory,
      createSonCategory,
      setSuccess,
      setError
    }}>
      {props.children}
    </CategoryContext.Provider>
  );
}


export const useCategory = () => useContext(CategoryContext);


