import React, { Component } from 'react';

class PreviewAppTutorialTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: localStorage.getItem('primaryColor'),
        }
    }
    render() {
        return (
            <div className="carousel slide app-carousel-banner" data-ride="carousel" style={{ backgroundColor: this.state.backgroundColor }}>
                <div className="tutorial-title">
                    <span class="material-icons">{this.props.icon}</span>
                    <br></br>
                    {this.props.title}
                </div>
            </div>
        )
    }
};

export default PreviewAppTutorialTitle;
