import React, { Component } from 'react';

require('dotenv/config');

export class Success extends Component {

    constructor(props) {
        super(props)
        this.state = {
            nome: ''
        }
    }

    changeDev() {
        this.props.dev();

    }
    componentWillMount() {


    }
    render() {

        return (
            <div className="initial">
                <div className="top-login">
                    <div className="row">
                        <div className="col ajuda">
                            <a href="https://lori.neomode.com.br/"> Voltar para tela de login</a>
                        </div>

                    </div>
                </div>
                <br />
                <h3 className="success">
                    <i className="material-icons">
                        check_circle_outline</i>
                        <br/>
                    Pronto</h3>
                    <br />
                <p>Enviamos um e-mail para você contendo as instruções para recuperar sua senha, verifique sua caixa de entrada 😉</p>
                <div className="">
                    <button type="button" className="btn btn-success" onClick={() => window.location.href = 'https://lori.neomode.com.br/'}>Ir para o login</button>
                </div>
                <div className="footer-login">
                    <div className="row">
                        <div className="col ajuda">
                            <a href="https://neomode.zendesk.com/" target="_blank">Ajuda</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};


export default Success;