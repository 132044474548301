import React, { useEffect, useState } from 'react';
import { useCategory } from '../providers/CategoriesContext';

// import { Container } from './styles';

function DeleteCategory() {
  const { selectedCategory, deleteCategory } = useCategory()
  const {category, setCategory} = useState({})
  return (
    <div class="modal fade" id="deleteCategory" tabindex="-1" aria-labelledby="deleteCategoryLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" style={{ minWidth: '567px' }}>
        <div class="modal-content" style={{ minWidth: '567px ', borderRadius: "8px" }}>
          <div class="modal-header">
            <h5 class="modal-title">Deseja Excluir a Categoria?</h5>
          </div>
          <div class="modal-body">
            <p style={{ color: '#747E8B', fontSize: "14px" }}>Atenção! Esta ação não poderá ser revertida e <strong>todas as informações contidas na categoria, assim como suas subcategorias, serão apagadas</strong></p>
            <p style={{ color: '#747E8B', fontSize: "12px" }}><strong>Nome da Categoria:</strong> {selectedCategory.name}</p>
            {selectedCategory.id && selectedCategory.children.length >  0 && <>
              <p style={{ color: '#747E8B', fontSize: "12px" }}><strong>SubCategoria(s):</strong></p>
              {selectedCategory.children.map(child =>
                <p style={{ color: '#747E8B', fontSize: "12px" }}>{child.name}</p>
              )}
            </>}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" style={{ color: '#FF5B5C', width: '150px' }} onClick={() => deleteCategory(selectedCategory)}>Sim, Excluir</button>
            <button type="button" class="btn" data-dismiss="modal" style={{ width: '150px' }}>Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteCategory;