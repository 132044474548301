import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';
import { APIV2 } from '../../../../API';
import Loading from '../../../../components/Loading';
import { CategoryContext, useCategory } from '../providers/CategoriesContext';
import DeleteCategory from './DeleteCategory';
import SonCategories from './SonCategories';

// import { Container } from './styles';

function CategoriesList() {

  const { categoryList, setCategoryList, reorder, loading, editCategory, selectedCategory, createSonCategory } = useCategory()

  const onDragEnd = async (result) => {
    console.log(result)
    if (!result.destination && !result.combine) return;
    if (!result.combine && result.destination.draggableId === result.source.droppableId && result.destination.index === result.source.index) {
      return; // dropped in same location
    }
    try {
      var body = {
        "order": result.destination.index + 1,
        "parentId": result.type === "sonCategory" ? result.destination.droppableId : null
      }
      APIV2.patch(`management/catalog/categories/${result.draggableId}/order`, body)
      toast.success("Lista reordenada")
      
    } catch (error) {
      toast.error("Não foi possível reordenada sua lista ")
    }
    if (result.type === "droppableItem") {
      if (result.destination) {
        const newOrder = reorder(
          categoryList,
          result.source.index,
          result.destination.index
        );
        setCategoryList(newOrder)
      }
    } else if (result.type === "sonCategory") {
      const itemSubItemMap = categoryList.reduce((acc, item) => {
        acc[item.id] = item.children;
        return acc;
      }, {});
      const sourceParentId = result.source ? result.source.droppableId : null;
      const destParentId = result.destination ? result.destination.droppableId : null;
      const combParentId = result.combine ? result.combine.droppableId : null;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...categoryList];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          result.source.index,
          result.destination.index
        );
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item.children = reorderedSubItems;
          }
          return item;
        });
        setCategoryList(newItems)
      }
      if (sourceParentId !== destParentId) {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(result.source.index, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(result.destination.index, 0, draggedItem);
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item.children = newSourceSubItems;
          } else if (item.id === destParentId) {
            item.children = newDestSubItems;
          }
          return item;
        });
        setCategoryList(newItems)
      }


    }
  }

  return (
    <div className="card category-card">
      {selectedCategory &&
        <DeleteCategory />
      }
      <div class="card-body">
        <h4 className="card-title category-title">Categorias</h4>
        <p style={{ color: '#747E8B', fontSize: '14px' }}>Para criar uma nova categoria, basta clicar em <strong>“Nova Categoria”</strong> e, para adicionar uma nova categoria abaixo de uma categoria ou subcategoria, clique no (+) ao lado de cada componente. Caso queira reposicionar uma categoria, clique e arraste para a posição desejada.</p>
        <div className="row" >
          <div className="col-12">
            <button className="btn btn-block btn-primary btn-icon">
              <i style={{ fontSize: '14px', width: '30%', textAlign: 'end' }} className="material-icons mr-2">add_circle_outline</i>
            Nova Categoria Principal
            </button>
          </div>
          {loading ? <Loading /> :
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={`category`} type="category" >
                {(provided, snapshot) => (
                  <div className="col-12 mt-3"
                    style={{ minHeight: '7em' }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}>
                    {/* {index} */}
                    {categoryList.map((category, index) =>
                      <Droppable droppableId={`${category.id}`} index={index} >
                        {(provided, snapshot) => (
                          <div className="row justify-content-end"
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            <Draggable draggableId={`${category.id}`} key={category.id} index={index}>
                              {(provided, snapshot) => (
                                <div>
                                  <div className="col-12 d-flex align-items-center justify-content-end flex-fill"
                                    style={{ display: 'inline' }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <div className="category d-flex  align-items-center justify-content-between ">
                                      <span className="d-flex align-items-center  justify-content-end">
                                        <span class="material-icons mr-2">drag_handle</span>
                                        {category.name}
                                      </span>
                                      <span className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end"
                                          style={{ padding: '0', textDecoration: 'none', color: '#FFF' }}
                                          onClick={() => editCategory(category)}>
                                          <span class="material-icons mr-2">edit</span>
                                        </button>
                                        {category.children.length > 0 &&
                                          <button className="btn btn-link btn-outline btn-sm d-flex align-items-center justify-content-end" data-toggle="collapse" href={`#categoryColapse${category.id}`} role="button" aria-expanded="false" aria-controls={`categoryColapse${category.id}`} style={{ padding: '0', textDecoration: 'none', color: '#FFF' }}>
                                            <span class="material-icons mr-2">expand_less</span>
                                          </button>
                                        }
                                      </span>
                                    </div>
                                    <button type="button" className="add-subcategory d-flex align-items-center justify-content-center ml-2" onClick={() => createSonCategory(category)}>+</button>
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                            <SonCategories category={category} />
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          }
        </div>
      </div>
    </div>
  );
}

export default CategoriesList;