import React, { Component, useEffect, useState } from 'react';
import { APIV2 } from '../../../../API'
import SearchList from "./SearchList"
import Create from './Create'
import { toast } from 'react-toastify';
import { useConfig } from '../../../../providers/ConfigProvider';


export default function Index(props) {
	const { managerConfigs } = useConfig()
	const [stock, setStock] = useState([]);
	const [create, setCreate] = useState(false);
	const [item, setItem] = useState(null)
	const [edit, setEdit] = useState(false)
	const [loadingStock, setLoadingStock] = useState(true)
	const [name, setName] = useState(null)
	const [id, setId] = useState(null)
	const [code, setCode] = useState(null)
	const [imageUrl, setImageUrl] = useState(null)
	const [isActive, setIsActive] = useState(false)
	const [barcode, setBarcode] = useState(null)


	useEffect(() => {
		APIV2.get('management/catalog/products/' + props.match.params.productId + '/skus/' + props.match.params.skuId)
			.then(async response => {
				setId(response.data.id)
				setCode(response.data.code)
				setImageUrl(response.data.imageUrl)
				setIsActive(response.data.isActive)
				setName(response.data.name)
				setBarcode(response.data.barcode)
				getStock()
			})
			.catch((error) => {
				console.log(error)
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.')
			})
	}, [])



	const changeMode = (e) => {
		setCreate(!create)
	}

	const changeEdit = (e) => {
		setEdit(!edit)
	}

	const getStock = () => {
		APIV2.get('management/catalog/products/' + props.match.params.productId + '/skus/' + props.match.params.skuId + '/stocks')
			.then(async response => {
				setStock(response.data)
				setLoadingStock(false)
			})
			.catch((error) => {
				console.log(error)
				toast.error('Parece que tivemos um erro... Tente novamente mais tarde.')
			})
	}


	const editStock = (e) => {
		APIV2.put(
			!e.id ?
				`management/catalog/products/${props.match.params.productId}/skus/${props.match.params.skuId}/stocks?sellerCode=${e.sellerCode}` :
				'management/catalog/products/' + props.match.params.productId + '/skus/' + props.match.params.skuId + '/stocks/' + e.id,
			e)
			.then(() => {
				setCreate(false)
				getStock()
				toast.success('Estoque editado com sucesso!😃')
			})
			.catch((error) => {
				setCreate(false)
				console.log(error)
				if (!e.quantity) {
					toast.error('Quantidade não foi preenchido.')
				} else {
					toast.error('Parece que tivemos um erro... Tente novamente mais tarde.')
				}
			})
	}

	const createStock = (e) => {

		APIV2.post(
			e.sellerCode ?
				`management/catalog/products/${props.match.params.productId}/skus/${props.match.params.skuId}/stocks?sellerCode=${e.sellerCode}` :
				'management/catalog/products/' + props.match.params.productId + '/skus/' + props.match.params.skuId + '/stocks',
			e)
			.then(async response => {
				setCreate(false)
				getStock()
				toast.success('Estoque criado com sucesso!😃')
			})
			.catch((error) => {
				setCreate(false)
				console.log(error)
				if (!e.quantity) {
					toast.error('Quantidade não foi preenchido.')
				} else {
					toast.error('Parece que tivemos um erro... Tente novamente mais tarde.')
				}
			})
	}

	const deleteStock = (e) => {
		setLoadingStock(true)
		APIV2.delete(!e.id ?
			`management/catalog/products/${props.match.params.productId}/skus/${props.match.params.skuId}/stocks?sellerCode=${e.sellerCode}` :
			'management/catalog/products/' + props.match.params.productId + '/skus/' + props.match.params.skuId + '/stocks/' + e.id)
			.then(async response => {
				getStock()
				toast.success('Estoque excluído com sucesso!😃')
			})
			.catch((error) => {
				console.log(error)
				toast.error('Não foi possível excluir... Tente novamente mais tarde.')
			})
	}

	const getEdit = (item) => {
		setItem(item)
		setEdit(true)
	}

	return (
		<div className="container dash-content" >
			<div className="card">
				<div className="card-content">
					<div className="card-body">
						<div className="row">
							{create || edit ?
								<Create name={name} create={createStock} change={() => changeMode()} edit={edit} item={item} changeEdit={() => changeEdit()} /> :
								<SearchList loading={loadingStock} name={name} edit={editStock} id={id} skuId={props.match.params.skuId} changeEdit={() => changeEdit()} listSeller={stock} getEdit={getEdit} placeholder={'Procure pela loja desejada'} change={() => changeMode()} delete={deleteStock} />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)

};

