import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../../../API";

export default function Cities({ newCondition }) {
	const [listCities, setListCities] = useState([]);
	const [list, setList] = useState([]);
	const [text, setText] = useState("");

	useEffect(() => {
		getAllcities();
		document.addEventListener("click", (evt) => {
			const autocomplete = document.querySelector("#autocomplete");
			let targetElement = evt.target;

			do {
				if (targetElement === autocomplete) {
					return;
				}
				targetElement = targetElement.parentNode;
			} while (targetElement);
			setListCities([]);
			if (document.querySelector("#search")) {
				document.querySelector("#search").value = "";
			}
		});
	}, [text, list]);

	const getAllcities = async () => {
		try {
			const response = await API.get(
				"api/autocomplete/states/allcities/" + text
			);
			!text.length ? setListCities([]) : setListCities(response.data);
		} catch (error) {
			console.log(error);
		}
	};

	const selectCities = (event) => {
		const component = {
			name: `${event.name} - ${event.state.code}`,
		};
		list <= 0 && setList([...list, component]);
		const listData = [...list].findIndex(
			(val) => val.name === `${event.name} - ${event.state.code}`
		);
		if (listData < 0) {
			setList([...list, component]);
		} else {
			toast.error("Cidade já foi adicionada!");
		}
	};

	const removeList = (index) => {
		setList((currentList) => currentList.filter((city, i) => i !== index));
		console.log(list);
	};

	const saveCities = () => {
		const city = list.length > 1 ? " Cidades" : " Cidade";
		let item = {
			cities: list,
			value: list,
		};
		let itemView = {
			name: "Cidades",
			value: list.length + city,
			itemId: "cities",
			modal: "#conditionModalCities",
		};
		newCondition(item, itemView);
	};

	return (
		<div
			class="modal fade"
			id="conditionModalCities"
			tabindex="-1"
			role="dialog"
			aria-labelledby="conditionModalCities"
			aria-hidden="true"
		>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title">Cidades</h3>
						<button
							type="button"
							className="close text-danger"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div
							className="form-group autocomplete icon-right"
							id="autocomplete"
						>
							<input
								type="text"
								className="search form-control"
								id="search"
								name="pesquisa"
								placeholder="Ecolha a cidade"
								autoComplete="off"
								onChange={(event) => setText(event.target.value)}
							/>
							<i className="material-icons">search</i>
							{!listCities.length ? null : (
								<div className="result">
									<ul>
										{listCities.map((city, index) => (
											<li
												key={index}
												id={city.id}
												onClick={() => selectCities(city)}
											>
												<div className="d-flex justify-content-start">
													<p
														style={{
															width: "390px",
															marginLeft: "20px",
															marginTop: "10px",
														}}
													>
														{city.name}
													</p>
												</div>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
						<div className="list">
							{list.length > 0 && (
								<div className="">
									<table className="table">
										<thead>
											<tr>
												<th scope="col">CIDADE</th>
												<th scope="col" className="text-right">
													AÇÕES
												</th>
											</tr>
										</thead>
										<tbody>
											{list.map((city, index) => (
												<tr key={index} id={city.id}>
													<td>{city.name}</td>
													<td className="text-right">
														<button
															type="button"
															className="btn btn-link btn-sm  btn-icon text-right"
															onClick={() => removeList(index)}
														>
															<i className="material-icons text-danger">
																delete_outline
															</i>
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
					<div
						className="modal-footer text-center"
						style={{ justifyContent: "center" }}
					>
						<button
							type="button"
							className="btn btn-light col-5"
							data-dismiss="modal"
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success col-5"
							data-dismiss="modal"
							onClick={saveCities}
						>
							Pronto
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
