import React, { Component } from "react";
import { toast } from "react-toastify";
import MultiDatePiker from "../../components/MultDatePiker";
import AssistantShipping from "../../components/AssistantShipping";

import moment from "moment";
import Button from "../../components/Button";

export class Index extends Component {
	constructor(props) {
		super(props);
		this.TextCopy = this.TextCopy.bind(this);
		this.save = this.save.bind(this);
		this.state = {
			themeClasses: [
				"Primary",
				"Secondary",
				"Success",
				"Danger",
				"Warning",
				"Info",
				"Light",
				"Dark",
				"Link",
			],
			date: [],
			step: "",
			loading: false,
		};
	}
	save = (step) => {
		this.setState({ step: step });
	};

	TextCopy = (id) => {
		var text = id.target.className;
		var dummy = document.createElement("textarea");
		// to avoid breaking orgain page when copying more words
		// cant copy when adding below this code
		// dummy.style.display = 'none'
		document.body.appendChild(dummy);
		//Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
		dummy.value = text;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		toast.success("Copiado!");
	};

	Loading = () => {
		console.log("teste");
		this.setState({ loading: true });
		setTimeout(() => {
			this.setState({ loading: false });
		}, 5000);
	};

	render() {
		return (
			<div className="" style={{ width: "100%" }}>
				<div className="container">
					<div className="row mt-5">
						<div className="col">
							<div className="accordion" id="accordionExample">
								<div className="card">
									<div className="card-header" id="headingOne">
										<h2 className="mb-0">
											<button
												className="btn btn-link"
												type="button"
												data-toggle="collapse"
												data-target="#buttons"
												aria-expanded="true"
												aria-controls="buttons"
											>
												Botões
											</button>
										</h2>
									</div>
									<div
										id="buttons"
										className="collapse show"
										aria-labelledby="headingOne"
										data-parent="#accordionExample"
									>
										<div className="card-body">
											<div className="mb-3">
												<Button
													className={"btn-light"}
													onClick={() => this.Loading()}
												>
													Teste Loading
												</Button>
											</div>
											<ul className="list-unstyled">
												<li>
													<h6>
														<strong>Default Button</strong>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()}`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>

																		{/* <button className={'btn btn-' + item.toLowerCase()} onClick={((e) => this.TextCopy(e))}>
                                                                            Text Button
                                                                        </button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with </strong> <code>.glow</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} glow`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' glow'} onClick={((e) => this.TextCopy(e))}>
                                                                            Text Button
                                                                        </button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with </strong> <code>.shadow</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} shadow`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' shadow'} onClick={((e) => this.TextCopy(e))}>
                                                                            Text Button
                                                                        </button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with </strong> <code>.round</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} round`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' round'} onClick={((e) => this.TextCopy(e))}>
                                                                            Text Button
                                                                        </button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with </strong> <code>.outline</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} outline`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' outline'} onClick={((e) => this.TextCopy(e))}>
                                                                            Text Button
                                                                        </button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with circle icon on left </strong>{" "}
														<code>.btn-icon-c-l</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} btn-icon-c-l`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			<i className="material-icons">
																				perm_identity
																			</i>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' btn-icon-c-l'} onClick={((e) => this.TextCopy(e))}>
                                                                            <i className="material-icons">perm_identity</i>Text Button
                                                                        </button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with circle icon on right </strong>{" "}
														<code>.btn-icon-c-r</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} btn-icon-c-r`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			<i className="material-icons">
																				perm_identity
																			</i>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' btn-icon-c-r'} onClick={((e) => this.TextCopy(e))}>
																			<i className="material-icons">perm_identity</i>Text Button
																		</button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with icon on left </strong>{" "}
														<code>.btn-icon-l</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} btn-icon-l`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			<i className="material-icons">
																				perm_identity
																			</i>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' btn-icon-l'} onClick={((e) => this.TextCopy(e))}>
																			<i className="material-icons">perm_identity</i>Text Button
																		</button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with icon on right </strong>{" "}
														<code>.btn-icon-r</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} btn-icon-r`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			<i className="material-icons">
																				perm_identity
																			</i>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' btn-icon-r'} onClick={((e) => this.TextCopy(e))}>
																			<i className="material-icons">perm_identity</i>Text Button
																		</button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with </strong> <code>.btn-sm</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} btn-sm`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' btn-sm'} onClick={((e) => this.TextCopy(e))}>
																			Text Button
																		</button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
												<li>
													<h6>
														<strong>Buttons with </strong> <code>.btn-lg</code>
													</h6>
													<div className="mb-3 row">
														<div className="col">
															{this.state.themeClasses.map((item, i) => {
																return (
																	<div
																		className="mb-3 mr-3 d-inline"
																		style={{ float: "left" }}
																	>
																		<Button
																			className={`btn-${item.toLowerCase()} btn-lg`}
																			loading={this.state.loading}
																			onClick={(e) => this.TextCopy(e)}
																		>
																			Text Button
																		</Button>
																		{/* <button className={'btn btn-' + item.toLowerCase() + ' btn-lg'} onClick={((e) => this.TextCopy(e))}>
																			Text Button
																		</button> */}
																	</div>
																);
															})}
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="card">
									<div className="card-header" id="headingTwo">
										<h2 className="mb-0">
											<button
												className="btn btn-link collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												Date
											</button>
										</h2>
									</div>
									<div
										id="collapseTwo"
										className="collapse"
										aria-labelledby="headingTwo"
										data-parent="#accordionExample"
									>
										<div className="container mb-3">
											<p>
												ISO string: {this.state.date[0]} : {this.state.date[1]}
											</p>
											<p>
												formatado:{" "}
												{moment(this.state.date[0]).format("DD/MM/YYYY")} :{" "}
												{moment(this.state.date[1]).format("DD/MM/YYYY")}
											</p>

											<MultiDatePiker
												date={(e) => {
													this.setState({ date: e });
												}}
											/>
										</div>
									</div>
								</div>
								{/* shipping progress */}
								<div className="card">
									<div className="card-header" id="headingThree">
										<h2 className="mb-3ws">
											<button
												className="btn btn-link collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												Assistente de Frete
											</button>
										</h2>
									</div>
									<div
										id="collapseThree"
										className="collapse"
										aria-labelledby="headingThree"
										data-parent="#accordionExample"
									>
										<div className="container mb-3">
											<AssistantShipping save={this.save} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Index;
