import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import IntegrationState from './components/IntegrationState'
import BlackFridayMonitoring from './components/BlackFridayMonitoring';
import CatalogCompilation from './components/CatalogCompilation';
import AllIntegrationsState from './components/AllIntegrationsState';
import LastOrders from './components/LastOrder.jsx';

require('dotenv/config');


export function Index(props) {

    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.href = '/dashboard'
        }
    }, [])

    const renderScreen = () => {
        return (
            <div className="container dash-content">
                <div className="row">
                    {/* <div className="col-3">
                            <div className="row mb-3">
                                <div className="col-12"><IntegrationState/></div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12"><IntegrationState/></div>
                            </div>
                            <div className="row">
                                <div className="col-12"><IntegrationState/></div>
                            </div>
                        </div>
                        <div className="col-4">
                            <IntegrationState/>
                        </div> */}
                    {localStorage.getItem('role') === '2' || localStorage.getItem('role') === '8' ?
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-5 mb-3">
                            <div className="card" style={{ height: '380px' }}>
                                <div className="card-body">
                                    <IntegrationState />
                                </div>
                            </div>
                        </div> : null
                    }
                    {localStorage.getItem('role') === '1' &&
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mb-3">
                            <CatalogCompilation />
                        </div>
                    }
                    {localStorage.getItem('role') === '1' &&
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mb-3">
                            <AllIntegrationsState />
                        </div>
                    }
                    {localStorage.getItem('role') === '2' || localStorage.getItem('role') === '8' ?
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-3">
                            <LastOrders />
                        </div> : null
                    }
                </div>
                <div className="row">
                    {localStorage.getItem('role') === '1' &&
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
                            <BlackFridayMonitoring />
                        </div>
                    }
                    {/* {localStorage.getItem('role') === '2' &&
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-3">
                            <LastOrders />
                        </div>
                    } */}
                    <div className="col-4">
                        {/* <IntegrationState/> */}
                    </div>
                </div>

                {/* <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center py-5">
                            <div className="text-center">
                                <h4 className="font-weight-bold">Ops, não tem nada por aqui ainda!</h4>
                                <h5>Estamos trabalhando para criar uma experiência ainda melhor com nossa plataforma, volte em alguns dias que teremos novidades para você 😉</h5>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


        )
    }


    return (
        renderScreen()
    )
};

export default Index;
