import React, { Component, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { API, APIV2 } from './API';
import Loading from './components/Loading'
const qs = require('querystring')


export default function Redirect(props) {
  const [redirectTo, setRedirectTo] = useState('')
  const [prevRoute, setPrevRoute] = useState('')
  const [token, setToken] = useState('')
  const [config, setConfig] = useState({})

  useEffect(() => {
    getParams()
  }, [])



  const getParams = () => {
    if (props.match.params.route) {
      setPrevRoute(atob(props.match.params.route))
      window.location.href = '/login/' + props.match.params.route
    }
    //recuperar dados e acesso token passados pela url.
    let params = (new URL(document.location)).searchParams;
    let clientId = params.get("client_id");
    let refreshToken = params.get("refresh_token");

    setRedirectTo(params.get("redirectTo"))

    var body = {
      "client_id": clientId,
      "client_secret": "eyJhbGcJhGTSUzI1NiIsImtpZCI6IjBiZTg5Y",
      "grant_type": "refresh_token",
      "refresh_token": refreshToken
    }

    const config = {
      headers: {
        Authorization: '*',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    API.post('connect/token', qs.stringify(body), config)
      .then(async response => {
        // If request is good...
        console.log(response)
        setToken(response.data.access_token)
        setConfig(response.data)
        // localStorage.setItem('token', response.data.access_token);
        // localStorage.setItem('client_id', clientId);
        // localStorage.setItem('config', response.data)
        setUserParams(response.data);
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const getAccess = (paramns, user) => {
    let params = (new URL(document.location)).searchParams
    let clientId = params.get("client_id");
    localStorage.setItem('token', paramns.access_token);
    localStorage.setItem('client_id', clientId);
    localStorage.setItem('configurations', JSON.stringify(paramns))

    localStorage.setItem('primaryColor', user.primaryColor);
    localStorage.setItem('secondaryColor', user.secondaryColor);
    localStorage.setItem('primaryLogo', user.logoHorizontalPrimaryImageUrl);
    localStorage.setItem('secondaryLogo', user.logoHorizontalSecondaryImageUrl);
    API.get('api/access')
      .then(res => {
        // If request is good...

        //localStorage.setItem('barColor', paramns.colorHome);
        localStorage.setItem('role', res.data[0].role);
        localStorage.setItem('retailerName', res.data[0].retailerName);
        localStorage.setItem('retailerIconUrl', res.data[0].retailerIconUrl);


        localStorage.setItem('token', paramns.access_token);
        localStorage.setItem('client_id', clientId);
        localStorage.setItem('configurations', JSON.stringify(paramns))

        localStorage.setItem('primaryColor', user.primaryColor);
        localStorage.setItem('secondaryColor', user.secondaryColor);
        localStorage.setItem('primaryLogo', user.logoHorizontalPrimaryImageUrl);
        localStorage.setItem('secondaryLogo', user.logoHorizontalSecondaryImageUrl);
        APIV2.get('management/retailer/configurations/')
							.then(async response => {

								setConfig(response.data)
								console.log(new Array(response.data))
								var configurations = config
								configurations = new Array(response.data)
								localStorage.setItem('configurations', JSON.stringify(configurations))
								localStorage.setItem('EnableUserPromotion', response.data.enableUserPromotion);
							})
              .then(() => {
                if (!params.get("redirectTo")) {
                  window.location.href = '/dashboard'
                } else {
                  window.location.href = params.get("redirectTo")
                }
              })
							.catch((error) => {
								console.log(error);
								toast.error('Parece que tivemos um erro... Tente novamente mais tarde.');
							})

      })
      .catch((error) => {
        console.log(error)
      })
  }




  const setUserParams = (paramns) => {
    let params = (new URL(document.location)).searchParams
    let clientId = params.get("client_id");
    localStorage.setItem('token', paramns.access_token);
    localStorage.setItem('client_id', clientId);
    localStorage.setItem('configurations', JSON.stringify(paramns))
    API.get('api/autosetup/retailers/')
      .then(response => {
        console.log(response.data)
        //localStorage.setItem('barColor', response.data.colorHome);
        localStorage.setItem('primaryColor', response.data.primaryColor);
        localStorage.setItem('secondaryColor', response.data.secondaryColor);
        localStorage.setItem('primaryLogo', response.data.logoHorizontalPrimaryImageUrl);
        localStorage.setItem('secondaryLogo', response.data.logoHorizontalSecondaryImageUrl);
        getAccess(paramns, response.data)

      })
      .then(() => {
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Loading />

  )
};

// redirect?redirectTo=channel-management/app/carousel-banner&client_id=condor&refresh_token=LflgZB4cHzXa5aQUMQ49OmKDDyQ2Imfw0HvvXLXHxxU

// https://hlog-app.neomode.com.br/redirect?redirectTo=products&client_id=condor&refresh_token=7QRQDETz7v-YiXSVXWJpeKjBlDbXoUlUTy_2VeS037c