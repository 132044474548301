import React from 'react';

export default function Loading() {
    return (
        <div className="container neo-loading" style={{
            width:'100%',
            height:'100%',
            margin: 'auto',
            alignItems: 'center',
            alignContent: 'center',
            display: 'flex'
        }}
        >
            <div className="loadingio-spinner-ripple-5l89yugl07g  " >
                <div className="ldio-wbg2kxit8hs">
                    <div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}
